import styled from 'styled-components'
import { Modal, Popover, Tooltip } from 'antd'

export const BoxTitle = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 24px;

  svg {
    margin-right: 16px;
  }
`

export const TextTitle = styled.h2`
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 20px;
  color: #150f0b;
`

export const LabelText = styled.div`
  font-family: 'Prompt';
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #150f0b;
  padding-bottom: 8px;
`

export const StyleDropdown = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #fee0d3;
    height: 40px;
    width: 100%;
    padding: 4px 11px;

    @media only screen and (max-width: 768px) {
      padding: 12px 16px;
    }

    @media only screen and (max-width: 375px) {
      padding: 12px 16px;
    }
  }

  .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
    background-color: #fff3ee !important;
    color: #fff3ee !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #fff3ee;
  }
`

export const Box = styled.div`
  /* background-color: white;
  min-height: 600px;
  align-items: center;
  border-radius: 20px; */
  font-family: 'Prompt' !important;
  .ant-input::placeholder {
    font-size: 14px !important;
    color: var(--web-backend-extended-orange-700, var(--web-backend-orange-700, #ffc4aa)) !important;
  }
  .ant-input:focus {
    border-color: #f36b24;
    color: #f36b24;
  }
  label,
  input {
    font-size: 12px !important;
  }
  textarea {
    font-size: 14px !important;
    color: #f36b24;
  }

  .ant-form-item-explain-error {
    color: rgb(237, 65, 96);
    font-size: 12px;
    text-align: end;
  }
  .ant-input:disabled {
    color: #ffc4aa !important;
    background-color: #fff8f4 !important;
  }
  .ant-input {
    border: 1px solid #ffeee7;
  }

  .ant-input:hover {
    border: 1px solid #ffeee7;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  label {
    font-family: Prompt;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #150f0b;
  }
  .ant-input {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    max-width: 824px;
    color: #150f0b;
    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      color: #fc6521;
      box-shadow: none;
      border: 1px solid #fc6521;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 48px;
    max-width: 824px;
    color: #fc6521;
    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
    //box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #ffeee7;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fbfbfb;
    border-color: #fbfbfb !important;
    color: #9baab1;
  }

  .row-data {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    padding-top: 24px;

    .col-1 {
      /* .ant-input{
        &:hover,
        &:active,
        &:focus {
            border: 1px solid #fc6521 !important;
            color: #fc6521 !important;
          }      }
   */

      .ant-input:not(.ant-input-disabled):hover {
        border-color: #fc6521;
      }

      /* .ant-input:not(.ant-inpu-focused):hover {
          border: 1px solid #FEE0D3;
       } */
      width: 100%;
      label {
        font-family: 'Prompt';
        font-weight: 400;
        font-size: 14px !important;
        color: #150f0b;
      }

      .ant-input[disabled] {
        border: none;

        background-color: #fff3ee !important;
        :hover {
          border: none;
        }
      }
      .ant-input::placeholder {
        color: #ffc4aa !important;
        font-family: 'Prompt';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      textarea.ant-input {
        border: 1px solid var(--mobile-extended-orange-500, #fee0d3);
        &:hover,
        &:active,
        &:focus {
          border: 1px solid #fc6521 !important;
          color: #fc6521 !important;
        }
      }
    }
  }

  .bt-next {
    background-color: #f36b24;
    width: 120px;
    height: 48px;
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    border-radius: 8px;

    :hover {
      border: none;
    }

    &.ant-btn[disabled] {
      background-color: #fee0d3 !important;
      border: none;
      color: #ffffff;
    }
  }
`

export const CustomPopover = styled(Popover)`
  .ant-popover-content {
    z-index: 9999 !important;
  }
  .ant-popover-inner {
    background: #001f34;
    box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
    backdrop-filter: blur(6px);
    border-radius: 24px;
    z-index: 9999 !important;
  }

  .ant-popover-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fbfbfb;
    z-index: 9999 !important;
  }
  .ant-popover-inner-content {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fbfbfb;
    z-index: 9999 !important;
  }
`

export const CustomTooltip = styled(Tooltip)`
  /* &.ant-tooltip-inner {
    background: #001f34;
    box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
    border-radius: 24px;
  } */
  background: #001f34;
  box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
  border-radius: 24px;
`

export const CustomUploadfile = styled.div`
  .upload {
    border: 1px dashed #ffc4aa !important;
  }
  .dest1 {
    font-family: 'Prompt';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px; /* 120% */
    color: #150f0b !important;
  }
  .dest2 {
    font-family: 'Prompt';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: #150f0b !important;
  }
  .underline {
    color: #f36b24 !important;
  }
`

export const LabelTextUpload = styled.span`
  font-family: 'Prompt';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`

export const BoxButton = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  z-index: 1400 !important;

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
