import styled from 'styled-components'

export const BoxSearch = styled.div`
  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 16px !important;
  }
  .ant-select-arrow {
    left: 11px !important;
    font-size: 18px;
    justify-content: end;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 10px;
    padding-top: 4px;
  }
  /* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
    border: 1px solid #f8e2e5 !important;
    width: 208px;
    height: 40px;
  } */

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
    border: 1px solid #e2e5f8;
    /* height: 100%; */
    width: 100%;
    /* display: flex; */
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .ant-select-disabled .ant-select-selector {
    background: #ffeee7 !important;
    border: 1px solid #e1eaf0 !important;
    color: #ffc4aa !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #9baab1;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #3f54d1 !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #3f54d1 !important;
  }

  .ant-select,
  .ant-select-single,
  .ant-select-show-arrow,
  .ant-select-show-search {
    width: 100%;
  }

  .ant-select-selection-placeholder {
    color: #ffc4aa !important;
  }
  .big-input .ant-select-selection-placeholder {
    line-height: 37px;
    padding-left: 0 !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffeee7;
    color: #ffc4aa;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
  }
  .ant-select-clear {
    margin-top: -8px !important;
    padding-right: 16px !important;
  }
`
