import React, { useState, useEffect, useRef } from 'react'
import {
  MessageContainer,
  TopHistoryChatStyle,
  HistoryAvatar,
  HistoryChatName,
  MainHistoryChat,
  MainChat,
  AreaChat,
  CardContent,
  WrapContentStaff,
  BoxContentStaff,
  CardContentStaff,
  HistoryTimeContentStaff,
  HistoryTimeContentUser,
  StyledInputChat,
} from '../chatbot.style'
import IconUser from 'assets/images/svg/User.svg'
import SendMessage from 'assets/images/svg/send-message-chatbot.svg'
import { ReactComponent as CloseChat } from 'assets/images/svg/icon-close-chat.svg'
import { Spin } from 'antd'
import { io } from 'socket.io-client'

const ChatHistory = ({ handleCloseChat, dataLineUser }) => {
  const [inputValue, setInputValue] = useState('')
  const messagesEndRef = useRef(null)
  const [messages, setMessages] = useState([])
  const [textrealtime, settextrealtime] = useState(true)
  const [textrealtimecheck, settextrealtimecheck] = useState(true)
  const [loadding, setLoadding] = useState(true)
  const baseURL = process.env.REACT_APP_BASEURL_CHATBOT
  const ioClient = io.connect(baseURL)
  useEffect(() => {
    ioClient.on('connect', () => {
      ioClient.emit('get:history_admin', dataLineUser?.line_id)
    })
  }, [dataLineUser])

  let newMsgPayload = []
  useEffect(() => {
    ioClient.on('res:message_admin', payload => {
      payload.created_at = `${new Date().getHours()}:${new Date().getMinutes()}`
      newMsgPayload.push(payload)
      setMessages(messages.concat(payload))
      setLoadding(false)
    })

    ioClient.on('res:message', (payload, text) => {
      payload.created_at = `${new Date().getHours()}:${new Date().getMinutes()}`
      if (Array.isArray(payload)) {
        setMessages(messages.concat(payload))
      } else {
        newMsgPayload.push(payload)
        setMessages(messages.concat(newMsgPayload.flat()))
      }
    })

    return () => {
      ioClient.off('res:message_admin')
      ioClient.off('res:message')
    }
  }, [])

  useEffect(() => {
    scrollToBottom()
  })

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const SuffixIcons = e => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={SendMessage}
        alt="Sticker"
        style={{ marginLeft: 8, cursor: 'pointer', width: '20px', height: '20px' }}
        onClick={e => {
          sendmessage(e)
          settextrealtimecheck(!textrealtimecheck)
        }}
      />
    </div>
  )
  const handleEnter = e => {
    sendmessage(e)
    settextrealtime(!textrealtime)
  }

  const scrollToBottom = () => {
    const domNode = messagesEndRef.current
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight
    }
  }
  const sendmessage = e => {
    const payload = {
      line_id: dataLineUser?.line_id,
      url_profile: dataLineUser?.url_profile,
      name: dataLineUser?.name,
      text: inputValue,
      action: 'reply',
      is_not_reply: false,
    }
    ioClient.emit('channel', dataLineUser?.line_id, payload)
    setInputValue('')
  }
  const conversDateChat = val => {
    const dateString = val
    const date = new Date(dateString)
    const localDate = new Date(date.getTime())
    const formattedTime = localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    const [hoursStr, minutesStr] = val.split(':')
    const hours = parseInt(hoursStr, 10)
    let minutes = parseInt(minutesStr, 10)
    const formattedTimeFull = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
    return formattedTime === 'Invalid Date' ? formattedTimeFull : formattedTime
  }
  return (
    <>
      <MessageContainer>
        <TopHistoryChatStyle>
          <div>
            <HistoryAvatar
              src={dataLineUser?.url_profile ? dataLineUser?.url_profile : IconUser}
              alt="avatar"
              onError={e => (e.target.src = IconUser)}
            />
            <HistoryChatName>{dataLineUser?.name}</HistoryChatName>
          </div>
          <CloseChat
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setMessages([])
              handleCloseChat()
            }}
          />
        </TopHistoryChatStyle>
        <MainHistoryChat>
          {loadding ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <MainChat ref={messagesEndRef}>
                <AreaChat>
                  {messages.map(item => {
                    if (item?.action == 'send') {
                      return (
                        <div>
                          <CardContent>
                            <div>{item.text}</div>
                          </CardContent>
                          <HistoryTimeContentUser>{conversDateChat(item.created_at)}</HistoryTimeContentUser>
                        </div>
                      )
                    } else if (item?.action == 'reply') {
                      return (
                        <WrapContentStaff>
                          <BoxContentStaff>
                            <CardContentStaff>{item.text}</CardContentStaff>
                            <HistoryTimeContentStaff>{conversDateChat(item.created_at)}</HistoryTimeContentStaff>
                          </BoxContentStaff>
                        </WrapContentStaff>
                      )
                    }
                  })}
                </AreaChat>
              </MainChat>
              <StyledInputChat
                value={inputValue}
                onPressEnter={e => handleEnter(e.target.value)}
                placeholder="Aa"
                onChange={handleInputChange}
                suffix={<SuffixIcons />}
              />
            </>
          )}
        </MainHistoryChat>
      </MessageContainer>
    </>
  )
}

export default ChatHistory
