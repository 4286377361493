export const checkColorStatusDonutEservice = val => {
  if (val === 'บริการรถดูดโคลนเลน') {
    return '#915EFF'
  }
  if (val === 'บริการถังขยะ') {
    return '#43b9f0'
  }
  if (val === 'ขอจำหน่ายสินค้าตลาดนัด') {
    return '#13AFA6'
  }
  if (val === 'ขอรับรองการประกอบอาชีพ OTOP') {
    return '#3F54D1'
  }

  if (val === 'บริการรถดับเพลิง') {
    return '#F85858'
  }
}
export const CheckStatusText = val => {
  if (val === 'received') {
    return 'รับเรื่อง'
  }
  if (val === 'in progress') {
    return 'อยู่ระหว่างดำเนินการ'
  }
  if (val === 'done') {
    return 'ดำเนินการแล้วเสร็จ'
  }
}
