import React, { useEffect, useState, version } from 'react'
import styled from 'styled-components'
import { ReactComponent as LocationIcon } from 'assets/images/svg/Location.svg'
import { ReactComponent as IconRepair } from 'assets/images/svg/repair-icon.svg'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const CardTracking = ({
  complaintId,
  setPreviewImg,
  setPreviewVdo,
  setOpenModalImage,
  setOpenModalVdo,
  dataComplaint,
}) => {
  const [MeOrganize, setMeOrganize] = useState([])
  const [dataTracking, setDataTracking] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')
  useEffect(() => {
    GetMeData()
    getAllTracking()
  }, [])

  const getAllTracking = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/all/tracking?filters=complaint_id:eq:${complaintId}&?sort=updated_at:ASC`
      const resTracing = await axios.get(`${shemadata}`)
      if (resTracing.status === 200 || resTracing.status === 201) {
        setDataTracking(resTracing?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const GetMeData = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMeData = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resMeData.status === 200 || resMeData.status === 201) {
        setMeOrganize(resMeData?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const Mapmedia = dataMedia => {
    const CardUi = dataMedia.map((item, index) => {
      if (item.includes('mp4')) {
        return (
          <div
            className="show-image-div click"
            onClick={() => {
              setPreviewVdo(item)
              setOpenModalVdo(true)
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
              <video style={{ width: '144px', height: '82px', borderRadius: '8px', margin: '10px 5px' }}>
                <source src={item} type="video/mp4" />
              </video>
              <div style={{ position: 'absolute' }}>
                {/* นำ icon มาวางที่นี่ */}
                <IvdoPlay />
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <img
              style={{ borderRadius: '8px', width: '144px', height: '82px', margin: '10px 5px' }}
              className="show-image click"
              src={item}
              alt="image"
              onClick={() => {
                setOpenModalImage(true)
                setPreviewImg(item)
              }}
            />
          </div>
        )
      }
    })
    return CardUi
  }

  const NextPageTracking = () => {
    navigate('/complaint-staff/bof-overview-complaint/update-form-status', {
      state: {
        type: 'tracking',
        complaintId,
        dataComplaint: dataComplaint,
      },
    })
  }
  let c = dataComplaint?.transaction_data?.filter((value, index, array) => value?.officer_data?.id === MeOrganize?.id)
  let s = c?.find(item => item?.status === 'done')
  let checkBtnTacking =
    (!dataComplaint?.status_data?.find(item => item?.name === 'done') &&
      MeOrganize?.position_data === '1730459515121299456') ||
    (MeOrganize?.position_data !== '1730459515121299456' && !s?.status)
  return (
    <>
      {dataTracking.length > 0 && (
        <div>
          <ContainerView className="pink-bg">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title>ข้อมูลการติดตามงาน</Title>

              {checkBtnTacking && (
                <div>
                  <ButtonEditCustom onClick={NextPageTracking}>เพิ่มการติดตาม</ButtonEditCustom>
                </div>
              )}
            </div>

            <div>หากการติดตามเสร็จสิ้น สามารถกด “ปฎิบัติงานเสร็จสิ้น” เพื่อจบการปฎิบัติงาน</div>

            <div>
              {dataTracking.map((itemBox, indexBox) => {
                return (
                  <Boxbg Bgcolor="#ffff" Padding="1.5em" BdRadius="24px">
                    <BoxResult>
                      <HeadeResult>
                        <IconRepair style={{ width: '20px' }} />
                        <div className="header-result">
                          ข้อมูลติดตามการปฎิบัติงานครั้งที่ {itemBox?.no}
                          {itemBox?.description && <div style={{ fontSize: '10px' }}>{itemBox?.description}</div>}
                        </div>
                      </HeadeResult>
                      <div style={{ display: 'flex', marginTop: '15px', overflow: 'auto', maxWidth: '768px' }}>
                        {Mapmedia(itemBox?.media_url)}
                      </div>
                      <div style={{ display: 'flex', gap: '10px', marginTop: '10px', alignItems: 'center' }}>
                        <LocationIcon />
                        <div>
                          <p style={{ color: '#C4C4C4', margin: 0 }}>{itemBox?.address}</p>
                        </div>
                      </div>
                    </BoxResult>
                  </Boxbg>
                )
              })}
            </div>
          </ContainerView>
        </div>
      )}
    </>
  )
}

export default CardTracking

const ButtonEditCustom = styled(Button)`
  font-size: 12px;
  width: 129px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid var(--web-backend-border-primary, #f36b24);
  border-color: #f36b24;
  color: black;
  :focus,
  :hover {
    width: 129px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;

    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
    border-radius: 8px;
    border: 1px solid var(--web-backend-border-primary, #f36b24);
    border-color: #f36b24;
    color: black;
  }
  span {
    color: #f36b24;
  }
`

const Title = styled.div`
  display: flex;
  font-size: 18px;
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 2em;
  margin-bottom: 20px;
  &.pink-bg {
    background-color: #fff8f4;
  }
`
const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};
  margin-bottom: 1em;
`
const HeadeResult = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BoxResult = styled.div`
  display: flex;
  flex-direction: column;
  margin: -1 10px;

  .header-result {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 0 4px;
  }

  .center-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonEdit = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    /* background: #f36b24; */

    background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#f36b24')};
    width: 140px;
    height: 40px;
  }

  &.ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    border-radius: 6px;
    border: none;
    width: 140px;
    height: 40px;
  }
`
