import List from './list'
// import List from './list/mapRender.jsx'
// import List from './list/openlayer.jsx'
import View from './view'
import Form from './form'
import Group from './group'
import UpdateStatusPc from './update-status/view'
import FormUpdateStatus from './update-status/form'

const Complaint = { List, View, Group, Form, UpdateStatusPc, FormUpdateStatus }

export default Complaint
