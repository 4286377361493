import { Outlet, useNavigate } from 'react-router-dom'
import UserContext from 'core/contexts/userContext'
import { useContext, useEffect, useState } from 'react'
import { getMe } from 'core/action/collection'

const ProtectedRoutes = () => {
  const { meData } = useContext(UserContext)
  const [checkGetMe, setCheckGetMe] = useState(false)
  const navigate = useNavigate()
  const getToken = localStorage.getItem('access_token')

  useEffect(() => {
    getMeData()
  }, [getToken])

  const getMeData = async () => {
    if (getToken) {
      try {
        const res = await getMe({ token: getToken })
        if (res?.code === 200) {
          setCheckGetMe(true)
        } else {
          navigate('/login')
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        navigate('/login')
      }
    } else {
      navigate('/login')
    }
  }

  return checkGetMe ? <Outlet /> : null
}

export default ProtectedRoutes
