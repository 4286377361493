import React, { useEffect, useState } from 'react'
import Iconclock from 'assets/images/svg/image-clock.svg'
import IconSucess from 'assets/images/svg/dashboard-success.svg'
import IconDoc from 'assets/images/svg/dashboard-doc.svg'
import styled from 'styled-components'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'
import MapCirCle from 'components/OlHeatMap/MapCircle'
import { BoxPointMap } from '../style'
import { checkColorStatusPoint } from '../container'
import { unixToDateThai } from 'util/date-format'
const ReportPdfDashboardAdminStat = ({
  CardProgressPdf,
  CardDonutPdf,
  ReportRef,
  CardColumnPdfMany,
  CardColumnPdf,
  CardColumnPdfGreen,
  dataDashboard,
  isPrint,
  heatmapData,
  Donutlabels,
  searchData,
  meData,
  CardColumnPdfManyCategory,
}) => {
  return (
    <>
      <BoxExport ref={ReportRef} style={{ display: isPrint ? 'block' : 'none' }}>
        <div style={{ padding: '10px' }}>
          <BoxHeader>
            <FlexColumnBox>
              <div className="big-text" style={{ fontSize: '20px', fontWeight: '500' }}>
                ภาพรวมสถิติและการติดตามสถานะเรื่องร้องเรียน
              </div>
              {/* <div>22ประจำปี 2566</div> */}
            </FlexColumnBox>
            <FlexColumnBox>
              <div>หน้าที่ 01/02 พิมพ์ ณ วันที {unixToDateThai(searchData?.date[0].unix())}</div>
              <div>
                {' '}
                ผู้พิมพ์ {meData && meData?.first_name?.th} {meData && meData?.last_name?.th}
              </div>
            </FlexColumnBox>
          </BoxHeader>
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <div style={{ width: '50%', margin: '0 5px' }}>
              <BoxNumOfComp>
                <LeftBoxNumOfComp>
                  <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                  <TextSatus>จำนวนผู้ร้องเรียน</TextSatus>
                </LeftBoxNumOfComp>
                <NumStyle> {dataDashboard?.total_user?.toLocaleString()}</NumStyle>
              </BoxNumOfComp>
            </div>
            <div style={{ width: '50%', margin: '0 5px' }}>
              <BoxNumOfComp>
                <LeftBoxNumOfComp>
                  <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                  <TextSatus>รายการร้องเรียน</TextSatus>
                </LeftBoxNumOfComp>
                <NumStyle>{dataDashboard?.total_complaint?.toLocaleString()}</NumStyle>
              </BoxNumOfComp>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BoxNumOfComp BgNumCom={'#F2FAFF'} style={{ width: '33%', margin: ' 10px' }}>
              <LeftBoxNumOfComp>
                <StyledImage src={Iconclock} alt="Megaphone Icon" />
                <TextSatus>กำลังดำเนินการ</TextSatus>
              </LeftBoxNumOfComp>
              <NumStyle>{dataDashboard?.total_inprocess_status?.toLocaleString()}</NumStyle>
            </BoxNumOfComp>
            <BoxNumOfComp BgNumCom="#F1FCFB" style={{ width: '33%', margin: '10px' }}>
              <LeftBoxNumOfComp>
                <StyledImage src={IconSucess} alt="Megaphone Icon" />
                <TextSatus>ดำเนินการแล้วเสร็จ</TextSatus>
              </LeftBoxNumOfComp>
              <NumStyle>{dataDashboard?.total_done_status?.toLocaleString()}</NumStyle>
            </BoxNumOfComp>
            <BoxNumOfComp BgNumCom="#F4F8FE" style={{ width: '33%', margin: '10px' }}>
              <LeftBoxNumOfComp>
                <StyledImage src={IconDoc} alt="Megaphone Icon" />
                <TextSatus>ส่งต่อหน่วยงานอื่นๆ</TextSatus>
              </LeftBoxNumOfComp>
              <NumStyle>{dataDashboard?.total_tranfer_status?.toLocaleString()}</NumStyle>
            </BoxNumOfComp>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <BoxbgChart style={{ width: '50%' }}>
              <TopicHeaderStatus style={{ display: 'flex', justifyContent: 'start' }}>
                <TextSatus> สถิติการร้องเรียนผ่านช่องทางต่างๆ </TextSatus>
              </TopicHeaderStatus>
              <div style={{ marginTop: '40px' }}>
                <div>{CardDonutPdf}</div>
              </div>
            </BoxbgChart>
            <BoxbgChart style={{ width: '50%' }}>
              <div style={{ position: 'relative' }}>
                <TextSatus style={{ position: 'absolute', zIndex: 999 }}>
                  แผนที่แสดงความหนาแน่นของเรื่องร้องเรียนตามพื้นที่ <span>(เรื่อง)</span>
                </TextSatus>
                <div
                  style={{
                    borderRadius: '10px',
                    padding: '10px',
                    position: 'absolute',
                    zIndex: 999,
                    top: '30px',
                    left: '0px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    rowGap: '5px',
                    columnGap: '10px',
                  }}
                >
                  {Donutlabels?.map((item, index) => (
                    <BoxPointMap BgColor={checkColorStatusPoint(item)} key={index}>
                      <div className="point" />
                      <span>{item}</span>
                    </BoxPointMap>
                  ))}
                </div>
                {isPrint && <MapCirCle heatmapData={heatmapData} isPrint={isPrint} />}
              </div>
            </BoxbgChart>
          </div>

          <Boxlong>
            <TopicHeaderStatus>
              <TextSatus> กราฟแสดงระยะเวลาการดำเนินงานของแต่ละหน่วยงาน </TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>
            {CardColumnPdf}
          </Boxlong>
        </div>

        <div style={{ pageBreakBefore: 'always' }}></div>
        <div style={{ padding: '10px' }}>
          <BoxHeader>
            <FlexColumnBox>
              <div className="big-text" style={{ fontSize: '20px', fontWeight: '500' }}>
                ภาพรวมสถิติและการติดตามสถานะเรื่องร้องเรียน
              </div>
              <div>22ประจำปี 2566</div>
            </FlexColumnBox>
            <FlexColumnBox>
              <div>หน้าที่ 02/02 พิมพ์ ณ วันที่ {unixToDateThai(searchData?.date[0].unix())}</div>
              <div>
                ผู้พิมพ์ {meData && meData?.first_name?.th} {meData && meData?.last_name?.th}
              </div>
            </FlexColumnBox>
          </BoxHeader>
          <Boxlong>
            <TopicHeaderStatus>
              <TextSatus> กราฟแสดงระยะเวลาแบ่งตามประเภทเรื่องร้องเรียน </TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>
            {CardColumnPdfGreen}
          </Boxlong>

          <Boxlong>
            <TopicHeaderStatus>
              <TextSatus> กราฟแสดงจำนวนสถิติความพึงพอใจของผู้ใช้งาน </TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>
            {CardColumnPdfMany}
          </Boxlong>

          <Boxlong>
            <TopicHeaderStatus>
              <TextSatus> กราฟประเมินความพึงพอใจแต่ละประเภท</TextSatus>
              {/* <TextSatusStay>(เรื่อง)</TextSatusStay> */}
              {/* <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', marginLeft: '140px' }}>
                <CustomBox style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Boxxx style={{ backgroundColor: '#F85858' }} />

                  <span style={{ fontSize: '11px' }}>1 คะแนน</span>
                </CustomBox>
                <CustomBox style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Boxxx style={{ backgroundColor: '#FFA643' }} />

                  <span style={{ fontSize: '11px' }}>2 คะแนน</span>
                </CustomBox>

                <CustomBox style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Boxxx style={{ backgroundColor: '#915EFF' }} />

                  <span style={{ fontSize: '11px' }}>3 คะแนน</span>
                </CustomBox>

                <CustomBox style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Boxxx style={{ backgroundColor: '#21ADFC' }} />
                  <span style={{ fontSize: '11px' }}>4 คะแนน</span>
                </CustomBox>

                <CustomBox style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Boxxx style={{ backgroundColor: '13AFA6' }} />
                  <span style={{ fontSize: '11px' }}>5 คะแนน</span>
                </CustomBox>
              </div> */}
            </TopicHeaderStatus>
            {CardColumnPdfManyCategory}
          </Boxlong>
        </div>
      </BoxExport>
    </>
  )
}

export default ReportPdfDashboardAdminStat

const CustomBox = styled.div`
  color: rgb(55, 61, 63);
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica, Arial, sans-serif;
`

const Boxxx = styled.div`
  height: 10px;
  width: 10px;
  background-color: red;
  margin-right: 5px;
  margin-left: 4px;
  margin-bottom: 2px;
`

const BoxExport = styled.div`
  font-family: 'Prompt';
  @page {
    size: A4 portrait;
  }
`

const NumStyle = styled.p`
  font-family: Prompt;
  padding-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`

export const BoxNumOfComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border-radius: 12px;
  background: ${props => (props.BgNumCom ? props.BgNumCom : '#fffbf8')};
`

export const LeftBoxNumOfComp = styled.div`
  display: flex;
  padding-top: 15px;
  gap: 8px;
`

export const StyledImage = styled.img`
  padding-bottom: 10px;
`

export const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`

const TextSatusStay = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
`

const TopicHeaderStatus = styled.div`
  display: flex;
  gap: 8px;
`

const BoxbgChart = styled.div`
  background-color: rgb(251, 251, 251);
  border-radius: 12px;
  padding: 10px;
  margin: 10px;
`

const Boxlong = styled.div`
  margin: 10px;
  background-color: rgb(251, 251, 251);
  padding: 10px;
  border-radius: 12px;
`

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Prompt';
`
const FlexColumnBox = styled.div`
  display: flex;
  flex-direction: column;

  .big-text {
    font-size: 18px;
    font-weight: 500;
  }
`
