import React, { useEffect, useState } from 'react'
import ColumnsChart from 'components/Charts/ColumnsChart'
import styled from 'styled-components'
import { unixToDateThai } from 'util/date-format'
import axios from 'axios'
const ReportPdfEservice = ({
  searchData,
  CardProgressPdf,
  CardDonutPdf,
  ReportRef,
  CardColumnPdfSlack,
  CardColumnPdf,
  meData,
}) => {
  return (
    <HiddenContainer>
      <Container ref={ReportRef}>
        <div>
          <BoxHeader>
            <FlexColumnBox>
              <div className="big-text">แดชบอร์ดแสดงภาพรวมของบริการสาธารณะ</div>
              {/* <div>22ประจำปี 2566</div> */}
            </FlexColumnBox>
            <FlexColumnBox>
              <div>หน้าที่ 01/01 พิมพ์ ณ วันที่ {unixToDateThai(searchData?.date[0].unix())} </div>
              <div>
                ผู้พิมพ์ {meData && meData?.first_name?.th} {meData && meData?.last_name?.th}
              </div>
            </FlexColumnBox>
          </BoxHeader>

          <BoxbgChart style={{ margin: '5% 0' }}>
            <TopicHeaderStatus>
              <TextSatus> ประเภทเรื่องที่ร้องขอรายเดือน </TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>

            {CardProgressPdf}
          </BoxbgChart>

          <BoxbgChart>
            <TopicHeaderStatus style={{ display: 'flex', justifyContent: 'start' }}>
              <TextSatus> ประเภทเรื่องที่ร้องขอรายเดือน </TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>

            <div style={{ display: 'flex', justifyContent: 'center' }}>{CardDonutPdf}</div>
          </BoxbgChart>

          <Boxlong>
            <TopicHeaderStatus>
              <TextSatus> ประเภทเรื่องที่ร้องขอรายเดือน </TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>
            {CardColumnPdf}
          </Boxlong>

          {/* <Boxlong>
            <TopicHeaderStatus>
              <TextSatus> ลำดับความสำคัญเรื่องที่ร้องขอรายเดือน </TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>
            {CardColumnPdfSlack}
          </Boxlong> */}
        </div>
      </Container>
    </HiddenContainer>
  )
}

export default ReportPdfEservice
const TextSatusStay = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
`
const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const TopicHeaderStatus = styled.div`
  display: flex;
  gap: 8px;
`

const BoxbgChart = styled.div`
  background-color: rgb(251, 251, 251);
  border-radius: 12px;
  padding: 24px;
  margin: 20px 10px;
`

const HiddenContainer = styled.div`
  position: fixed;
  top: -990px;
  width: 290mm;
  /* background: white; */
  background: #b10202;
  z-index: -99;
  /* position: relative; */
`

const Boxlong = styled.div`
  background-color: rgb(251, 251, 251);
  padding: 10px;
  border-radius: 12px;
  width: 1200px;
  /* display: flex;
  justify-content: center; */
`

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const FlexColumnBox = styled.div`
  display: flex;
  flex-direction: column;

  .big-text {
    font-size: 18px;
    font-weight: 500;
  }
`

const Cardtop = styled.div`
  /* background-color: red; */
  width: 50%;

  &.center-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  background-color: #fbfbfb;

  margin: 10px;
`

const BoxFlex = styled.div`
  display: flex;
`

const Container = styled.div`
  @page {
    size: A4 portrait;
    margin: 5mm 10mm !important;
  }
`
