import styled from 'styled-components'
import { Modal } from 'antd'
export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  z-index: 1400 !important;

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
export const Content = styled.div`
  font-family: 'Prompt';
  .custom-table td {
    border-top: none !important;
  }
  .custom-table td:last-child {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
  }
  .custom-table td:first-child {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: none !important;
  }
  .text-title-import-file {
    font-size: 20px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #150f0b;
  }
  .sub-text-upload {
    font-size: 12px;
    font-weight: 300;
    color: #150f0b;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24;
    border-right-width: 1px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #ffeee7;
    border-radius: 10px;
    height: 40px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #ffc4aa;
  }
  .btn-confirm-file {
    border-radius: 10px;
    background-color: #f36b24;
    border: 1px solid #f36b24;
    color: #fff;
    align-items: center;
    display: flex;
  }

  .btn-confirm-file-disable {
    border-radius: 10px;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    align-items: center;
    display: flex;
  }

  .btn-go-black {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f36b24;
    color: #f36b24;
    align-items: center;
    display: flex;
  }
  .ant-drawer-header {
    display: none !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b24;
    border-color: #f36b24;
  }
  .ant-checkbox-inner:after {
    top: 45% !important;
    left: 15.5% !important;
  }
  .ant-checkbox-indeterminate {
    top: 50%;
    left: 50%;
    background-color: #f36b24;
  }
  .selected-row {
    background-color: #ffeee7;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff;
    border-color: #ffeee7;
  }
  .ant-pagination-item-active a {
    color: #f36b24;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #f36b24;
  }
  .ant-pagination-item:hover {
    border-color: #f36b24;
    color: #f36b24;
    transition: all 0.3s;
  }

  .ant-pagination-item:hover a {
    color: #f36b24;
  }
  .ant-select-selection-item:hover {
    border-color: #f36b24;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    border-color: #f36b24 !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #f36b24;
  }
  .ant-checkbox-checked:after {
    border: 1px solid #f36b24;
  }
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #f36b24;
    border-color: #f36b24;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    border: 1px solid rgb(255, 238, 231);
    border-radius: 10px;
    height: 40px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f1efef !important;
  }
`
export const BoxDrawer = styled.div`
  .ant-drawer-header {
    display: none !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 4px !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #a19c9c !important;
    cursor: not-allowed;
  }
`

export const CustomUploadfile = styled.div`
  .upload {
    border: 1px dashed #ffc4aa !important;
  }
  .dest1 {
    font-family: 'Prompt';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px; /* 120% */
    color: #150f0b !important;
  }
  .dest2 {
    font-family: 'Prompt';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: #150f0b !important;
  }
  .underline {
    color: #f36b24 !important;
  }
`
export const BoxNameColums = styled.div`
  margin: ${props => (props.margin ? props.margin : '40px 20px')};
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
`
export const BoxColums = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  hr {
    border: 1px solid #f9f9f9;
  }
`
export const TextTH = styled.div`
  color: #717171;
  font-size: 10px;
  font-weight: 400;
`
