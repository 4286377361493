import styled, { createGlobalStyle } from 'styled-components'
import { Checkbox } from 'antd'
export const CustomCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 4px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b24;
    border-color: #f36b24;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    /* left: 7px !important; */
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #f36b24 !important;
  }

  .ant-checkbox-checked:after {
    border: 1px solid #f36b24 !important;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-color: #f36b24;
  }
`
export const BoxSearch = styled.div`
  width: 100%;
  overflow-x: auto;
  .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper {
    border-color: #ffeee7;
    border-radius: 8px;
    height: 40px;
    min-width: 150px !important;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #f36b24 !important;
  }

  .ant-picker {
    background: #ffffff !important;
    border: 1px solid #ffeee7 !important;
    border-radius: 8px !important;
    height: 40px !important;
    width: 100% !important;
    color: #ffc4aa !important;
  }
  .ant-picker-input > input {
    color: #ffc4aa !important;
  }
  .ant-picker-input::placeholder > input {
    color: #ffc4aa !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #ffeee7;
    color: #f36b24 !important;
    height: 40px;
    border-radius: 8px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #ffc4aa;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow {
    color: #f36b24;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 10px;
    background: none !important;

    .ant-input-affix-wrapper {
      min-width: 200px !important;
    }
  }

  @media only screen and (max-width: 375px) {
    padding: 10px 10px;
    background: none !important;
    .ant-input-affix-wrapper {
      min-width: 200px !important;
    }
  }
`
