import React from 'react';
import ReactApexChart from 'react-apexcharts';

export function ApexChartDonut({ labels, colors, series }) { 
    const options = {
        chart: {
            id: 'complaints-donut-chart'
        },
        labels: labels,  
        legend: {
            position: 'right',
            offsetX: -35,
            offsetY: 45,
            formatter: function(seriesName, opts) {
                return `<span style="font-family: Prompt; font-size: 12px; font-weight: 500; line-height: 14.4px; color: #150F0B; margin:20px 20px 24px 0px;">${opts.w.globals.series[opts.seriesIndex]}</span>
                        <span style="font-family: Prompt; font-size: 10px; font-style: normal; font-weight: 400; line-height: 12px; color: #717171;">${seriesName}</span>`;
            }
        },
        colors: colors,  
        plotOptions: {
            pie: {
                donut: {
                    size: '80%',
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'ทั้งหมด',
                            fontSize: '10px', 
                            fontFamily: 'Prompt',
                            fontWeight: 400, 
                            color: '#717171', 
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => a + b, 0).toLocaleString();
                            }
                        },
                        value: {
                            fontSize: '20px', 
                            fontFamily: 'Prompt',
                            fontWeight: 500,
                            color: '#150F0B'
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            show: false
        },
    };

    return (
        <div >
            <ReactApexChart
                options={options}
                series={series}  
                type="donut"
                width="525px"
            />
        </div>
    );
}
