import styled from 'styled-components'
import { Space, Button, Modal } from 'antd'

export const CustomSpace = styled(Space)`
  padding-top: 20px;
  .ant-btn-ghost {
    color: #f36b24;
    border-color: #f36b24;
    height: 40px;
    width: 88px;
    border-radius: 12px;
  }
  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 88px;
    border-radius: 12px;
  }
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
`
export const Box = styled.div`
  background-color: white;
  min-height: 600px;
  align-items: center;
  border-radius: 20px;
  input:disabled {
    background-color: #fff8f4 !important;
    color: #ffc4aa !important;
  }
  textarea:disabled {
    background-color: #fff8f4 !important;
    color: #ffc4aa !important;
  }
  label {
    color: #150f0b !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
  }
`
export const ButtonEdit = styled(Button)`
  font-size: 16px;
  width: 129px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid var(--web-backend-border-primary, #f36b24);
  border-color: #f36b24;
  color: black;
  :focus,
  :hover {
    color: black;
    border-color: transparent;
  }
  span {
    color: #f36b24;
  }
`
export const ButtonDelete = styled(Button)`
  font-size: 16px;
  width: 113px;
  height: 40px;
  background: #ed4160;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border-color: transparent;
  color: #ffffff;
  :focus,
  :hover {
    color: #ffffff;
    border-color: transparent;
    background: #ed4160;
  }
`
export const ButtonBack = styled(Button)`
  width: 40px;
  height: 40px;
  border: none !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    border-color: transparent;
    background: #fbfbfb !important;
  }
`

// export const CustomModal = styled(Modal)`
//   .ant-input::placeholder {
//     font-size: 14px !important;
//     color: var(--web-backend-extended-orange-700, var(--web-backend-orange-700, #ffc4aa)) !important;
//   }
//   .ant-input:focus {
//     border-color: #f36b24;
//     color: #f36b24;
//   }
//   label,
//   input {
//     font-size: 12px !important;
//   }
//   textarea {
//     font-size: 14px !important;
//     color: #f36b24;
//   }

//   .ant-form-item-explain-error {
//     color: rgb(237, 65, 96);
//     font-size: 12px;
//     text-align: end;
//   }
//   .ant-input:disabled {
//     color: #ffc4aa !important;
//     background-color: #fff8f4 !important;
//   }
//   .ant-input {
//     border: 1px solid #ffeee7;
//   }

//   .ant-input:hover {
//     border: 1px solid #ffeee7;
//   }

//   .ant-input::placeholder {
//     color: #ffc4aa !important;
//   }

//   label {
//     font-family: Prompt;
//     font-size: 12px;
//     font-style: normal;
//     font-weight: 400;
//     color: #150f0b;
//   }
//   .ant-input {
//     min-height: 40px;
//     border: 1px solid #ffeee7;
//     border-radius: 8px;
//     max-width: 824px;
//     color: #150f0b;
//     :focus {
//       /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
//       color: #fc6521;
//       box-shadow: none;
//       border: 1px solid #fc6521;
//     }
//   }
//   .ant-input-affix-wrapper {
//     border: 1px solid #ffeee7;
//     border-radius: 8px;
//     height: 48px;
//     max-width: 824px;
//     color: #fc6521;
//     .ant-input {
//       border-right: none;
//       border-left: none;
//       border-top-left-radius: 0;
//       border-top-right-radius: 0;
//       border-bottom-left-radius: 0;
//       border-bottom-right-radius: 0;
//     }
//     :focus {
//       box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
//       border: 1px solid #fc6521;
//     }
//   }

//   .ant-input-affix-wrapper:focus .ant-input {
//     //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
//     border: 1px solid #fc6521;

//     border-right: none;
//     border-left: none;
//   }
//   .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//     border-color: #fc6521;
//     //box-shadow: none;
//   }
//   .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
//     border: 1px solid #ffeee7;
//   }
//   .ant-input-affix-wrapper-focused {
//     border-color: #fc6521;
//     box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
//   }
//   .ant-input[disabled],
//   .ant-input-affix-wrapper-disabled {
//     background-color: #fbfbfb;
//     border-color: #fbfbfb !important;
//     color: #9baab1;
//   }

//   font-family: 'Prompt';
//   .ant-modal-mask {
//     background: rgba(21, 15, 11, 0.5) !important;
//     backdrop-filter: blur(8px) !important;
//   }

//   .ant-modal-content {
//     border-radius: 16px;
//     /* width: 500px;
//     height: 226px; */

//     max-width: 600px;
//     margin: 0 auto;
//     .ant-modal-body {
//       padding: 24px 24px 10px 24px !important;
//     }
//   }
//   .ant-modal-header {
//     border-radius: 16px;
//     color: white;
//     border: 0;
//   }
//   .ant-modal-body {
//     text-align: center;
//     padding-bottom: 20px;
//     .modal-title {
//       font-weight: 400 !important;
//       font-size: 16px !important ;
//       font-style: normal;
//     }
//     .modal-message {
//       margin-top: 8px;
//       font-weight: 300 !important;
//       font-size: 12px !important ;
//       font-style: normal;

//       color: #c4c4c4 !important;
//     }
//   }
//   .ant-modal-footer {
//     border: 0;
//     text-align: center;

//     .ant-btn-default {
//       border-radius: 12px;
//       border-color: #f36b24;
//       color: #f36b24;
//       font-size: 16px;
//       width: 120px;
//       height: 40px;
//       font-weight: 500;
//     }
//     .ant-btn-primary {
//       border-radius: 12px;
//       background-color: #f36b24;
//       border-color: #f36b24;
//       font-size: 16px;
//       width: 120px;
//       height: 40px;
//       font-weight: 500;
//     }
//   }
// `

export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  z-index: 1400 !important;

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomModal2 = styled(Modal)`
  font-family: 'Prompt';

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      font-style: normal;

      color: #c4c4c4 !important;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
export const BoxBtn = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .btn-edti-staff {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f36b24;
    color: #f36b24;
    align-items: center;
    display: flex;
  }

  .btn-update-complant {
    border-radius: 10px;
    background-color: #f36b24;
    border: 1px solid #f36b24;
    color: #fff;
    align-items: center;
    display: flex;
  }
`
