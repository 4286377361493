import * as Yup from 'yup'

export const defaultValues = {
  first_name: '',
  last_name: '',
  mobile_number: '',
  email: '',
  group_id: '',
  position_id: '',
  role_id: '',
  organize_id: '',
}

export const Schema = Yup.object().shape({
  first_name: Yup.string()
    .required('')
    .max(255, 'กรุณากรอกไม่เกิน 255 ตัวอักษร')
    .matches(/^[ก-๏เ-๙\s]+$/, 'กรุณากรอกภาษาไทยเท่านั้น'),
  last_name: Yup.string()
    .required('')
    .max(255, 'กรุณากรอกไม่เกิน 255 ตัวอักษร')
    .matches(/^[ก-๏เ-๙\s]+$/, 'กรุณากรอกภาษาไทยเท่านั้น'),
  group_id: Yup.string().required(''),
  position_id: Yup.string().required(''),
  role_id: Yup.string().required(''),
  organize_id: Yup.string().required(''),
})
