import FroalaEditor from 'react-froala-wysiwyg'
import FroalaeditorX from 'froala-editor'
import get from 'lodash.get'
import React, { useRef, useState } from 'react'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'font-awesome/css/font-awesome.css'

import styled from 'styled-components'
import editorBold from 'assets/images/svg/editor-bold.svg'
import editorItalic from 'assets/images/svg/editor-italic.svg'
import editorUnderline from 'assets/images/svg/editor-underline.svg'
import editorUnorderedList from 'assets/images/svg/editor-unordered-list.svg'
import editorOrderedList from 'assets/images/svg/editor-ordered-list.svg'
import iconCloseDanger from 'assets/images/svg/icon-close-danger.svg'
import editorUploadImage from 'assets/images/svg/editor-upload-image.svg'
import editorUploadVideo from 'assets/images/svg/editor-upload-vdo.svg'
import editorInsertLink from 'assets/images/svg/editor-insert-link.svg'
import editorAlign from 'assets/images/svg/editor-align.svg'
import axios from 'axios'
import { getTokenStorage } from '../../../core/action/collection'
import { iconRedo, iconUndo } from '../../../core/constants/svgPathStr'

FroalaeditorX.DefineIcon('bold', { SRC: editorBold, ALT: 'bold', template: 'image' })
FroalaeditorX.DefineIcon('italic', { SRC: editorItalic, ALT: 'italic', template: 'image' })
FroalaeditorX.DefineIcon('underline', { SRC: editorUnderline, ALT: 'underline', template: 'image' })
FroalaeditorX.DefineIcon('formatUL', { SRC: editorUnorderedList, ALT: 'Unordered List', template: 'image' })
FroalaeditorX.DefineIcon('formatOLSimple', { SRC: editorOrderedList, ALT: 'Ordered List', template: 'image' })
FroalaeditorX.DefineIcon('undo', { PATH: iconUndo, template: 'svg' })
FroalaeditorX.DefineIcon('redo', { PATH: iconRedo, template: 'svg' })
FroalaeditorX.DefineIcon('IconUploadImage', { SRC: editorUploadImage, ALT: 'upload image', template: 'image' })
FroalaeditorX.DefineIcon('IconUploadVideo', { SRC: editorUploadVideo, ALT: 'upload video', template: 'image' })
FroalaeditorX.DefineIcon('IconInsertLink', { SRC: editorInsertLink, ALT: 'insert link', template: 'image' })
FroalaeditorX.DefineIcon('IconAlign', { SRC: editorAlign, ALT: 'align', template: 'image' })

// Define popup template.
FroalaeditorX.POPUP_TEMPLATES['customPluginImage.popup'] = '[_CUSTOM_LAYER_]'
FroalaeditorX.POPUP_TEMPLATES['customPluginVideo.popup'] = '[_CUSTOM_LAYER_]'
FroalaeditorX.POPUP_TEMPLATES['customPluginLink.popup'] = '[_CUSTOM_LAYER_]'
FroalaeditorX.POPUP_TEMPLATES['customPluginAlign.popup'] = '[_CUSTOM_LAYER_]'
// The custom popup is defined inside a plugin (new or existing).
FroalaeditorX.PLUGINS.customPlugin = function (editor) {
  // Create custom popup.
  function initPopup(isVideo) {
    if (isVideo) {
      let template = {
        custom_layer: `
        <div class='custom-layer'>
         <button id='editorUploadVideo' type='button' class='ant-btn ant-btn-primary' style='width: 179px' onclick="document.querySelector('#uploadVideo').click()">
          <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOCSURBVHgBrVVbTJNJFP7mL7YChdKlbMjC7jabNewqKmBRg7caY3wsxvjgg1o1vviiNTExJsYSE1/FZ03AxEu8RPGSGDUqXohRMVSjGBURjQoRsaW0/ZV2/vH8Q1tKrULVL5nMzJk55zvnzJkZhgmg7a6/iinKIgFWxRgCMcGvL3RYWyaiy8Y13j7YSN3mDIo9XGjrFtRaWzFRgmBIuMlDlwCqErJYVNgzKXKuQf3MkaMwzx+lxkZ8j0BVhZ0LnBZi1HA2ICPN0WF4rFYW+GotbvwaGbfj59BSkM+WpwuVmAb3LzCuo/75S9WbLsyh5kIWGPB/xsGT3XjbG0F5aR7WrPwHxVaTXDMZlV037w1iQa3Fm9ivANnl/dSFF3jY2UdEQfio33+4M7k2aZIChWFXW3uwKZUgK/g6exH5FEZEDUGl/v6jNxl2Cfet+8G9P0RQaGZkPBxvITnPBCbElmsd/qKsCbZsmI2a6TYZRcW/FuzZ7vzmXhNX1rKhsBA9bwZx4/ZbhNUo+j+qKPktFyXFuaidWYq/ywsxUYTCMXS/jqSKmvUqwl1fH85feQGhT6hmwfSwBY6fewp7uQWb3DNlny30d0umKG+yAVyL0fWnpnHwmD7miNK869UAtjZcxbGzT5AtNMF8MgL7nxZpUHceI87HexEfMhxpeYy+D2FsXu/AgaM+3Ol4h1BkmLxkmFNdJuVp6Ika+BlJUFlRApNJQZgUJEScI1kgI6yXr3dRqgqRl5uD3vfB5Eplhe0r7zk0z+JqayBZRatcU0dSRJHoadL0VPFEG5UfOvUAS+bZMVlPK8lsVBDTycExuadiS/wXOoFPH9Qv+0+GKs8ipcV4YsxlHwhGcOlGF1xLp8i5Z+Nc/G7Ll4aHo5pu3VvnsOxLkBl27PSWUvROfeKYUYaBQATPuvsp/YIOSchz0DQ6C6FJmd76+oewekUV/p9iQ53jr6TnAx+j3ppKc0NaNADdhQ6kvEkXW5+j6UQ75XlodNPI0UjUVJZh97alMOcZU2156bluQBqkrt8vigxG7KWJO3XR9/idJNEPtCDfhAKzCfNn28mwaYyR4ZjWWGwxeJABYx6SIVUsogzUs5Ro6HY6Myly+qXUT5zWtebqafnr8A2M++nfvOd3KorShAyfEpVxY90si+d7+uMSJNDWEXQxTTgpwiKqFZ9RMbTOqTE/GE/vC21zpE1bfrn8AAAAAElFTkSuQmCC' alt='upload image'>
           <span style='padding-left: 6px; padding-top: 1px; font-size: 16px;'>
            อัปโหลดไฟล์วิดีโอ
           </span>
         </button>
          <p>เฉพาะไฟล์ .mp4 เพียง 1 ไฟล์ ขนาดไม่เกิน 20MB</p>
        </div>
       `,
      }
      return editor.popups.create('customPluginVideo.popup', template)
    } else {
      // Load popup template.
      let template = {
        custom_layer: `
        <div class='custom-layer'>
         <button id='editorUploadImage' type='button' class='ant-btn ant-btn-primary' onclick="document.querySelector('#uploadImage').click()">
          <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOCSURBVHgBrVVbTJNJFP7mL7YChdKlbMjC7jabNewqKmBRg7caY3wsxvjgg1o1vviiNTExJsYSE1/FZ03AxEu8RPGSGDUqXohRMVSjGBURjQoRsaW0/ZV2/vH8Q1tKrULVL5nMzJk55zvnzJkZhgmg7a6/iinKIgFWxRgCMcGvL3RYWyaiy8Y13j7YSN3mDIo9XGjrFtRaWzFRgmBIuMlDlwCqErJYVNgzKXKuQf3MkaMwzx+lxkZ8j0BVhZ0LnBZi1HA2ICPN0WF4rFYW+GotbvwaGbfj59BSkM+WpwuVmAb3LzCuo/75S9WbLsyh5kIWGPB/xsGT3XjbG0F5aR7WrPwHxVaTXDMZlV037w1iQa3Fm9ivANnl/dSFF3jY2UdEQfio33+4M7k2aZIChWFXW3uwKZUgK/g6exH5FEZEDUGl/v6jNxl2Cfet+8G9P0RQaGZkPBxvITnPBCbElmsd/qKsCbZsmI2a6TYZRcW/FuzZ7vzmXhNX1rKhsBA9bwZx4/ZbhNUo+j+qKPktFyXFuaidWYq/ywsxUYTCMXS/jqSKmvUqwl1fH85feQGhT6hmwfSwBY6fewp7uQWb3DNlny30d0umKG+yAVyL0fWnpnHwmD7miNK869UAtjZcxbGzT5AtNMF8MgL7nxZpUHceI87HexEfMhxpeYy+D2FsXu/AgaM+3Ol4h1BkmLxkmFNdJuVp6Ika+BlJUFlRApNJQZgUJEScI1kgI6yXr3dRqgqRl5uD3vfB5Eplhe0r7zk0z+JqayBZRatcU0dSRJHoadL0VPFEG5UfOvUAS+bZMVlPK8lsVBDTycExuadiS/wXOoFPH9Qv+0+GKs8ipcV4YsxlHwhGcOlGF1xLp8i5Z+Nc/G7Ll4aHo5pu3VvnsOxLkBl27PSWUvROfeKYUYaBQATPuvsp/YIOSchz0DQ6C6FJmd76+oewekUV/p9iQ53jr6TnAx+j3ppKc0NaNADdhQ6kvEkXW5+j6UQ75XlodNPI0UjUVJZh97alMOcZU2156bluQBqkrt8vigxG7KWJO3XR9/idJNEPtCDfhAKzCfNn28mwaYyR4ZjWWGwxeJABYx6SIVUsogzUs5Ro6HY6Myly+qXUT5zWtebqafnr8A2M++nfvOd3KorShAyfEpVxY90si+d7+uMSJNDWEXQxTTgpwiKqFZ9RMbTOqTE/GE/vC21zpE1bfrn8AAAAAElFTkSuQmCC' alt='upload image'>
           <span style='padding-left: 6px; padding-top: 1px; font-size: 16px;'>
            อัปโหลดรูปภาพ
           </span>
         </button>
          <p>เฉพาะไฟล์ .jpg .png และ .gif สูงสุด 5 ภาพ<br/>ขนาดไม่เกิน 2MB ต่อภาพ</p>
        </div>
       `,
      }
      // Create popup.
      return editor.popups.create('customPluginImage.popup', template)
    }
  }

  function initPopupLink() {
    let template = {
      custom_layer: `
        <div class='custom-layer'>
         <div class='label-title'> URL </div>
         <div> <input id='txtLinkURL' type='text' class='ant-input'> </div>
         <div style='height: 18px;'></div>
         <div class='label-title'> ข้อความ </div>
         <div> <input id='txtLinkName' type='text' class='ant-input'> </div>
        </div>
       `,
    }
    return editor.popups.create('customPluginLink.popup', template)
  }

  function initPopupAlign() {
    let template = {
      custom_layer: `
        <div class='custom-layer'>
          <div class='custom-row'>
            <div id='btnAlignLeft'>
              <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABJSURBVHgB7dIxDQAgDETRO4wAIvAvABGAEcAAhJB26NC3Nulw+UQsDVgJIuwBmJDT+KGNfxuxY9R8unxuZHGLG3pHT97Rm72ONkUfHZUf9zwuAAAAAElFTkSuQmCC' alt='align left'>
            </div>
            <div id='btnAlignCenter'>
              <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABOSURBVHgB7ZKxCQAgDAT/XUQdwv0HcAh1EbUStBIxRYpc9wQC/xzhUwFGwBesDuj4R+KHNDzS016saDmu5M7jS1eNu+zQPLpiHt3R59EEnXAdlctDiJAAAAAASUVORK5CYII=' alt='align left'>
            </div>
            <div id='btnAlignRight'>
              <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABFSURBVHgB7ZKxCQAgDAT/XUSXcP9RdBIV7EMISZEi1wYCfxzR5wLugAvuBhz4ifgRDcWLyd13JGHZndNRdaRRHenk6+gBvLQZ+5jzZ8gAAAAASUVORK5CYII=' alt='align left'>
            </div>
          </div>
        </div>
       `,
    }
    return editor.popups.create('customPluginAlign.popup', template)
  }

  // Show the popup
  function showPopup(isVideo) {
    // Get the popup object defined above.
    let $popup = editor.popups.get('customPluginImage.popup')
    if (isVideo) {
      $popup = editor.popups.get('customPluginVideo.popup')
    }

    // If popup doesn't exist then create it.
    // To improve performance it is best to create the popup when it is first needed
    // and not when the editor is initialized.
    if (!$popup) $popup = initPopup(isVideo)

    // Set the editor toolbar as the popup's container.
    if (isVideo) {
      editor.popups.setContainer('customPluginVideo.popup', editor.$tb)
    } else {
      editor.popups.setContainer('customPluginImage.popup', editor.$tb)
    }

    // This custom popup is opened by pressing a button from the editor's toolbar.
    // Get the button's object in order to place the popup relative to it.
    let $btn = editor.$tb.find('.fr-command[data-cmd="customUploadImage"]')
    if (isVideo) {
      $btn = editor.$tb.find('.fr-command[data-cmd="customUploadVideo"]')
    }

    // Set the popup's position.
    const left = $btn.offset().left + $btn.outerWidth() / 2 - 100
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10) + 12

    // Show the custom popup.
    // The button's outerHeight is required in case the popup needs to be displayed above it.
    if (isVideo) {
      editor.popups.show('customPluginVideo.popup', left, top, $btn.outerHeight())
    } else {
      editor.popups.show('customPluginImage.popup', left, top, $btn.outerHeight())
    }
  }

  function showPopupLink() {
    let $popup = editor.popups.get('customPluginLink.popup')
    // clear value
    const txtLinkURLForClear = document.querySelector('#txtLinkURL')
    const txtLinkNameForClear = document.querySelector('#txtLinkName')
    if (txtLinkURLForClear) {
      txtLinkURLForClear.value = ''
    }
    if (txtLinkNameForClear) {
      txtLinkNameForClear.value = ''
    }
    if (!$popup) {
      $popup = initPopupLink()
      const txtLinkURL = document.querySelector('#txtLinkURL')
      const txtLinkName = document.querySelector('#txtLinkName')
      if (txtLinkURL) {
        txtLinkURL.addEventListener('keypress', e => {
          if (e.key === 'Enter') {
            editor.popups.hide('customPluginLink.popup')
            if (txtLinkURL.value) {
              editor.link.insert(txtLinkURL.value, txtLinkName.value ? txtLinkName.value : '')
            }
          }
        })
      }

      if (txtLinkName) {
        txtLinkName.addEventListener('keypress', e => {
          if (e.key === 'Enter') {
            editor.popups.hide('customPluginLink.popup')
            if (txtLinkURL.value) {
              editor.link.insert(txtLinkURL.value, txtLinkName.value ? txtLinkName.value : '')
            }
          }
        })
      }
    }
    editor.popups.setContainer('customPluginLink.popup', editor.$tb)
    let $btn = editor.$tb.find('.fr-command[data-cmd="customInsertLink"]')
    const left = $btn.offset().left + $btn.outerWidth() / 2 - 130
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10) + 12
    editor.popups.show('customPluginLink.popup', left, top, $btn.outerHeight())
  }

  function showPopupAlign() {
    let $popup = editor.popups.get('customPluginAlign.popup')
    if (!$popup) {
      $popup = initPopupAlign()
      const btnAlignLeft = document.querySelector('#btnAlignLeft')
      const btnAlignCenter = document.querySelector('#btnAlignCenter')
      const btnAlignRight = document.querySelector('#btnAlignRight')
      if (btnAlignLeft) {
        btnAlignLeft.addEventListener('click', () => {
          editor.align.apply('left')
          editor.popups.hide('customPluginAlign.popup')
        })
      }
      if (btnAlignCenter) {
        btnAlignCenter.addEventListener('click', () => {
          editor.align.apply('center')
          editor.popups.hide('customPluginAlign.popup')
        })
      }
      if (btnAlignRight) {
        btnAlignRight.addEventListener('click', () => {
          editor.align.apply('right')
          editor.popups.hide('customPluginAlign.popup')
        })
      }
    }
    editor.popups.setContainer('customPluginAlign.popup', editor.$tb)
    let $btn = editor.$tb.find('.fr-command[data-cmd="customAlign"]')
    const left = $btn.offset().left + $btn.outerWidth() / 2 - 40
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10) + 12
    editor.popups.show('customPluginAlign.popup', left, top, $btn.outerHeight())
  }

  // Methods visible outside the plugin.
  return {
    showPopup: showPopup,
    showPopupLink: showPopupLink,
    showPopupAlign: showPopupAlign,
  }
}

const Editor = props => {
  const {
    label,
    name,
    placeholder,
    errors,
    touched: touches,
    form: { setFieldValue, setFieldTouched, values },
    required,
    isEdit = false,
    firstRenderAdd = false,
    disabled = false,
    insertImage = false,
    insertVideo = false,
    insertLink = false,
    align = false,
  } = props
  const imageList = useRef([])
  const videoList = useRef([])
  const isToggleCustomPluginAlign = useRef(false)
  const isToggleCustomPluginLink = useRef(false)
  const isToggleCustomUploadImage = useRef(false)
  const isToggleCustomPluginVideo = useRef(false)
  const value = get(values, name, '')
  const error = get(errors, name, '')
  const touched = get(touches, name, '')
  const [editor, setEditor] = useState(null)
  const [focus, setFocus] = useState(false)
  const [hover, setHover] = useState(false)

  videoList.current = props.videoLink
  imageList.current = props.imageLink

  const handleChange = content => {
    setFieldValue(name, content)
  }

  const setErrorEditor = (isShow, message, showClose) => {
    const elm = document.querySelector('.editor-error-noti')
    if (elm) {
      if (showClose) {
        elm.innerHTML = `<span class='message'></span> <img src='${iconCloseDanger}' alt='' />`
      } else {
        elm.innerHTML = `<span class='message'></span>`
      }
      const messageElm = elm.querySelector('.message')
      messageElm.innerText = message
      if (isShow) {
        elm.setAttribute('style', 'display:flex')
        if (showClose) {
          elm.querySelector('img').addEventListener('click', e => {
            setErrorEditor(false, '', false)
            elm.querySelector('img').removeEventListener('click', () => {})
          })
        }
      } else {
        elm.setAttribute('style', 'display:none')
      }
    }
  }

  React.useEffect(() => {
    // fix insert link not update valid
    const elmLink = document.querySelector('.fr-element.fr-view p a')
    if (elmLink) {
      fnCheckDisplayError('', false)
    } else {
      fnCheckDisplayError(error, touched)
    }
  }, [error, touched])

  const fnCheckDisplayError = (error, touched) => {
    if (error && touched) {
      setErrorEditor(true, error, false)
    } else {
      setErrorEditor(false, error, false)
    }
  }
  const handleFileChangeImage = e => {
    fnCheckDisabledUpload()
    fnCheckDisplayError(error, touched)
    if (e.target.files) {
      const file = e.target.files[0]
      // clear input file
      const elm = document.querySelector('#uploadImage')
      elm.value = null
      const type = file.type
      if ((type !== 'image/png' && type !== 'image/jpeg' && type !== 'image/gif') || file.size > 1048576 * 2) {
        setErrorEditor(true, 'อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาดรูปไม่ถูกต้อง หรือนามสกุลไฟล์ไม่ถูกต้อง', true)
        return
      }

      // upload image to server
      let data = new FormData()
      data.append('file[]', file)
      data.append('status', 'active')
      axios
        .post(process.env.REACT_APP_MEDIA + '/medias', data, {
          headers: {
            Authorization: `Bearer ${getTokenStorage()}`,
          },
        })
        .then(res => {
          let Location = res.data && res.data.link ? res.data.link : null
          editor.image.insert(Location)
          let imageArr = imageList.current
          imageArr.push(Location)
          imageList.current = imageArr
          editor.popups.hide('customPluginImage.popup')
          setTimeout(() => {
            fnCheckDisabledUpload()
          }, 200)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  }
  const handleFileChangeVideo = e => {
    fnCheckDisabledUpload()
    fnCheckDisplayError(error, touched)
    if (e.target.files) {
      const file = e.target.files[0]
      // clear input file
      const elm = document.querySelector('#uploadVideo')
      elm.value = null
      const type = file.type
      if (type !== 'video/mp4' || file.size > 1048576 * 20) {
        setErrorEditor(true, 'อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาดวิดีโอไม่ถูกต้อง หรือนามสกุลไฟล์ไม่ถูกต้อง', true)
        return
      }

      // upload image to server
      let data = new FormData()
      data.append('file[]', file)
      data.append('status', 'active')
      axios
        .post(process.env.REACT_APP_MEDIA + '/medias', data, {
          headers: {
            Authorization: `Bearer ${getTokenStorage()}`,
          },
        })
        .then(res => {
          let Location = res.data && res.data.link ? res.data.link : null
          editor.video.insert(
            `<video src='${Location}' style='width: 100%;' controls='' class='fr-draggable'>Your browser does not support HTML5 video.</video>`
          )
          let imageArr = videoList.current
          imageArr.push(Location)
          videoList.current = imageArr
          editor.popups.hide('customPluginVideo.popup')
          setTimeout(() => {
            fnCheckDisabledUpload()
          }, 200)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  }

  const fnDeleteMedia = url => {
    const urlArr = url.split('/')
    axios
      .get(process.env.REACT_APP_MEDIA + '/medias', {
        params: {
          filters: 'new_name:eq:' + urlArr[urlArr.length - 1],
        },
        headers: {
          Authorization: `Bearer ${getTokenStorage()}`,
        },
      })
      .then(res => {
        let lists = res.data && res.data.data ? res.data.data.lists : []
        if (lists.length > 0) {
          const id = lists[0].id
          const status = lists[0].status
          if (status !== 'temporary') {
            axios
              .delete(process.env.REACT_APP_MEDIA + '/medias/' + id, {
                headers: {
                  Authorization: `Bearer ${getTokenStorage()}`,
                },
              })
              .then(res => {})
              .catch(err => {
                console.log('delete medias err', err)
              })
          }
        }
      })
      .catch(err => {})
  }

  const fnGetSrcList = tagName => {
    let srcList = []
    const elmList = document.querySelectorAll(`.froala-editor .fr-element.fr-view ${tagName}`)
    if (elmList) {
      for (let i = 0; i < elmList.length; i++) {
        srcList.push(elmList[i].src)
      }
    }
    return srcList
  }

  const fnCheckDisabledUpload = () => {
    let videoUrlList = fnGetSrcList('video')
    let imageUrlList = fnGetSrcList('img')
    const btnUploadImage = document.querySelector('.fr-command[data-cmd="customUploadImage"]')
    if (btnUploadImage) {
      if (imageUrlList.length >= 5) {
        btnUploadImage.disabled = true
        btnUploadImage.classList.add('fr-disabled')
      } else {
        if (btnUploadImage.classList.contains('fr-no-refresh')) {
          btnUploadImage.disabled = true
          btnUploadImage.classList.add('fr-disabled')
        } else {
          btnUploadImage.disabled = false
          btnUploadImage.classList.remove('fr-disabled')
        }
      }
    }

    const btnUploadVideo = document.querySelector('.fr-command[data-cmd="customUploadVideo"]')
    if (btnUploadVideo) {
      if (videoUrlList.length >= 1) {
        btnUploadVideo.disabled = true
        btnUploadVideo.classList.add('fr-disabled')
      } else {
        if (btnUploadVideo.classList.contains('fr-no-refresh')) {
          btnUploadVideo.disabled = true
          btnUploadVideo.classList.add('fr-disabled')
        } else {
          btnUploadVideo.disabled = false
          btnUploadVideo.classList.remove('fr-disabled')
        }
      }
    }

    if (props.onFileChange) {
      props.onFileChange(videoUrlList, imageUrlList)
    }
  }

  React.useEffect(() => {
    FroalaeditorX.RegisterCommand('customUploadImage', {
      title: 'Insert Image',
      icon: 'IconUploadImage',
      undo: false,
      focus: false,
      plugin: 'customPlugin',
      callback() {
        if (!isToggleCustomUploadImage.current) {
          this.customPlugin.showPopup(false)
        }
        isToggleCustomUploadImage.current = !isToggleCustomUploadImage.current
      },
    })
    FroalaeditorX.RegisterCommand('customUploadVideo', {
      title: 'Insert Video',
      icon: 'IconUploadVideo',
      undo: false,
      focus: false,
      plugin: 'customPlugin',
      callback() {
        if (!isToggleCustomPluginVideo.current) {
          this.customPlugin.showPopup(true)
        }
        isToggleCustomPluginVideo.current = !isToggleCustomPluginVideo.current
      },
    })
    FroalaeditorX.RegisterCommand('customInsertLink', {
      title: 'Insert Link',
      icon: 'IconInsertLink',
      undo: false,
      focus: false,
      plugin: 'customPlugin',
      callback() {
        if (!isToggleCustomPluginLink.current) {
          this.customPlugin.showPopupLink()
        }
        isToggleCustomPluginLink.current = !isToggleCustomPluginLink.current
      },
    })
    FroalaeditorX.RegisterCommand('customAlign', {
      title: 'Align',
      icon: 'IconAlign',
      undo: false,
      focus: false,
      plugin: 'customPlugin',
      callback() {
        if (!isToggleCustomPluginAlign.current) {
          this.customPlugin.showPopupAlign()
        }
        isToggleCustomPluginAlign.current = !isToggleCustomPluginAlign.current
      },
    })
  }, [])

  return (
    <div style={{ padding: '10px 0' }}>
      <label htmlFor={name} style={{ fontSize: '16px', fontWeight: 500, color: '#001F34', display: 'flex' }}>
        {label}
        <div className="red-star" style={{ paddingLeft: '4px' }}>
          {required && '*'}
        </div>
      </label>

      <div className={`froala-editor ${error && touched && 'error'}`}>
        <EditorZ
          onMouseOver={() => {
            if (disabled) {
              setHover(false)
            } else {
              setHover(true)
            }
          }}
          onMouseLeave={() => setHover(false)}
          hover={hover}
          focus={focus}
        >
          <FroalaEditor
            tag="textarea"
            id={name}
            model={firstRenderAdd ? '' : value}
            onModelChange={handleChange}
            config={{
              charCounterMax: 5,
              key: process.env.REACT_APP_FROALA_KEY,
              placeholderText: placeholder,
              attribution: false,
              initOnClick: isEdit ? true : false,
              toolbarButtons: {
                moreText: {
                  buttons: ['bold', 'italic', 'underline', '|'],
                },
                moreParagraph: {
                  buttons: ['formatUL', 'formatOLSimple', align && '|', align && 'customAlign', align && '|'],
                },
                moreRich: {
                  buttons: [
                    insertLink ? 'customInsertLink' : '',
                    insertImage ? 'customUploadImage' : '',
                    insertVideo ? 'customUploadVideo' : '',
                  ],
                },
                moreMisc: {
                  buttons: ['undo', 'redo'],
                  align: 'right',
                  buttonsVisible: 2,
                },
              },
              quickInsertEnabled: false,
              heightMin: 200,
              heightMax: 250,
              linkAlwaysBlank: true,
              charCounterCount: false,
              pasteAllowedStyleProps: [],
              videoInsertButtons: ['videoBack'],
              videoEditButtons: ['videoAlign', 'videoCaption', 'videoRemove'],
              imageInsertButtons: ['imageBack'],
              imageEditButtons: ['imageAlign', 'imageCaption', 'imageRemove'],
              imagePaste: false,
              imageUpload: false,
              fileUpload: false,
              pasteDeniedTags: ['video'],

              events: {
                blur: () => {
                  setFocus(false)
                  setFieldTouched(name)
                  setTimeout(() => {
                    fnCheckDisabledUpload()
                  }, 200)
                },
                initialized() {
                  // editor = this
                  setEditor(this)
                  if (disabled) {
                    this.edit.off()
                  }

                  // add element noti error
                  const bodyEditor = document.querySelector('.fr-toolbar.fr-desktop.fr-top.fr-basic')
                  const errorElm = document.createElement('div')
                  errorElm.classList.add('editor-error-noti')
                  bodyEditor.append(errorElm)
                },
                'image.beforeRemove': function (img) {
                  let imageUrlList = fnGetSrcList('img')
                  if (props.onImageDelete) {
                    props.onImageDelete(imageUrlList)
                  }
                },
                'image.removed': function (img) {
                  setTimeout(() => {
                    isToggleCustomUploadImage.current = false
                  }, 100)
                },
                'video.beforeRemove': function () {
                  if (props.onVideoDelete) {
                    props.onVideoDelete([])
                  }
                },
                'video.removed': function (img) {
                  setTimeout(() => {
                    isToggleCustomPluginVideo.current = false
                  }, 100)
                },
                'commands.mousedown': function (btn) {
                  console.log('btn', btn)
                },
                click() {
                  fnCheckDisabledUpload()
                  setTimeout(() => {
                    isToggleCustomPluginAlign.current = false
                    isToggleCustomPluginLink.current = false
                    isToggleCustomUploadImage.current = false
                    isToggleCustomPluginVideo.current = false
                  }, 100)
                },
                focus: () => {
                  if (disabled) {
                    setFocus(false)
                  } else {
                    setFocus(true)
                  }
                },
                keydown: function (keydownEvent) {
                  if (keydownEvent.key === 'Backspace' || keydownEvent.key === 'Delete') {
                    fnCheckDisabledUpload()
                  }
                },
                'commands.redo': function () {
                  fnCheckDisabledUpload()
                },
                'commands.undo': function () {
                  fnCheckDisabledUpload()
                },
                'buttons.refresh': function () {
                  fnCheckDisabledUpload()
                },
                'popups.hide.customPluginAlign.popup': function () {
                  setTimeout(() => {
                    isToggleCustomPluginAlign.current = false
                  }, 100)
                },
                'popups.hide.customPluginLink.popup': function () {
                  setTimeout(() => {
                    isToggleCustomPluginLink.current = false
                  }, 100)
                },
                'popups.hide.customPluginImage.popup': function () {
                  setTimeout(() => {
                    isToggleCustomUploadImage.current = false
                  }, 100)
                },
                'popups.hide.customPluginVideo.popup': function () {
                  setTimeout(() => {
                    isToggleCustomPluginVideo.current = false
                  }, 100)
                },
              },
            }}
          />
        </EditorZ>
        {/*{error && touched ? <span style={{ color: '#ED4160', fontSize: 'small', float: 'right' }}>{error}</span> : null}*/}
      </div>
      <input
        type="file"
        id="uploadImage"
        accept="image/png, image/jpeg, image/gif"
        onChange={e => {
          handleFileChangeImage(e)
        }}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        id="uploadVideo"
        accept="video/mp4"
        onChange={e => {
          handleFileChangeVideo(e)
        }}
        style={{ display: 'none' }}
      />
    </div>
  )
}

Editor.defaultProps = {
  error: false,
  name: 'richTextContent',
  onChange: () => {},
}

export default Editor

const EditorZ = styled.div`
  border: ${props => (props.focus || props.hover ? '1px solid #3f54d1' : '1px solid #e2e5f8')};
  border-radius: 10px;

  .fr-box.fr-basic .fr-wrapper {
    border: none;
    border-top: ${props => (props.focus || props.hover ? '1px solid #3f54d1 !important' : 'none')};
  }

  .fr-toolbar {
    border: none;
  }

  .fr-second-toolbar {
    border: none;
  }

  .fr-disabled button {
    cursor: auto !important;
  }

  .fr-disabled {
    color: #9baab1 !important;
  }

  fr-toolbar,
  .fr-desktop,
  .fr-top,
  .fr-basic,
  .fr-sticky-off {
    background-color: #e2e5f8;
  }

  .fr-toolbar.fr-disabled {
    background-color: #e1eaf0;
  }

  .fr-wrapper {
    ::-webkit-scrollbar {
      width: 6px;
      padding-right: 20px;
      /* width: 14px; */
      /* padding-top: 100px; */
    }

    ::-webkit-scrollbar-track {
      background: #c8d3da;
      border-radius: 10px;

      margin-top: 24px;
      margin-bottom: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #9baab1;
      border-radius: 10px;

      border-right: 2px solid transparent;
      border-left: 2px solid transparent;
    }
  }

  .fr-placeholder {
    font-family: 'Prompt';
  }

  .fr-element.fr-view.fr-element-scroll-visible {
    * {
      font-family: 'Prompt';
    }
  }

  .fr-box.fr-basic .fr-element.fr-disabled {
    * {
      color: lightgray !important;
      font-family: 'Prompt';
    }
  }

  .fr-toolbar .fr-command.fr-btn img,
  .fr-popup .fr-command.fr-btn img,
  .fr-modal .fr-command.fr-btn img {
    height: 15px;
  }

  .fr-btn-grp.fr-float-left {
    margin: 0;
  }

  .ant-btn-primary {
    border-color: #fc6521;
    background-color: #fc6521;
    height: 40px;
    width: 167px;
    border-radius: 12px;
  }

  .fr-popup.fr-desktop.fr-active {
    border-radius: 0 0 16px 16px;
    box-shadow: 0px 2px 40px rgba(225, 234, 240, 0.1);
  }

  .custom-layer {
    font-family: 'Prompt';
    font-style: normal;
    padding: 16px 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #203983;
    text-align: center;
    background: #f4f6ff;
    border-radius: 0 0 16px 16px;
  }

  .custom-layer button {
    margin-bottom: 8px;
  }

  .custom-layer p {
    margin-bottom: 0;
    text-align: left;
  }

  .custom-layer .label-title {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #001f34;
    padding-bottom: 4px;
  }

  .custom-layer .ant-input {
    border: 1px solid #e2e5f8;
    border-radius: 12px;
    height: 27px;
    width: 245px;
    color: #001f34;
    font-size: 12px;
    line-height: 15px;
  }

  .custom-layer .ant-input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 1px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
  }

  .custom-row {
    display: inline-flex;
    gap: 30px;
  }

  .custom-row div {
    cursor: pointer;
  }
`
