import React from 'react'
import styled from 'styled-components'

const Message = styled.p`
  color: ${props => (props.type ? '#ED4160' : props.setting.color)};
  font-size: ${props => (props.type ? '12px' : props.setting.fontSize)};
  white-space: pre-line;
  margin: 2px 0 0 0;
  max-width: 824px;
  text-align: end;
  font-family: 'Prompt';
`

Message.defaultProps = {
  type: 'default',
  setting: {
    color: 'var(--warning)',
    fontSize: '12px',
  },
}

export default Message
