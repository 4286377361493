import React, { useEffect, useRef, useState } from 'react'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'
import ProgressChart from 'components/Charts/ProgressChart'
import { useReactToPrint } from 'react-to-print'
import MapCirCle from 'components/OlHeatMap/MapCircle'
import moment from 'moment'
import { Button, notification, Tooltip, Dropdown, Menu, Row, Col, DatePicker, Select } from 'antd'
import IconImport from 'assets/images/svg/icon-import-complaint.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import { checkColorStatusPoint } from './container'
import ReportPdfExusive from './ReportPdf'
import axios from 'axios'
import {
  BoxPointMap,
  BoxNumOfComp,
  TextSatus,
  TextSatusStay,
  LeftBoxNumOfComp,
  NumStyle,
  StyledImage,
  BoxbgChart,
  CardGrid,
  BoxOverAll,
  BoxForm,
  BoxAllState,
  Menucustom,
  MonthText,
  StyleDropdown,
  BoxTitle,
  BoxStatus,
  FlexStatus,
  Numstatus,
  HeaderTopic,
} from './style'
const DashboardExecutiveReport = () => {
  const { Option } = Select
  const ReportRef = useRef(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [dataDashboard, setDataDashboard] = useState([])
  const [isPrints, setIsPrints] = useState(false)
  const [meData, setMedata] = useState([])
  const [monthsTH, setMonthsTH] = useState([])

  const token = localStorage?.getItem('access_token')
  const Donutlabels2 = [
    'รับเรื่อง',
    'อยู่ระหว่างดำเนินการ',
    'ดำเนินการแล้วเสร็จ',
    'ติดตาม',
    'ไม่อยู่ในความรับผิดชอบ',
    'เกินระยะเวลาที่กำหนด',
  ]

  useEffect(() => {
    GetMeData()
    onChangeDuedate()
  }, [])

  const NotdefaultColor = {
    รับเรื่อง: '#915EFF',
    อยู่ระหว่างดำเนินการ: '#FFBB0B',
    ดำเนินการแล้วเสร็จ: '#13AFA6',
    ไม่อยู่ในความรับผิดชอบ: '#3F54D1',
    ติดตาม: '#F36B24',
    เกินระยะเวลาที่กำหนด: '#F85858',
  }

  const dataMockStausColor = {
    ทำทันที: '#FFE4E4',
    ด่วนที่สุด: '#FFEEE7',
    ด่วนมาก: '#FDF2FE',
    ด่วน: '#FFF6EB',
    ปกติ: '#DFF6F5',
  }

  const handlePrint = useReactToPrint({
    content: () => ReportRef.current,
    documentTitle: `reportdash`,

    onAfterPrint: () => {
      setIsPrints(false)
    },
  })

  const onDownload = async typeFile => {
    try {
      const startDate = moment()
        .month(monthsTH ? monthsTH : d.getMonth())
        .startOf('month')
        .startOf('day')
      const endDate = moment()
        .month(monthsTH ? monthsTH : d.getMonth())
        .endOf('month')
        .endOf('day')
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASEURL
        }/dashboard-report/api/v1/dashboard/executive/export?start_at=${startDate.unix()}&end_at=${endDate.unix()}&file_extension=${typeFile}`,
        {
          responseType: 'blob', // Important
        }
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `dashboard-execusive-report.${typeFile}`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {}
  }

  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'export-pdf') {
      setIsPrints(true)
      setTimeout(() => {
        handlePrint()
      }, 2000)
    } else if (e.key === 'export-excel') {
      onDownload('xlsx')
    } else if (e.key === 'export-csv') {
      onDownload('csv')
    }
  }

  const menuImport = value => {
    // setrecord(value)
    return (
      <Menucustom onClick={handleMenuImportClick}>
        <Menucustom.Item key={`export-pdf`}>PDF</Menucustom.Item>
        <Menucustom.Item key={`export-excel`}>XLSX</Menucustom.Item>
        <Menucustom.Item key={`export-csv`}>CSV</Menucustom.Item>
      </Menucustom>
    )
  }
  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }

  const GetMeData = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMeData = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resMeData.status === 200 || resMeData.status === 201) {
        setMedata(resMeData?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const d = new Date()
  const onChangeDuedate = async data => {
    const startDate = moment()
      .month(data ? data : d.getMonth())
      .startOf('month')
      .startOf('day')
    const endDate = moment()
      .month(data ? data : d.getMonth())
      .endOf('month')
      .endOf('day')
    setMonthsTH(data)
    try {
      axios
        .get(
          `${
            process.env.REACT_APP_BASEURL
          }/dashboard-report/api/v1/dashboard/executive?start_at=${startDate.unix()}&end_at=${endDate.unix()}`
        )
        .then(res => {
          let item = res?.data?.data
          item.month = thaiMonths[data]
          setDataDashboard(item)
        })
        .catch(err => {
          console.log('err', err)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ]

  return (
    <>
      <BoxOverAll>
        <HeaderTopic>
          <div>รายงานผู้บริหาร (Executive Report)</div>
          <BoxTitle>
            <Dropdown
              overlay={() => menuImport()}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px',
                  width: '160px',
                  backgroundColor: '#F36B24',
                  borderRadius: '8px',
                  height: '40px',
                  cursor: 'pointer',
                }}
              >
                <img src={IconImport} alt="add" style={{ display: 'inline' }} />
                <span style={{ paddingLeft: '4px', paddingTop: '1px', fontSize: '16px', color: '#ffffff' }}>
                  ส่งออกข้อมูล
                </span>
                <img
                  src={IconArrowDown}
                  alt="add"
                  style={{
                    display: 'inline',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </div>
            </Dropdown>
          </BoxTitle>
        </HeaderTopic>
        <BoxForm className="flex-column">
          <div>เลือกเดือน</div>
          <StyleDropdown>
            <Select
              onChange={onChangeDuedate}
              placeholder="เลือกเดือน"
              value={dataDashboard?.month ? dataDashboard?.month : thaiMonths[d.getMonth()]}
            >
              {thaiMonths?.map((month, index) => (
                <Option key={index} value={index} disabled={index > moment().month()}>
                  {month}
                </Option>
              ))}
            </Select>
          </StyleDropdown>
        </BoxForm>
        <div></div>

        <BoxAllState id={`capture`}>
          <MonthText>
            ประจำเดือน {dataDashboard?.month ? dataDashboard?.month : thaiMonths[d.getMonth()]} {moment().year() + 543}
          </MonthText>

          <CardGrid>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <BoxNumOfComp>
                <LeftBoxNumOfComp>
                  <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                  <TextSatus>จำนวนเรื่องร้องเรียนทั้งหมด</TextSatus>
                  <TextSatusStay>(เรื่อง)</TextSatusStay>
                </LeftBoxNumOfComp>
                <NumStyle>{dataDashboard?.total_complaint}</NumStyle>
              </BoxNumOfComp>
              <BoxbgChart>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ProgressChart
                    data={dataDashboard?.summary_status}
                    timeline
                    NotdefaultColor={NotdefaultColor}
                    PgHeight="30px"
                    isPrints={isPrints}
                  />
                </div>
              </BoxbgChart>

              <BoxbgChart style={{ margin: '20px 10px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>จำนวนเรื่องร้องเรียนจำแนกตามสถานะ</div>
                  <div>
                    {dataDashboard?.summary_urgent?.map((item, index) => {
                      return (
                        <BoxStatus key={index} Bgstatus={dataMockStausColor[item?.urgent_name]}>
                          <FlexStatus>
                            <div>{item?.urgent_name}</div>
                            <div style={{ margin: ' 0 10px' }}>คิดเป็น {item?.percent}%</div>
                          </FlexStatus>
                          <Numstatus colorType={item?.urgent_name}>{item?.total}</Numstatus>
                        </BoxStatus>
                      )
                    })}
                  </div>
                </div>
              </BoxbgChart>
            </div>

            <BoxbgChart>
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', zIndex: 999 }}>แผนที่แสดงเรื่องร้องเรียนจำแนกตามสถานะ</div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    padding: '10px',
                    position: 'absolute',
                    zIndex: 999,
                    width: '145px',
                    height: '160px',
                    top: '30px',
                    left: '20px',
                  }}
                >
                  {Donutlabels2?.map((item, index) => (
                    <BoxPointMap BgColor={checkColorStatusPoint(item)} key={index}>
                      <div className="point" />
                      <span>{item}</span>
                    </BoxPointMap>
                  ))}
                </div>
                {!isPrints && <MapCirCle heatmapData={dataDashboard?.summary_map_status} zooms={13} height={'600px'} />}
              </div>
            </BoxbgChart>
          </CardGrid>
        </BoxAllState>
      </BoxOverAll>

      <div ref={ReportRef} style={{ display: isPrints ? 'block' : 'none' }}>
        <ReportPdfExusive
          dataDashboard={dataDashboard}
          ReportRef={ReportRef}
          NotdefaultColor={NotdefaultColor}
          dataMockStausColor={dataMockStausColor}
          Donutlabels2={Donutlabels2}
          checkColorStatusPoint={checkColorStatusPoint}
          isPrints={isPrints}
          dateHeader={`ประจำเดือน ${dataDashboard?.month ? dataDashboard?.month : thaiMonths[d.getMonth()]} ${
            moment().year() + 543
          }`}
          meData={meData}
        />
      </div>
    </>
  )
}

export default DashboardExecutiveReport
