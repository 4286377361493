import React, { useEffect, useState } from 'react'
import TableCustom from 'components/TableCustom/Row'
import PaginationCustom from 'components/PaginationCustom'
import styled from 'styled-components'
import { Pagination, Select } from 'antd'
import axios from 'axios'
export default function Index() {
  const [dataGroupUser, setDataGroupUser] = useState([])
  const [options, setOptions] = useState([])
  const [currantPage, setCurrantPage] = useState(1)
  const [idGroupUser, setIdGroupUser] = useState('')
  const [dataLength, setDataLength] = useState(0)

  useEffect(() => {
    getMasterGroupUser()
  }, [])

  useEffect(() => {
    getGroupUser()
  }, [idGroupUser, currantPage])

  const getGroupUser = async () => {
    try {
      let confix = {
        headers: {
          'Accept-Language': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        params: {
          page: currantPage,
          per_page: 12,
          filters: idGroupUser ? 'group_id:eq:' + idGroupUser : undefined,
        },
      }
      const res = await axios.get(`${process.env.REACT_APP_BASEURL}/authen/api/v1/users`, confix)
      let confixAll = {
        headers: {
          'Accept-Language': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        params: {
          filters: idGroupUser ? 'group_id:eq:' + idGroupUser : undefined,
        },
      }
      const resAll = await axios.get(`${process.env.REACT_APP_BASEURL}/authen/api/v1/users`, confixAll)
      setDataLength(resAll.data.data?.length)
      setDataGroupUser(res.data.data)
    } catch (error) {
      console.log('error', error)
      setDataGroupUser([])
      setDataLength(0)
    }
  }

  const getMasterGroupUser = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASEURL}/master/api/v1/all/group`)
    let newOptions = []
    res.data.data.map(item => {
      newOptions.push({
        value: item?.id,
        label: item?.name,
      })
    })
    setOptions(newOptions)
  }

  const handleChangeOption = e => {
    setIdGroupUser(e)
  }

  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'sort',
      key: 'sort',
    },
    {
      title: 'กลุ่มผู้ใช้งาน',
      dataIndex: 'group',
      key: 'status',
      render: data => <>{data?.name}</>,
    },
    {
      title: 'ชื่อ',
      render: data => <>{data?.name?.first_name}</>,
    },
    {
      title: 'นามสกุล',
      render: data => <>{data?.name?.last_name}</>,
    },
    {
      title: 'Line ID',
      dataIndex: 'line_name',
      key: 'line_name',
      render: line_name => <>{line_name ? line_name : '-'}</>,
    },
  ]

  return (
    <Content>
      <h2>กลุ่มผู้ใช้งาน</h2>
      <div style={{ backgroundColor: '#f5e7e3', padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
        <p>กลุ่มผู้ใช้งาน</p>
        <Select
          placeholder="เลือกกลุ่มผู้ใช้งาน"
          style={{ width: '200px' }}
          allowClear
          options={options}
          onChange={handleChangeOption}
        />
      </div>
      <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '10px' }}>
        <TableCustom
          columns={columns}
          dataSource={dataGroupUser}
          pagination={false}
          scroll={{
            x: 1000,
          }}
        />
        {dataLength ? (
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              defaultCurrent={currantPage}
              onChange={setCurrantPage}
              total={dataLength}
              pageSize={12}
              showSizeChanger={false}
            />
          </div>
        ) : null}
      </div>
    </Content>
  )
}

const Content = styled.div`
  padding: 24px;
  .ant-table-footer {
    display: none !important;
  }
  .ant-pagination-item-active a {
    color: #000000 !important;
  }

  .ant-pagination-item-active {
    font-weight: 500;
    background: #f36b24;
    border-color: #f36b24;
  }
  .ant-pagination-item {
    border: none !important;
  }
  .ant-pagination-item:hover {
    border-color: #f36b24;
    transition: all 0.3s;
  }
  .ant-pagination-item-link {
    border: none !important;
  }
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #f36b24 !important;
    border-color: #f36b24;
  }
  .ant-pagination-item:hover a {
    color: #000000 !important;
  }
  .ant-pagination-item-link {
    border: none !important;
  }
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: #f36b24;
    border-color: #f36b24;
    border: none !important;
  }
  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 12px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #ffeee7;
    color: #f36b24 !important;
    height: 40px;
    border-radius: 8px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #ffc4aa;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow {
    color: #f36b24;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`
