import styled, { createGlobalStyle } from 'styled-components'
import { Button, Space, Modal, Menu, Drawer } from 'antd'

export const BoxTableCustom = styled.div`
  .ant-table-tbody tr {
    height: 60px !important;
    /* height: 48px !important; */
    td {
      background: #ffffff !important;
      font-weight: var(--text-weight-regular);
    }
  }

  .eclip-text {
    @media only screen and (max-width: 900px) {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media only screen and (max-width: 768px) {
    overflow-x: auto !important;

    table {
      table-layout: auto !important;
      overflow-x: auto !important;
    }
  }

  @media only screen and (max-width: 375px) {
    overflow-x: auto !important;

    table {
      padding-bottom: 20px !important;
      table-layout: auto !important;
    }
  }
`

export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 22px;
  border-radius: 8px;

  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 162px;
    border-radius: 8px;
  }

  @media only screen and (max-width: 768px) {
    .ant-btn-primary {
      height: 40px;
      width: 122px;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 375px) {
    .ant-btn-primary {
      height: 40px;
      width: 122px;
      border-radius: 8px;
    }
  }
`
export const BoxSearch = styled.div`
  height: 90px;
  background: rgba(252, 101, 33, 0.03);
  border-radius: 8px !important;
  margin-bottom: 10px;
  padding: 10px 30px;

  .ant-select-selection-search-input {
    /* border-color: #F36B24 !important; */
    border-radius: 8px !important;
    border: #ffffff;
  }
  /* .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #F36B24 !important;
    border: 1px solid var(--web-backend-primary-brand, #F36B24) !important;
    border-radius: 8px;
  } */

  .ant-input-affix-wrapper:focus {
    border-color: #f36b24 !important;
    box-shadow: none !important;
    border-radius: 8px;
  }
  /* .ant-input-affix-wrapper {
    border-radius: 8px !important;
    border-color: #F36B24 !important;
  } */
  /* .ant-select,.ant-select-auto-complete,.ant-select-single,.ant-select-customize-input,.ant-select-show-search{
    border-color: #F36B24 !important;

  } */
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: #f36b24 !important;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #f36b24 !important;
  }
  .bsrNcp .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
  }

  .ant-select-selection-search-input {
    color: #f36b24 !important;
  }
  /* input{
    color:#F36B24 !important;
    border-color: #F36B24 !important;
  } */
  /* input{
      color:#F36B24 !important;
    } */
  .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
    box-shadow: none !important;
    border-radius: 8px;
    ${props =>
      props.isLogin &&
      `
   .ant-input-prefix {
      display: none !important;
    }
  `}
  }
  .ant-input::placeholder {
    border-color: #f36b24 !important;
    color: var(--web-backend-extended-orange-700, var(--web-backend-orange-700, #ffc4aa)) !important;
  }
  .bJujbi .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24 !important;
  }
  .ant-select {
    cursor: text;
    caret-color: #f36b24 !important;
  }

  .ant-input-affix-wrapper:focus {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }
  .ant-select,
  .ant-select-auto-complete,
  .ant-select-single,
  .ant-select-customize-input,
  .ant-select-show-search {
    border-color: #f36b24 !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24 !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .bsrNcp .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24 !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }
  .ant-select-focused:not(.ant-select:hover) {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }
  .bsrNcp .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }
  .ant-input-affix-wrapper-focused {
    .ant-select {
      box-shadow: none !important;
      border-color: #f36b24 !important;
    }
  }

  .bJujbi .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffeee7 !important;
    color: #f36b24 !important;
  }
  .ant-input:hover {
    border-color: #f36b24 !important;
  }
  .eIHHSN .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .ant-select:hover,
  .ant-select-auto-complete:hover,
  .ant-select-single:hover,
  .ant-select-customize-input:hover,
  .ant-select-show-search:hover {
    border-color: #f36b24 !important;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 10px;
    background: none !important;

    input {
      width: 110px !important;
    }
    span.ant-input-affix-wrapper .ant-select-selection-search-input {
      width: 110px !important;
    }
    span.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
      width: 110px !important;
    }
    .ant-input-affix-wrapper {
      min-width: 110px !important;
    }
  }

  @media only screen and (max-width: 375px) {
    padding: 10px 10px;
    background: none !important;

    input {
      width: 110px !important;
    }
    span.ant-input-affix-wrapper .ant-select-selection-search-input {
      width: 110px !important;
    }
    span.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
      width: 110px !important;
    }
    .ant-input-affix-wrapper {
      min-width: 110px !important;
    }
  }
`

export const BoxIcon = styled.div`
  display: flex !important;
  align-items: center !important;
  gap: 8px;

  .ant-btn-circle {
    height: 100%;
    min-width: 16px !important;
    display: flex;
    align-items: center;
    border-color: transparent;
    background-color: transparent;
    justify-content: center;
    box-shadow: none !important;
    color: transparent !important;

    .ant-btn:focus,
    .ant-btn:hover {
      border-color: transparent;
      background-color: transparent;
      box-shadow: none !important;
      color: transparent !important;
    }
  }

  .ant-btn-icon-only {
    width: 18px !important;
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'IBM Plex Sans Thai', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

export const NotiStyle2 = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'IBM Plex Sans Thai', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

export const Menucustom = styled(Menu)`
  padding: 8px !important;
  width: 160px !important;
  .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
  .ant-dropdown-menu-title-content:hover {
    background-color: #ffeee7;
    color: #f36b24;
    border-radius: 4px;
  }
`

export const CustomModal2 = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomModal3 = styled(Modal)`
  font-family: 'IBM Plex Sans Thai', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const Box = styled.div`
  background-color: white;
  min-height: 600px;
  align-items: center;
  border-radius: 20px;
`

export const BoxNoData = styled.div`
  min-height: 600px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #597081;
  }
`

export const StyleDropdown = styled.div`
  .ant-select,
  .ant-select-selector {
    border-radius: 8px !important;
    border: #ffffff !important;
    height: 40px;
    width: 100%;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
    border: #ffffff !important;
    height: 40px;
    width: 100%;
    /* display: flex; */
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffeee7;
    color: #f36b24;

    .bsrNcp .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: #ffeee7;
      color: #f36b24;
    }
    .ant-select-item-option-active {
      background-color: #ffeee7 !important ;
      color: #f36b24 !important;
    }
    .ant-select:hover {
      border-color: #f36b24 !important;
    }
    .ant-select-focused {
      border-color: #f36b24 !important;
    }

    /* .ant-select-focused:focus{
    border-color: #F36B24 !important;

  } */
  }
`

export const DrawerFormInput = styled(Drawer)`
  .ant-drawer-body {
    padding: 40px;
  }

  .close-icon {
    position: absolute;
    right: -30px;
    top: -27px;
    cursor: pointer;
  }

  .ant-drawer-body {
    /* overflow: scroll;
    overflow-x: hidden; */
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background-color: #eaeaeb !important; /* Set the scrollbar track color */
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #f36b24 !important; /* Set the scrollbar thumb color */
      color: red !important;
      border-radius: 8px;
    }
  }
`

export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  z-index: 1400 !important;

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
