import React, { useState, useContext, useEffect } from 'react'
import { Button, notification, Tooltip, Dropdown, Menu, Row, Col } from 'antd'
import Table from 'components/Table/Row'
import moment from 'moment'
import Pagination from 'components/pagination'
import styled, { createGlobalStyle } from 'styled-components'
import { getList } from 'core/action/collection'
import { unixToDateThai } from 'util/date-format'
import { conversDate } from 'core/utils/DateTimeFormat'
import Search from './search'
import { convertAtivityIcon, convertAtivityThai } from './container'
import { BoxTableCustom } from './list.style'

const ListPc = () => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [record, setrecord] = React.useState([])
  const [dataTotal, setDatToal] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const [masterData, setMasterData] = useState([])
  const [searchData, setSearchData] = useState([])

  useEffect(() => {
    getMasterData()
    getListData()
  }, [currentPage, searchData])

  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })
    const resStatus = await getList({
      schema: '/master/api/v1/all/status',
      params: '',
    })
    const resUrgency = await getList({
      schema: '/master/api/v1/all/urgency',
      params: '',
    })
    const resDepartment = await getList({
      schema: '/master/api/v1/all/organizations',
      params: '',
    })

    const resActivity = await getList({
      schema: '/master/api/v1/all/activity-log',
      params: '',
    })

    setMasterData({
      categories: resCategories?.items,
      status: resStatus?.items,
      urgency: resUrgency?.items,
      department: resDepartment?.items,
      activity: resActivity?.items,
    })
  }

  const getListData = async () => {
    const res = await getList({
      schema: `/complaint/api/v1/activitylog`,
      // schema: `/complaint/api/v1/complaint`,
      params: {
        page: currentPage,
        per_page: 10,
        filters: [
          searchData?.activity ? `activity_type:eq:${searchData?.activity}` : undefined,
          searchData?.category ? `category_id:eq:${searchData?.category}` : undefined,
          searchData?.department ? `department_data.name:eq:${searchData?.department}` : undefined,
          searchData?.status ? `status_data.name:eq:${searchData?.status}` : undefined,
          searchData?.urgency ? `urgency_id:eq:${searchData?.urgency}` : undefined,
          searchData?.satisfaction ? `rating.rating:eqInt:${searchData?.satisfaction}` : undefined,
          searchData?.date
            ? `created_at:between:${moment(searchData?.date[0]).startOf('day').unix()}|${moment(searchData?.date[1])
                .endOf('day')
                .unix()}`
            : undefined,
          searchData?.search ? `staff_data.first_name:like:${searchData?.search}` : undefined,
          searchData?.search ? `staff_data.last_name:like:${searchData?.search}` : undefined,
        ],
        // search: [
        //   searchData?.search ? `staff_data.first_name:like:${searchData?.search}` : undefined,
        //   searchData?.search ? `staff_data.last_name:like:${searchData?.search}` : undefined,
        // ],
      },
      url: '',
      baseURL: '',
    })

    if (res.dataLength > 0) {
      setrecord(res?.items)
      setDatToal(res.dataLength)
    } else {
      setrecord([])
    }
  }

  const columns = [
    {
      title: <div style={{ color: '#717171' }}>เรื่องที่ร้องเรียน</div>,
      dataIndex: 'complaint_name',
      ellipsis: true,
      // className: 'gap',
      render: data => <>{data ? (data ? data : '-') : '-'}</>,
    },
    {
      title: <div style={{ color: '#717171' }}>เลขที่เรื่องร้องเรียน</div>,
      dataIndex: 'complaint_id',
      render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      // title: 'กิจกรรม',
      title: <div style={{ color: '#717171' }}>กิจกรรม</div>,
      dataIndex: '',
      render: (text, data) => {
        return data.activity_type ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src={convertAtivityIcon(data?.activity_type)} style={{ width: '14px', height: '14px' }} />
            {/* {data?.category?.name} */}
            {convertAtivityThai(data.activity_type)}
          </div>
        ) : (
          <div>-</div>
        )
      },
    },
    {
      title: <div style={{ color: '#717171' }}>หน่วยงานที่รับผิดชอบ</div>,
      dataIndex: '',
      render: data => (
        <>
          {data ? (
            data ? (
              data?.department_data?.map(item => {
                return <div style={{ color: '#556A82' }}>{item.name}</div>
              })
            ) : (
              '-'
            )
          ) : (
            <span>-</span>
          )}
        </>
      ),
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      title: <div style={{ color: '#717171' }}>ผู้รับผิดชอบ</div>,
      dataIndex: '',
      render: data => (
        <>
          {data ? (
            data ? (
              <div>
                <span>{data?.staff_data?.first_name}</span> <span>{data?.staff_data?.last_name}</span>
              </div>
            ) : (
              '-'
            )
          ) : (
            <span>-</span>
          )}
        </>
      ),
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      title: <div style={{ color: '#717171' }}>วันที่แจ้งเรื่องร้องเรียน</div>,
      dataIndex: 'created_at',
      // className: 'font-w-400',
      render: data => {
        return <div>{unixToDateThai(data)}</div>
      },
    },
  ]

  const onPageChange = page => {
    setCurrentPage(page)
  }

  return (
    <>
      <NotiStyle />
      {contextHolder}
      <BoxContainer>
        <h1>บันทึกการใช้งาน (Logs)</h1>

        <BoxSearch>
          <Search
            masterData={masterData}
            setSearchData={setSearchData}
            searchData={searchData}
            conversDate={conversDate}
          />
        </BoxSearch>
        <BoxTableCustom>
          <Table columns={columns} dataSource={record} />
        </BoxTableCustom>

        <div style={{ marginBottom: '20px' }}>
          <Pagination defaultCurrent={1} total={dataTotal} onChange={e => onPageChange(e)} current={currentPage} />
        </div>
      </BoxContainer>
    </>
  )
}
export default ListPc

const BoxSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 90px; */
  background: rgba(252, 101, 33, 0.03);
  border-radius: 24px !important;
  padding: 15px;
  /* margin-bottom: 10px;
  padding: 10px 30px; */
`

const BoxContainer = styled.div`
  margin: 0 2%;
`

const Boxbetween = styled.div`
  display: flex;
  justify-content: space-between;
`

const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 4px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 3px;
    }
  }
`
