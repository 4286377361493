import React from 'react'
import { Button, Tooltip, Space, Form, Input } from 'antd'
import { InputCustom } from 'components/input'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'

import { postData, putData, getData, getList } from 'core/action/collection'
import { Schema, defaultValues } from './container'
import {
  CustomSpace,
  Title,
  Box,
  CustomModal,
  ValidateInput,
  Content,
  BoxRolePermission,
  Label,
  ButtonBack,
  ButtonEdit,
  ButtonDelete,
  CustomCheckbox,
  CustomModal2,
} from './form.style'

import useAllPermissions from 'core/hooks/useAllPermissions'
import { authentication2 } from 'core/schemas'

import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { DashboardOutlined } from '@ant-design/icons'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import iconbin from 'assets/images/svg/icon-bin-org-manage.svg'
import iconedit from 'assets/images/svg/icon-edit-org-manage.svg'
import { Col, Row } from 'antd'
import { ReactComponent as IconAlert } from 'assets/images/svg/alert.svg'
import Alert from 'assets/images/svg/offline/alert.js'
import { TRUE } from 'ol/functions'

const Index = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const allow = useAllPermissions(props.permissions)

  const { state } = location
  const id = state?.record?.id
  const name = state?.record?.name
  const description = state?.record?.description
  const role = state?.role

  const isView = state?.isView ? state?.isView : false

  const [defaultValueFromList, setDefaultValueFromList] = React.useState(
    { name, description } || { name: '', description: '' }
  )
  const [formAntd] = Form.useForm()

  const [leaveModalOpen, setLeaveModalOpen] = React.useState(false)
  const [editModalOpen, setEditModalOpen] = React.useState(false)
  const [isUnique, setIsUnique] = React.useState(false)
  const [alertModalOpen, setAlertModalOpen] = React.useState(false)
  const [checkclick, setcheckclick] = React.useState(false)
  const [allMenu, setAllMenu] = React.useState([])
  const [allMenuCheck, setAllMenuCheck] = React.useState([])
  const [allMenuCheckInit, setAllMenuCheckInit] = React.useState([])
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false)
  const [deletionType, setDeletionType] = React.useState(null)
  const [isValidForm, setIsValidForm] = React.useState(false)
  const [isValidFormEdit, setIsValidFormEdit] = React.useState(false)
  const [nameEdit, setNameEdit] = React.useState('')
  const [discriptionEdit, setDiscriptionEdit] = React.useState('')
  // const [permission, setPermission] = React.useState([])
  let validate = true

  const roles = [
    {
      label: 'SuperAdministrator',
      value: 'SuperAdministrator',
    },
  ]

  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {},
  })
  const initialValues = formik.initialValues.name

  React.useEffect(() => {
    formik.setStatus(false)
    if (state?.isEdit || isView) {
      getAllMenus()
      // getRolePermission()
    } else {
      getAllMenus()
    }

    if (state?.isDelete) {
      setDeleteConfirmModalOpen(true)
      setDeletionType(state.record?.status)
    }
  }, [])

  const onOkDelete = () => {
    setDeleteConfirmModalOpen(false)
    localStorage.setItem('modal', 'delete')
    navigate('/operation-tools/bof-role', {
      state: {
        showNotidelete: true,
        record: state.record,
        isDelete: true,
      },
    })
  }

  const callfunction = state => {
    setDeleteConfirmModalOpen(true)
    setDeletionType(state.record?.status)
  }

  const canceldelete = () => {
    setDeleteConfirmModalOpen(false)
    navigate('/operation-tools/bof-role')
  }

  const checkValidateRoleChecked = (allMenu_, allMenuCheck_) => {
    if (checkclick) {
      let validate = false
      let permissionCheck = []
      try {
        for (var i in allMenuCheck_) {
          if (allMenuCheck_[i]) {
            permissionCheck.push({
              key_slug: i.split('|')[0],
              type: i.split('|')[1],
            })
          }
        }
      } catch (e) {}
      let permissionCheck_ = []
      try {
        for (var i in allMenu_) {
          if (allMenu_[i]) {
            permissionCheck_.push({
              key_slug: i.split('|')[0],
              type: i.split('|')[1],
            })
          }
        }
      } catch (e) {}

      const disappearedItems = permissionCheck.filter(
        itemA => !permissionCheck_.some(itemB => itemB.key_slug === itemA.key_slug && itemB.type === itemA.type)
      )
      const addedItems = permissionCheck_.filter(
        itemB => !permissionCheck.some(itemA => itemA.key_slug === itemB.key_slug && itemA.type === itemB.type)
      )

      if (addedItems.length > 0 || disappearedItems.length > 0) {
        validate = true
      }

      return validate
    }
  }

  const isValidateRole = checkValidateRoleChecked(allMenuCheck, allMenuCheckInit)
  let checkName = formAntd.getFieldValue('nameRole') !== nameEdit
  let checkDiscription = formAntd.getFieldValue('description') !== discriptionEdit
  const isValidFormBack = checkName || checkDiscription
  const isDescriptionEmpty =
    formik.getFieldMeta('description').value === undefined || formik.getFieldMeta('description').value === ''

  React.useEffect(() => {
    handleChange()
  }, [isValidateRole, nameEdit])

  const uniqueValidate = async value => {
    if (formAntd?.getFieldValue('nameRole') === nameEdit) return false
    const sendToList = {
      schema: `${authentication2}/roles`,
      params: { page: 1, per_page: 10, filters: [`name:eq:${formAntd.getFieldValue('nameRole')}`] },
      url: '',
      baseURL: '',
    }
    const res = await getList(sendToList).catch(err => {
      console.log('err', err)
    })

    if (state?.isEdit) {
      if (res?.code === 200 && res?.items?.[0]?.name === initialValues) {
        setIsUnique(false)
        return
      }
    }

    if (res?.code === 200) {
      formAntd.setFields([
        {
          name: 'nameRole', // required
          value: formAntd.getFieldValue('nameRole'), //optional
          errors: ['กรุณาพิมพ์คำใหม่ เนื่องจากมีรายการที่ใช้คำดังกล่าวแล้ว'],
        },
      ])
      setIsUnique(value)
      setIsValidFormEdit(false)
      setIsValidForm(false)
    } else {
      formAntd.setFields([
        {
          name: 'nameRole', // required
          value: formAntd.getFieldValue('nameRole'), //optional
          errors: '',
        },
      ])
    }
  }

  const getAllMenus = async () => {
    await getData({
      schema: `${authentication2}/all/menus`,
    })
      .then(res => {
        id ? getRoles() : setAllMenu(res?.data ? res.data : [])
      })
      .catch(err => {
        console.log('err', err)
        // setAlertModalOpen(true)
      })
  }

  const getRoles = async () => {
    const resp = await getData({ schema: `${authentication2}/roles/${id}` })
    const { name, description } = resp?.data ? resp.data : {}
    let menuCheck = {}
    for (var i = 0; i < resp?.data?.roles_menus?.length; i++) {
      for (var j = 0; j < resp?.data?.roles_menus[i]?.sub_menus?.length; j++) {
        let sub_menus = resp?.data?.roles_menus[i]?.sub_menus[j]
        if (sub_menus?.roles_permission && sub_menus?.roles_permission?.can_read) {
          menuCheck[`${sub_menus?.key_slug}|can_read`] = true
        } else {
          menuCheck[`${sub_menus?.key_slug}|can_read`] = false
        }
        if (sub_menus?.roles_permission && sub_menus?.roles_permission?.can_create) {
          menuCheck[`${sub_menus?.key_slug}|can_create`] = true
        } else {
          menuCheck[`${sub_menus?.key_slug}|can_create`] = false
        }
        if (sub_menus?.roles_permission && sub_menus?.roles_permission?.can_update) {
          menuCheck[`${sub_menus?.key_slug}|can_update`] = true
        } else {
          menuCheck[`${sub_menus?.key_slug}|can_update`] = false
        }
        if (sub_menus?.roles_permission && sub_menus?.roles_permission?.can_delete) {
          menuCheck[`${sub_menus?.key_slug}|can_delete`] = true
        } else {
          menuCheck[`${sub_menus?.key_slug}|can_delete`] = false
        }
      }
    }
    setAllMenuCheckInit(menuCheck)
    formAntd.setFieldValue('nameRole', name)
    formAntd.setFieldValue('description', description)
    setNameEdit(name)
    setDiscriptionEdit(description)
    const newFormat = resp?.data?.roles_menus?.map(item => {
      const newSubMenu =
        (item?.sub_menus &&
          item?.sub_menus?.length > 0 &&
          item?.sub_menus?.map(sub => {
            const { can_create, can_read, can_update, can_delete } = sub.roles_permission
            return {
              ...sub,
              roles_permission: {
                read: can_read,
                create: can_create,
                update: can_update,
                delete: can_delete,
              },
            }
          })) ||
        []
      return { ...item, sub_menus: newSubMenu }
    })
    const newPermission = newFormat?.map(item => {
      return { ...item, isReqMain: checkValidate(item.sub_menus) }
    })
    validate = newPermission?.every(e => e.isReqMain === true || e.isReqMain === undefined)

    await setAllMenu(newPermission)
  }

  const changeFormatPermission = async permission => {
    const filterPermiss = permission.map(item => {
      const newSub =
        (item?.sub_menus &&
          item?.sub_menus?.length > 0 &&
          item?.sub_menus?.filter(sub => {
            return (
              sub?.roles_permission &&
              (sub?.roles_permission?.read ||
                sub?.roles_permission?.create ||
                sub?.roles_permission?.update ||
                sub?.roles_permission?.delete)
            )
          })) ||
        []

      return newSub
    })
    let arrPermiss = []

    for (let i = 0; i < filterPermiss.length; i++) {
      arrPermiss = arrPermiss.concat(filterPermiss[i])
    }

    const newPermission = arrPermiss.map(item => {
      const { roles_permission, sub_menu_permission } = item

      const read = roles_permission.read ? sub_menu_permission.read : []
      const create = roles_permission.create ? sub_menu_permission.create : []
      const update = roles_permission.update ? sub_menu_permission.update : []
      const can_delete = roles_permission.delete ? sub_menu_permission.delete : []
      const module = sub_menu_permission.module
      const itemPermiss = {
        read,
        create,
        update,
        delete: can_delete,
        module,
      }

      Object.entries(itemPermiss).map(items => (!items[1] || !items[1].length ? delete itemPermiss[items[0]] : 0))
      let dataResult = { ...itemPermiss, key_sub_menu: item.key_slug }

      return dataResult
    })

    return newPermission
  }

  const handlerSubmit = async () => {
    const newPermission = await changeFormatPermission(allMenu)
    const tmpValues = {
      name: formAntd.getFieldValue('nameRole'),
      description: formAntd.getFieldValue('description'),
      status: state && state.record && state.record.status ? state.record.status : 'inactive',
      permissions: newPermission,
    }

    if (!state?.isEdit) {
      const res = await postData(`${authentication2}/roles`, { ...tmpValues })
      if (res.code === 201) {
        localStorage.setItem('modal', 'create')
        navigate('/operation-tools/bof-role', { state: { showNoti: true, role: role } })
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
      if (res.errors?.[0].code === 'unique') {
        formik.setErrors({ name: 'กรุณาพิมพ์คำใหม่ เนื่องจากมีรายการที่ใช้คำดังกล่าวแล้ว' })
        setIsUnique(true)
      }
    } else {
      const res = await putData(`${authentication2}/roles`, id, { ...tmpValues })
      if (res.code === 200) {
        localStorage.setItem('modal', 'update')
        navigate('/operation-tools/bof-role', { state: { showNoti: true, role: role } })
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
      if (res.errors?.[0].code === 'unique') {
        formik.setErrors({ name: 'กรุณาพิมพ์คำใหม่ เนื่องจากมีรายการที่ใช้คำดังกล่าวแล้ว' })
        setIsUnique(true)
      }
    }
  }

  const renderTooltipCreate = () => {
    return (
      <React.Fragment>
        {!isValidForm ? (
          <Tooltip
            overlayInnerStyle={{
              backgroundColor: '#597081',
              borderRadius: '12px',
              fontSize: '10px',
              width: '212px',
              height: '36px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 6px 10px 12px',
            }}
            overlayStyle={{ padding: '4px' }}
            showArrow={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValidForm}
              onClick={handlerSubmit}
              style={{ fontSize: '16px' }}
            >
              บันทึก
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValidForm}
            onClick={handlerSubmit}
            style={{ fontSize: '16px' }}
          >
            บันทึก
          </Button>
        )}
      </React.Fragment>
    )
  }
  const renderTooltipEdit = () => {
    return (
      <React.Fragment>
        {!isValidFormEdit ? (
          <Tooltip
            overlayInnerStyle={{
              backgroundColor: '#597081',
              borderRadius: '12px',
              fontSize: '10px',
              width: '212px',
              height: '36px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 6px 10px 12px',
            }}
            overlayStyle={{ padding: '4px' }}
            showArrow={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValidFormEdit}
              onClick={handlerSubmit}
              style={{ fontSize: '16px' }}
            >
              บันทึก
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValidFormEdit}
            onClick={() => {
              setEditModalOpen(true)
            }}
            style={{ fontSize: '16px' }}
          >
            บันทึก
          </Button>
        )}
      </React.Fragment>
    )
  }
  const renderDeleteModal = isOffline => {
    if (isOffline === 'inactive') {
      return (
        <CustomModal
          transitionName=""
          centered
          open={deleteConfirmModalOpen}
          onOk={onOkDelete}
          onCancel={canceldelete}
          closable={false}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <Alert />
          </div>
          <p
            style={{
              color: '#001F34',
              fontSize: '16px',
              fontWeight: '400',
              marginBottom: '2px',
            }}
          >
            คุณต้องการลบข้อมูลนี้?
          </p>
          <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300' }}>
            หากคุณลบข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
          </p>
        </CustomModal>
      )
    } else if (isOffline === 'active') {
      return (
        <CustomModal2
          transitionName=""
          centered
          open={deleteConfirmModalOpen}
          closable={false}
          okText="ตกลง"
          footer={
            <Button type="primary" onClick={() => canceldelete()}>
              ตกลง
            </Button>
          }
        >
          <div style={{ paddingBottom: '10px' }}>
            <Alert />
          </div>
          <p style={{ color: '#001F34', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
            ไม่สามารถลบข้อมูลได้
          </p>
          <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300', lineHeight: '14.4px' }}>
            เนื่องจากข้อมูลถูกใช้งานอยู่ในเมนูจัดการผู้ใช้งาน
            <br />
            กรุณาลบข้อมูลที่ใช้งานจากเมนูข้างต้นก่อน
          </p>
        </CustomModal2>
      )
    }
  }
  const renderMode = () => {
    if (id) {
      return (
        <React.Fragment>
          {!isValidFormEdit ? (
            <CustomSpace>
              {renderTooltipEdit()}
              <CustomModal
                transitionName=""
                centered
                open={leaveModalOpen}
                onOk={() => {
                  setLeaveModalOpen(false)
                  navigate(-1)
                }}
                onCancel={() => setLeaveModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
                <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
              </CustomModal>
            </CustomSpace>
          ) : (
            <CustomSpace>
              {renderTooltipEdit()}
              <CustomModal
                transitionName=""
                centered
                open={editModalOpen}
                onOk={() => {
                  setEditModalOpen(false)
                  handlerSubmit()
                }}
                onCancel={() => setEditModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p
                  style={{
                    color: '#001F34',
                    fontSize: '16px',
                    marginBottom: '2px',
                    fontWeight: '400',
                  }}
                >
                  ยืนยันการแก้ไขข้อมูล
                </p>
                <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300' }}>
                  หากคุณแก้ไขข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
                </p>
              </CustomModal>
            </CustomSpace>
          )}
        </React.Fragment>
      )
    } else {
      // Create Mode
      return (
        <React.Fragment>
          {!isValidFormBack && isDescriptionEmpty ? (
            <CustomSpace align="end">
              {renderTooltipCreate()}
              <CustomModal
                transitionName=""
                centered
                open={leaveModalOpen}
                onOk={() => {
                  setLeaveModalOpen(false)
                  navigate(-1)
                }}
                onCancel={() => setLeaveModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
                <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
              </CustomModal>
            </CustomSpace>
          ) : (
            <CustomSpace align="end">
              {renderTooltipCreate()}

              <CustomModal
                transitionName=""
                centered
                open={leaveModalOpen}
                onOk={() => {
                  setLeaveModalOpen(false)
                  navigate(-1)
                }}
                onCancel={() => setLeaveModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
                <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
              </CustomModal>
            </CustomSpace>
          )}
        </React.Fragment>
      )
    }
  }
  const renderCheckbox = (e, role, mainMenu, permission) => {
    const newPermission = permission.map(main => {
      if (main.name === mainMenu) {
        const newSub = main.sub_menus.map(sub => {
          if (sub.name === e.target.name) {
            if (sub.key_slug.substring(0, 6) === 'mobile') {
              return { ...sub, roles_permission: { create: e.target.checked } }
            } else if (RegExp('pdpa').test(sub.key_slug)) {
              if (e.target.checked === true) {
                if (role === 'update') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      update: true,
                    },
                  }
                } else {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      read: true,
                    },
                  }
                }
              } else {
                if (role === 'read') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      read: false,
                    },
                  }
                } else {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      update: false,
                    },
                  }
                }
              }
            } else {
              if (e.target.checked === true) {
                if (role === 'delete') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      delete: true,
                    },
                  }
                } else if (role === 'update') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      update: true,
                    },
                  }
                } else if (role === 'create') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      create: true,
                    },
                  }
                } else {
                  return {
                    ...sub,
                    roles_permission: { ...sub.roles_permission, read: true },
                  }
                }
              } else {
                if (role === 'delete') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      delete: false,
                    },
                  }
                } else if (role === 'update') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      update: false,
                    },
                  }
                } else if (role === 'create') {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      create: false,
                    },
                  }
                } else {
                  return {
                    ...sub,
                    roles_permission: {
                      ...sub.roles_permission,
                      read: false,
                      delete: false,
                      create: false,
                      update: false,
                    },
                  }
                }
              }
            }
          } else {
            return { ...sub }
          }
        })
        return { ...main, sub_menus: newSub, isReqMain: checkValidate(newSub) }
      } else {
        return { ...main }
      }
    })
    return newPermission
  }

  const checkValidate = newSub => {
    const reqSub = newSub.map(item => {
      if (item.roles_permission) {
        const permission = { ...item.roles_permission }
        delete permission.module

        const req = Object.values(permission).every(e => e === false)

        return { ...item, isReqSub: req }
      }

      return { ...item, isReqSub: true }
    })

    const reqMain = reqSub.every(e => e.isReqSub === true)
    return reqMain
  }

  const onChangePermission = (e, role, mainMenu, sub_menu, can_crud) => {
    let _allMenuCheck = { ...allMenuCheckInit }
    _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = e.target.checked
    setcheckclick(true)

    if (e.target.checked) {
      if (can_crud === 'can_read') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = true
      } else if (can_crud === 'can_create') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = true
      } else if (can_crud === 'can_update') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = true
      } else if (can_crud === 'can_delete') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = true
      }
    } else {
      if (can_crud === 'can_read') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = false
      } else if (can_crud === 'can_create') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = false
      } else if (can_crud === 'can_update') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = false
      } else if (can_crud === 'can_delete') {
        _allMenuCheck[`${sub_menu.key_slug}|${can_crud}`] = false
      }
    }

    setAllMenuCheck(_allMenuCheck)

    const newPermission = renderCheckbox(e, role, mainMenu, allMenu)
    validate = newPermission.every(e => e.isReqMain === true || e.isReqMain === undefined)

    setAllMenu(newPermission)
    handleChange()
  }

  const handleChange = () => {
    if (!formAntd.getFieldValue('nameRole')?.length) {
      setIsValidForm(false)
      setIsValidFormEdit(false)
    } else {
      setIsValidForm(
        isValidateRole &&
          formAntd.getFieldValue('nameRole')?.length &&
          formAntd.getFieldsError()[0]?.errors.length === 0
      )
      let checkName = formAntd.getFieldValue('nameRole') !== nameEdit
      let checkDiscription = formAntd.getFieldValue('description') !== discriptionEdit
      setIsValidFormEdit(
        checkName || checkDiscription || (isValidateRole && formAntd.getFieldsError()[0]?.errors.length === 0)
      )
    }
  }

  return (
    <Content>
      <div className="box-role">
        <form onSubmit={formik.handleSubmit} onChange={handleChange}>
          {!isView ? (
            <>
              {state?.isEdit ? (
                <Space>
                  <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => setLeaveModalOpen(true)} />
                  <Title>แก้ไขสิทธิ์ผู้ใช้งาน</Title>
                </Space>
              ) : (
                <Space>
                  <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => setLeaveModalOpen(true)} />
                  <Title>เพิ่มข้อมูลสิทธิ์ผู้ใช้งาน</Title>
                </Space>
              )}
            </>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '20px' }}>
                <Space>
                  <ButtonBack
                    shape="circle"
                    icon={<IconArrowLeft />}
                    onClick={() => navigate('/operation-tools/bof-role')}
                  />
                  <Title>ข้อมูลสิทธิ์ผู้ใช้งาน</Title>
                </Space>
                <Space>
                  <ButtonEdit
                    onClick={() => {
                      const id = state.record.id || '#'
                      navigate(`/operation-tools/bof-role/update/${id}`, {
                        state: { record: state.record, isEdit: true },
                      })
                    }}
                    icon={<img src={iconedit} alt="edit" style={{ paddingRight: '8px' }} />}
                  >
                    แก้ไขข้อมูล
                  </ButtonEdit>
                  <ButtonDelete
                    onClick={() => callfunction(state)}
                    icon={<img src={iconbin} alt="bin" style={{ paddingRight: '8px' }} />}
                  >
                    ลบข้อมูล
                  </ButtonDelete>
                </Space>
              </div>
            </>
          )}

          <Box>
            <div className="box-checkbox">
              <Form form={formAntd} layout="vertical" onValuesChange={handleChange}>
                <Form.Item
                  label={
                    <div>
                      ชื่อสิทธิ์ <span style={{ color: '#F85858' }}>*</span>
                    </div>
                  }
                  name="nameRole"
                  style={{ paddingBottom: '0px', maxWidth: '824px' }}
                >
                  {isView ? (
                    <Input disabled={isView} />
                  ) : (
                    <Input
                      placeholder="กรอกชื่อสิทธิ์"
                      maxLength={35}
                      onBlur={uniqueValidate}
                      onMouseLeave={uniqueValidate}
                      onChange={() => {
                        handleChange()
                        formAntd.setFields([
                          {
                            name: 'nameRole', // required
                            value: formAntd.getFieldValue('nameRole'), //optional
                            errors: '',
                          },
                        ])
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item label={'รายละเอียด'} name="description">
                  {isView ? (
                    <Input disabled={isView} />
                  ) : (
                    <Input
                      placeholder="กรอกรายละเอียด"
                      maxLength={255}
                      onChange={() => {
                        formAntd.setFields([
                          {
                            name: 'description', // required
                            value: formAntd.getFieldValue('description'), //optional
                            errors: '',
                          },
                        ])
                      }}
                    />
                  )}
                </Form.Item>
              </Form>
              <Label>
                สิทธิ์การใช้งานระบบ <span style={{ color: 'red', paddingLeft: 2 }}> *</span>{' '}
              </Label>
              <BoxRolePermission>
                {allMenu?.map((item, index) => {
                  return (
                    <>
                      <Row key={index}>
                        <Col span={24}>
                          <div className="div-header-ui">
                            {item?.icon ? <img className="icon-ui" src={`/icon/${item?.icon}.png`} /> : null}
                            <span className="text"> {item.name} </span>
                          </div>
                        </Col>
                        <Col span={24} className="body-ui-mobile">
                          <div className="div-subheader-ui">
                            {item?.sub_menus ? (
                              <>
                                {item?.sub_menus.map((sub, index) => {
                                  return (
                                    <>
                                      <Row
                                        style={{
                                          padding: '8px 8px',
                                          borderBottom:
                                            item.sub_menus.length != 1 && item.sub_menus.length != index + 1
                                              ? '1px solid #FFEEE7'
                                              : 'none',
                                        }}
                                        key={index}
                                        className="div-hr-ui"
                                      >
                                        <div style={{ display: 'grid', gridTemplateColumns: '120px 1fr' }}>
                                          <div>{sub?.name}</div>
                                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div>
                                              <div>
                                                <CustomCheckbox
                                                  name={sub?.name}
                                                  onChange={e =>
                                                    onChangePermission(e, 'read', item.name, sub, 'can_read')
                                                  }
                                                  disabled={sub.can_set_permission.can_read === false || isView}
                                                  checked={sub.roles_permission ? sub.roles_permission.read : false}
                                                >
                                                  ดูข้อมูล
                                                </CustomCheckbox>
                                              </div>
                                              <div>
                                                <CustomCheckbox
                                                  name={sub?.name}
                                                  onChange={e =>
                                                    onChangePermission(e, 'create', item.name, sub, 'can_create')
                                                  }
                                                  checked={
                                                    (sub.roles_permission ? sub.roles_permission.create : false) &&
                                                    (sub.roles_permission
                                                      ? sub.roles_permission.read == false
                                                        ? false
                                                        : true
                                                      : true)
                                                  }
                                                  disabled={
                                                    sub.can_set_permission.can_create === false ||
                                                    isView ||
                                                    (sub.roles_permission
                                                      ? sub.roles_permission.read == true
                                                        ? false
                                                        : true
                                                      : true)
                                                  }
                                                  style={{
                                                    display: sub.can_set_permission.can_create === false ? 'none' : '',
                                                  }}
                                                >
                                                  เพิ่มข้อมูล
                                                </CustomCheckbox>
                                              </div>
                                            </div>
                                            <div>
                                              <div>
                                                <CustomCheckbox
                                                  name={sub?.name}
                                                  onChange={e =>
                                                    onChangePermission(e, 'update', item.name, sub, 'can_update')
                                                  }
                                                  checked={
                                                    (sub.roles_permission ? sub.roles_permission.update : false) &&
                                                    (sub.roles_permission
                                                      ? sub.roles_permission.read == false
                                                        ? false
                                                        : true
                                                      : true)
                                                  }
                                                  disabled={
                                                    sub.can_set_permission.can_update === false ||
                                                    isView ||
                                                    (sub.roles_permission
                                                      ? sub.roles_permission.read == true
                                                        ? false
                                                        : true
                                                      : true)
                                                  }
                                                  style={{
                                                    display: sub.can_set_permission.can_update === false ? 'none' : '',
                                                  }}
                                                >
                                                  แก้ไขข้อมูล
                                                </CustomCheckbox>
                                              </div>
                                              <div>
                                                <CustomCheckbox
                                                  name={sub?.name}
                                                  onChange={e =>
                                                    onChangePermission(e, 'delete', item.name, sub, 'can_delete')
                                                  }
                                                  checked={
                                                    (sub.roles_permission ? sub.roles_permission.delete : false) &&
                                                    (sub.roles_permission
                                                      ? sub.roles_permission.read == false
                                                        ? false
                                                        : true
                                                      : true)
                                                  }
                                                  disabled={
                                                    sub.can_set_permission.can_delete === false ||
                                                    isView ||
                                                    (sub.roles_permission
                                                      ? sub.roles_permission.read == true
                                                        ? false
                                                        : true
                                                      : true)
                                                  }
                                                  style={{
                                                    display: sub.can_set_permission.can_delete === false ? 'none' : '',
                                                  }}
                                                >
                                                  ลบข้อมูล
                                                </CustomCheckbox>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Row>
                                    </>
                                  )
                                })}
                              </>
                            ) : null}
                          </div>
                        </Col>
                        <Col span={24} className="body-ui-pc">
                          <div className="div-subheader-ui">
                            {item?.sub_menus ? (
                              <>
                                {item?.sub_menus.map((sub, index) => {
                                  return (
                                    <>
                                      <Row
                                        style={{
                                          padding: '8px 8px',
                                          borderBottom:
                                            item.sub_menus.length != 1 && item.sub_menus.length != index + 1
                                              ? '1px solid #FFEEE7'
                                              : 'none',
                                        }}
                                        key={index}
                                        className="div-hr-ui"
                                      >
                                        <Col span={8} style={{ margin: 'auto' }}>
                                          {sub?.name}
                                        </Col>
                                        <Col span={4}>
                                          <CustomCheckbox
                                            name={sub?.name}
                                            onChange={e => onChangePermission(e, 'read', item.name, sub, 'can_read')}
                                            disabled={sub.can_set_permission.can_read === false || isView}
                                            checked={sub.roles_permission ? sub.roles_permission.read : false}
                                          >
                                            ดูข้อมูล
                                          </CustomCheckbox>
                                        </Col>
                                        <Col span={4}>
                                          <CustomCheckbox
                                            name={sub?.name}
                                            onChange={e =>
                                              onChangePermission(e, 'create', item.name, sub, 'can_create')
                                            }
                                            checked={
                                              (sub.roles_permission ? sub.roles_permission.create : false) &&
                                              (sub.roles_permission
                                                ? sub.roles_permission.read == false
                                                  ? false
                                                  : true
                                                : true)
                                            }
                                            disabled={
                                              sub.can_set_permission.can_create === false ||
                                              isView ||
                                              (sub.roles_permission
                                                ? sub.roles_permission.read == true
                                                  ? false
                                                  : true
                                                : true)
                                            }
                                            style={{
                                              display: sub.can_set_permission.can_create === false ? 'none' : '',
                                            }}
                                          >
                                            เพิ่มข้อมูล
                                          </CustomCheckbox>
                                        </Col>
                                        <Col span={4}>
                                          <CustomCheckbox
                                            name={sub?.name}
                                            onChange={e =>
                                              onChangePermission(e, 'update', item.name, sub, 'can_update')
                                            }
                                            checked={
                                              (sub.roles_permission ? sub.roles_permission.update : false) &&
                                              (sub.roles_permission
                                                ? sub.roles_permission.read == false
                                                  ? false
                                                  : true
                                                : true)
                                            }
                                            disabled={
                                              sub.can_set_permission.can_update === false ||
                                              isView ||
                                              (sub.roles_permission
                                                ? sub.roles_permission.read == true
                                                  ? false
                                                  : true
                                                : true)
                                            }
                                            style={{
                                              display: sub.can_set_permission.can_update === false ? 'none' : '',
                                            }}
                                          >
                                            แก้ไขข้อมูล
                                          </CustomCheckbox>
                                        </Col>
                                        <Col span={4}>
                                          <CustomCheckbox
                                            name={sub?.name}
                                            onChange={e =>
                                              onChangePermission(e, 'delete', item.name, sub, 'can_delete')
                                            }
                                            checked={
                                              (sub.roles_permission ? sub.roles_permission.delete : false) &&
                                              (sub.roles_permission
                                                ? sub.roles_permission.read == false
                                                  ? false
                                                  : true
                                                : true)
                                            }
                                            disabled={
                                              sub.can_set_permission.can_delete === false ||
                                              isView ||
                                              (sub.roles_permission
                                                ? sub.roles_permission.read == true
                                                  ? false
                                                  : true
                                                : true)
                                            }
                                            style={{
                                              display: sub.can_set_permission.can_delete === false ? 'none' : '',
                                            }}
                                          >
                                            ลบข้อมูล
                                          </CustomCheckbox>
                                        </Col>
                                      </Row>
                                    </>
                                  )
                                })}
                              </>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )
                })}
              </BoxRolePermission>
            </div>
          </Box>
          {!isView ? <>{renderMode()}</> : null}
        </form>
        {renderDeleteModal(deletionType)}
        <CustomModal
          transitionName=""
          centered
          open={leaveModalOpen}
          onOk={() => {
            setLeaveModalOpen(false)
            navigate(-1)
          }}
          onCancel={() => setLeaveModalOpen(false)}
          closable={false}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <ExclamationCircle />
          </div>
          <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
          <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
        </CustomModal>
      </div>
    </Content>
  )
}

export default Index
