import styled from 'styled-components'

export const InputContainer = styled.div`
  padding-bottom: 16px;
  min-height: 102px;

  label {
    font-family: 'Prompt';
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #150f0b;
  }

  .ant-input {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 40px;
    max-width: 824px;
    color: #150f0b;
    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      color: #fc6521;
      box-shadow: none;
      border: 1px solid #fc6521;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 48px;
    max-width: 824px;
    color: #fc6521;
    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
    //box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #ffeee7;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fbfbfb;
    border-color: #fbfbfb !important;
    color: #9baab1;
  }

  .ant-input::placeholder {
    color: #c4c4c4 !important;
  }
`
