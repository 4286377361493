import React from 'react'
import styled from 'styled-components'
import { checkStatusText } from './container'
export default function PinInfor({ idInfor, id, img, status }) {
  const checkColorStatus = val => {
    if (val === 'received') {
      return '#915EFF'
    }
    if (val === 'in progress') {
      return '#FFBB0B'
    }
    if (val === 'done') {
      return '#13AFA6'
    }
    if (val === 'outside the area') {
      return '#3F54D1'
    }
    if (val === 'tracking') {
      return '#F36B24'
    }
    if (val === 'over duedate') {
      return '#F85858'
    }
  }
  return (
    <BoxPin>
      {idInfor === id ? (
        <div style={{ position: 'relative', width: '50px', height: '80px' }}>
          <PinActive>
            <CricleActive>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  transform: 'rotate(-45deg)',
                }}
              >
                <img src={img} style={{ width: '22px', height: '22px' }} />
              </div>
            </CricleActive>
            <BoxStatusActive>
              <StatusActive color={checkColorStatus(status)} />
            </BoxStatusActive>
          </PinActive>
          <PointActive />
        </div>
      ) : (
        <div style={{ position: 'relative', width: '32px', height: '32px', cursor: 'pointer' }}>
          <PinDefault></PinDefault>
          <CricleDefault>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={img} style={{ width: '18px', height: '18px' }} />
            </div>
          </CricleDefault>
          <BoxStatus>
            <StatusPin color={checkColorStatus(status)} />
          </BoxStatus>
        </div>
      )}
    </BoxPin>
  )
}

const BoxPin = styled.div``

const PointActive = styled.div`
  position: absolute;
  bottom: 0px;
  left: 20px;
  background-color: #005a31;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`

const StatusActive = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: ${props => (props.color ? props.color : '')};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  z-index: 2;
`

const BoxStatusActive = styled.div`
  position: absolute;
  top: -5px;
  left: 18px;
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 1;
`
const CricleActive = styled.div`
  position: absolute;
  background-color: #cbddff;
  top: 7px;
  left: 7px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`

const PinActive = styled.div`
  position: absolute;
  bottom: 25px;
  width: 50px;
  height: 50px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 5%;
  transform: rotate(45deg);
  background-color: #005a31;
`

const PinDefault = styled.div`
  position: absolute;
  background-color: #005a31;
  width: 32px;
  height: 32px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 5%;
  z-index: 1;
`
const CricleDefault = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #cbddff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  z-index: 2;
`
const BoxStatus = styled.div`
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 3;
`
const StatusPin = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: ${props => (props.color ? props.color : '')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  z-index: 4;
`
