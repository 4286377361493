import React, { useEffect, useState, useContext } from 'react'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import NakhonRatchasima_provice from 'core/utils/NakhonRatchasima_provice.json'
import UserContext from 'core/contexts/userContext'
import GoogleMapApi from 'components/GoogleMap'
import InfoDetail from './InfoDetail'
import { getData, getList, getMe } from 'core/action/collection'
import moment from 'moment'
import Search from './search'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { ReactComponent as Pin } from './image/svg/Pin 32 px.svg'

export default function Index() {
  const [map, setMap] = useState(null)
  const [searchData, setSearchData] = useState({
    date: [moment().subtract(30, 'days'), moment()],
  })
  const [masterData, setMasterData] = useState([])
  const { meData } = useContext(UserContext)
  const [dataMap, setDataMap] = useState([])
  const [idInfor, setIdInfor] = useState('')

  let checkRoleSupAdmin = meData?.position_data?.id === '1730459515121299456'
  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
          },
          error => {
            console.error('Error getting location:', error.message)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }

    fetchUserLocation() // Call the function when the component mounts
  }, [])
  let latlng = NakhonRatchasima?.features[0]?.geometry?.coordinates[0]
  let latlng_provice = NakhonRatchasima_provice?.features[0]?.geometry?.coordinates[0]
  useEffect(() => {
    let arr = []
    latlng?.map((item, index) => {
      arr.push({ lat: item[1], lng: item[0] })
    })
    let arrProvice = []
    latlng_provice?.map((item, index) => {
      arrProvice.push({ lat: item[1], lng: item[0] })
    })

    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arr,
      strokeColor: '#f36b24',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    const bermudaTriangleProvice = new window.google.maps.Polygon({
      paths: arrProvice,
      strokeColor: '#f00505',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    bermudaTriangleProvice.setMap(map)
    bermudaTriangle.setMap(map)
    return () => {
      bermudaTriangle.setMap(null)
      bermudaTriangleProvice.setMap(null)
    }
  }, [latlng, map])

  useEffect(() => {
    getAllDataEservice()
    getMasterData()
  }, [searchData])

  const getAllDataEservice = async () => {
    const res = await getList({
      schema: '/complaint/api/v1/e-service/complaint',
      params: {
        filters: [
          meData?.position_data?.id === '1730459515121299456'
            ? undefined
            : meData?.position_data?.id === '1730459515154853888'
            ? `department_data:eq:${meData?.organize_data?.org_id}`
            : `officer_data.staff_by:eq:${meData?.id}`,
          searchData?.status ? `status_data.name:inLast:${searchData?.status}` : undefined,
          searchData?.channel ? `channel:eq:${searchData?.channel}` : undefined,
          searchData?.type_of_complaint ? `type_of_complaint:eq:${searchData?.type_of_complaint}` : undefined,
          searchData?.date
            ? `created_at:between:${moment(searchData?.date[0]).startOf('day').unix()}|${moment(searchData?.date[1])
                .endOf('day')
                .unix()}`
            : undefined,
        ],
        search: [
          searchData?.search ? `title_complaint:${searchData?.search}` : undefined,
          searchData?.search ? `complaint_no:${searchData?.search}` : undefined,
          searchData?.search ? `mobile_number:${searchData?.search}` : undefined,
        ],
      },
    })
    if (res?.dataLength > 0) {
      setTimeout(() => {
        setDataMap(res?.items)
      }, 500)
    } else {
      setDataMap([])
    }
  }

  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })
    const resStatus = await getList({
      schema: '/master/api/v1/all/status',
      params: '',
    })
    const resUrgency = await getList({
      schema: '/master/api/v1/all/urgency',
      params: '',
    })
    const resDepartment = await getList({
      schema: '/master/api/v1/all/organizations',
      params: '',
    })
    const resChannel = await getList({
      schema: '/master/api/v1/all/channel',
      params: '',
    })
    setMasterData({
      categories: resCategories?.items,
      status: resStatus?.items,
      urgency: resUrgency?.items,
      department: resDepartment?.items,
      channel: resChannel?.items,
    })
  }

  return (
    <div style={{ padding: '0% 1%' }}>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <Search
          masterData={masterData}
          setSearchData={setSearchData}
          searchData={searchData}
          conversDate={conversDate}
        />
      </div>
      <GoogleMapApi height={'83vh'} zoom={14} setMap={setMap}>
        {dataMap?.map((value, index, array) => {
          return (
            <InfoDetail
              pin={
                <Pin
                  style={{
                    cursor: 'pointer',
                    width: idInfor === value?.key ? '60px' : '40px',
                    height: idInfor === value?.key ? '60px' : '40px',
                  }}
                />
              }
              key={index}
              id={value?.key}
              coordinates={{
                lat: value?.coordinates?.latitude,
                lng: value?.coordinates?.longitude,
              }}
              setIdInfor={setIdInfor}
              idInfor={idInfor}
              data={value}
              conversFullDate={conversFullDate}
            />
          )
        })}
      </GoogleMapApi>
    </div>
  )
}
