import React from 'react'
import { Table as TB } from 'antd'
import { ReactComponent as BlankIcon } from 'assets/images/svg/nosearch-table.svg'
import { BoxTable, BlankContainer } from './table.style'
import EmtyData from 'assets/images/svg/Search Empty.svg'
const TableCustom = props => {
  const {
    columns,
    dataSource,
    isAfterCreate,
    height,
    onRow,
    noData,
    noDataText,
    dataSourceDefault,
    isAfterEdit,
    isSearch,
    NodataCustom,
    checkserch,
    widthDisplay,
    isFullTable,
    pagination,
    getRowClassName,
    rowSelection,
    expandable,
  } = props
  const CustomEmptyComponent = () => (
    <div>
      <img src={EmtyData} />
      <p>{noDataText}</p>
    </div>
  )

  return (
    <>
      {dataSourceDefault && (
        <BoxTable>
          <TB
            className="custom-table"
            rowClassName="custom-row"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{
              x: 2000,
              y: 1000,
            }}
          />
        </BoxTable>
      )}
      {!noData && (
        <BoxTable>
          <TB
            className="custom-table"
            rowClassName={(record, index) => getRowClassName && getRowClassName(record, index)}
            columns={columns}
            dataSource={dataSource}
            locale={{ emptyText: <CustomEmptyComponent /> }}
            pagination={false}
            rowKey="id"
            rowSelection={rowSelection && rowSelection}
            expandable={expandable && expandable}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                onRow && onRow(record)
              },
            })}
          />
        </BoxTable>
      )}
    </>
  )
}

export default TableCustom
