import React, { useEffect, useState, useRef } from 'react'
import { Marker, Autocomplete } from '@react-google-maps/api'
import iconeMarker from 'assets/images/svg/markericon.svg'
import { ReactComponent as Iconsearch } from '../../image/svg/Search.svg'
import { ReactComponent as IconClear } from '../../image/svg/Cancel.svg'
import CurrentLocation from '../../image/png/current location.png'
import CurrantMeLocation from './CurrantLocation'
import GoogleMapApi from 'components/GoogleMap'
import { Button, Input } from 'antd'
import { BoxAutocomplate, BoxBtnInMap } from './form.style'
const Viewmap = ({
  isModalOpen,
  clickedLatLng,
  meLocation,
  address,
  arrArear,
  handleOk,
  handleClickMap,
  setClickedLatLng,
  checkArear,
  setIsModalOpenError,
  dataComplaint,
}) => {
  const [autocomplete, setAutocomplete] = useState(null)
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [searchValue, setSearchValue] = useState('')

  const [map, setMap] = useState(null)
  const mapStyles = {
    height: isModalOpen ? '100%' : '30vh',
    width: '100%',
    borderRadius: '25px',
  }

  const handleOnLoad = autocomplete => {
    setAutocomplete(autocomplete)
  }
  const handleOnPlaceChanged = () => {
    const place = autocomplete?.getPlace()
    let check = checkArear({ lat: place?.geometry?.location.lat(), lng: place?.geometry?.location.lng() })
    if (!check) {
      setIsModalOpenError(true)
      setSearchValue('')
    } else {
      setClickedLatLng({ lat: place?.geometry?.location.lat(), lng: place?.geometry?.location.lng() })
      setAutocompleteValue(place?.formatted_address)
    }
  }

  useEffect(() => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
      strokeColor: '#f36b24',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    bermudaTriangle.setMap(map)
    window.google.maps.event.addListener(bermudaTriangle, 'click', handleClickMap)
    return () => {
      window.google.maps.event.clearListeners(bermudaTriangle, 'click')
      bermudaTriangle.setMap(null)
    }
  }, [arrArear, map])
  let coordinates = dataComplaint?.coordinates
  const coord = {
    lat: coordinates?.latitude,
    lng: coordinates?.longitude,
  }
  return (
    <>
      <GoogleMapApi
        center={clickedLatLng?.lat ? clickedLatLng : coord?.lat ? coord : meLocation}
        setMap={setMap}
        zoom={14}
        mapContainerStyle={mapStyles}
        handleClickMap={handleClickMap}
      >
        {clickedLatLng?.lat ? (
          <Marker position={clickedLatLng} icon={iconeMarker} />
        ) : (
          coord?.lat && <Marker position={coord} icon={iconeMarker} />
        )}
        <BoxAutocomplate>
          <Autocomplete onLoad={handleOnLoad} onPlaceChanged={handleOnPlaceChanged}>
            <Input
              type="text"
              value={autocompleteValue ? autocompleteValue : searchValue}
              placeholder="ระบุชื่อสถานที่ที่ต้องการ"
              prefix={<Iconsearch />}
              onChange={e => setSearchValue(e.target.value)}
              suffix={
                searchValue && (
                  <IconClear
                    style={{ color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }}
                    onClick={() => {
                      setSearchValue('')
                      setAutocompleteValue('')
                      setClickedLatLng(null)
                    }}
                  />
                )
              }
            />
          </Autocomplete>
          <div style={{ padding: '10px', backgroundColor: '#FFF8F4', borderRadius: '10px', marginTop: '10px' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '40px 1fr', alignItems: 'center' }}>
              <img src={CurrentLocation} />
              <div> {address}</div>
            </div>
          </div>
          <BoxBtnInMap>
            <Button style={{ marginTop: '10px' }} onClick={() => handleOk()}>
              ยืนยันตำแหน่ง
            </Button>
          </BoxBtnInMap>
        </BoxAutocomplate>
      </GoogleMapApi>
    </>
  )
}

export default Viewmap
