import { Button, Form, Input, Select, Modal } from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import { BoxForm, CustomUploadfile, BoxBtn, BoxBtnInMap, BoxSuccess, CustomModal, StyleDropdown } from './form.style'
import TextArea from 'antd/lib/input/TextArea'
import UploadFile from 'components/Inputs/UploadFile'
import Viewmap from '../viewmap'
import { conversDate } from 'core/utils/DateTimeFormat'
import moment from 'moment'
import useGetAddress from 'core/hooks/useGetAddress'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import LocationIcon from '../../../image/png/Location.png'
import { ReactComponent as Back } from '../../../image/svg/Back.svg'
import DateIcon from '../../../image/png/Group 48097655.png'
import BeforeIcon from '../../../image/png/Before Success.png'
import ArrowRightIcon from '../../../image/png/Arrow-right.png'
import { ReactComponent as CloseModelMap } from '../../../image/svg/CloseModelMap.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'

export default function FormUpdateStatus() {
  const locations = useLocation()
  const { Option } = Select
  let coordinates = locations?.state?.dataComplaint?.coordinates
  let dataComplaint = locations?.state?.dataComplaint
  const token = localStorage?.getItem('access_token')
  const [formAntd] = Form.useForm()
  const navigate = useNavigate()
  const [cover, setCover] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenError, setIsModalOpenError] = useState(false)
  const [clickedLatLng, setClickedLatLng] = React.useState(null)
  const [loaddingCraete, setloaddingCraete] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [meLocation, setMeLocation] = useState({ lat: coordinates?.latitude, lng: coordinates?.longitude })
  const [statusMaster, setStatusMaster] = useState([])
  const [SelectStatus, setSelectStatus] = useState([])
  const [checkStatusDone, setCheckStatusDone] = useState(false)
  const address = useGetAddress(clickedLatLng ? clickedLatLng : meLocation)
  let dataNow = conversDate(moment().unix())
  let arrArear = []
  NakhonRatchasima?.features[0]?.geometry?.coordinates[0]?.map((item, index) => {
    arrArear.push({ lat: item[1], lng: item[0] })
  })

  useEffect(() => {
    getAllStatus()
    checkArear(meLocation)
  }, [])

  useEffect(() => {
    handleChangeForm()
  }, [cover])

  useEffect(() => {
    if (locations?.state?.method === 'update') {
      formAntd.setFieldsValue({
        description: dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.description,
      })
      dataComplaint?.transaction_data[dataComplaint?.transaction_data?.length - 1]?.media_url?.map(
        async (value, index, array) => {
          let namefile = value.split('https://gateway.koratcity.go.th/sit/media/images/')
          let urlkey = value.split('https://')
          let sizeFile = await getFileSizeFromUrl(value)
          setCover([
            {
              name: namefile[1],
              status: 'success',
              size: sizeFile?.megabytes,
              msg: 'อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาดรูปภาพวิดีโอไม่ถูกต้อง หรือนามสกุลไฟล์ไม่ถูกต้อง',
              img: value,
              urlkey: urlkey[1],
            },
          ])
        }
      )
    }
  }, [dataComplaint])

  async function getFileSizeFromUrl(url) {
    try {
      const response = await fetch(url, { method: 'HEAD' })
      const contentLength = response.headers.get('Content-Length')

      if (contentLength) {
        const sizeInBytes = parseInt(contentLength)
        const sizeInKilobytes = sizeInBytes / 1024
        const sizeInMegabytes = sizeInKilobytes / 1024
        return {
          bytes: sizeInBytes,
          kilobytes: sizeInKilobytes.toFixed(2),
          megabytes: sizeInMegabytes.toFixed(2),
        }
      } else {
        throw new Error('Content-Length header not found')
      }
    } catch (error) {
      throw new Error(`Failed to get file size from URL: ${error.message}`)
    }
  }

  const getAllStatus = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/master/api/v1/all/status`
      const resStatus = await axios.get(`${shemadata}`)
      if ((resStatus.status === 200) | (resStatus.status === 201)) {
        const filterStatusData = resStatus?.data?.data?.filter(
          item => item?.name === 'ติดตาม' || item?.name === 'ดำเนินการแล้วเสร็จ'
        )
        setStatusMaster(filterStatusData)
      }
    } catch (error) {}
  }

  const checkArear = async val => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
    })
    const pointToCheck = new window.google.maps.LatLng(val?.lat, val?.lng)
    if (
      window.google.maps.geometry &&
      window.google.maps.geometry.poly &&
      window.google.maps.geometry.poly.containsLocation
    ) {
      const isInside = window.google.maps.geometry.poly.containsLocation(pointToCheck, bermudaTriangle)
      return isInside
    } else {
      console.error("Google Maps 'containsLocation' method is not available.")
      return false // Return false assuming the location is outside if the check cannot be performed
    }
  }

  const handleChangeForm = () => {
    let valForm = formAntd.getFieldsValue()
    if (locations?.state?.type === 'befor') {
      if (!cover?.length) {
        setDisableBtn(true)
      } else {
        setDisableBtn(false)
      }
    } else if (locations?.state?.type === 'after') {
      if (!cover?.length || !valForm?.statusData?.length) {
        setDisableBtn(true)
      } else {
        setDisableBtn(false)
      }
    } else {
      if (!cover?.length) {
        setDisableBtn(true)
      } else {
        setDisableBtn(false)
      }
    }
  }

  const handleClickMap = event => {
    const cord = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    }

    if (checkArear(cord)) {
      setClickedLatLng({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      })
    } else {
      setIsModalOpenError(true)
    }
  }

  const handleUpdateStatusFile = code => {
    cover.map(item => {
      const body = {
        status: 'active',
        url: item?.img,
      }
      axios
        .put(process.env.REACT_APP_MEDIA + '/medias', body)
        .then(res => {})
        .catch(err => {
          setloaddingCraete(false)
          console.log('err', err)
        })
    })
    setTimeout(() => {
      setloaddingCraete(false)
    }, 2000)
  }

  const handleUpdateCoordinates = code => {
    let dataComplaint = locations?.state?.dataComplaint
    const body = {
      address: address[2]?.formatted_address,
      area_number: dataComplaint?.area_number,
      category_id: dataComplaint?.category?.id,
      channel: dataComplaint?.channel,
      complaint_name: dataComplaint?.complaint_name,
      coordinates: {
        latitude: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
        longitude: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
      },
      description: dataComplaint?.description,
      district_id: dataComplaint?.district_id,
      duedate_at: dataComplaint?.duedate_at,
      media_url: dataComplaint?.media_url,
      mobile_number: dataComplaint?.mobile_number,
      notidate_at: dataComplaint?.notidate_at,
      name: dataComplaint?.name,
      org_id: '19118133238380',
      department_data: Array.isArray(dataComplaint?.department_data)
        ? dataComplaint?.department_data?.map((value, index, array) => value?.id)
        : dataComplaint?.department_data?.id,
      province_id: dataComplaint?.province_id,
      sub_category_id: dataComplaint?.sub_category_data?.id,
      sub_district_id: dataComplaint?.sub_district_id,
      water_number: dataComplaint?.water_number,
      urgency_id: dataComplaint?.urgency?.id,
      zib_code: dataComplaint?.zib_code,
    }
    const token = localStorage.getItem('access_token')
    axios
      .put(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint/${locations?.state?.complaintId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setloaddingCraete(false)
        navigate('/complaint-staff/bof-overview-complaint/view-update-status', {
          state: {
            record: locations?.state?.complaintId,
            path: locations?.state?.path,
          },
        })
      })
      .catch(err => {})
  }

  const UpdateStatusComplaint = async (data, id) => {
    const bodyUpdateStatus = {
      description: formAntd.getFieldValue('description'),
      name: data,
    }
    try {
      const resUpstatus = await axios.put(
        `${process.env.REACT_APP_BASEURL}/complaint/api/v1/status/${locations?.state?.complaintId}`,
        bodyUpdateStatus,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (
        resUpstatus.status !== 200 ||
        resUpstatus.status !== 201 ||
        resUpstatus?.code !== 200 ||
        resUpstatus?.code !== 201
      ) {
        deleteTransaction(id)
      }
    } catch (error) {}
  }

  const deleteTransaction = async id => {
    const res = await axios.delete(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/transaction/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const haldeChageStaus = value => {
    const dataFilter = statusMaster?.filter(item => item?.id === value)
    setSelectStatus(dataFilter)
    if (dataFilter[0].name_en === 'done') {
      setCheckStatusDone(true)
    } else {
      setCheckStatusDone(false)
    }
  }

  const handleCreateComplant = async () => {
    setloaddingCraete(true)
    const lat = clickedLatLng?.lat || meLocation?.lat
    const lng = clickedLatLng?.lng || meLocation?.lng
    try {
      const isInsidePolygon = await checkArear({ lat, lng })
      if (!isInsidePolygon) {
        setIsModalOpenError(true)
        setDisableBtn(true)
      } else {
        setDisableBtn(false)
        const value = formAntd.getFieldsValue()
        let arrFile = []
        cover.map(item => {
          arrFile.push(item?.img)
        })

        const bodyTran = {
          complaint_id: locations?.state?.complaintId,
          coordinates: {
            latitude: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
            longitude: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
          },
          department_id: locations?.state?.department_id,
          description: value?.description,
          media_url: arrFile,
          status: SelectStatus?.length > 0 ? SelectStatus[0]?.name_en : 'in progress',
          no: 0,
          type: locations?.state?.type,
          address: address[2]?.formatted_address,
        }

        const bodyTracking = {
          complaint_id: locations?.state?.complaintId,
          coordinates: {
            latitude: clickedLatLng?.lat ? clickedLatLng?.lat : meLocation?.lat,
            longitude: clickedLatLng?.lng ? clickedLatLng?.lng : meLocation?.lng,
          },
          description: value?.description,
          media_url: arrFile,
          address: address[2]?.formatted_address,
        }
        if (
          (SelectStatus.length > 0 && SelectStatus[0]?.name_en === 'tracking') ||
          locations?.state.type === 'tracking'
        ) {
          try {
            const resUpdate = await axios.post(
              `${process.env.REACT_APP_BASEURL}/complaint/api/v1/tracking`,
              bodyTracking,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            if (
              resUpdate?.status === 200 ||
              resUpdate?.status === 201 ||
              resUpdate?.code === 200 ||
              resUpdate?.code === 201
            ) {
              handleUpdateStatusFile(resUpdate?.data?.status)
              handleUpdateCoordinates()
              if (locations?.state?.manyOrganize === false) {
                UpdateStatusComplaint('tracking', resUpdate?.data?.data)
              } else {
                setloaddingCraete(false)
                // navigate(-1)
              }
            }
          } catch (error) {}
        } else {
          try {
            let resUpdate = ''
            if (locations?.state?.method === 'update') {
              resUpdate = await axios
                .put(
                  `${process.env.REACT_APP_BASEURL}/complaint/api/v1/transaction/${locations?.state?.complaintId}`,
                  bodyTran,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then(res => {
                  return res
                })
            } else {
              resUpdate = await axios
                .post(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/transaction`, bodyTran, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then(res => {
                  return res
                })
            }
            if (
              resUpdate?.status === 200 ||
              resUpdate?.status === 201 ||
              resUpdate?.code === 200 ||
              resUpdate?.code === 201
            ) {
              handleUpdateStatusFile(resUpdate?.data?.status)
              handleUpdateCoordinates()
              if (locations.state.type === 'befor') {
                let dataComplaint = locations?.state?.dataComplaint
                if (dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'in progress') {
                  UpdateStatusComplaint('in progress', resUpdate?.data?.data)
                }
              } else if (
                locations.state.type === 'after' &&
                locations.state.manyOrganize === false &&
                SelectStatus.length > 0 &&
                SelectStatus[0]?.name_en === 'done'
              ) {
                UpdateStatusComplaint('done', resUpdate?.data?.data)
              } else if (locations.state.type === 'after' && locations.state.manyOrganize === true) {
                setloaddingCraete(false)
                navigate(-1)
              }
            }
          } catch (error) {}
        }
      }
    } catch (error) {
      console.error('Error checking area:', error)
    }
  }

  const validateDescription = () => {
    let valForm = formAntd.getFieldsValue()
    if (valForm?.description?.length) {
      if (valForm?.description?.length <= 255) {
        handleChangeForm()
        return Promise.resolve()
      } else if (valForm?.description?.length > 255) {
        setDisableBtn(true)
        return Promise.reject('กรุณากรอกไม่เกิน 255 ตัวอักษร')
      }
    }
  }

  const handleOk = () => {
    setIsModalOpen(false)
    handleChangeForm()
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setClickedLatLng(null)
  }

  return (
    <>
      <Form layout="vertical" form={formAntd} onValuesChange={handleChangeForm}>
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <Back style={{ marginRight: '-10px', cursor: 'pointer' }} onClick={() => setLeaveModalOpen(true)} />{' '}
          <p style={{ fontSize: '24px', fontWeight: '500', color: '#150F0B', margin: 0 }}>
            {locations?.state?.type === 'befor'
              ? 'อัปเดตข้อมูลก่อนการปฏิบัติงาน'
              : locations?.state?.type === 'after'
              ? 'อัปเดตข้อมูลหลังการปฏิบัติงาน'
              : 'อัปเดตข้อมูลติดตามการปฎิบัติงาน'}
          </p>
        </div>
        <BoxForm>
          <div style={{ width: '800px' }}>
            <div className="box-date-complaint">
              <img src={DateIcon} style={{ marginRight: '10px' }} /> เวลาแจ้งเรื่อง:{' '}
              {dataComplaint?.created_at ? conversDate(dataComplaint?.created_at) : dataNow}
            </div>
            <Form.Item
              name="description"
              rules={[{ validator: validateDescription }]}
              label={<div>รายละเอียดเพิ่มเติม</div>}
            >
              <TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder="ระบุรายละเอียดบริเวณที่เกิดปัญหา หรือรายละเอียดของปัญหาเพิ่มเติม"
              />
            </Form.Item>
            <Form.Item
              name="media"
              label={
                <div>
                  อัปโหลดรูปภาพและวิดีโอ (อัปโหลดได้ไม่เกิน 3 ไฟล์) <span style={{ color: 'red' }}>*</span>
                </div>
              }
            >
              <CustomUploadfile>
                <UploadFile
                  accept={{
                    'image/jpeg': [],
                    'image/png': [],
                    'video/mp4': [],
                  }}
                  keys={1}
                  files={cover}
                  isCrop={false}
                  maxFile={3}
                  maxSize={1024 * 1024 * 5}
                  typeUpload={'imagevdo'}
                  text={'ไฟล์ที่อัปโหลดต้องเป็นไฟล์นามสกุล JPEG , PNG , MP4 และ มีขนาดแต่ละไฟล์ไม่เกิน 10 MB'}
                  onFileRetuen={(files, file) => {
                    let _image = [...files]
                    _image.push(file)
                    setCover(_image)
                  }}
                  onFileRemove={(files, res) => {
                    let _image = [...files]
                    _image = _image.filter((r, i) => i !== res.index)
                    setCover(_image)
                  }}
                />
              </CustomUploadfile>
            </Form.Item>
            <div
              style={{
                padding: '15px',
                width: 'auto',
                height: '80px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0px 2px 10px 0px #F4F4F4',
                cursor: 'pointer',
              }}
              onClick={() => setIsModalOpen && setIsModalOpen(true)}
            >
              <div style={{ display: 'grid', gridTemplateColumns: '40px 1fr 40px', alignItems: 'center' }}>
                <div>
                  <img src={LocationIcon} />
                </div>
                <div>
                  <p style={{ fontSize: '16px', margin: 0, color: '#150F0B' }}>เลือกตำแหน่งที่ตั้ง</p>
                  {address && locations?.method !== 'update' && (
                    <p style={{ fontSize: '12px', margin: 0, color: '#ABA9A8' }}>{address[2]?.formatted_address}</p>
                  )}
                  {locations?.method === 'update' && (
                    <p style={{ fontSize: '12px', margin: 0, color: '#ABA9A8' }}>{dataComplaint?.address}</p>
                  )}
                </div>
                <div>
                  <img src={ArrowRightIcon} />
                </div>
              </div>
            </div>
          </div>

          {locations?.state?.type === 'after' && (
            <StyleDropdown>
              <Form.Item name="statusData" label="กำหนดสถานะเรื่องร้องเรียน">
                <Select onChange={haldeChageStaus} placement="bottomLeft" placeholder="กรุณาเลือกสถานะ">
                  {statusMaster &&
                    statusMaster?.map((item, index) => (
                      <Option key={index} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </StyleDropdown>
          )}

          <Modal
            open={isModalOpen}
            closeIcon={<CloseModelMap onClick={handleCancel} />}
            className="model-map-create-complant"
            title="เลือกตำแหน่งที่ตั้ง"
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{ height: '80vh', width: '90vw', borderRadius: '10px' }}
            style={{ borderRadius: '10px' }}
            centered
          >
            <Viewmap
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              clickedLatLng={clickedLatLng}
              setClickedLatLng={setClickedLatLng}
              meLocation={meLocation}
              address={address[2]?.formatted_address}
              arrArear={arrArear}
              setIsModalOpenError={setIsModalOpenError}
              handleOk={handleOk}
              handleClickMap={handleClickMap}
              checkArear={checkArear}
              dataComplaint={locations?.state?.dataComplaint}
            />
          </Modal>
          <Modal
            closable={false}
            open={isModalOpenError}
            footer={null}
            className="model-map-create-complant-error"
            centered
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Prompt , sans-serif',
                textAlign: 'center',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '15%',
                  }}
                >
                  <img src={BeforeIcon} style={{ width: '35px', height: '35px' }} />
                </div>
                <p style={{ fontSize: '20px', margin: 0 }}>ไม่อยู่ในเขตที่รับผิดชอบ</p>
                <p style={{ fontSize: '16px', color: '#C4C4C4', marginBottom: '15%' }}>กรุณาเลือกตำแหน่งใหม่</p>

                <BoxBtnInMap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      style={{ width: '100px' }}
                      onClick={() => {
                        setIsModalOpenError(false)
                        setloaddingCraete(!loaddingCraete)
                      }}
                    >
                      ตกลง
                    </Button>
                  </div>
                </BoxBtnInMap>
              </div>
            </div>
          </Modal>
        </BoxForm>
        <BoxBtn>
          <Button
            className="btn-submit-complant"
            type="primary"
            // disabled={disableBtn}
            loading={loaddingCraete}
            onClick={() => handleCreateComplant()}
          >
            อัปเดตข้อมูลปฎิบัติงาน
          </Button>
        </BoxBtn>
        <CustomModal
          transitionName=""
          centered
          open={leaveModalOpen}
          onOk={() => {
            setLeaveModalOpen(false)
            navigate(-1)
          }}
          onCancel={() => setLeaveModalOpen(false)}
          closable={false}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <ExclamationCircle />
          </div>
          <p
            style={{
              color: '#001F34',
              fontSize: '16px',
              marginBottom: '2px',
              fontWeight: '400',
            }}
          >
            คุณต้องการออกจากหน้านี้?
          </p>
          <p
            style={{
              color: '#597081',
              fontSize: '14px',
              fontWeight: '300',
            }}
          >
            หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
          </p>
        </CustomModal>
      </Form>
    </>
  )
}
