import React from 'react'
import get from 'lodash.get'

import Message from 'components/output/Message'

import * as P from './password.style'

const Password = props => {
  const {
    label,
    name,
    type,
    placeholder,
    errors,
    touched: touches,
    form,
    required,
    disabled,
  } = props

  const error = get(errors, name, '')
  const touched = get(touches, name, '')

  return (
    <P.PasswordContainer data-testid="test-password">
      <label htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      <div className="input">
        <input
          type={type}
          name={name}
          autoComplete="new-password"
          placeholder={placeholder}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          disabled={disabled}
        />

        {error && touched ? <Message>{error}</Message> : null}
      </div>
    </P.PasswordContainer>
  )
}

export default Password
