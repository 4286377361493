import React, { useState } from 'react'
import { CustomDrawer, BtSubmit, CustomModal } from './list/list.style'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconTitleAddOfficer } from 'assets/images/svg/icon-title-add-officer.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { ReactComponent as Transfer } from 'assets/images/svg/icon-title-transfer.svg'
import { ReactComponent as TransferSuccess } from 'assets/images/svg/icon-modal-success-transfer.svg'
import { InputCustom } from 'components/input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Modal } from 'antd'
import { postData, putData } from 'core/action/collection'
import { complaint } from 'core/schemas'

const TransferComplaint = ({
  openDrawerTransfer,
  onCloseTransfer,
  meData,
  complaintId,
  openNotification,
  getDataComplant,
  getAllDataComplant,
  getDataId,
}) => {
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const Schema = Yup.object().shape({
    reason_transfer: Yup.string().required('').max(255, 'กรุณากรอกไม่เกิน 255 ตัวอักษร'),
    // .matches(/^[ก-๏เ-๙\s]+$/, 'กรุณากรอกภาษาไทยเท่านั้น'),
  })

  const formik = useFormik({
    initialValues: { reason_transfer: '' },
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  const isValidForm = formik.isValid && formik.dirty

  const showModalSeccess = async () => {
    setSuccessModal(true)
  }

  const onCloseModalSeccess = () => {
    formik.resetForm()
    setSuccessModal(false)
    onCloseTransfer()
    openNotification('transfer')
    getDataComplant && getDataComplant()
    getAllDataComplant && getAllDataComplant()
  }

  const onSubmit = async () => {
    const payload = {
      complaint_id: complaintId,
      department_id: meData?.organize_data?.org_id,
      reason_transfer: formik.values.reason_transfer,
      status_data: null,
    }

    const res = await postData(`${complaint}/transfer`, payload)
    if (res) {
      if (res?.code === 201) {
        const updateStatus = async () => {
          const statusPayload = {
            description: 'โอนย้ายหน่วยงาน',
            name: 'transfer',
          }
          const res = await putData(`${complaint}/status`, complaintId, statusPayload)
          if (res) {
            if (res?.code === 200) {
              showModalSeccess()
            } else {
              console.log('err', res)
            }
          }
        }
        updateStatus()
      } else {
        console.log('Err', res)
      }
    }
  }

  return (
    <>
      <CustomDrawer
        placement="right"
        open={openDrawerTransfer}
        onClose={() => setLeaveModalOpen(true)}
        closable={false}
        width={600}
      >
        <div style={{ height: '6.5em', position: 'relative' }}>
          <div className="close-icon" onClick={() => setLeaveModalOpen(true)}>
            <IconClear />
          </div>
        </div>
        <div className="wrap-body">
          <div className="wrap-title">
            <Transfer style={{ marginRight: '16px' }} />
            <div className="wrap-box-title">
              <div className="title">โอนย้ายเรื่องร้องเรียน</div>
            </div>
          </div>
          <Box>
            <InputCustom
              label="เหตุผลการโอนย้าย"
              name="reason_transfer"
              placeholder="ระบุเหตุผลการโอนย้ายเรื่องร้องเรียน"
              type="textarea"
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              maxLength={255}
              required={true}
              // disabled
            />
          </Box>

          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '4em',
              marginBottom: '4em',
            }}
          >
            <BtSubmit disabled={!isValidForm} onClick={() => onSubmit()}>
              ยืนยัน
            </BtSubmit>
          </div>
        </div>
      </CustomDrawer>

      <CustomModal
        transitionName=""
        centered
        open={leaveModalOpen}
        onOk={() => {
          formik.resetForm()
          setLeaveModalOpen(false)
          onCloseTransfer()
        }}
        onCancel={() => setLeaveModalOpen(false)}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
        <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
      </CustomModal>

      <ModalSuccess open={successModal} closable={false} onCancel={onCloseModalSeccess} width={440} footer={null}>
        <TransferSuccess />
        <div className="title">โอนย้ายเรื่องร้องเรียนสำเร็จ</div>
        <div className="description">กรุณารอส่วนกลางอนุมัติอีกครั้ง เพื่อยืนยันการโอนย้ายเรื่องร้องเรียน</div>
        <BtSubmit onClick={() => onCloseModalSeccess()}>กลับสู่หน้าหลัก</BtSubmit>
      </ModalSuccess>
    </>
  )
}

export default TransferComplaint

const Box = styled.div`
  font-family: 'Prompt' !important;
  .ant-input::placeholder {
    font-size: 14px !important;
    color: var(--web-backend-extended-orange-700, var(--web-backend-orange-700, #ffc4aa)) !important;
  }
  .ant-input:focus {
    border-color: #f36b24;
    color: #f36b24;
  }
  label,
  input {
    font-size: 12px !important;
  }
  textarea {
    font-size: 14px !important;
    color: #f36b24;
  }

  .ant-form-item-explain-error {
    color: rgb(237, 65, 96);
    font-size: 12px;
    text-align: end;
  }
  .ant-input:disabled {
    color: #ffc4aa !important;
    background-color: #fff8f4 !important;
  }
  .ant-input {
    border: 1px solid #ffeee7;
  }

  .ant-input:hover {
    border: 1px solid #ffeee7;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  label {
    font-family: Prompt;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #150f0b;
  }
  .ant-input {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    max-width: 824px;
    color: #150f0b;
    :focus {
      color: #fc6521;
      box-shadow: none;
      border: 1px solid #fc6521;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 48px;
    max-width: 824px;
    color: #fc6521;
    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #ffeee7;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fbfbfb;
    border-color: #fbfbfb !important;
    color: #9baab1;
  }

  .row-data {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    padding-top: 24px;

    .ant-input:not(.ant-input-disabled):hover {
      border-color: #fc6521;
    }

    width: 100%;
    label {
      font-family: 'Prompt';
      font-weight: 400;
      font-size: 14px !important;
      color: #150f0b;
    }

    .ant-input[disabled] {
      border: none;

      background-color: #fff3ee !important;
      :hover {
        border: none;
      }
    }
    .ant-input::placeholder {
      color: #ffc4aa !important;
      font-family: 'Prompt';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
    textarea.ant-input {
      border: 1px solid var(--mobile-extended-orange-500, #fee0d3);
      &:hover,
      &:active,
      &:focus {
        border: 1px solid #fc6521 !important;
        color: #fc6521 !important;
      }
    }
  }

  .bt-next {
    background-color: #f36b24;
    width: 120px;
    height: 48px;
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    border-radius: 8px;

    :hover {
      border: none;
    }

    &.ant-btn[disabled] {
      background-color: #fee0d3 !important;
      border: none;
      color: #ffffff;
    }
  }
`

const ModalSuccess = styled(Modal)`
  font-family: 'Prompt' !important;

  .ant-modal-content {
    font-family: 'Prompt' !important;
    border-radius: 24px;
  }

  .ant-modal-body {
    font-family: 'Prompt' !important;
    padding: 24px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    color: #150f0b;
    margin-top: 1rem;
    margin-bottom: 8px;
  }

  .description {
    font-weight: 300;
    font-size: 12px;
    color: #c4c4c4;
    margin-bottom: 2rem;
  }
`
