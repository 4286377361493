import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Input, Select, DatePicker } from 'antd'
import moment from 'moment'
// import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
import { BoxSearch } from './list.style'
// dayjs.extend(customParseFormat)
const { RangePicker } = DatePicker

export default function Search({ setSearchData, searchData, dataCatagory, setCurantPage }) {
  let catagory = dataCatagory?.map((value, index, array) => {
    return {
      label: value?.name,
      value: value?.id,
    }
  })
  const handleChange = (value, type) => {
    setCurantPage(1)
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      if (type === 'date') {
        setSearchData({
          ...searchData,
          date: value ? [moment(value[0]), moment(value[1])] : [moment(), moment()],
        })
      } else {
        setSearchData({
          ...searchData,
          [type]: value ? value : null,
        })
      }
    }
  }

  const disabledDate = current => {
    return current && current > moment().endOf('day')
  }
  const RangPickerCallBack = useCallback(() => {
    return (
      <RangePicker
        disabledDate={disabledDate}
        //  locale={customLocale}
        //   format={value => thaiYearFormat(value).format('DD/MM/YYYY')}
        defaultValue={[searchData.date[0], searchData.date[1]]}
        onChange={val => handleChange(val, 'date')}
        format={'DD/MM/YYYY'}
        allowClear={false}
      />
    )
  }, [searchData])

  return (
    <BoxSearch>
      <BoxSelect>
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr ', gap: '10px' }}
          className="search-component"
        >
          {' '}
          <div>
            <div>บริการสาธารณะ</div>
            <Input
              placeholder="ค้นหาจากบริการสาธารณะ"
              allowClear={true}
              onChange={val => handleChange(val, 'input')}
              value={searchData?.search}
            />
          </div>
          <div>
            <div>เลือกช่วงวันที่ข้อมูล</div>
            <RangPickerCallBack />
          </div>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <div>
              <div>ประเภท</div>
              <Select
                placeholder="ประเภทบริการสาธารณะ"
                allowClear
                onChange={val => handleChange(val, 'category')}
                options={catagory}
                value={searchData?.category}
              />
            </div>

            <div>
              <span
                style={{
                  textAlign: 'left',
                  color: '#556A82',
                  textDecorationLine: 'underline',
                  paddingTop: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSearchData({
                    date: [moment().subtract(30, 'days'), moment()],
                  })
                  setCurantPage(1)
                }}
              >
                ล้างข้อมูล
              </span>
            </div>
          </div>
        </div>
      </BoxSelect>
    </BoxSearch>
  )
}

const BoxSelect = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;

  .date-picker,
  .ant-picker-input > input {
    color: #f36b24 !important;
  }
  .ant-picker-range .ant-picker-input > input::placeholder {
    /* Change the placeholder color to red */
    color: #ffc4aa !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`
