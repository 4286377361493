import * as React from 'react'
import { useLocation } from 'react-router-dom'

import bgContent from 'assets/images/png/bgContent.png'
import bgHome from 'assets/images/png/bg-homepage.png'

import { Container, GlobalStyle } from './Content.style'

const Content = React.forwardRef(({ children, onScroll }, ref) => {
  const location = useLocation()
  return (
    <Container
      path={location.pathname}
      // bg={location.pathname === '/home' ? bgHome : bgContent}
      ref={ref}
      onScroll={() => onScroll && onScroll()}
    >
      <GlobalStyle />
      {children}
    </Container>
  )
})

export default Content
