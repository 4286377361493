import React, { useEffect, useState } from 'react'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'
import ProgressChart from 'components/Charts/ProgressChart'
import MapCirCle from 'components/OlHeatMap/MapCircle'
import styled from 'styled-components'
import { unixToDateThai } from 'util/date-format'
import {
  BoxPointMap,
  BoxNumOfComp,
  TextSatus,
  TextSatusStay,
  LeftBoxNumOfComp,
  NumStyle,
  BoxHeader,
  FlexColumnBox,
  StyledImage,
  BoxbgChart,
  CardGrid,
  BoxStatus,
  FlexStatus,
  Numstatus,
} from '../style'
import moment from 'moment/moment'
const ReportPdfExusive = ({
  dataDashboard,
  NotdefaultColor,
  dataMockStausColor,
  Donutlabels2,
  checkColorStatusPoint,
  isPrints,
  ReportRef,
  dateHeader,
  meData,
}) => {
  return (
    <HiddenContainer ref={ReportRef}>
      <Container>
        <div>
          <BoxHeader>
            <FlexColumnBox>
              <div className="big-text">ภาพรวมสถิติและการติดตามสถานะเรื่องร้องเรียน</div>
              <div>{dateHeader}</div>
            </FlexColumnBox>
            <FlexColumnBox>
              <div>หน้าที่ 01/01 พิมพ์ ณ วันที {unixToDateThai(moment().unix())}</div>
              <div>
                {' '}
                ผู้พิมพ์ {meData && meData?.first_name?.th} {meData && meData?.last_name?.th}
              </div>
            </FlexColumnBox>
          </BoxHeader>

          <CardGrid>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <BoxNumOfComp>
                <LeftBoxNumOfComp>
                  <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                  <TextSatus isPrints={isPrints}>จำนวนเรื่องร้องเรียนทั้งหมด</TextSatus>
                  <TextSatusStay isPrints={isPrints}>(เรื่อง)</TextSatusStay>
                </LeftBoxNumOfComp>
                <NumStyle isPrints={isPrints}>{dataDashboard?.total_complaint}</NumStyle>
              </BoxNumOfComp>
              <BoxbgChart isPrints={isPrints}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ProgressChart
                    data={dataDashboard?.summary_status}
                    timeline
                    NotdefaultColor={NotdefaultColor}
                    PgHeight="20px"
                    isPrints={isPrints}
                    fontSize={'10px'}
                    height={'20px'}
                  />
                </div>
              </BoxbgChart>

              <BoxbgChart isPrints={isPrints}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ fontSize: '10px' }}>
                    จำนวนเรื่องร้องเรียนจำแนกตามสถานะ <span style={{ fontSize: '10px' }}>(เรื่อง)</span>
                  </div>

                  <div>
                    {dataDashboard?.summary_urgent?.map((item, index) => {
                      return (
                        <BoxStatus key={index} Bgstatus={dataMockStausColor[item?.urgent_name]}>
                          <FlexStatus>
                            <div style={{ fontSize: '10px' }}>{item?.urgent_name}</div>
                            <div style={{ margin: ' 0 10px', fontSize: '10px' }}>คิดเป็น {item?.percent}%</div>
                          </FlexStatus>
                          <Numstatus colorType={item?.urgent_name} style={{ fontSize: '10px' }}>
                            {item?.total}
                          </Numstatus>
                        </BoxStatus>
                      )
                    })}
                  </div>
                </div>
              </BoxbgChart>
            </div>

            <BoxbgChart isPrints={isPrints}>
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', zIndex: 999, fontSize: '10px' }}>
                  แผนที่แสดงเรื่องร้องเรียนจำแนกตามสถานะ
                </div>
                <div
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    padding: '10px',
                    position: 'absolute',
                    zIndex: 999,
                    width: '145px',
                    height: '160px',
                    top: '30px',
                    left: '20px',
                  }}
                >
                  {Donutlabels2?.map((item, index) => (
                    <BoxPointMap BgColor={checkColorStatusPoint(item)} key={index}>
                      <div className="point" />
                      <span>{item}</span>
                    </BoxPointMap>
                  ))}
                </div>
                {isPrints && (
                  <MapCirCle
                    heatmapData={dataDashboard?.summary_map_status}
                    zooms={13}
                    height={'600px'}
                    isPrint={true}
                  />
                )}
              </div>
            </BoxbgChart>
          </CardGrid>
        </div>
        <div style={{ pageBreakBefore: 'always' }}></div>
      </Container>
    </HiddenContainer>
  )
}

export default ReportPdfExusive

const HiddenContainer = styled.div``

const Container = styled.div`
  margin: 2%;
  font-family: 'Prompt';
  @page {
    size: A4 portrait;
    margin: 5mm 10mm !important;
  }
`
