import React from 'react'
import { Table as TB } from 'antd'

// import { ReactComponent as BlankIcon } from 'assets/images/svg/nodata-table.svg'
import { ReactComponent as BlankIcon } from 'assets/images/svg/nosearch-table.svg'
// import { ReactComponent as BlankIcon } from 'assets/images/svg/nodata-table-search.svg'

// import * as TE from './table.style'
import { BoxTable, BlankContainer } from './table.style'
import { tableText } from '../../../core/constants/text'

const Table = props => {
  // const { columns, dataSource, isAfterCreate, height, onRow, noData, dataSourceDefault, isAfterEdit } = props
  const {
    columns,
    dataSource,
    isAfterCreate,
    height,
    onRow,
    noData,
    // noDataText = tableText,
    noDataText,
    dataSourceDefault,
    isAfterEdit,
    isSearch,
    NodataCustom,
    checkserch,
    minHeightTb,
  } = props

  const onChange = (pagination, filters, sorter, extra) => {}

  let checkData = dataSource && dataSource.length > 0 ? false : true

  return (
    <>
      {dataSourceDefault && (
        <BoxTable minHeightTb={minHeightTb}>
          <TB
            showHeader={dataSourceDefault ? false : true}
            showSorterTooltip={false}
            columns={columns}
            dataSource={dataSource}
            onChange={onChange}
            pagination={false}
            // onRow={onRow && onRow}
            hide={noData ? !noData : isSearch ? isSearch : checkData}
            rowKey="id"
            rowClassName={(_, index) => index === 0 && isAfterCreate && !isAfterEdit && 'after-created'}
          />
        </BoxTable>
      )}
      {!noData && (
        <BoxTable minHeightTb={minHeightTb}>
          <TB
            showHeader={dataSourceDefault ? false : true}
            showSorterTooltip={false}
            columns={columns}
            dataSource={dataSource}
            onChange={onChange}
            pagination={false}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                onRow && onRow(record)
              },
            })}
            // scroll={{
            //   x: 2000,
            //   y: 500,
            // }}
            rowKey="id"
            rowClassName={(_, index) => index === 0 && isAfterCreate && !isAfterEdit && 'after-created'}
            locale={{
              emptyText: (
                <BlankContainer>
                  <div style={{ padding: '2rem' }}>
                    <div className="nodataitem">
                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {NodataCustom ? <NodataCustom /> : <BlankIcon />}
                      </div>
                      <p style={{ textAlign: 'center' }}>{noDataText}</p>
                    </div>
                  </div>
                </BlankContainer>
              ),
            }}
          />
        </BoxTable>
      )}
    </>
  )
}

export default Table
