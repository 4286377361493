import React, { useState, useEffect, useContext } from 'react'
import UserContext from 'core/contexts/userContext'

const UsePermission = (permission, path) => {
  const { userPermission: user } = useContext(UserContext)

  const [allow, setAllow] = useState(null)

  const userPermissions = user?.permissions

  useEffect(() => {
    if (user === null) {
      setAllow(false)
      return
    }

    const allow = userPermissions?.includes(permission) || permission?.includes('*')

    setAllow(allow)
  }, [user, userPermissions, permission])

  const loading = user === null

  return { loading, allow }
}

export default UsePermission
