import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LocationIcon } from 'assets/images/svg/Location.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/svg/arrow-icon.svg'
import { ReactComponent as IconRepair } from 'assets/images/svg/repair-icon.svg'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import IconUpdateGreen from 'assets/images/svg/gear-green.svg'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { dePartMentIcon } from '../../container'

const CardResultBox = ({
  complaintId,
  dataTranSaction,
  setPreviewImg,
  setPreviewVdo,
  setOpenModalImage,
  setOpenModalVdo,
}) => {
  const navigate = useNavigate()

  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '250px', height: '150px', borderRadius: '8px', margin: '1em' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    {/* นำ icon มาวางที่นี่ */}
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <div>
              <img
                style={{ borderRadius: '8px', width: '180px', height: '120px', margin: '10px 5px' }}
                className="show-image click"
                src={item}
                alt="image"
                onClick={() => {
                  setOpenModalImage(true)
                  setPreviewImg(item)
                }}
              />
            </div>
          )
        }
      })
    return CardUi
  }

  const onPageForm = type => {
    navigate('/complaint-staff/update-form-status', {
      state: {
        type,
        complaintId,
      },
    })
  }
  return (
    <>
      {dataTranSaction.length > 0 ? (
        dataTranSaction.map((itemBox, indexBox) => {
          if (itemBox?.length === 2 && itemBox.status != '') {
            return (
              <ContainerView>
                <div style={{ width: '100%', margin: '1em' }}>
                  <CardHeader>
                    <Title>
                      <span style={{ margin: '0 10px' }}>ผลการปฎิบัติงาน</span>
                      {/* <span>
                        {itemBox?.map((itemIcon, indexIcon) => (
                          <div key={indexIcon}>{indexIcon === 0 && itemIcon?.department_name}</div>
                        ))}
                      </span> */}
                      <BoxIcon>
                        <div>{dePartMentIcon[itemBox[0]?.department_name]}</div>
                        <div style={{ margin: '0 8px', fontSize: '14px' }}> {itemBox[0]?.department_name}</div>
                      </BoxIcon>
                    </Title>

                    <Statusbox>
                      {itemBox[0]?.officer_data?.status && (
                        <div className="status-color">{itemBox && itemBox[0]?.officer_data?.status}</div>
                      )}

                      <div>
                        ผู้รับผิดชอบ :{itemBox && itemBox[0]?.officer_data?.first_name}{' '}
                        {itemBox && itemBox[0]?.officer_data?.last_name}{' '}
                      </div>
                    </Statusbox>
                  </CardHeader>

                  <BoxMedia>
                    {itemBox?.map((itemCard, indexCard) => {
                      return (
                        <>
                          {indexCard === 1 && (
                            <ArrowIconBox>
                              <ArrowIcon />
                            </ArrowIconBox>
                          )}

                          <Boxbg Bgcolor="#F9F9F9" Padding="1.5em" BdRadius="24px" Bwidth={'49%'} key={indexCard}>
                            <BoxResult>
                              <HeadeResult>
                                <IconRepair />
                                <div className="header-result">
                                  ก่อนการปฏิบัติงาน
                                  <div style={{ fontSize: '14px' }}>เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน</div>
                                </div>
                              </HeadeResult>

                              <div style={{ display: 'flex' }}>{Mapmedia(itemCard?.media_url)}</div>

                              <div style={{ display: 'flex' }}>
                                <LocationIcon />
                                <div>
                                  <p style={{ color: '#C4C4C4' }}>
                                    {itemCard?.address}
                                    {/* {itemCard?.coordinates && <DisplayAddress coordinates={itemCard?.coordinates} />} */}
                                  </p>
                                </div>
                              </div>
                            </BoxResult>
                          </Boxbg>
                        </>
                      )
                    })}
                  </BoxMedia>
                </div>
              </ContainerView>
            )
          } else if (itemBox.length === 1 && itemBox.find(item => item.type === 'befor')) {
            return (
              <ContainerView>
                <div style={{ width: '100%', margin: '0' }}>
                  <CardHeader>
                    <Title>
                      <span style={{ margin: '0 10px' }}>ผลการปฎิบัติงาน</span>
                      <BoxIcon>
                        <div>{dePartMentIcon[itemBox[0]?.department_name]}</div>
                        <div style={{ margin: '0 8px', fontSize: '14px' }}> {itemBox[0]?.department_name}</div>
                      </BoxIcon>
                    </Title>

                    <Statusbox>
                      {itemBox[0]?.officer_data?.status && (
                        <div className="status-color">{itemBox && itemBox[0]?.officer_data?.status}</div>
                      )}

                      <div>
                        ผู้รับผิดชอบ :{itemBox && itemBox[0]?.officer_data?.first_name}{' '}
                        {itemBox && itemBox[0]?.officer_data?.last_name}{' '}
                      </div>
                    </Statusbox>
                  </CardHeader>

                  <BoxMedia>
                    {itemBox?.map((itemCard, indexCard) => (
                      <>
                        <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px" Bwidth={'49%'}>
                          <BoxResult>
                            <HeadeResult>
                              <IconRepair />
                              <div className="header-result">
                                ก่อนการปฏิบัติงาน
                                <div style={{ fontSize: '14px' }}>เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน</div>
                              </div>
                            </HeadeResult>

                            <div style={{ display: 'flex' }}>{Mapmedia(itemCard?.media_url)}</div>

                            <div style={{ display: 'flex' }}>
                              <LocationIcon />
                              <div>
                                <p style={{ color: '#C4C4C4' }}>
                                  {itemCard?.address}
                                  {/* {itemCard?.coordinates && <DisplayAddress coordinates={itemCard?.coordinates} />} */}
                                </p>
                              </div>
                            </div>
                          </BoxResult>
                        </Boxbg>
                        <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px" Bwidth={'49%'}>
                          <BoxResult>
                            <HeadeResult>
                              <IconRepair />
                              <div className="header-result">
                                หลังการปฏิบัติงาน
                                <div style={{ fontSize: '14px' }}>เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน</div>
                              </div>
                            </HeadeResult>

                            <ImageCard className="center-image">
                              <div>
                                <img src={IconUpdateGreen} alt="update-org" />
                              </div>
                            </ImageCard>
                          </BoxResult>
                        </Boxbg>
                      </>
                    ))}
                  </BoxMedia>
                </div>
              </ContainerView>
            )
          } else {
            return (
              <ContainerView>
                <CardHeader>
                  <Title>
                    <span style={{ margin: '0 10px' }}>ผลการปฎิบัติงาน</span>

                    <BoxIcon>
                      <div>{dePartMentIcon[itemBox[0]?.department_name]}</div>
                      <div style={{ margin: '0 8px', fontSize: '14px' }}> {itemBox[0]?.department_name}</div>
                    </BoxIcon>
                  </Title>

                  <Statusbox>
                    {itemBox[0]?.officer_data?.status && (
                      <div className="status-color">{itemBox && itemBox[0]?.officer_data?.status}</div>
                    )}

                    {itemBox[0] && itemBox[0]?.officer_data?.first_name && (
                      <div>
                        ผู้รับผิดชอบ :{itemBox && itemBox[0]?.officer_data?.first_name}{' '}
                        {itemBox && itemBox[0]?.officer_data?.last_name}{' '}
                      </div>
                    )}
                  </Statusbox>
                </CardHeader>
              </ContainerView>
            )
          }
        })
      ) : (
        <></>
      )}
    </>
  )
}

export default CardResultBox

const NoStausCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bigTitle {
    font-size: 18px;
    margin: 0 3px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Statusbox = styled.div`
  display: flex;
  flex-direction: column;

  .status-color {
    background-color: green;
    padding: 4px;
  }
`

const Title = styled.div`
  display: flex;
  font-size: 18px;

  .small-name {
    margin-top: 5px;
    font-size: 14px;
    color: #556a82;
  }
`

const BoxIcon = styled.div`
  background-color: #fff;
  padding: 5px;
  margin: 0 3px;
  display: flex;
  color: #556a82;
`

const ImageCard = styled.div`
  /* margin: 3%; */
  align-self: center;
  img {
    /* min-height: 5.5vw; */
    /* 
 width: '180px', height: '120px' */
    min-width: 180px;
    min-height: 120px;
  }
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 2em;
  margin-bottom: 20px;
`
const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};
  min-height: 300px;

  &.minibox {
    min-height: 100px;
  }
`
const HeadeResult = styled.div`
  display: flex;
  flex-direction: row;
  /* margin: 0 1em; */
`

const BoxMedia = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 19px;
`
const ArrowIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`

const BoxResult = styled.div`
  display: flex;
  flex-direction: column;
  margin: -1 10px;

  .header-result {
    display: flex;
    flex-direction: column;
    font-size: 19px;
    margin: 0 4px;
  }

  .center-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonEdit = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    /* background: #f36b24; */

    background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#f36b24')};
    width: 140px;
    height: 40px;
  }

  &.ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    border-radius: 6px;
    border: none;
    width: 140px;
    height: 40px;
  }
`
