import moment from 'moment'
import 'moment/locale/th'
import { conversDate } from 'core/utils/DateTimeFormat'

export const ThaiFormattedDate = date => {
  moment.locale('th')
  const formattedDate = moment(moment(date).add(543, 'years')).format('D MMMM YYYY')
  return formattedDate
}
export const checkDueDate = val => {
  let currant = moment().unix() * 1000
  let due = val * 1000
  const date1 = moment(due)
  const date2 = moment(currant)
  const diffInDays = date1.diff(date2, 'days') + 1
  const formattedDate1 = date1.format('DD/MM/YYYY')
  const isDueDatePassed = moment(date1).isBefore(date2)
  if (isDueDatePassed) {
    const dateMoment = moment(formattedDate1, 'DD/MM/YYYY')
    const unixTimestamp = dateMoment.valueOf() / 1000 // Convert milliseconds to seconds
    return formatThaiDate(conversDate(unixTimestamp))
  } else {
    if (diffInDays > 0 && diffInDays <= 3) {
      return 'เหลืออีก ' + diffInDays + ' วัน'
    } else {
      const dateMoment = moment(formattedDate1, 'DD/MM/YYYY')
      const unixTimestamp = dateMoment.valueOf() / 1000 // Convert milliseconds to seconds
      return formatThaiDate(conversDate(unixTimestamp))
    }
  }
}
export const formatThaiDate = dateString => {
  const [day, month, year] = dateString?.split(' ')
  const formattedDay = day?.length === 1 ? `0${day}` : day // Add leading zero if the day is single-digit
  return `${formattedDay} ${month} ${year}`
}
export const checkStatusText = val => {
  if (val === 'received') {
    return 'รับเรื่อง'
  }
  if (val === 'in progress') {
    return 'อยู่ระหว่างดำเนินการ'
  }
  if (val === 'done') {
    return 'ดำเนินการแล้วเสร็จ'
  }
  if (val === 'outside the area') {
    return 'ไม่อยู่ในความรับผิดชอบ'
  }
  if (val === 'tracking') {
    return 'ติดตาม'
  }
  if (val === 'over duedate') {
    return 'เกินระยะเวลากำหนด'
  }
  if (val === 'transfer') {
    return 'โอนย้ายหน่วยงาน'
  }
}
export const checkRating = val => {
  if (val === 1) {
    return 'ควรปรับปรุง'
  }
  if (val === 2) {
    return 'พอใช้'
  }
  if (val === 3) {
    return 'ดี'
  }
  if (val === 4) {
    return 'ดีมาก'
  }
  if (val === 5) {
    return 'ยอดเยี่ยม'
  }
}
