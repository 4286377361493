import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { ThaiFormattedDate, checkStatusText, checkRating } from './container'

export default function ExportPdf({ dataSource, componentRefAll, searchData }) {
  const chunkArray = (arr, size) => {
    const chunkedArray = []
    for (let i = 0; i < arr.length; i += size) {
      chunkedArray.push(arr.slice(i, i + size))
    }
    return chunkedArray
  }

  const pageSize = 12
  const subArrays = chunkArray(dataSource, pageSize)
  const colums = [
    {
      title: 'เรื่องที่ร้องเรียน',
      dataIndex: 'complaint_name',
      render: text => (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100px' }}>{text}</div>
      ),
    },
    {
      title: 'เลขที่เรื่องร้องเรียน',
      dataIndex: 'complaint_no',
    },
    {
      title: 'ประเภทเรื่องร้องเรียน',
      dataIndex: 'category',
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {data?.category_data?.icon_url && (
            <img src={data?.category_data?.icon_url} style={{ width: '14px', height: '14px' }} />
          )}
          {data?.category_data?.name ? data?.category_data?.name : '-'}
        </div>
      ),
    },
    {
      title: 'ประเภทย่อย',
      dataIndex: 'sub_category_name',
      render: (text, data) => <div>{data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}</div>,
    },
    {
      title: 'หน่วยงานที่รับผิดชอบ',
      dataIndex: 'department_data',
      render: (text, data) => (
        <div>
          {text
            ? text?.map((value, index, array) => {
                return (
                  <div key={index} style={{ color: '#556A82' }}>
                    {value?.department_name ? value?.department_name : '-'}
                  </div>
                )
              })
            : '-'}
        </div>
      ),
    },
    {
      title: 'สถานะความเร่งด่วน',
      render: (text, data) => (data?.urgency ? <span>{data?.urgency}</span> : '-'),
    },
    {
      title: 'ความพึงพอใจ',
      render: (text, data) => <div>{data?.rating ? checkRating(data?.rating) : 'รอการยืนยัน'}</div>,
    },
    {
      title: 'วันที่แจ้งเรื่อง',
      render: (text, data) => <div>{conversDate(data?.created_at)}</div>,
    },
    {
      title: 'วันที่กำหนดเสร็จ',
      render: (text, data) => <div>{data?.duedate_at ? conversDate(data?.duedate_at) : '-'}</div>,
    },
    {
      title: 'วันที่แล้วเสร็จ',
      dataIndex: 'finishdate_at',
      render: (text, data) => <div>{data?.finishdate_at ? conversDate(data?.finishdate_at) : '-'}</div>,
    },
    {
      title: 'สถานะการร้องเรียน',
      render: (text, data) =>
        data?.status_data?.length ? (
          <span>
            {data?.status_data[data?.status_data?.length - 1]?.name
              ? checkStatusText(data?.status_data[data?.status_data?.length - 1]?.name)
              : '-'}
          </span>
        ) : (
          '-'
        ),
    },
  ]

  return (
    <Box ref={componentRefAll}>
      <div>
        {subArrays.map((value, index, array) => {
          return (
            <div key={index} style={{ margin: '2%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <div>
                  <div style={{ textAlign: 'center' }}>รายงานเรื่องร้องเรียน</div>
                  <div style={{ textAlign: 'center' }}>
                    ประจำวันที่ {ThaiFormattedDate(searchData?.date[0])} ถึง {ThaiFormattedDate(searchData?.date[1])}
                  </div>
                </div>
              </div>
              <Table dataSource={value} columns={colums} pagination={false} />
              <div style={{ pageBreakAfter: 'always' }} />
            </div>
          )
        })}
      </div>
    </Box>
  )
}
export const Box = styled.div`
  padding: 1%;
  font-family: 'Prompt' !important;
  @media print {
    @page {
      size: A4 landscape;
      margin: 20mm !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    position: relative;
    padding: 8px !important;
    overflow-wrap: break-word;
    font-size: 10px;
  }
`
