import React from 'react'
import { TextMenu, MainMenuStyle } from './Sider.style'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import ArrowUp from 'assets/images/svg/filter-invert-chevron-org.svg'

export default function SiderMenu({
  allMenu,
  checkclick,
  checkclickmenu,
  slugSelect,
  onClickMenu,
  state,
  collapAction,
  MenuIcon,
  subMenuTitle,
  userData,
  responsive,
}) {
  return (
    <div>
      <div className="menu-scrollbar">
        {allMenu &&
          allMenu?.map((item, index) => {
            if (responsive && item?.key_slug === 'chat') {
              return null
            }
            return (
              <MainMenuStyle
                key={`sider${index}`}
                className={`main-menu${index}`}
                checkclick={checkclick}
                index={checkclickmenu}
              >
                <Tooltip placement="rightTop" overlayClassName="sider-tooltip">
                  <div
                    className={classNames({
                      'text-menu border-menu': true,
                      selected: item?.key_slug === slugSelect,
                    })}
                    tabIndex={1}
                    onClick={() => onClickMenu(item, null, state[item?.key_slug])}
                  >
                    <img
                      className="icon"
                      src={item?.slugSelect === slugSelect ? MenuIcon[item?.iconactive] : MenuIcon[item?.icon]}
                      alt=""
                    />
                    <TextMenu width="120px" selected={item?.key_slug === slugSelect} collapAction={collapAction}>
                      {item?.name}
                    </TextMenu>
                    <img
                      width={18}
                      className="arrow-sub-menu"
                      src={state[item?.key_slug] === undefined ? '' : state[item?.key_slug] ? '' : ArrowUp}
                      style={{
                        position: 'absolute',
                        right: '12px',
                      }}
                      alt=""
                    />
                  </div>
                </Tooltip>
                {item?.sub_menus?.length > 0 && (
                  <div className="submenu" hidden={state[item.key_slug] === undefined ? true : state[item.key_slug]}>
                    <div style={{ width: '100%' }}>
                      {item?.sub_menus?.map((sub, i) => (
                        <Tooltip key={`${i + sub.name}`} placement="rightTop" overlayClassName="sider-tooltip">
                          <div
                            className={classNames({
                              'sub-menu': true,
                              'text-menu': true,
                              selected: sub?.key_slug === subMenuTitle,
                            })}
                            tabIndex={1}
                            onClick={() => onClickMenu(sub, item)}
                          >
                            <img className="icon" src={MenuIcon[sub?.icon]} alt="" />
                            <TextMenu
                              selected={sub?.key_slug === subMenuTitle}
                              style={{ paddingLeft: '25px' }}
                              collapAction={collapAction}
                            >
                              {sub?.name}
                            </TextMenu>
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                )}
              </MainMenuStyle>
            )
          })}
      </div>
    </div>
  )
}
