import styled, { createGlobalStyle } from 'styled-components'
import { Button, Menu, Modal } from 'antd'
export const BoxSinglePrint = styled.div``
export const BoxPrintAll = styled.div``
export const BoxSearch = styled.div`
  width: 100%;
  overflow-x: auto;
  .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper {
    border-color: #ffeee7;
    border-radius: 8px;
    height: 40px;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #f36b24 !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #ffeee7;
    color: #f36b24 !important;
    height: 40px;
    border-radius: 8px;
  }

  .ant-picker {
    background: #ffffff !important;
    border: 1px solid #ffeee7 !important;
    border-radius: 8px !important;
    height: 40px !important;
    width: 100% !important;
    color: #ffc4aa !important;
  }
  .ant-picker-input > input {
    color: #ffc4aa !important;
  }
  .ant-picker-input::placeholder > input {
    color: #ffc4aa !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #ffc4aa;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow {
    color: #f36b24;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 10px;
    background: none !important;

    .ant-input-affix-wrapper {
      min-width: 200px !important;
    }
  }

  @media only screen and (max-width: 375px) {
    padding: 10px 10px;
    background: none !important;
    .ant-input-affix-wrapper {
      min-width: 200px !important;
    }
  }
`
export const BoxTableCustom = styled.div`
  .ant-table-tbody tr {
    height: 48px !important;
    td {
      background: #ffffff !important;
      font-weight: var(--text-weight-regular);
    }
  }

  @media only screen and (max-width: 768px) {
    overflow-x: auto !important;

    table {
      table-layout: auto !important;
      overflow-x: auto !important;
    }
  }

  @media only screen and (max-width: 375px) {
    overflow-x: auto !important;

    table {
      padding-bottom: 20px !important;
      table-layout: auto !important;
    }
  }
`
export const Menucustom = styled(Menu)`
  padding: 8px !important;
  width: 160px !important;
  .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
  .ant-dropdown-menu-title-content:hover {
    background-color: #ffeee7;
    color: #f36b24;
    border-radius: 4px;
  }

  .ant-dropdown-menu-title-content {
    color: #001f34;
  }
`
export const BoxHeader = styled.div`
  .dropdown-export-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #f36b24;
    border-radius: 8px;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      height: 30px;
      width: 160px;
    }
    @media only screen and (min-width: 769px) {
      height: 40px;
      width: 160px;
    }
  }
  span {
    padding-left: 4px;
    padding-top: 1px;
    color: #fff;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media only screen and (min-width: 769px) {
      font-size: 16px;
    }
  }
  p {
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media only screen and (min-width: 769px) {
      font-size: 18px;
    }
  }
  .btn-update-complant {
    border-radius: 10px;
    background-color: #f36b24;
    border: 1px solid #f36b24;
    color: #fff;
    align-items: center;
    display: flex;
    height: 40px;
  }
`

export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .ant-btn-primary {
    border-color: #fc6521;
    background-color: #fc6521;
    height: 40px;
    width: 150px;
    border-radius: 12px;
  }

  @media only screen and (max-width: 768px) {
    .ant-btn-primary {
      height: 40px;
      width: 122px;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 375px) {
    .ant-btn-primary {
      height: 40px;
      width: 122px;
      border-radius: 8px;
    }
  }
`

export const BoxReport = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 10px 24px;
  font-family: 'Prompt', sans-serif;
`

export const BoxReportTotalUser = styled.div`
  height: 56px;
  border-radius: 12px;
  background: rgba(63, 84, 209, 0.03);
  padding: 16px;
  margin-bottom: 16px;

  .total-user-left {
    //font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #19263f;
    display: flex;
    gap: 8px;
    align-items: center;

    //.total-user-left-sub {
    //  font-weight: 400;
    //}
  }

  .total-user-right {
    font-weight: 600;
    font-size: 24px;
    color: #19263f;
    line-height: 30px;
    text-align: right;
  }
`

export const BoxIcon = styled.div`
  display: flex !important;
  align-items: center !important;
  padding-right: 40px;

  .ant-btn-circle {
    height: 100%;
    display: flex;
    align-items: center;
    border-color: transparent;
    background-color: transparent;
    justify-content: center;
    box-shadow: none !important;
    color: transparent !important;
    min-width: 16px;
    width: 25px;
    /* padding: 0px !important; */

    .ant-btn:focus,
    .ant-btn:hover {
      border-color: transparent;
      background-color: transparent;
      box-shadow: none !important;
      color: transparent !important;
    }
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }

    .ant-notification-top {
      margin-left: 60px;
    }

    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }

    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }

    .ant-notification-notice-message {
      font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;

      a {
        line-height: 18px;
      }

      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }

    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

export const NotiStyle2 = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }

    .ant-notification-top {
      margin-left: 60px;
    }

    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }

    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }

    .ant-notification-notice-message {
      font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;

      a {
        line-height: 18px;
      }

      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }

    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

export const CustomModal = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }

  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }

  .ant-modal-body {
    text-align: center;
    padding-bottom: 20px;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }

    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomModal2 = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }

  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }

  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }

    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomModal3 = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }

  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }

  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }

    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const BoxNoData = styled.div`
  min-height: 240px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #597081;
  }
`

export const StyleDropdown = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
    border: 1px solid #e2e5f8 !important;
    height: 40px;
    width: 100%;
    /* display: flex; */
  }
`

export const TitleReport = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #19263f;
  height: 40px;
  padding-top: 15px;
`
export const TitleChart = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #001f34;
  padding-bottom: 16px;
  padding-left: 16px;
`

export const StyleDatePicker = styled.div`
  .ant-picker {
    height: 40px !important;
  }
`

export const WrapDropdownNoti = styled.div`
  border-radius: 24px;
  padding: 24px;
  width: 408px;
  height: auto;
  max-height: 776px;
  background-color: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(46, 60, 79, 0.08);
  position: absolute;
  right: 4px;
  overflow: scroll;
`
