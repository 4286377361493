import React, { useState } from 'react'
import { Table, Select } from 'antd'

const { Option } = Select

const data = [
  {
    key: Date.now(),
    channel: '1',
    status: true,
  },
  {
    key: Date.now() + 2,
    channel: '2',
    status: false,
  },
  {
    key: Date.now() + 3,
    channel: '3',
    status: false,
  },
  {
    key: Date.now() + 4,
    channel: '4',
    status: false,
  },
  {
    key: Date.now() + 5,
    channel: '5',
    status: false,
  },
  {
    key: Date.now() + 6,
    channel: '6',
    status: false,
  },
  {
    key: Date.now() + 7,
    channel: '7',
    status: false,
  },
  {
    key: Date.now() + 8,
    channel: '8',
    status: false,
  },
  {
    key: Date.now() + 9,
    channel: '9',
    status: false,
  },
  {
    key: Date.now() + 10,
    channel: '10',
    status: false,
  },
  {
    key: Date.now() + 11,
    channel: '11',
    status: false,
  },
  {
    key: Date.now() + 12,
    channel: '12',
    status: false,
  },

  {
    key: Date.now() + 13,
    channel: '13',
    status: false,
  },
  {
    key: Date.now() + 14,
    channel: '14',
    status: false,
  },
  {
    key: Date.now() + 15,
    channel: '15',
    status: false,
  },
  {
    key: Date.now() + 16,
    channel: '16',
    status: false,
  },
  {
    key: Date.now() + 17,
    channel: '17',
    status: false,
  },
]

const columns = (handleProvinceChange, handleDistrictChange) => [
  {
    title: 'ช่องทางการร้องเรียน',
    dataIndex: 'channel',
    key: 'channel',
    width: 150,
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    width: 150,
  },
  {
    title: 'จังหวัด',
    dataIndex: 'province',
    render: (text, record) => (
      <Select
        defaultValue={record.province}
        onChange={value => handleProvinceChange(value, record.key)}
        style={{
          width: '100%',
        }}
        allowClear
      >
        {provinces.map(province => (
          <Option key={province.idProvinces}>{province.name}</Option>
        ))}
      </Select>
    ),
  },
  {
    title: 'อำเภอ',
    dataIndex: 'district',
    render: (text, record) => (
      <Select
        disabled={!record.province}
        value={record.district}
        onChange={value => handleDistrictChange(value, record.key)}
        style={{
          width: '100%',
        }}
        allowClear
      >
        {record.province &&
          districts
            .filter(district => district.idProvinces === record.province)
            .map(district => <Option key={district.idDistricts}>{district.name}</Option>)}
      </Select>
    ),
  },
]

const provinces = [
  {
    idProvinces: '1',
    name: 'Province 1',
  },
  {
    idProvinces: '2',
    name: 'Province 2',
  },
] // Example province data

const districts = [
  {
    idDistricts: '1-1',
    name: 'District 1',
    idProvinces: '1',
  },
  {
    idDistricts: '1-2',
    name: 'District 2',
    idProvinces: '1',
  },
  {
    idDistricts: '2-1',
    name: 'District 3',
    idProvinces: '2',
  },
  {
    idDistricts: '2-2',
    name: 'District 4',
    idProvinces: '2',
  },
]
const RowTable = () => {
  const [tableData, setTableData] = useState(data)
  const handleProvinceChange = (value, key) => {
    const updatedData = tableData.map(item =>
      item.key === key ? { ...item, province: value, district: undefined } : item
    )
    setTableData(updatedData)
  }

  const handleDistrictChange = (value, key) => {
    const updatedData = tableData.map(item => (item.key === key ? { ...item, district: value } : item))
    setTableData(updatedData)
  }

  return (
    <Table columns={columns(handleProvinceChange, handleDistrictChange)} dataSource={tableData} pagination={true} />
  )
}

export default RowTable
