import React, { useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import styled, { css } from 'styled-components'
import OtpInput from 'react-otp-input'
import './Otp.css'
import { ReactComponent as Logo } from 'assets/images/svg/icon-korat-city-blacktext.svg'
import BackIcon from 'assets/images/svg/button-back-login.svg'
import BackIconMobile from 'assets/images/svg/button-back-login-mobile.svg'
import { ReactComponent as PageNumber1 } from 'assets/images/svg/page-verify.svg'
import { ReactComponent as PageNumber2 } from 'assets/images/svg/number-page-2-select.svg'
import { ReactComponent as IconActionOTP } from 'assets/images/svg/icon-action-otp-page.svg'
import { ReactComponent as VerifyEmail } from 'assets/images/svg/icon-verify-email.svg'
import BgLogin from 'assets/images/png/BackgroundPage-login.png'
import Error from 'assets/images/png/cross.png'

import { useFormik } from 'formik'
import { Input } from 'components/input'
import {
  Container,
  BoxContent,
  BoxInput,
  BtSubmit,
  ModalConfirmEmail,
  BtSubmitModal,
  BtSubmitOTP,
} from './formotp.style'
import { Space } from 'antd'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { postData } from 'core/action/collection'
import { verifyEmail } from 'core/schemas'

const FormOTP = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const urlParams = new URLSearchParams(location.search)

  const ref_code = urlParams?.get('ref_code')
  const email = urlParams?.get('email')
  const line_id = urlParams?.get('line_id')
  const line_name = urlParams?.get('line_name')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [otp, setOtp] = useState('')
  const [otpForApi, setOtpForApi] = useState('')
  const [otpErr, setOtpErr] = useState(false)
  const [checkVeriBtn, setVeriBtn] = useState('')
  const [timeOutOtp, setTimeoutOtp] = useState(0)
  const [apiError, setApiError] = useState(false)
  const [isModalErrorOpen, setIsModalErrorOpen] = React.useState(false)

  const isSizeMobile = 768

  // useEffect(() => {
  //   state?.line_id === null && navigate('/login')
  // }, [])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const defaultValues = {
    email: '',
  }

  const Schema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'รูปแบบอีเมลล์ไม่ถูกต้อง'),
  })

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  useEffect(() => {
    otp && joinOTP()
  }, [otp])

  useEffect(() => {
    countTimeOtp()
  }, [])

  const countTimeOtp = useCallback(() => {
    var incomeTicker = 120
    const intervalId = setInterval(function () {
      if (incomeTicker > 1) {
        incomeTicker = incomeTicker - 1
        setTimeoutOtp(incomeTicker)
      } else {
        clearInterval(intervalId)
        setTimeoutOtp(0)
      }
    }, 1000)
  }, [timeOutOtp])

  const joinOTP = () => {
    // const otpString = otp?.join('')
    setOtpForApi(otp?.join(''))
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleChange = (value, index) => {
    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)
    setVeriBtn('')
    setOtpErr(false)
  }

  const login = async () => {
    const res = await postData(`${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/login-line`, {
      line_id: line_id ? line_id : state?.line_id,
    })

    if (res) {
      if (res.code === 200) {
        localStorage.setItem('access_token', res.data.access_token)
        localStorage.setItem('refresh_token', res.data.refresh_token)
        localStorage.setItem('user', true)
        navigate('/home')
      } else {
        console.log('Error', res)
      }
    }
  }

  const handleSubmit = async () => {
    const payload = {
      code: otpForApi,
      contact: email ? email : state?.email,
      lineId: line_id ? line_id : state?.line_id,
      ref_code: ref_code ? ref_code : state?.ref_code,
      lineName: line_name ? line_name : state?.line_name,
    }

    const res = await postData(`${verifyEmail}/verify`, payload)

    if (res?.code === 200) {
      setApiError(false)
      login()
    } else {
      console.log('Error', res)
      setApiError(true)
    }
  }

  const hanleSendOtp = async () => {
    const payload = {
      channel: 'email',
      contact: email ? email : state?.email,
      expire_time: 300,
      id: 'string',
      length_otp: 6,
      length_ref_code: 6,
      service_name: 'admin',
      verify_count: 3,
    }

    const res = await postData(`${verifyEmail}/request`, payload)

    if (res?.code === 200) {
      countTimeOtp()
    } else {
      console.log('err', res)
    }
  }

  function formatTime(minutes) {
    if (minutes < 0) {
      minutes = -1
    }

    const hours = Math.floor(minutes / 60)
    const minutesLeft = minutes % 60

    return `${hours.toString().padStart(2, '0')}:${minutesLeft.toString().padStart(2, '0')}`
  }

  const inputRefs = Array.from({ length: 6 }, () => React.createRef())

  const handleKeyDown = (e, index) => {
    if (/^[0-9]$/.test(e.key)) {
      e.preventDefault()
      const newOtp = [...otp]
      newOtp[index] = e.key
      setOtp(newOtp)

      if (index < 5) {
        inputRefs[index + 1].current.focus()
      }
    } else {
      e.preventDefault() // ป้องกันพิมพ์อักขระที่ไม่ใช่ตัวเลข
    }
  }

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault()
      const newOtp = [...otp]
      newOtp[index] = '' // ลบค่าในช่องปัจจุบัน
      setOtp(newOtp)

      if (index > 0) {
        inputRefs[index - 1].current.focus() // ย้ายโฟกัสไปยังช่องก่อนหน้า (ถ้ามี)
      }

      if (otpForApi.length === 6) {
        setApiError(false) // ตั้งค่าเป็น false เมื่อความยาวครบ 6 ตัว
      }
    }
  }

  return (
    <>
      <Container url={BgLogin}>
        <Logo />
        <BoxContent>
          <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'space-between' }}>
            <img
              src={windowWidth <= isSizeMobile ? `${BackIconMobile}` : `${BackIcon}`}
              alt="backicon"
              onClick={() => navigate('/flow-login/email')}
              style={{ cursor: 'pointer' }}
            />
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <Space size={13}>
                <PageNumber1 className="number-page" />
                <PageNumber2 className="number-page" />
              </Space>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: windowWidth <= isSizeMobile ? '0.563em' : '1.563em',
            }}
          >
            <BoxInput>
              <IconActionOTP className="icon-mail" />
              <h3>กรอกรหัส Code เพื่อยืนยันตัวตน</h3>
              <h4 className="toemail">กรุณากรอก Code ที่ส่งไปที่ {email ? email : state?.email}</h4>
              <div
                style={{
                  // marginTop: '10%',
                  display: 'flex',
                }}
              >
                {Array.from({ length: 6 }, (_, index) => (
                  <input
                    key={index}
                    type="text"
                    inputMode="tel"
                    ref={inputRefs[index]}
                    value={otp[index]}
                    onChange={e => handleChange(e.target.value, index)}
                    className={`otp-input ${otp[index] ? 'otp-filled' : ''} ${apiError ? 'error' : ''}`}
                    maxLength={1} // กำหนดความยาวสูงสุดเป็น 1
                    onKeyDown={e => handleKeyDown(e, index)}
                    onKeyUp={e => handleBackspace(e, index)}
                  />
                ))}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <Textrender disabled={timeOutOtp === 0 ? false : true} onClick={hanleSendOtp}>
                  ส่งรหัสอีกครั้ง?
                  <span
                    style={{
                      color: timeOutOtp >= 0 ? '#150F0B' : '#A5AFC4',
                      marginLeft: '5px',
                    }}
                  >
                    {timeOutOtp === 0 ? '' : `${formatTime(timeOutOtp)}`}
                  </span>
                </Textrender>

                {apiError && <TextErr>Code ไม่ถูกต้อง กรุณาลองอีกครั้ง</TextErr>}
              </div>
              <BtSubmitModal onClick={handleSubmit} disabled={otpForApi.length !== 6 || apiError}>
                ยืนยัน
              </BtSubmitModal>
            </BoxInput>
          </div>
        </BoxContent>

        <ModalConfirmEmail
          open={isModalErrorOpen}
          onCancel={() => setIsModalErrorOpen(false)}
          footer={false}
          closable={false}
        >
          <img src={Error} alt="" style={{ width: '120px', height: '120px', marginBottom: '2rem' }} />
          <h5 className="title">คุณส่งคำขอถี่เกินไป</h5>
          <BtSubmitModal onClick={() => setIsModalErrorOpen(false)}>ยืนยัน</BtSubmitModal>
        </ModalConfirmEmail>
      </Container>
    </>
  )
}

export default FormOTP

const TextErr = styled.div`
  color: #f85858;
  font-size: 12px;
  font-weight: 400;
`

const Textrender = styled.button`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border: none;
  background: transparent;
  color: ${props => (props.disabled ? '#C4C4C4' : '#f36b24')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 12px;
  }
`
