import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import OpenlayerMap from 'components/OpenlayerMap'
import { conversFullMonth } from 'core/utils/DateTimeFormat'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import { ReactComponent as IconExport } from 'assets/images/svg/IconExport.svg'
import { Button, Modal } from 'antd'
import { useReactToPrint } from 'react-to-print'
import IconCloseModal from 'assets/images/svg/close-modal.svg'
import ExportComplante from '../export'
import axios from 'axios'
export default function Index() {
  let location = useLocation()
  const navigate = useNavigate()
  const componentRef = useRef(null)
  const [isPrint, setIsPrint] = useState(false)
  const [isShowVideo, setIsShowVideo] = useState(false)
  const [isVideo, setIsVideo] = useState(false)
  const [isShowImage, setIsShowImage] = useState(false)
  const [isImage, setIsImage] = useState(false)
  const [dataById, setDataById] = useState([])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `reportdash`,
    onAfterPrint: () => setIsPrint(false),
  })
  const token = localStorage?.getItem('access_token')

  useEffect(() => {
    getDataId()
  }, [])

  const getDataId = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
      const compalintId = location?.state?.data?.id
      const resp = await axios.get(`${shemadata}/${compalintId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      if (resp?.data?.code === 200) {
        setDataById(resp?.data?.data)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  let dep = dataById?.department_data?.map(item => item.name)
  return (
    <BoxContent>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'space-between',
          margin: '0 5%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => navigate('/report/bof-report')} />
          <Title>ข้อมูลเรื่องร้องเรียน </Title>
        </div>
        <HeaderBox>
          <Button
            onClick={() => {
              setIsPrint(true)
              setTimeout(() => {
                handlePrint()
              }, 2000)
            }}
            className="btn-dowload"
          >
            <IconExport style={{ marginRight: '10px' }} />
            พิมพ์ข้อมูล
          </Button>
        </HeaderBox>
      </div>
      <BoxExport>
        <div className="box-grid">
          <span className="title">เรื่องร้องเรียน</span>
          <span className="detail">{dataById?.complaint_name}</span>
        </div>
        <div className="box-grid">
          <span className="title">วันที่/เวลารับเรื่อง</span>
          <span className="detail">{conversFullMonth(dataById?.created_at)}</span>
        </div>
        <div className="box-grid">
          <span className="title">เลขที่เรื่องร้องเรียน</span>
          <span className="detail">{dataById?.complaint_no}</span>
        </div>
        <div className="box-grid">
          <span className="title">หน่วยงานที่รับผิดชอบ</span>
          <span className="detail">{dep?.toString() ? dep?.toString() : '-'}</span>
        </div>
        <div className="box-grid">
          <span className="title">ช่องทางการร้องเรียน</span>
          <span className="detail">{dataById?.channel}</span>
        </div>
        <div className="box-grid">
          <span className="title">ข้อมูลผู้ร้องเรียน</span>
          <span className="detail">
            ชื่อ {dataById?.name?.first_name} {dataById?.name?.last_name} หมายเลขโทรศัพท์ {dataById?.mobile_number}
          </span>
        </div>
        <div className="box-grid">
          <span className="title">ที่อยู่ผู้ร้อง</span>
          <span className="detail">{dataById?.address ? dataById?.address : '-'}</span>
        </div>
        <div className="box-grid">
          <span className="title">รายละเอียดการร้องเรียน</span>
          <span className="detail">{dataById?.description ? dataById?.description : '-'}</span>
        </div>
        <div style={{ marginBottom: '2%' }} className="title">
          เอกสารแนบอื่นๆ(ถ้ามี)
        </div>
        <div className="box-img">
          {dataById?.media_url?.length > 0
            ? dataById?.media_url?.map(r => {
                return r.includes('.mp4') ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsShowVideo(true)
                      setIsVideo(r)
                    }}
                  >
                    <video width="750" height="500" controls>
                      <source src={r} type="video/mp4" />
                    </video>
                    <div style={{ position: 'absolute' }}>
                      <IvdoPlay />
                    </div>
                  </div>
                ) : (
                  <img
                    alt="show img"
                    onClick={() => {
                      setIsShowImage(true)
                      setIsImage(r)
                    }}
                    src={r}
                  />
                )
              })
            : '-'}
        </div>
        <div style={{ marginBottom: '20px' }} className="title">
          ตำแหน่ง
        </div>
        {!isPrint && <OpenlayerMap data={dataById} />}
        <div className="box-coordinates">
          <div>
            <p>ละติจูด</p>
            <p>{dataById?.coordinates?.latitude}° N</p>
          </div>
          <div>
            <p>ลองจิจูด</p>
            <p>{dataById?.coordinates?.longitude}° E</p>
          </div>
        </div>
        <ModalPreviewImage
          title=""
          centered
          transitionName=""
          closable={false}
          open={isShowVideo || isShowImage}
          footer={false}
          onOk={() => {
            setIsShowVideo(false)
            setIsShowImage(false)
          }}
          onCancel={() => {
            setIsShowVideo(false)
            setIsShowImage(false)
          }}
        >
          <ModelContent>
            <div
              className="close-ui"
              onClick={() => {
                setIsShowVideo(false)
                setIsShowImage(false)
              }}
            >
              <img src={IconCloseModal} alt="dd" style={{ width: '80px', height: '70px' }} />
            </div>
            {isVideo ? (
              <video width="750" height="500" controls>
                <source src={isVideo} type="video/mp4" />
              </video>
            ) : (
              <div className="vdo-content">
                <img src={isImage} style={{ width: '100%', height: 'auto' }} />
              </div>
            )}
          </ModelContent>
        </ModalPreviewImage>
      </BoxExport>
      {isPrint && (
        <div style={{ display: isPrint ? 'block' : 'none', position: 'absolute', bottom: '100%' }}>
          <ExportComplante componentRef={componentRef} data={dataById} isPrint={isPrint} />
        </div>
      )}
    </BoxContent>
  )
}

const BoxContent = styled.div``

const ModalPreviewImage = styled(Modal)`
  font-family: 'IBM Plex Sans Thai', sans-serif;

  .close-ui {
    position: fixed;
    top: 35px;
    right: 90px;
    padding: 0px;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    font-weight: 800;
    color: #3f54d1;
    z-index: 100;
    transition: none;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    width: auto;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
    .ant-btn-default {
      border-radius: 12px;
      border-color: #00ada4 !important;
      color: #00ada4 !important;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #00ada4 !important;
      border-color: #00ada4;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

const ModelContent = styled.div`
  .vdo-content {
    position: relative;
    padding: 0;
  }
  .model-ui {
    font-style: normal;
    .header-ui {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 47px;
      color: #19263f;
      text-align: left;
    }
    .model-crop {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .controls {
      background: #f8e2e2;
      border-radius: 35px;
      width: 200px;
      padding: 0.2em 12px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      .slider {
        width: 160px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .button-zoom {
        line-height: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #1f3983;
        width: 20px;
        height: 20px;
        user-select: none;
      }
    }
  }
`
const Title = styled.div`
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 769px) {
    font-size: 24px;
  }
`

const HeaderBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 18px;
  .btn-dowload {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f36b24;
    color: #f36b24;
    align-items: center;
    display: flex;
    @media screen and (max-width: 768px) {
      height: 30px;
      font-size: 10px;
    }
    @media screen and (min-width: 769px) {
      height: 40px;
      font-size: 12px;
    }
  }

  .btn-print {
    border-radius: 10px;
    background-color: #f36b24;
    border: 1px solid #f36b24;
    color: #fff;
    align-items: center;
    display: flex;
    height: 40px;
  }
`
const ButtonBack = styled(Button)`
  width: 40px;
  height: 40px;
  border: none !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    border-color: transparent;
    background: #fbfbfb !important;
  }
`
const BoxExport = styled.div`
  padding: 5%;
  margin: 2% 5%;
  font-family: 'Prompt';
  background-color: #fff;
  border-radius: 20px;
  .bw {
    filter: grayscale(100%);
  }
  .box-coordinates {
    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-weight: 600;
    color: #24292f;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
    @media screen and (min-width: 769px) {
      font-size: 12px;
    }
  }
  .title {
    font-weight: 600;
    color: #24292f;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
    @media screen and (min-width: 769px) {
      font-size: 12px;
    }
  }
  .box-grid {
    display: grid;
    margin-bottom: 2%;
    @media screen and (max-width: 768px) {
      grid-template-columns: 2fr 4fr;
    }
    @media screen and (min-width: 769px) {
      grid-template-columns: 1fr 3fr;
    }
  }

  .detail {
    font-weight: 400;
    color: #24292f;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
    @media screen and (min-width: 769px) {
      font-size: 12px;
    }
  }
  .box-img {
    display: flex;
    gap: 2%;
    margin-bottom: 2%;
    video,
    img {
      width: 110px;
      height: 100px;
      border-radius: 10px;
    }
  }
  .print:last-child {
    page-break-after: auto;
  }

  .new-page {
    page-break-before: always;
  }

  @media print {
    @page {
      size: A4 portrait !important;
      margin: 0mm !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
`
