import moment from 'moment'

export const unixToDate = (value, type) => {
  let date = new Date(moment.unix(value))
  const options =
    type === 'monthly' ? { year: 'numeric', month: 'long' } : { year: 'numeric', month: 'long', day: 'numeric' }
  const dateTime = `${date.toLocaleDateString('th', options)} `
  // const dateTimeFormat = moment(moment.unix(value)).format('DD MMM YYYY').toString()
  return dateTime
}

export const unixToDateTime = (moment, format) => {
  var christianYear = moment.format('YYYY')
  var buddhishYear = (parseInt(christianYear) + 543).toString()
  return moment
    .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
    .replace(christianYear, buddhishYear)
}

export const conversDate = unixTimestamp => {
  if (!unixTimestamp) return null
  const date = new Date(unixTimestamp * 1000) // Convert Unix timestamp to milliseconds
  const thaiMonths = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
  const thaiDate = `${date.getDate()} ${thaiMonths[date.getMonth()]} ${date.getFullYear() + 543}`
  return formatThaiDate(thaiDate)
}
export const formatThaiDate = dateString => {
  const [day, month, year] = dateString?.split(' ')
  const formattedDay = day?.length === 1 ? `0${day}` : day // Add leading zero if the day is single-digit
  return `${formattedDay} ${month} ${year}`
}
export const conversFullDate = unixTimestamp => {
  if (!unixTimestamp) return null
  const date = new Date(unixTimestamp * 1000) // Convert Unix timestamp to milliseconds
  const thaiMonthsAbbreviated = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ]

  const formattedTime = date.toLocaleTimeString('th-TH', {
    hour: '2-digit',
    minute: '2-digit',
  })

  const thaiDateAbbreviated = `${date.getDate()} ${thaiMonthsAbbreviated[date.getMonth()]} ${date.getFullYear() + 543}`

  const fullThaiDate = `${formattedTime} น.,${thaiDateAbbreviated}`
  return fullThaiDate
}

export const conversFullMonth = unixTimestamp => {
  if (!unixTimestamp) return null
  const date = new Date(unixTimestamp * 1000) // Convert Unix timestamp to milliseconds
  const thaiMonthsAbbreviated = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ]

  const formattedTime = date.toLocaleTimeString('th-TH', {
    hour: '2-digit',
    minute: '2-digit',
  })

  const thaiDateAbbreviated = `${date.getDate()} ${thaiMonthsAbbreviated[date.getMonth()]} ${date.getFullYear() + 543}`

  const fullThaiDate = ` ${thaiDateAbbreviated},${formattedTime} น.`
  return fullThaiDate
}
