import { Input, Select, DatePicker, ConfigProvider, Divider } from 'antd'
import React, { useCallback } from 'react'
import { BoxSearch } from './map.style'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/th' // Import Thai locale for moment
import localeTH from 'antd/es/date-picker/locale/th_TH'
import thES from 'antd/es/locale/th_TH'
const { Option } = Select
const { RangePicker } = DatePicker
export default function Search({ masterData, setSearchData, searchData, checkRoleSupAdmin, loadingTable }) {
  let optionsCategories = masterData?.categories?.map(item => {
    return {
      label: (
        <div>
          <img src={item?.icon_url} style={{ marginRight: '10px', width: '14px', height: '14px' }} />
          {item.name}
        </div>
      ),
      value: item.id,
    }
  })
  let optionsStatus = masterData?.status?.map(item => {
    return {
      label: item.name,
      value: item.name_en,
    }
  })
  let optionsUrgency = masterData?.urgency?.map(item => {
    return {
      label: item?.name,
      value: item.id,
    }
  })
  let optionsDepartment = masterData?.department?.map(item => {
    return {
      label: item?.name,
      value: item.id,
    }
  })
  let optionsSatisfaction = [
    {
      label: 'ยอดเยี่ยม',
      value: 5,
    },
    {
      label: 'ดีมาก',
      value: 4,
    },
    {
      label: 'ดี',
      value: 3,
    },
    {
      label: 'พอใช้',
      value: 2,
    },
    {
      label: 'ควรปรับปรุง',
      value: 1,
    },
  ]
  let optionsChannel = masterData?.channel?.map((items, index) => ({
    value: items?.name_en,
    label: items?.name_th,
    key: index,
  }))
  const handleChange = (value, type) => {
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  const thaiYearFormat = date => {
    if (date) {
      return date.clone().add(543, 'years')
    }
    return null
  }

  const disabledDate = current => {
    return current && current > moment().endOf('day')
  }

  const customLocale = {
    ...localeTH,
    lang: {
      ...localeTH.lang,
      yearFormat: year => thaiYearFormat(year).format('YYYY'),
      dateFormat: 'DD/MM/YYYY',
      dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
      cellYearFormat: year => thaiYearFormat(year).format('YYYY'),
    },
  }

  const globalBuddhistLocale = {
    ...thES,
    DatePicker: {
      ...thES.DatePicker,
      lang: customLocale.lang,
    },
  }
  const yearPanelRender = current => {
    const year = current.year() + 543
    console.log('current', current)
    return (
      <div className="ant-picker-cell">
        <div className="ant-picker-cell-inner">{year}</div>
      </div>
    )
  }

  const RangPickerCallBack = useCallback(() => {
    return (
      <RangePicker
        disabledDate={disabledDate}
        //  locale={customLocale}
        //   format={value => thaiYearFormat(value).format('DD/MM/YYYY')}
        defaultValue={[searchData.date[0], searchData.date[1]]}
        onChange={val => handleChange(val, 'date')}
        format={'DD/MM/YYYY'}
        allowClear={false}
      />
    )
  }, [searchData])

  return (
    <BoxSearch>
      <BoxSelect>
        <div
          style={{
            display: loadingTable ? 'none' : 'grid',
            gridTemplateColumns: checkRoleSupAdmin
              ? '1fr 1fr 1fr 1fr 1fr 1fr 1fr 250px 1fr'
              : '1fr 1fr 1fr 1fr 1fr 1fr 250px 1fr',
            gap: '10px',
          }}
          className="search-component"
        >
          <Input
            placeholder="ค้นหาจากเรื่องร้องเรียน,เลขที่เรื่องร้องเรียน,ชื่อ,เบอร์โทร"
            value={searchData?.search}
            onChange={val => handleChange(val, 'input')}
            allowClear={true}
          />
          <Select
            placeholder="ประเภททั้งหมด"
            value={searchData?.category}
            options={optionsCategories}
            onSelect={val => handleChange(val, 'category')}
            onClear={val => handleChange(val, 'category')}
            allowClear
          />

          <Select
            placeholder="สถานะทั้งหมด"
            options={optionsStatus}
            value={searchData?.status}
            onSelect={(val, e) => {
              handleChange(e?.value, 'status')
            }}
            onClear={val => handleChange(val, 'status')}
            allowClear
          />
          {checkRoleSupAdmin && (
            <Select
              placeholder="หน่วยงานที่รับผิดชอบ"
              options={optionsDepartment}
              value={searchData?.department}
              onSelect={(val, e) => {
                handleChange(e?.value, 'department')
              }}
              onClear={val => handleChange(val, 'department')}
              listHeight={300}
              allowClear
            />
          )}
          <Select
            placeholder="ความเร่งด่วน"
            optionFilterProp="label"
            value={searchData?.urgency}
            options={optionsUrgency}
            onSelect={val => handleChange(val, 'urgency')}
            onClear={val => handleChange(val, 'urgency')}
            optionLabelProp="label"
            allowClear
          />
          <Select
            placeholder="ความพึงพอใจ"
            optionFilterProp="label"
            value={searchData?.satisfaction}
            options={optionsSatisfaction}
            onSelect={val => handleChange(val, 'satisfaction')}
            onClear={val => handleChange(val, 'satisfaction')}
            allowClear
          />
          <Select
            placeholder="ประเภทช่องทางการร้องเรียน"
            optionFilterProp="label"
            value={searchData?.channel}
            options={optionsChannel}
            onSelect={val => handleChange(val, 'channel')}
            onClear={val => handleChange(val, 'channel')}
            allowClear
          />
          {/* <ConfigProvider locale={globalBuddhistLocale}> */}
          <RangPickerCallBack />
          {/* </ConfigProvider> */}

          {/* <RangePicker
            // placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
            // value={[startDate, endDate]}
            // onChange={val => handleChange(val, 'date')}
            // format={'DD/MM/YYYY'}
            // className="date-picker"
            // disabledDate={disabledDate}
            // defaultValue={[moment().subtract(30, 'days'), moment()]}
            // defaultValue={defaultValue}
            picker="year"
            disabledDate={disabledDate}
            locale={customLocale}
            format={value => thaiYearFormat(value).format('DD/MM/YYYY')}
            defaultValue={[searchData.date[0], searchData.date[1]]}
            onChange={val => handleChange(val, 'date')}
          /> */}
          <span
            style={{
              color: '#556A82',
              textDecorationLine: 'underline',
              paddingTop: '10px',
              cursor: 'pointer',
              width: '60px',
            }}
            onClick={() =>
              setSearchData({
                date: [moment().subtract(30, 'days'), moment()],
              })
            }
          >
            ล้างข้อมูล
          </span>
        </div>
      </BoxSelect>
    </BoxSearch>
  )
}
const BoxSelect = styled.div`
  margin-top: 5px;

  .date-picker,
  .ant-picker-input > input {
    color: #f36b24 !important;
  }

  .ant-picker-header {
    display: none !important;
  }

  .ant-picker-range .ant-picker-input > input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`
