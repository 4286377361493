import styled from 'styled-components'
import { Menu } from 'antd'
export const Menucustom = styled(Menu)`
  padding: 8px !important;
  width: 160px !important;
  .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
  .ant-dropdown-menu-title-content:hover {
    background-color: #ffeee7;
    color: #f36b24;
    border-radius: 4px;
  }
`
export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-radius: 8px;
  gap: 15px;

  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 162px;
    border-radius: 8px;
  }
`
export const TitleName = styled.div`
  display: flex;
  align-self: start;
  margin-bottom: 40px;
`
export const BoxLegendChart = styled.div`
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.BgColor ? props.BgColor : '')};
  }
`

export const BoxPointMap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.BgColor ? props.BgColor : '')};
  }

  span {
    font-size: 10px;
    color: #717171;
    font-weight: 300;
  }
`

export const StyleDropdown = styled.div`
  /* width: 50%; */
  max-width: 500px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    max-width: 500px;
    min-width: 200px;
    height: 40px;
    padding: 0 11px;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }

  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 8px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #ffeee7;
    /* width: 100%; */
  }

  .ant-select-disabled .ant-select-selector {
    background: #ffeee7 !important;
    border: 1px solid #ffeee7 !important;
    color: #f36b24 !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #f36b24;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }

  .ant-select,
  .ant-select-single,
  .ant-select-show-arrow,
  .ant-select-show-search {
    width: 100%;
  }

  .ant-select-selection-placeholder {
    color: #ffc4aa !important;
  }
  .big-input .ant-select-selection-placeholder {
    /* line-height: 37px; */
    padding-left: 0 !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffeee7;
    color: #f36b24;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
  }
  .ant-select-clear {
    margin-top: -8px !important;
    padding-right: 16px !important;
  }
`

export const BoxbgChart = styled.div`
  background-color: rgb(251, 251, 251);
  border-radius: 12px;
  padding: 24px;
  margin: 0 10px;
`

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

export const BoxOverAll = styled.div`
  width: 100%;
  padding: 0 40px 40px 40px;

  @media only screen and (max-width: 820px) {
    padding: 0 20px 20px 20px;
  }
`

export const HeaderBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 18px;
  .btn-dowload {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #f36b24;
    color: #f36b24;
    align-items: center;
    display: flex;
    height: 40px;
  }

  .btn-print {
    border-radius: 10px;
    background-color: #f36b24;
    border: 1px solid #f36b24;
    color: #fff;
    align-items: center;
    display: flex;
    height: 40px;
  }
`

export const HeaderTopic = styled.h1`
  font-family: Prompt;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  text-align: center;
  margin-top: 33px;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    text-align: left;
  }

  @media only screen and (max-width: 635px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    text-align: left;
  }

  @media only screen and (max-width: 376px) {
    font-size: 14px;
    text-align: left;
  }
`

export const BoxForm = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  border-radius: 12px;
  background: rgba(252, 101, 33, 0.03);
  margin: 16px 0px 16px 0px;
  padding: 16px;
`

export const CustomTooltip = styled.div`
  .apexcharts-tooltip.apexcharts-theme-dark,
  .apexcharts-tooltip.apexcharts-theme-light {
    width: 170px;
    height: 50px;
    background: #fafafa;
    box-shadow: 0px 1px 16px #eaeaea;
    border-radius: 16px;
    border: none !important;
    padding: 10px 16px;
    color: #000;
    font-size: 12px;
    z-index: 99999;

    .center-tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text-title {
      margin: 0px;
      font-size: 12px;
      color: rgba(0, 31, 52, 1);
      font-weight: 600;
    }
    .line {
      width: 160px;
      border: 1px solid #dde0df;
      margin: 10px 0px;
    }
    .row {
      align-items: center;
      .box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
      }
      .text {
        margin: 0 10px 0 0;
        font-size: 12px;
        padding-right: 45px;
      }
      .text-b {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
`

export const BoxAllState = styled.div`
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;

  .wrap-status-firstline {
    display: flex;

    @media only screen and (max-width: 820px) {
      flex-direction: column;
    }
  }

  .wrap-box-numofcomp {
    width: 50%;
    margin: 5px;

    @media only screen and (max-width: 820px) {
      width: 100%;
    }
  }

  .wrap-status-secondline {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 820px) {
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  .wrap-box-numofcomp-secondline {
    width: 33%;
    margin: 10px;

    @media only screen and (max-width: 820px) {
      width: 100%;
    }
  }
`

export const MonthText = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const StyledImage = styled.img`
  padding-bottom: 10px;
`

export const BoxNumOfComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border-radius: 12px;
  /* background: #fffbf8; */
  background: ${props => (props.BgNumCom ? props.BgNumCom : '#fffbf8')};
`

export const LeftBoxNumOfComp = styled.div`
  display: flex;
  padding-top: 15px;
  gap: 8px;
`

export const TopicHeaderStatus = styled.div`
  display: flex;
  gap: 8px;
`

export const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

export const TextSatusStay = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
`

export const FlexAgency = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

export const NumStyle = styled.p`
  font-family: Prompt;
  padding-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`
