import React from 'react'
import { Select, Input, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { ReactComponent as IconSearch } from 'assets/images/svg/icon-search.svg'
import { ReactComponent as Chevron } from 'assets/images/svg/filter-chevron-org.svg'
import { ReactComponent as InvertChevron } from 'assets/images/svg/filter-invert-chevron-org.svg'
// import { ReactComponent as Chevron } from 'assets/images/svg/filter-chevron.svg'
// import { ReactComponent as InvertChevron } from 'assets/images/svg/filter-invert-chevron.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-orange.svg'

import { BoxSearch } from './DropdownSearch.style'

const { Option } = Select
const Index = ({
  onChange,
  options,
  className,
  placeholder = 'ทั้งหมด',
  onSelectFunc,
  isSearchFunc,
  totalFunc,
  total,
  currentPageFunc,
  currentPage,
  defaultValue,
  name,
  setTypeFilter,
  setStatusFilter,
  showSearch = true,
  showTooltip = false,
  allowClear = false,
  disabled,
  dropdownValue,
  popupClassName,
  style = { inlineSize: 192, height: 'auto', wordWrap: 'break-word' },
}) => {
  const [controlDropdow, setControlDropdow] = React.useState(false)
  const [content, setContent] = React.useState(null)

  const myRef = React.createRef()

  if (options.length === 0 || (options.length === 1 && options[0].label === 'ทั้งหมด')) {
    disabled = true
  }

  const newOptions =
    options &&
    options.map(item => {
      return {
        value: item.value,
        label: item.label,
        key: item.key,
        title: '',
      }
    })

  const renderSuffix = () => {
    if (controlDropdow) {
      return <InvertChevron style={{ cursor: disabled ? 'auto' : 'pointer', pointerEvents: 'auto' }} />
    } else {
      return <Chevron style={{ cursor: disabled ? 'auto' : 'pointer', pointerEvents: 'auto' }} />
    }
  }

  return (
    <div>
      <BoxSearch>
        <div style={{ fontSize: '14px', color: '#001F34' }}>{name}</div>
        <Select
          ref={myRef}
          className={className}
          allowClear={allowClear}
          clearIcon={<IconClear />}
          suffixIcon={renderSuffix()}
          defaultValue={defaultValue}
          showSearch={showSearch}
          disabled={disabled}
          open={controlDropdow}
          onClear={() => {
            totalFunc && totalFunc(total)
            currentPageFunc && currentPageFunc(currentPage)
            if (setTypeFilter) setTypeFilter({ value: 'ทั้งหมด', label: 'ทั้งหมด', key: 'ทั้งหมด', title: '' })
            if (setStatusFilter) setStatusFilter('all')
          }}
          onClick={() => {
            if (!disabled) {
              setControlDropdow(!controlDropdow)
            }
          }}
          onBlur={() => {
            setControlDropdow(false)
          }}
          onInputKeyDown={e => {
            setControlDropdow(true)
          }}
          onSelect={(value, option) => {
            if (setTypeFilter) setTypeFilter(option)
            if (setStatusFilter)
              setStatusFilter({
                value: value,
                label: value,
              })
            myRef.current.blur()
          }}
          placeholder={placeholder}
          filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          listHeight={280}
          listItemHeight={1}
          onSearch={value => {
            setContent(value)
          }}
          onChange={value => {
            onChange && onChange(value)
            if (dropdownValue) {
              if (setTypeFilter) setTypeFilter(value)
            }
          }}
          value={dropdownValue || undefined}
          notFoundContent={<div style={{ color: '#597081' }}>ไม่พบข้อมูลของ "{content}"</div>}
          getPopupContainer={triggerNode => triggerNode.parentElement}
          style={style}
          popupClassName={popupClassName}
        >
          {newOptions.map(obj => {
            return (
              <Option value={obj.value} key={obj.key}>
                {showTooltip && (
                  <Tooltip title={obj.label} placement="bottomLeft" overlayClassName="tooltip-action">
                    {obj.label}
                  </Tooltip>
                )}
                {!showTooltip && obj.label}
              </Option>
            )
          })}
        </Select>
      </BoxSearch>
    </div>
  )
}

export default Index
