import React, { useEffect, useState } from 'react'
import DropdownForm from 'components/Inputs/DropdownForm'
import { Button, Drawer, Modal, Spin } from 'antd'
import { complaint } from 'core/schemas'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconTitleAddOfficer } from 'assets/images/svg/icon-title-add-officer.svg'

import { ReactComponent as IconGear } from 'assets/images/svg/department/gear-icon.svg'
import { ReactComponent as IconMoney } from 'assets/images/svg/department/money.svg'
import { ReactComponent as IconEngineer } from 'assets/images/svg/department/engineer.svg'
import { ReactComponent as IconStudy } from 'assets/images/svg/department/study.svg'
import { ReactComponent as IconHuman } from 'assets/images/svg/department/human.svg'
import { ReactComponent as IconStaff } from 'assets/images/svg/department/staff.svg'
import { ReactComponent as IconHospital } from 'assets/images/svg/department/hospital.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { getMe, postData } from 'core/action/collection'
import styled from 'styled-components'

const AddOfficer = ({
  openDrawerAddOfficer,
  setLeaveModalOpen,
  loadingAddOfficer,
  deparmentData,
  organizeId,
  listAdmin,
  complaintId,
  onCloseAddOfficer,
  openNotification,
  getDataEservice,
}) => {
  const departmentid = localStorage.getItem('organize')

  const dePartMentIcon = {
    สำนักช่าง: <IconGear />,
    สำนักคลัง: <IconMoney />,

    สำนักการศึกษา: <IconStudy />,
    กองสวัสดิการสังคม: <IconHuman />,
    กองการเจ้าหน้าที่: <IconMoney />,
    สำนักงานปลัดเทศบาล: <IconStaff />,
    กองยุทธศาสตร์และงบประมาณ: <IconMoney />,
    สำนักสาธารณสุขและสิ่งแวดล้อม: <IconHospital />,
  }

  const Schema = Yup.object().shape({
    staff_by: Yup.string().required(''),
  })

  const formik = useFormik({
    initialValues: { staff_by: '' },
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const [addOffData, setAddOffData] = useState([])
  const [openModal, setOpenModal] = useState(false)

  const isValidate = formik.dirty && formik.isValid

  const onChangeDropdwon = (id, value) => {
    formik.setFieldValue('staff_by', value)
  }

  const onSubmit = async () => {
    const payload = {
      e_service_id: complaintId,
      department_id: departmentid,
      staff_by: formik.values.staff_by,
    }

    const res = await postData(`${complaint}/e-service/officer`, payload)

    if (res) {
      if (res?.code === 201) {
        setOpenModal(false)
        onCloseAddOfficer()
        openNotification('addofficer')
        getDataEservice()
      } else {
        console.log('Err', res)
        setOpenModal(false)
      }
    }
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const selectedDepartment = deparmentData?.id === organizeId ? deparmentData : null

  const filteredUsers = listAdmin.filter(user => user.organize_data.org_id === selectedDepartment?.id)
  const optionsUser = filteredUsers.map(user => ({
    label: `${user.first_name.th} ${user.last_name.th}`,
    value: user.id,
  }))

  return (
    <>
      <CustomDrawer
        placement="right"
        open={openDrawerAddOfficer}
        onClose={() => setLeaveModalOpen(true)}
        closable={false}
      >
        {loadingAddOfficer ? (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
            <Spin />
          </div>
        ) : (
          <>
            <div style={{ height: '6.5em', position: 'relative' }}>
              <div className="close-icon" onClick={() => setLeaveModalOpen(true)}>
                <IconClear />
              </div>
            </div>
            <div className="wrap-body">
              <div className="wrap-title">
                <IconTitleAddOfficer style={{ marginRight: '16px' }} />
                <div className="wrap-box-title">
                  <div className="title">เพิ่มข้อมูลเจ้าหน้าที่</div>
                  <div className="description">กรุณาเลือกผู้รับผิดชอบ</div>
                </div>
              </div>
              <div style={{ marginBottom: '1rem' }}>
                <LabelText>
                  <BoxLabel>
                    <div style={{ marginRight: '8px' }}>{dePartMentIcon[selectedDepartment?.name]}</div>
                    <div>{selectedDepartment?.name}</div>
                  </BoxLabel>
                </LabelText>
                <StyleDropdown>
                  <DropdownForm
                    title="เจ้าหน้าที่"
                    placeholder="กรุณาเลือก"
                    showSearch={false}
                    required={true}
                    onChange={value => onChangeDropdwon(selectedDepartment?.id, value)}
                    className="big-input"
                    options={optionsUser}
                  />
                </StyleDropdown>
              </div>

              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '4em',
                  marginBottom: '4em',
                }}
              >
                <BtSubmit disabled={!isValidate} onClick={() => showModal()}>
                  ยืนยัน
                </BtSubmit>
              </div>
            </div>
          </>
        )}
      </CustomDrawer>

      <CustomModal
        transitionName=""
        centered
        open={openModal}
        onOk={() => onSubmit()}
        onCancel={() => onCloseModal()}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">ยืนยันการเพิ่มข้อมูลเจ้าหน้าที่</p>
        <p className="modal-message">หากคุณเพิ่มข้อมูลเจ้าหน้าที่นี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้</p>
      </CustomModal>
    </>
  )
}

export default AddOfficer

const CustomDrawer = styled(Drawer)`
  font-family: 'Prompt' !important;
  /* .ant-drawer-body {
    padding: 40px;
  } */

  .close-icon {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }

  .wrap-body {
    padding: 0 16px;
    width: 100%;
    height: auto;
  }

  .wrap-title {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .wrap-box-title {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    color: #150f0b;
  }

  .description {
    color: #c4c4c4;
    font-weight: 300;
    font-size: 12px;
  }
`
const LabelText = styled.div`
  font-family: 'Prompt';
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #556a82;
  /* padding-bottom: 8px; */
  margin-bottom: 8px;
  /* width: auto; */
`

const StyleDropdown = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #fee0d3;
    height: 40px;
    width: 100%;
  }

  .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
    background-color: #fff3ee !important;
    color: #fff3ee !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #fff3ee;
  }
`
const BoxLabel = styled.div`
  box-shadow: 0px 0px 8px 0px rgba(46, 60, 79, 0.06);
  border-radius: 8px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
`

const BtSubmit = styled(Button)`
  background-color: #fc6521;
  border-color: #fc6521;
  /* background-color: ${props => (props.disabled ? 'transparent' : '#fc6521')}; */
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  width: 120px;
  height: 48px;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #fc6521;
    border-color: #fc6521;
  }

  &.ant-btn[disabled] {
    background: rgba(252, 101, 33, 1) !important;
    color: #ffffff;
    opacity: 0.2;
  }
`
export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  z-index: 1400 !important;

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
