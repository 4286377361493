// import {
//   GoogleMap,
//   Marker,
//   Autocomplete,
//   DirectionsRenderer,
//   InfoWindow,
//   InfoWindowF,
//   LoadScript,
// } from '@react-google-maps/api'

// import React, { useState } from 'react'

// const Viewmap = () => {
//   const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })

//   const mapStyles = {
//     height: '70vh',
//     width: '100%',
//     borderRadius: '25px',
//   }

//   const defaultCenter = { lat: 15, lng: 100 } // Default center for the map

//   const CurrantLocation = ({ meLocation }) => <Marker position={meLocation} label="Me" />

//   return (
//     <>
//       <GoogleMap
//         mapContainerStyle={mapStyles}
//         zoom={10}
//         center={meLocation.lat ? meLocation : defaultCenter}
//         options={{
//           zoomControl: false,
//           streetViewControl: false,
//           mapTypeControl: false,
//           fullscreenControl: false,
//           minZoom: 6,
//           clickableIcons: false,
//           restriction: {
//             latLngBounds: {
//               north: 20.856935,
//               south: 5.612851,
//               west: 97.337267,
//               east: 105.638703,
//             },
//           },
//         }}
//         // onLoad={onLoad}
//       >
//         {meLocation.lat && <CurrantLocation meLocation={meLocation} />}
//       </GoogleMap>
//     </>
//   )
// }

// export default Viewmap

import React, { useEffect, useState, useRef } from 'react'
import iconedit from 'assets/images/svg/icon-edit-org.svg'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import iconeMarker from 'assets/images/svg/markericon.svg'
import { Button } from 'antd'
import { Container } from './view.style'
import MapButton from './GoogleMap/Map'

// import { ReactComponent as IconCurrentLocationright } from 'assets/images/svg/icon-current-location-org.svg'
// import { ReactComponent as IconCurrentLocationchangeright } from 'assets/images/svg/icon-current-location-change.svg'
import { ReactComponent as IconZoomIn } from 'assets/images/svg/icon-zoom-in.svg'
import { ReactComponent as IconZoomOut } from 'assets/images/svg/icon-zoom-out.svg'

const Viewmap = ({ setLocationFromMap }) => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [zoom, setZoom] = useState(10)
  const [current, setCurrent] = useState(false)
  const [map, setMap] = useState(null)
  const [submit, setsubmit] = React.useState({})
  const [locationme, setlocationme] = React.useState({})

  const zoomInRef = useRef(null)
  const zoomOutRef = useRef(null)

  const mapStyle = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#7c88a6',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#dde6e8',
        },
      ],
    },
    {
      featureType: 'administrative.neighborhood',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dde6e8',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#7c88a6',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f2f2f2',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#7c88a6',
        },
      ],
    },
    {
      featureType: 'poi.business',
      elementType: 'labels.icon',
      stylers: [
        {
          color: '#937f66',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#e6e6e6',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#97a6cc',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dddfe1',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#dddfe1',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#97a6cc',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#97a6cc',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#97a6cc',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#97a6cc',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#81c0fe',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ccd4e0',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#bac4d1',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#868ea2',
        },
      ],
    },
  ]

  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })

  const mapStyles = {
    height: '280px',
    width: '100%',
    borderRadius: '25px',
  }

  const defaultCenter = { lat: 15, lng: 100 }

  const CurrantLocation = ({ meLocation }) => <Marker position={meLocation} icon={iconeMarker} />

  // useEffect(() => {
  //   const fetchUserLocation = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         position => {
  //           const { latitude, longitude } = position.coords
  //           setMeLocation({ lat: latitude, lng: longitude })
  //         },
  //         error => {
  //           console.error('Error getting location:', error.message)
  //         }
  //       )
  //     } else {
  //       console.error('Geolocation is not supported by this browser.')
  //     }
  //   }

  //   fetchUserLocation() // Call the function when the component mounts

  //   // Optionally, you can include cleanup logic here if needed
  // }, [])

  useEffect(() => {
    handleGetCurrentPosition()
  }, [])

  const handleZoomIn = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1)
    }
  }

  const handleZoomOut = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1)
    }
  }

  const onLoad = map => {
    setMap(map)
  }

  const handleGetCurrentPosition = type => {
    setLoading(true)
    setError(null)
    if (type === 'currentlocation') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setMeLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })

            setLocationFromMap({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })

            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            setZoom(16)
            // covertTothai(position.coords.latitude, position.coords.longitude)
            setCurrent(true)
          },
          error => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.error('User denied the request for Geolocation.')
                break
              case error.POSITION_UNAVAILABLE:
                console.error('Location information is unavailable.')
                break
              case error.TIMEOUT:
                console.error('The request to get user location timed out.')
                break
              default:
                console.error('An unknown error occurred.')
                break
            }
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setMeLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })

            setLocationFromMap({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })

            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            setZoom(16)
            // covertTothai(position.coords.latitude, position.coords.longitude)
            setCurrent(false)
          },
          error => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.error('User denied the request for Geolocation.')
                break
              case error.POSITION_UNAVAILABLE:
                console.error('Location information is unavailable.')
                break
              case error.TIMEOUT:
                console.error('The request to get user location timed out.')
                break
              default:
                console.error('An unknown error occurred.')
                break
            }
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }

    setLoading(false)
  }

  const updateLocation = () => {
    setMeLocation()
  }

  const GoogleMapRender = () => {
    return (
      <>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={15}
          center={meLocation.lat ? meLocation : defaultCenter}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            // draggableCursor: isClickDistance ? 'crosshair' : '',
            minZoom: 6,
            clickableIcons: false,
            styles: mapStyle,
            restriction: {
              latLngBounds: {
                north: 20.856935,
                south: 5.612851,
                west: 97.337267,
                east: 105.638703,
              },
            },
          }}
          onLoad={onLoad}
        >
          <div id={'option-button'}>
            <div style={{ paddingBottom: '8px' }}>
              {/* <Button
                className="btn-get-location"
                onClick={() => handleGetCurrentPosition('currentlocation')}
                icon={
                  meLocation?.lat && current == true ? <IconCurrentLocationchangeright /> : <IconCurrentLocationright />
                }
              /> */}
            </div>
            <div>
              <Button className="btn-zoom-in" ref={zoomInRef} onClick={() => handleZoomIn()} icon={<IconZoomIn />} />
            </div>
            <div>
              <Button
                className="btn-zoom-out"
                ref={zoomOutRef}
                onClick={() => handleZoomOut()}
                icon={<IconZoomOut />}
              />
            </div>
          </div>
          <div id={'openmap'}>
            <MapButton
              OnReturnLocation={(lat, lng) => {
                setMeLocation({
                  lat: lat,
                  lng: lng,
                })
                setLocationFromMap({
                  lat: lat,
                  lng: lng,
                })
              }}
              submitcheck={submit}
            ></MapButton>
          </div>
          {meLocation.lat && <CurrantLocation meLocation={meLocation} />}
        </GoogleMap>
      </>
    )
  }

  return (
    <>
      {/* <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY"> */}
      <Container>{loading ? <p>Loading...</p> : GoogleMapRender()}</Container>
      {/* </LoadScript> */}
    </>
  )
}

export default Viewmap
