import React, { useState } from 'react'
import styled from 'styled-components'
import { Input, Select, DatePicker, Space, Divider, Button } from 'antd'
import IconHambur from 'assets/images/svg/hambur-log.svg'
import IconCloselog from 'assets/images/svg/close-modal.svg'
import moment from 'moment'

const { Option } = Select
const SearchMobile = ({ masterData, setSearchData, searchData, conversDate }) => {
  const [openModal, setOpenModal] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const onOpen = () => {
    setOpenModal(!openModal)
  }
  let optionActivity = masterData?.activity?.map(item => {
    return {
      label: item.name_th,
      value: item.name_en,
    }
  })
  let optionsDepartment = masterData?.department?.map(item => {
    return {
      label: item?.name,
      value: item.name,
    }
  })

  const handleChange = (value, type) => {
    setDisableBtn(false)
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else if (type === 'date') {
      const dataDate = [value, value]

      setSearchData({
        ...searchData,
        date: dataDate,
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  return (
    <>
      {openModal ? (
        <div>
          <CardModal>
            <CardForm>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '' }}>
                <div style={{ fontSize: '22px', margin: '0 15px' }}>ตัวกรอง</div>
                <div onClick={onOpen}>
                  <img src={IconCloselog} alt="closelog" height="80px" />
                </div>
              </div>
              <BoxSearch style={{ padding: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 0' }}>
                  <div>ชื่อหน่วยงาน</div>
                  <Select
                    placeholder="หน่วยงานที่รับผิดชอบ"
                    options={optionsDepartment}
                    value={searchData?.department}
                    onSelect={(val, e) => {
                      handleChange(e?.value, 'department')
                    }}
                    onClear={val => handleChange(val, 'department')}
                    allowClear
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '15px 0' }}>
                  <div>ชื่อกิจกรรม</div>
                  <Select
                    placeholder="ชื่อกิจกรรม"
                    options={optionActivity}
                    value={searchData?.activity}
                    onSelect={(val, e) => {
                      handleChange(e?.value, 'activity')
                    }}
                    onClear={val => handleChange(val, 'activity')}
                    allowClear
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', margin: '15px 0' }}>
                  <div>เลือกวันที่</div>
                  <DatePicker
                    placeholder={'วันที่เริ่มต้น'}
                    // value={[startDate]}
                    onChange={val => handleChange(val, 'date')}
                    format={'DD/MM/YYYY'}
                    className="date-picker"
                    // disabledDate={disabledDate}
                  />
                </div>

                <CustomBtn>
                  <Button type="primary" disabled={disableBtn}>
                    ยืนยัน
                  </Button>
                </CustomBtn>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#556A82',
                    textDecorationLine: 'underline',
                    paddingTop: '10px',
                    cursor: 'pointer',
                    width: '100%',
                  }}
                  onClick={() => setSearchData([])}
                >
                  ล้างข้อมูล
                </div>
              </BoxSearch>
            </CardForm>
          </CardModal>
        </div>
      ) : (
        <ContainerBox>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>ค้นหา</div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BoxSearch>
                <Input
                  style={{ margin: ' 0 10px' }}
                  placeholder="ค้นหาชื่อเจ้าหน้าที่"
                  value={searchData?.search}
                  onChange={val => handleChange(val, 'input')}
                  allowClear={true}
                />
              </BoxSearch>
              <Boxhambur onClick={onOpen}>
                <img src={IconHambur} alt="xx" />
              </Boxhambur>
            </div>
          </div>
        </ContainerBox>
      )}
    </>
  )
}

export default SearchMobile

const CustomBtn = styled.div`
  margin: 10px 0px;

  .ant-btn {
    width: 100%;
    height: 5vh;
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    background: #f36b24;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    /* border-color: #d9d9d9; */
    border: none;
    background: #fee0d3;
    text-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 5vh;
  }
`

const CardForm = styled.div`
  background-color: #ffff;
  width: 100%;
  height: 50vh;
  z-index: 20;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`

const CardModal = styled.div`
  transition-delay: 2s;

  width: 100%; /* Full width */
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 10;
  bottom: 0;
`

const BoxSearch = styled.div`
  .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper {
    border-color: #ffeee7;
    border-radius: 8px;
    height: 40px;
    width: 80vw;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #f36b24 !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #ffeee7;
    color: #f36b24 !important;
    height: 40px;
    border-radius: 8px;
  }
  .ant-picker {
    background: #ffffff !important;
    border: 1px solid #ffeee7 !important;
    border-radius: 8px !important;
    /* height: 40px !important;
    width: 100% !important; */
    color: #ffc4aa !important;
  }
  .ant-picker-input > input {
    color: #ffc4aa !important;
  }
  .ant-picker-input::placeholder > input {
    color: #ffc4aa !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #ffc4aa;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow {
    color: #f36b24;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }
`

const ContainerBox = styled.div`
  background-color: #f7f1ee;
  padding: 10px;
`
const Boxhambur = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fee0d3;
  /* padding: 8px; */
  height: 30px;
  width: 30px;
  border-radius: 8px;
`
