import React, { useEffect, useState, useContext } from 'react'
import { Button, Tooltip, Space, Form, Input } from 'antd'
import InputFormUser from 'components/input/inputFormUser'
import { useFormik } from 'formik'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import UserContext from 'core/contexts/userContext'
import { postData, putData, getList, getData } from 'core/action/collection'
import { Schema, defaultValues } from './container'
import { CustomSpace, Title, Box, CustomModal, ButtonBack, StyleDropdown, LabelText } from './form.style'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { adminauth2, authentication2, masterdata } from 'core/schemas'
import DropdownForm from 'components/Inputs/DropdownForm'
import Loader from 'components/Layout/Loader'

const Index = () => {
  const [formAntd] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()

  const lineId = localStorage.getItem('line_id') || 'edd'

  const [loading, setLoading] = useState(false)
  const [leaveModalOpen, setLeaveModalOpen] = React.useState(false)
  const [editModalOpen, setEditModalOpen] = React.useState(false)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [roleData, setRoleData] = useState([])
  const [groupData, setGroupData] = useState([])
  const [organizeData, setOrganizeData] = useState([])
  const [adminDataById, setAdminDataById] = useState('')
  const [positionData, setPositionData] = useState([])
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [isValidForm, setIsValidForm] = React.useState(false)
  const [isValidFormEdit, setIsValidFormEdit] = React.useState(false)
  const [disableFormikPosition, setDisableFormikPosition] = useState(false)
  const [disableFormikOrganize, setDisableFormikOrganize] = useState(false)

  const formik = useFormik({
    initialValues: id ? adminDataById : defaultValues,
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  useEffect(() => {
    setLoading(true)
    getMasterData()
  }, [])

  useEffect(() => {
    id && getAdminById()
  }, [])

  const getMasterData = async () => {
    await getListRole()
    await getListOrganize()
    await getListGroup()
    await getAllPosition()
    setLoading(false)
  }

  useEffect(() => {
    setIsValidForm(
      formik.isValid &&
        formik.dirty &&
        formAntd.getFieldsError()[0]?.errors?.length === 0 &&
        formAntd.getFieldsError()[1]?.errors?.length === 0
    )
    if (formAntd.getFieldValue('tel') === tel && formAntd.getFieldValue('email') === email) {
      setIsValidFormEdit(formik.isValid && formik.dirty)
    }
  }, [formik])

  const isNameEmpty = formik.getFieldMeta('name').value === undefined || formik.getFieldMeta('name').value === ''
  const isDescriptionEmpty =
    formik.getFieldMeta('description').value === undefined || formik.getFieldMeta('description').value === ''

  const getListRole = async () => {
    const res = await getData({
      schema: `${authentication2}/roles`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistRole = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setRoleData(maplistRole)
      } else {
        console.log('Error', res)
      }
    }
  }

  const getListOrganize = async () => {
    const res = await getData({
      schema: `${masterdata}/organizations`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistRole = res?.data?.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setOrganizeData(maplistRole)
      } else {
        console.log('Error', res)
      }
    }
  }

  const getListGroup = async () => {
    const res = await getData({
      schema: `${adminauth2}/group`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistGroup = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setGroupData(maplistGroup)
      } else {
        console.log('Error', res)
      }
    }
  }

  const getAdminById = async () => {
    const res = await getData({
      schema: `${adminauth2}/admin/` + id,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const data = res?.data
        const adminData = {
          first_name: data.first_name.th,
          last_name: data.last_name.th,
          group_id: data.group_data.id,
          position_id: data.position_data.id,
          role_id: data.roles_data[0].id,
          organize_id: data.organize_data.org_id,
        }
        setAdminDataById(adminData)
        setTel(phoneNumberAutoFormat(data.mobile_number))
        setEmail(data.email)
        formAntd.setFieldValue('tel', phoneNumberAutoFormat(data.mobile_number))
        formAntd.setFieldValue('email', data?.email)
      } else {
        console.log('Error', res)
      }
    }
  }

  const getAllPosition = async () => {
    const res = await getData({
      schema: `${masterdata}/position`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistPosition = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setPositionData(maplistPosition)
      } else {
        console.log('Error', res)
      }
    }
  }

  const uniqueValidateMobile = async value => {
    if (formAntd?.getFieldValue('tel') === tel) return false
    if (formAntd.getFieldValue('tel')?.length < 12) {
      formAntd.setFields([
        {
          name: 'tel', // required
          value: formAntd.getFieldValue('tel'), //optional
          errors: ['รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'],
        },
      ])
      setIsValidForm(false)
      setIsValidFormEdit(false)
    }
    if (formAntd.getFieldValue('tel')?.length === 12) {
      const mobileNumber = formAntd.getFieldValue('tel')?.replace(/-/g, '')
      const res = await getList({
        schema: `${adminauth2}/admin`,
        params: { page: 1, per_page: 10, filters: [`mobile_number:eq:${mobileNumber}`] },
        url: '',
        baseURL: '',
      }).catch(err => {
        console.log('err', err)
      })

      if (res?.code === 200) {
        setTimeout(() => {
          formAntd.setFields([
            {
              name: 'tel', // required
              value: formAntd.getFieldValue('tel'), //optional
              errors: ['หมายเลขโทรศัพท์นี้ ถูกใช้งานแล้ว กรุณากรอกหมายเลขอื่น'],
            },
          ])
          setIsValidForm(false)
          setIsValidFormEdit(false)
        }, 100)
      } else {
        // setIsValidForm(true)
        // setIsValidFormEdit(true)
        formAntd.setFields([
          {
            name: 'tel', // required
            value: formAntd.getFieldValue('tel'), //optional
            errors: '',
          },
        ])
      }
    }
  }

  const uniqueValidateEmail = async value => {
    if (formAntd?.getFieldValue('email') === email) return false
    if (
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formAntd?.getFieldValue('email')) === false &&
      formAntd?.getFieldValue('email')?.length
    ) {
      formAntd.setFields([
        {
          name: 'email', // required
          value: formAntd.getFieldValue('email'), //optional
          errors: ['รูปแบบอีเมลล์ล์ไม่ถูกต้อง'],
        },
      ])
      setIsValidForm(false)
      setIsValidFormEdit(false)
    } else {
      const res = await getList({
        schema: `${adminauth2}/admin`,
        params: { page: 1, per_page: 10, filters: [`email:eq:${formAntd?.getFieldValue('email')}`] },
        url: '',
        baseURL: '',
      }).catch(err => {
        console.log('err', err)
      })

      if (res?.code === 200) {
        setTimeout(() => {
          formAntd.setFields([
            {
              name: 'email', // required
              value: formAntd.getFieldValue('email'), //optional
              errors: ['E-mail นี้ ถูกใช้งานแล้ว กรุณากรอก E-mail อื่น'],
            },
          ])
          setIsValidForm(false)
          setIsValidFormEdit(false)
        }, 1000)
      } else {
        formAntd.setFields([
          {
            name: 'email', // required
            value: formAntd.getFieldValue('email'), //optional
            errors: '',
          },
        ])
      }
    }
  }

  const handlerSubmit = async () => {
    const mobileNumber = formAntd.getFieldValue('tel')?.replace(/-/g, '')
    const body = {
      available_at: 0,
      email: formAntd.getFieldValue('email'),
      first_name: {
        en: '',
        requiredEn: false,
        requiredTh: true,
        th: formik.values.first_name,
      },
      last_name: {
        en: '',
        requiredEn: false,
        requiredTh: true,
        th: formik.values.last_name,
      },
      group_id: formik.values.group_id,
      mobile_number: mobileNumber,
      organize_id: formik.values.organize_id,
      password: 'a0123456789',
      position_id: formik.values.position_id,
      role_id: [formik.values.role_id],
      prefix: {
        en: '',
        requiredEn: false,
        requiredTh: false,
        th: '',
      },
      status: 'inactive',
      status_activate: 'pending',
      line_id: lineId,
    }
    if (id) {
      const res = await putData(`${adminauth2}/admin`, id, body)
      if (res?.code === 200) {
        localStorage.setItem('modal', 'update')
        navigate('/operation-tools/bof-users', {
          state: {
            showNoti: true,
          },
        })
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
      if (res?.errors?.[0]?.code === 'unique') {
        formik.setErrors({ name: 'กรุณาพิมพ์คำใหม่ เนื่องจากมีรายการที่ใช้คำดังกล่าวแล้ว' })
      }
    } else {
      const res = await postData(`${adminauth2}/admin`, body)
      if (res.code === 201) {
        localStorage.setItem('modal', 'create')
        navigate('/operation-tools/bof-users', {
          state: {
            showNoti: true,
          },
        })
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
    }
  }

  const renderTooltipCreate = () => {
    return (
      <React.Fragment>
        {!isValidForm ? (
          <Tooltip
            overlayInnerStyle={{
              backgroundColor: '#597081',
              borderRadius: '12px',
              fontSize: '10px',
              width: '212px',
              height: '36px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 6px 10px 12px',
            }}
            overlayStyle={{ padding: '4px' }}
            showArrow={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValidForm}
              onClick={handlerSubmit}
              style={{ fontSize: '16px' }}
            >
              บันทึก
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValidForm}
            onClick={handlerSubmit}
            style={{ fontSize: '16px' }}
          >
            บันทึก
          </Button>
        )}
      </React.Fragment>
    )
  }

  const renderTooltipEdit = () => {
    return (
      <React.Fragment>
        {!isValidFormEdit ? (
          <Tooltip
            // title={!isUnique ? 'กดบันทึกไม่ได้ เนื่องจากกรอกข้อมูลไม่ครบถ้วน' : undefined}
            overlayInnerStyle={{
              backgroundColor: '#597081',
              borderRadius: '12px',
              fontSize: '10px',
              width: '212px',
              height: '36px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 6px 10px 12px',
            }}
            overlayStyle={{ padding: '4px' }}
            showArrow={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValidFormEdit}
              onClick={handlerSubmit}
              style={{ fontSize: '16px' }}
            >
              บันทึก
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValidFormEdit}
            onClick={() => {
              setEditModalOpen(true)
            }}
            style={{ fontSize: '16px' }}
          >
            บันทึก
          </Button>
        )}
      </React.Fragment>
    )
  }

  const renderMode = () => {
    if (id) {
      // Edit Mode
      return (
        <React.Fragment>
          {!isValidFormEdit ? (
            <CustomSpace>
              {renderTooltipEdit()}

              <CustomModal
                transitionName=""
                centered
                open={leaveModalOpen}
                onOk={() => {
                  setLeaveModalOpen(false)
                  navigate(-1)
                }}
                onCancel={() => setLeaveModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p
                  style={{
                    color: '#001F34',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginBottom: '2px',
                  }}
                >
                  คุณต้องการออกจากหน้านี้?
                </p>
                <p
                  style={{
                    color: '#597081',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
                </p>
              </CustomModal>
            </CustomSpace>
          ) : (
            <CustomSpace>
              {renderTooltipEdit()}
              <CustomModal
                transitionName=""
                centered
                open={editModalOpen}
                onOk={() => {
                  setEditModalOpen(false)
                  handlerSubmit()
                }}
                onCancel={() => setEditModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p
                  style={{
                    color: '#001F34',
                    fontSize: '16px',
                    marginBottom: '2px',
                    fontWeight: '400',
                  }}
                >
                  ยืนยันการแก้ไขข้อมูล
                </p>
                <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300' }}>
                  หากคุณแก้ไขข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
                </p>
              </CustomModal>

              <CustomModal
                transitionName=""
                centered
                open={leaveModalOpen}
                onOk={() => {
                  setLeaveModalOpen(false)
                  navigate(-1)
                }}
                onCancel={() => setLeaveModalOpen(false)}
                closable={false}
                okText="ยกเลิก"
                cancelText="ตกลง"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p
                  style={{
                    color: '#001F34',
                    fontSize: '16px',
                    marginBottom: '2px',
                    fontWeight: '400',
                  }}
                >
                  คุณต้องการออกจากหน้านี้?
                </p>
                <p
                  style={{
                    color: '#597081',
                    fontSize: '14px',
                    fontWeight: '300',
                  }}
                >
                  หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
                </p>
              </CustomModal>
            </CustomSpace>
          )}
        </React.Fragment>
      )
    } else {
      // Create Mode
      return (
        <React.Fragment>
          {!isValidForm && isDescriptionEmpty && isNameEmpty ? (
            <CustomSpace align="end">{renderTooltipCreate()}</CustomSpace>
          ) : (
            <CustomSpace align="end">{renderTooltipCreate()}</CustomSpace>
          )}
        </React.Fragment>
      )
    }
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')
    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formAntd.setFieldValue('tel', targetValue)
  }

  const handleChange = () => {
    setIsValidForm(
      formik.isValid &&
        formik.dirty &&
        formAntd.getFieldsError()[0]?.errors?.length === 0 &&
        formAntd.getFieldsError()[1]?.errors?.length === 0
    )
    setIsValidFormEdit(
      (formik.isValid || formik.dirty) &&
        (formAntd?.getFieldValue('tel') !== tel || formAntd?.getFieldValue('email') !== email) &&
        formAntd?.getFieldValue('tel').length === 12
    )
  }

  return (
    <Loader loading={loading}>
      <div style={{ padding: '20px 40px' }}>
        <form onSubmit={formik.handleSubmit}>
          {id ? (
            <Space style={{ paddingTop: '20px' }}>
              <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => setLeaveModalOpen(true)} />
              <Title>แก้ไขบัญชีผู้ใช้งาน</Title>
            </Space>
          ) : (
            <Space style={{ paddingTop: '20px' }}>
              <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => setLeaveModalOpen(true)} />
              <Title>สร้างบัญชีผู้ใช้งาน</Title>
            </Space>
          )}

          <Box>
            <div style={{ padding: '2rem' }}>
              <InputFormUser
                label="ชื่อ"
                name="first_name"
                className="nobottom"
                placeholder="กรอกชื่อ"
                type="text"
                required={true}
                errors={formik.errors}
                touched={formik.touched}
                form={formik}
                status={formik.status}
                maxLength={255}
              />
              <InputFormUser
                label="นามสกุล"
                className="nobottom"
                name="last_name"
                placeholder="กรอกนามสกุล"
                type="text"
                required={true}
                errors={formik.errors}
                touched={formik.touched}
                form={formik}
                status={formik.status}
                maxLength={255}
              />
              <Form
                form={formAntd}
                layout="vertical"
                style={{ paddingBottom: '0px', height: '190px', maxWidth: '824px' }}
              >
                <Form.Item
                  name="tel"
                  label={
                    <div>
                      หมายเลขโทรศัพท์ <span style={{ color: '#F85858', fontSize: '17px' }}>*</span>
                    </div>
                  }
                >
                  <Input
                    placeholder="กรอกหมายเลขโทรศัพท์"
                    className="nobottom"
                    onBlur={uniqueValidateMobile}
                    onMouseLeave={uniqueValidateMobile}
                    maxLength={12}
                    onChange={e => {
                      handleChange()
                      onPhoneNumberChange(e)
                      formAntd.setFields([
                        {
                          name: 'tel', // required
                          value: formAntd.getFieldValue('tel'), //optional
                          errors: '',
                        },
                      ])
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={
                    <div>
                      อีเมลล์ <span style={{ color: '#F85858', fontSize: '17px' }}>*</span>
                    </div>
                  }
                >
                  <Input
                    style={{ fontSize: '16px' }}
                    placeholder="กรอกอีเมลล์"
                    onBlur={uniqueValidateEmail}
                    onMouseLeave={uniqueValidateEmail}
                    onChange={e => {
                      handleChange()
                      formAntd.setFields([
                        {
                          name: 'email', // required
                          value: formAntd.getFieldValue('email'), //optional
                          errors: '',
                        },
                      ])
                    }}
                  />
                </Form.Item>
              </Form>

              <div style={{ width: '50%', marginBottom: '1rem' }}>
                <LabelText>
                  ชื่อกลุ่ม
                  <div className="red-star" style={{ paddingLeft: '2px' }}>
                    *
                  </div>
                </LabelText>
                <StyleDropdown style={{ minWidth: '60vw' }}>
                  <DropdownForm
                    title="ชื่อกลุ่ม"
                    placeholder="เลือกชื่อกลุ่ม"
                    showSearch={false}
                    required={true}
                    onChange={value => {
                      formik.setFieldValue('group_id', value)
                    }}
                    className="big-input"
                    options={groupData}
                    value={formik?.values?.group_id}
                  />
                </StyleDropdown>
              </div>
              <div style={{ width: '50%', marginBottom: '1rem' }}>
                <LabelText>
                  รูปแบบการปฏิบัติงาน
                  <div className="red-star" style={{ paddingLeft: '2px' }}>
                    *
                  </div>
                </LabelText>
                <StyleDropdown style={{ minWidth: '60vw' }}>
                  <DropdownForm
                    title="รูปแบบการปฏิบัติงาน"
                    placeholder="เลือกรูปแบบการปฏิบัติงาน"
                    showSearch={false}
                    required={true}
                    onChange={value => {
                      formik.setFieldValue('position_id', value)
                      if (value === '1730459515121299456') {
                        formik.setFieldValue('organize_id', '1730459515008053248')
                        setDisableFormikOrganize(true)
                      } else {
                        setDisableFormikOrganize(false)
                        // if (formik?.values?.organize_id === '1730459515008053248') {
                        //   formik.setFieldValue('organize_id', '')
                        //   setDisableFormikOrganize(false)
                        // } else {
                        //   formik.setFieldValue('organize_id', formik?.values?.organize_id)
                        //   setDisableFormikOrganize(false)
                        // }
                      }
                    }}
                    disabled={disableFormikPosition}
                    className="big-input"
                    options={positionData}
                    value={formik?.values?.position_id}
                  />
                </StyleDropdown>
              </div>
              <div style={{ width: '50%', marginBottom: '1rem' }}>
                <LabelText>
                  สิทธิ์การใช้งาน
                  <div className="red-star" style={{ paddingLeft: '2px' }}>
                    *
                  </div>
                </LabelText>
                <StyleDropdown style={{ minWidth: '60vw' }} className="custom-width">
                  <DropdownForm
                    title="สิทธิ์การใช้งาน"
                    placeholder="เลือกสิทธิ์การใช้งาน"
                    showSearch={false}
                    required={true}
                    onChange={value => {
                      formik.setFieldValue('role_id', value)
                    }}
                    className="big-input"
                    options={roleData}
                    value={formik?.values?.role_id}
                  />
                </StyleDropdown>
              </div>
              <div style={{ width: '50%' }}>
                <LabelText>
                  ชื่อหน่วยงาน
                  <div className="red-star" style={{ paddingLeft: '2px' }}>
                    *
                  </div>
                </LabelText>
                <StyleDropdown style={{ minWidth: '60vw' }}>
                  <DropdownForm
                    title="ชื่อหน่วยงาน"
                    placeholder="เลือกชื่อหน่วยงาน"
                    showSearch={false}
                    required={true}
                    disabled={disableFormikOrganize}
                    onChange={value => {
                      formik.setFieldValue('organize_id', value)
                      // if (value === '1730459515008053248') {
                      //   formik.setFieldValue('position_id', '1730459515121299456')
                      //   setDisableFormikPosition(true)
                      // } else {
                      //   if (formik?.values?.position_id === '1730459515121299456') {
                      //     formik.setFieldValue('position_id', '')
                      //     setDisableFormikPosition(false)
                      //   } else {
                      //     formik.setFieldValue('position_id', formik?.values?.position_id)
                      //     setDisableFormikPosition(false)
                      //   }
                      // }
                    }}
                    className="big-input"
                    options={organizeData}
                    value={formik?.values?.organize_id}
                  />
                </StyleDropdown>
              </div>
            </div>
          </Box>
          {renderMode()}
        </form>
        <CustomModal
          transitionName=""
          centered
          open={leaveModalOpen}
          onOk={() => {
            setLeaveModalOpen(false)
            navigate('/operation-tools/bof-users')
          }}
          onCancel={() => setLeaveModalOpen(false)}
          closable={false}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <ExclamationCircle />
          </div>
          <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
          <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
        </CustomModal>
      </div>
    </Loader>
  )
}

export default Index
