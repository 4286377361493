import React, { useState, useEffect } from 'react'
import {
  ChatContainer,
  SearchContainer,
  StyledInput,
  ChatList,
  ChatListItem,
  Avatar,
  ChatInfo,
  ChatTopRow,
  ChatBottomRow,
  ChatName,
  TimeStamp,
  RedDot,
  CustomButton,
  ChatPreview,
  NotificationBadge,
} from './chatbot.style'
import SearchIcon from 'assets/images/svg/chatbot-image/Search.svg'
import iconmanagechat from 'assets/images/svg/icon-button-manage-chatbot.svg'
import IconUser from 'assets/images/svg/User.svg'

import ChatHistory from './ChatHistory/ChatHistory'
import EmptyChat from './EmptyChat/EmptyChat'
import { io } from 'socket.io-client'

function Index() {
  const [searchValue, setSearchValue] = useState('')
  const [showChat, setShowChat] = useState(false)
  const [stateMessage, setStateMessage] = useState([])
  const [dataLineUser, setDataLineUser] = useState([])
  const baseURL = process.env.REACT_APP_BASEURL_CHATBOT
  const ioClient = io.connect(baseURL)
  useEffect(() => {
    ioClient.on('connect', () => {
      console.log('connected socket')
      ioClient.emit('get:line_users')
    })
    ioClient.on('res:line_users', payload_all_user => {
      newMsgPayload.push(payload_all_user)
      const uniqueArray = uniqueArrayByProperty(newMsgPayload.flat(), 'line_id')
      setStateMessage(uniqueArray)
    })
  }, [])

  let newMsgPayload = []

  useEffect(() => {
    ioClient.on('res:line_users', payload_all_user => {
      newMsgPayload.push(payload_all_user)
      const uniqueArray = uniqueArrayByProperty(newMsgPayload.flat(), 'line_id')
      setStateMessage(uniqueArray)
    })
  }, [ioClient])

  const uniqueArrayByProperty = (arr, prop) => {
    const seen = new Map()
    return arr.filter(item => {
      const key = item[prop]
      if (seen.has(key)) {
        return false
      } else {
        seen.set(key, true)
        return true
      }
    })
  }

  const handleSearchChange = e => {
    setSearchValue(e.target.value)
  }

  const handleShowChat = async data => {
    await setShowChat(false)
    await setDataLineUser(data)
    await setShowChat(true)
  }

  const handleCloseChat = () => {
    setShowChat(false)
  }

  return (
    <>
      <div
        style={{
          fontFamily: 'Prompt',
          fontWeight: '500',
          fontSize: '24px',
          color: '#150F0B',
          marginBottom: '24px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0px 1rem',
        }}
      >
        <div>แชทและประวัติการสนทนา</div>
        <CustomButton
          icon={<img src={iconmanagechat} alt="add" style={{ marginRight: '5px', marginBottom: '1px' }} />}
          onClick={() => window.open(`${process.env.REACT_APP_DIALOGFLOW}`, '_blank')}
        >
          <span>การจัดการคำถาม</span>
        </CustomButton>
      </div>
      <ChatContainer>
        <SearchContainer>
          <StyledInput
            value={searchValue}
            onChange={handleSearchChange}
            hasContent={searchValue?.length > 0}
            placeholder="ค้นหาชื่อผู้ใช้ หรือ บทสนทนา"
            prefix={<img src={SearchIcon} alt="Search" />}
            allowClear={<allowClearIcon />}
          />
          <ChatList>
            {stateMessage?.map((chat, index) => (
              <ChatListItem key={chat?.id} onClick={() => handleShowChat(chat)}>
                <Avatar
                  src={chat?.url_profile ? chat?.url_profile : IconUser}
                  alt="avatar"
                  onError={e => (e.target.src = IconUser)}
                />
                <ChatInfo>
                  <ChatTopRow>
                    <ChatName>{chat?.name}</ChatName>
                    <TimeStamp>
                      {chat?.is_not_reply == true ? (
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: '10px' }}>ขอติดต่อเจ้าหน้าที่</div>
                          <RedDot />
                        </div>
                      ) : (
                        ''
                      )}
                    </TimeStamp>
                  </ChatTopRow>
                  <ChatBottomRow>
                    {/* <ChatPreview>{chat?.last_message}</ChatPreview>
                    <NotificationBadge>123</NotificationBadge> */}
                  </ChatBottomRow>
                </ChatInfo>
              </ChatListItem>
            ))}
          </ChatList>
        </SearchContainer>
        {showChat ? <ChatHistory handleCloseChat={handleCloseChat} dataLineUser={dataLineUser} /> : <EmptyChat />}
      </ChatContainer>
    </>
  )
}

export default Index
