import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CustomSpace, Title, Box, ButtonEdit, ButtonDelete, ButtonBack, CustomModal, CustomModal2 } from './view.style'
import Iconpreview from 'assets/images/svg/icon-preview.svg'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import { Button, Space, Modal, Form, Input, Divider, Spin } from 'antd'
import styled from 'styled-components'
import Viewmap from '../viewmap'
import { Select } from 'antd'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import axios from 'axios'
import moment from 'moment'
import { unixToDateThai } from 'util/date-format'
import { dePartMentIcon } from '../container'
import { postData } from 'core/action/collection'

import qs from 'qs'
const { Option } = Select

const ViewPc = () => {
  const [formAntd] = Form.useForm()
  const navigate = useNavigate()
  const [previewImg, setPreviewImg] = React.useState(null)
  const [previewVdo, setPreviewVdo] = React.useState(null)
  const [OpenModalVdo, setOpenModalVdo] = useState(false)
  const [OpenModalImage, setOpenModalImage] = useState(false)
  const [OpenForm, setOpenForm] = useState(false)
  const [leaveModalOpen, setLeaveModalOpen] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [Userdata, setUserdata] = useState([])
  const [masterOrganize, setMasterOrganize] = useState([])
  const [statusMaster, setStatusMaster] = useState([])
  const [SelectOrg, setSelectOrg] = useState('')
  const [SelectStatus, setSelectStatus] = useState([])
  const [meData, setMeData] = useState('')
  const [dueDate, setDuedate] = useState(0)
  const [notiDate, setNotiDate] = useState(0)
  const [officeData, setOfficeData] = useState([])
  const [checkBtn, setBtn] = useState(true)
  const [checkBtnNewcomplaint, setBtnNewcomplaint] = useState(true)
  const [loaddingPage, setLoaddingPage] = useState(true)

  const location = useLocation()
  const { state } = location
  const token = localStorage?.getItem('access_token')
  const organize = localStorage?.getItem('organize')

  let checkPositionSup = meData?.position_data?.id === '1730459515121299456'

  useEffect(() => {
    getDataId()
    getAllOrganize()
    getAllUrgency()
    getMedata()
    getOfficer()
  }, [])

  useEffect(() => {
    onChangeNotiDate()
  }, [dueDate])

  const getDataId = async () => {
    const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
    const resp = await axios.get(`${shemadata}/${state?.record}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (resp?.status === 200 || resp?.status === 201) {
      setUserdata(resp?.data?.data)
      setSelectOrg(
        resp?.data?.data?.department_data?.map((value, index, array) => {
          return value?.id
        })
      )
      if (checkPositionSup) {
        formAntd.setFieldsValue({
          organize: resp?.data?.data?.department_data?.map((value, index, array) => {
            return value?.id
          }),
        })
      } else {
        formAntd.setFieldsValue({
          organize: resp?.data?.data?.department_data?.filter((value, index, array) => {
            return value?.id === organize ? value?.id : ''
          })[0]?.id,
        })
      }
      setTimeout(() => {
        setLoaddingPage(false)
      }, 2000)
    } else {
      setLoaddingPage(false)
    }
  }

  const getMedata = async () => {
    try {
      const respGetme = await axios.get(`${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (respGetme?.status === 200 || respGetme?.status === 204) {
        setMeData(respGetme?.data?.data)
      }
    } catch (error) {}
  }

  const getAllOrganize = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/master/api/v1/all/organizations`
      const resOrganize = await axios.get(`${shemadata}`)
      if ((resOrganize.status === 200) | (resOrganize.status === 201)) {
        setMasterOrganize(resOrganize?.data?.data)
      }
    } catch (error) {}
  }
  const getAllUrgency = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/master/api/v1/all/urgency`
      const resStatus = await axios.get(`${shemadata}`)
      if ((resStatus.status === 200) | (resStatus.status === 201)) {
        setStatusMaster(resStatus?.data?.data)
      }
    } catch (error) {}
  }

  const onSubmitUpdate = async () => {
    const bodyUpdate = {
      address: Userdata?.address,
      category_id: Userdata?.category?.id,
      channel: Userdata?.channel,
      complaint_name: Userdata?.complaint_name,
      coordinates: {
        latitude: Userdata?.coordinates?.latitude,
        longitude: Userdata?.coordinates?.longitude,
      },
      department_data: SelectOrg,
      description: Userdata?.description,
      district_id: Userdata?.district_id,
      duedate_at: dueDate,
      line_id: Userdata?.line_id,
      media_url: Userdata?.media_url,
      mobile_number: Userdata?.mobile_number ? Userdata?.mobile_number : '0000000000',
      notidate_at: notiDate === 'ทุกวัน' ? '' : notiDate,
      name: {
        first_name: Userdata?.name?.first_name ? Userdata?.name?.first_name : '-',
        last_name: Userdata?.name?.last_name ? Userdata?.name?.last_name : '-',
      },
      org_id: '19118133238380',
      province_id: Userdata?.province_id,
      sub_category_id: Userdata?.sub_category_data?.id,
      sub_district_id: Userdata?.sub_district_id,
      urgency_id: SelectStatus && SelectStatus[0]?.id,
    }
    const payload = {
      complaint_id: Userdata?.id,
      department_id: formAntd?.getFieldValue('organize'),
      staff_by: formAntd?.getFieldValue('officer'),
    }

    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
      const res = await postData(`complaint/api/v1/officer`, payload)
      const resUpdate = await axios.put(`${shemadata}/${Userdata?.id}`, bodyUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if ((resUpdate?.status === 200) | (resUpdate?.status === 201) | (res?.status === 201)) {
        localStorage.setItem('isReceived', true)
        navigate('/operation/bof-new-complaint')
      }
    } catch (error) {}
  }

  const onChangeDuedate = value => {
    const momentObject = moment.unix(new Date() / 1000)
    const dataDate = moment(momentObject)
      .add(+value, 'days')
      .unix()
    setDuedate(dataDate)
  }
  const onChangeNotiDate = value => {
    let dateNoti = formAntd.getFieldValue('notiDate')
    if (dueDate) {
      const momentObject = moment.unix(dueDate)
      const dataDate = momentObject?.subtract(dateNoti, 'days').unix()
      setNotiDate(dataDate)
    }
  }

  const onSubmitStatus = () => {
    setOpenForm(true)
  }

  const handleChangeOrg = value => {
    setSelectOrg(value)
    setBtn(false)
  }

  const haldeChageStaus = value => {
    const dataFilter = statusMaster.filter(item => item.id === value)
    setSelectStatus(dataFilter)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '250px', height: '150px', borderRadius: '8px', margin: '1em' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    {/* นำ icon มาวางที่นี่ */}
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <div>
              <img
                style={{ borderRadius: '8px', width: '180px', height: '120px', margin: '10px 5px' }}
                className="show-image click"
                src={item}
                alt="image"
                onClick={() => {
                  setOpenModalImage(true)
                  setPreviewImg(item)
                }}
              />
            </div>
          )
        }
      })
    return CardUi
  }

  const OnCannotSubmit = async () => {
    const desData = formAntd.getFieldValue('connotStaus')

    try {
      const resDel = await axios.delete(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint/${Userdata?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if ((resDel.status === 200) | (resDel.status === 201)) {
        setIsModalOpen(false)
        navigate(-1)
      }
    } catch (error) {}
  }

  const handleChangeForm = () => {
    let valForm = formAntd.getFieldsValue()
    if (
      valForm?.organize?.length > 0 &&
      valForm?.officer?.length > 0 &&
      valForm?.duaDate?.length > 0 &&
      valForm?.statusData?.length > 0 &&
      valForm?.notiDate
    ) {
      setBtnNewcomplaint(false)
    } else {
      setBtnNewcomplaint(true)
    }
  }

  const generateDateOptions = () => {
    const options = []
    for (let i = 1; i <= 15; i++) {
      options.push(
        <Option key={i} value={i.toString()} label={i + ' วัน'}>
          {i + ' วัน'}
        </Option>
      )
    }
    return options
  }

  const getOfficer = () => {
    let confix = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        filters: [`organize_id:eq:${organize}`],
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    }
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/all/admin`, confix)
      .then(res => {
        setOfficeData(res?.data?.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      {loaddingPage ? (
        <div>
          <Spin
            style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            size="large"
          />
        </div>
      ) : (
        <BoxContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '20px' }}>
            <Space style={{ paddingTop: '20px' }}>
              <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => navigate(-1)} />
              <Title>รายละเอียดเรื่องร้องเรียนใหม่</Title>
            </Space>
            <Space></Space>
          </div>

          <BoxTopView>
            <ContainerView>
              <HeaderboxView>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Textttile>{Userdata?.complaint_name}</Textttile>
                  <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                    <span>แจ้งเมื่อ : {unixToDateThai(Userdata?.created_at)}</span>
                    <hr style={{ border: '1px solid #FFC4AA', width: '15px', transform: 'rotate(90deg)' }} />
                    <span>เลขที่ : {Userdata?.complaint_no}</span>
                    <hr style={{ border: '1px solid #FFC4AA', width: '15px', transform: 'rotate(90deg)' }} />
                    <span>ร้องเรียน : {Userdata?.channel}</span>
                  </div>
                </div>
                <div> {Userdata?.description}</div>
              </HeaderboxView>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '3%', marginTop: '2%' }}>
                <div>
                  <Boxbg Bgcolor="#FBFBFB">
                    <BoxDescription>
                      <div className="text-title">ประเภทเรื่องร้องเรียน</div>
                      <div className="text-title">ประเภทย่อย</div>
                    </BoxDescription>
                    <BoxDescription>
                      {Userdata?.category?.name ? (
                        <div>
                          {Userdata?.category?.icon_url && (
                            <img
                              src={`${Userdata?.category?.icon_url}`}
                              style={{ width: '20px', height: '20px' }}
                              alt="cate"
                            />
                          )}
                          <span style={{ margin: '0 3px' }}>{Userdata?.category?.name}</span>
                        </div>
                      ) : (
                        '-'
                      )}
                      <div className="to-right">{Userdata?.sub_category_data?.name}</div>
                    </BoxDescription>
                    <BoxOrganize>
                      <div className="text-title">หน่วยงานที่รับผิดชอบ</div>
                    </BoxOrganize>
                    <BoxOrganize>
                      {Userdata?.department_data ? (
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                          {Userdata &&
                            Userdata?.department_data?.map((item, index) => {
                              return (
                                <BoxIcon key={index}>
                                  <div>{dePartMentIcon[item?.name]}</div>
                                  <div style={{ margin: '0 4px' }}> {item?.name}</div>
                                </BoxIcon>
                              )
                            })}
                        </div>
                      ) : (
                        '-'
                      )}
                    </BoxOrganize>
                    <BoxDescription>
                      <div>
                        <div className="text-title">ผู้ร้องเรียน</div>
                        <div className="text-sub-title">
                          {Userdata?.name ? `${Userdata?.name?.first_name} ${Userdata?.name?.last_name}` : '-'}
                        </div>
                      </div>
                      <div>
                        <div className="text-title">เบอร์โทรศัพท์</div>
                        <div>
                          <div className="text-sub-title">
                            {Userdata?.mobile_number ? Userdata?.mobile_number : '-'}
                          </div>
                        </div>
                      </div>
                    </BoxDescription>
                  </Boxbg>
                </div>
                <div>
                  <div>
                    <div className="text-title">รูปภาพและวิดีโอ</div>
                    <div style={{ display: 'flex' }}>{Userdata?.media_url && Mapmedia(Userdata?.media_url)}</div>
                  </div>
                  <div>
                    <div className="text-title" style={{ marginBottom: '10px' }}>
                      สถานที่แจ้งเรื่อง
                    </div>
                    <div style={{ display: 'flex' }}></div>
                  </div>
                  <Viewmap latitude={Userdata?.coordinates?.latitude} longitude={Userdata?.coordinates?.longitude} />
                </div>
              </div>
            </ContainerView>

            {!OpenForm &&
            !checkPositionSup | (Userdata?.category?.id === '1735509869739372551' && checkPositionSup && !OpenForm) ? (
              <div style={{ display: 'flex', justifyContent: 'end', margin: '2em 1em' }}>
                <ButtonEdit style={{ margin: '0 1em' }} onClick={showModal}>
                  ปฎิเสธ
                </ButtonEdit>

                <ButtonSuccess type="primary" onClick={onSubmitStatus}>
                  รับเรื่องร้องเรียน
                </ButtonSuccess>
              </div>
            ) : (
              ''
            )}
          </BoxTopView>

          {OpenForm && (
            <div>
              <CustomForm>
                <Form form={formAntd} layout="vertical" onValuesChange={handleChangeForm}>
                  {checkPositionSup && Userdata?.category?.id === '1735509869739372551' ? (
                    <>
                      <StyleDropdown>
                        <Form.Item
                          name="organize"
                          label="หน่วยงานที่รับผิดชอบ"
                          rules={[
                            {
                              required: true,
                              message: 'กรุณากรอกข้อมูล',
                            },
                          ]}
                        >
                          <Select
                            onChange={handleChangeOrg}
                            maxTagCount={3}
                            mode="multiple"
                            placeholder="เลือกหน่วยงานที่รับผิดชอบ"
                            listHeight={300}
                          >
                            {masterOrganize &&
                              masterOrganize?.map((item, index) => (
                                <Option key={index} value={item?.id}>
                                  {item?.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </StyleDropdown>
                      <div style={{ display: 'flex', justifyContent: 'end', margin: '2em 1em', paddingBottom: '1em' }}>
                        <ButtonSuccess
                          type="primary"
                          htmlType="submit"
                          disabled={formAntd.getFieldValue('organize')?.length === 0}
                          onClick={onSubmitUpdate}
                          Btnwidth="250px"
                        >
                          ส่งเรื่องร้องเรียนไปยังหน่วยงาน
                        </ButtonSuccess>
                      </div>
                    </>
                  ) : (
                    <Boxform>
                      <h2>กำหนดข้อมูลเรื่องร้องเรียน</h2>
                      <p className="text-sub-title">เพื่อส่งต่อเรื่องร้องเรียน ไปยังหน่วยงานที่เกี่ยวข้อง</p>
                      <StyleDropdown>
                        <Form.Item
                          name="duaDate"
                          label={
                            <div>
                              วันที่กำหนดเสร็จ <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                            </div>
                          }
                        >
                          <Select onChange={onChangeDuedate} placeholder="เลือกวันที่กำหนดเสร็จ">
                            {generateDateOptions()}
                          </Select>
                        </Form.Item>
                      </StyleDropdown>
                      <StyleDropdown>
                        <Form.Item
                          name="notiDate"
                          label={
                            <div>
                              การแจ้งเตือน <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                            </div>
                          }
                        >
                          <Select onChange={onChangeNotiDate} placeholder="เลือกการแจ้งเตือน">
                            <Option value={'ทุกวัน'} label={'ทุกวัน'}>
                              ทุกวัน
                            </Option>
                            <Option value={1} label={'1 วันก่อนเกินระยะเวลาที่กำหนด'}>
                              1 วันก่อนเกินระยะเวลาที่กำหนด
                            </Option>
                            <Option value={2} label={'2 วันก่อนเกินระยะเวลาที่กำหนด'}>
                              2 วันก่อนเกินระยะเวลาที่กำหนด
                            </Option>
                            <Option value={3} label={'3 วันก่อนเกินระยะเวลาที่กำหนด'}>
                              3 วันก่อนเกินระยะเวลาที่กำหนด
                            </Option>
                            <Option value={4} label={'4 วันก่อนเกินระยะเวลาที่กำหนด'}>
                              4 วันก่อนเกินระยะเวลาที่กำหนด
                            </Option>
                          </Select>
                        </Form.Item>
                      </StyleDropdown>
                      <Form.Item
                        name="dateSucess"
                        label={
                          <div>
                            วันที่คาดว่าจะแล้วเสร็จ<span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                          </div>
                        }
                      >
                        <BoxTimer>
                          <div style={{ margin: '0 1em', color: '#FFC4AA' }}>
                            {dueDate ? unixToDateThai(dueDate) : 'วันที่คาดว่าจะแล้วเสร็จ'}
                          </div>
                        </BoxTimer>
                      </Form.Item>
                      <StyleDropdown>
                        <Form.Item
                          name="organize"
                          label={
                            <div>
                              หน่วยงานที่รับผิดชอบ <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                            </div>
                          }
                        >
                          {checkPositionSup ? (
                            <Select
                              onChange={handleChangeOrg}
                              placeholder="เลือกหน่วยงานที่รับผิดชอบ"
                              listHeight={300}
                              mode="multiple"
                            >
                              {masterOrganize &&
                                masterOrganize?.map((item, index) => (
                                  <Option key={index} value={item?.id}>
                                    {item?.name}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Select
                              onChange={handleChangeOrg}
                              placeholder="เลือกหน่วยงานที่รับผิดชอบ"
                              listHeight={300}
                              disabled={
                                checkPositionSup && Userdata?.category?.id !== '1735509869739372551' ? false : true
                              }
                            >
                              {masterOrganize &&
                                masterOrganize?.map((item, index) => (
                                  <Option key={index} value={item?.id}>
                                    {item?.name}
                                  </Option>
                                ))}
                            </Select>
                          )}
                        </Form.Item>
                      </StyleDropdown>
                      {!checkPositionSup ? (
                        <StyleDropdown>
                          <Form.Item
                            name="officer"
                            label={
                              <div>
                                ผู้รับผิดชอบ <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                              </div>
                            }
                          >
                            <Select placeholder="เลือกผู้รับผิดชอบ" listHeight={300}>
                              {officeData &&
                                officeData?.map((item, index) => (
                                  <Option key={index} value={item?.id}>
                                    {item?.first_name?.th} {item?.last_name?.th}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </StyleDropdown>
                      ) : (
                        ''
                      )}
                      <StyleDropdown>
                        <Form.Item
                          name="statusData"
                          label={
                            <div>
                              สถานะความเร่งด่วน <span style={{ color: 'red', fontSize: '14px' }}>*</span>
                            </div>
                          }
                        >
                          <Select onChange={haldeChageStaus} placeholder="เลือกสถานะความเร่งด่วน">
                            {statusMaster &&
                              statusMaster?.map((item, index) => (
                                <Option key={index} value={item?.id}>
                                  {item?.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </StyleDropdown>

                      <Form.Item
                        name="descriptionData"
                        label="รายละเอียดเพิ่มเติม"
                        // rules={[{ validator: validateComplantDetail }]}
                      >
                        <Input.TextArea rows={4} />
                      </Form.Item>
                      <div style={{ display: 'flex', justifyContent: 'end', margin: '2em 1em', paddingBottom: '1em' }}>
                        <ButtonSuccess
                          type="primary"
                          htmlType="submit"
                          disabled={checkBtnNewcomplaint}
                          onClick={onSubmitUpdate}
                          Btnwidth="250px"
                        >
                          ส่งเรื่องร้องเรียนไปยังหน่วยงาน
                        </ButtonSuccess>
                      </div>
                    </Boxform>
                  )}
                </Form>
              </CustomForm>
            </div>
          )}

          {/* ////////////////////notไม่ แสดงยกเว้นกด  */}
          <div>
            <ModalPreviewImage
              title=""
              centered
              transitionName=""
              closable={false}
              open={OpenModalImage}
              footer={false}
              onOk={() => {
                setPreviewImg(null)
                setOpenModalImage(false)
              }}
              onCancel={() => {
                setPreviewImg(null)
                setOpenModalImage(false)
              }}
            >
              <ModelContent>
                <div
                  className="close-ui"
                  onClick={() => {
                    setOpenModalImage(false)
                    setPreviewImg(null)
                  }}
                >
                  <img src={Iconpreview} alt="dd" />
                </div>
                {previewImg ? (
                  <div className="vdo-content">
                    <img src={previewImg} style={{ width: '100%', height: 'auto' }} />
                  </div>
                ) : null}
              </ModelContent>
            </ModalPreviewImage>
          </div>

          <div>
            <ModalPreviewImage
              title=""
              centered
              transitionName=""
              closable={false}
              open={OpenModalVdo}
              footer={false}
              onOk={() => {
                setOpenModalVdo(false)
              }}
              onCancel={() => {
                setOpenModalVdo(false)
              }}
            >
              <ModelContent>
                <div
                  className="close-ui"
                  onClick={() => {
                    setOpenModalVdo(false)
                  }}
                ></div>
                {previewVdo ? (
                  <div className="vdo-content">
                    <video width="750" height="500" controls>
                      <source src={previewVdo} type="video/mp4" />
                    </video>{' '}
                  </div>
                ) : null}
              </ModelContent>
            </ModalPreviewImage>
          </div>

          {/* ////////////////////notไม่ แสดงยกเว้นกด  */}

          <CustomModal
            closable={false}
            title="ปฎิเสธการรับเรื่องร้องเรียน ?"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            style={{ width: '500px' }}
          >
            <>
              <CustomForm>
                <Form form={formAntd} layout="vertical">
                  <StyleDropdown>
                    <Form.Item
                      name="connotStaus"
                      label="เหตุผลในการปฎิเสธ"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select onChange={handleChangeOrg}>
                        <Option value="ไม่อยู่ในอำนาจหน้าที่">ไม่อยู่ในอำนาจหน้าที่</Option>
                        <Option value="ไม่อยู่ในเขตรับผิดชอบ">ไม่อยู่ในเขตรับผิดชอบ</Option>
                        <Option value="การร้องเรียนมีเจตนาก่อกวน">การร้องเรียนมีเจตนาก่อกวน</Option>
                        <Option value="การร้องเรียนซ้ำ">การร้องเรียนซ้ำ</Option>
                      </Select>
                    </Form.Item>
                  </StyleDropdown>

                  <Form.Item name="textarea" label="เหตุผลเพิ่มเติม">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Form>
              </CustomForm>

              <div style={{ display: 'flex', justifyContent: 'center', margin: '2em 1em' }}>
                <ButtonEdit style={{ margin: '0 1em' }} onClick={() => setLeaveModalOpen(true)}>
                  ยกเลิก
                </ButtonEdit>

                <ButtonSuccess type="primary" disabled={checkBtn} onClick={OnCannotSubmit} Btnwidth={'100px'}>
                  ยืนยัน
                </ButtonSuccess>
              </div>
            </>
          </CustomModal>

          <CustomSpace>
            <CustomModalLeaves
              transitionName=""
              centered
              open={leaveModalOpen}
              onOk={() => {
                setLeaveModalOpen(false)
                navigate(-1)
              }}
              onCancel={() => setLeaveModalOpen(false)}
              closable={false}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <div style={{ paddingBottom: '10px' }}>{<ExclamationCircle />}</div>
              <p
                style={{
                  color: '#001F34',
                  fontSize: '16px',
                  fontWeight: '400',
                  marginBottom: '2px',
                }}
              >
                คุณต้องการออกจากหน้านี้?
              </p>
              <p
                style={{
                  color: '#C4C4C4',
                  fontSize: '12px',
                  fontWeight: '300',
                }}
              >
                หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
              </p>
            </CustomModalLeaves>
          </CustomSpace>
        </BoxContainer>
      )}
    </>
  )
}

export default ViewPc

const CustomForm = styled.div`
  .text-sub-title {
    color: #c4c4c4;
    font-weight: 300;
  }
  .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
  }
`
const BoxTopView = styled.div`
  margin-bottom: 20px;
  .text-title {
    color: #c4c4c4;
    font-size: 12px;
  }
  .text-sub-title {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .to-right {
    font-size: 12px;
  }
`
const BoxIcon = styled.div`
  background-color: #fff;
  padding: 5px;
  margin: 0 3px;
  display: flex;
  font-size: 12px;
`
const BoxTimer = styled.div`
  margin: 10px 0;
  color: #f36b24;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  min-width: 500px;
  border-radius: 8px;
  border: 1px solid var(--Web-Backend-border-secondary, #ffeee7);
  background: var(--web-backend-orange-200, #fff8f4);
  height: 40px;
`

const BoxContainer = styled.div`
  margin: 0 2%;
`

export const CustomModalLeaves = styled(Modal)`
  font-family: 'Prompt';
  .ant-modal-mask {
    background: rgba(21, 15, 11, 0.5) !important;
    backdrop-filter: blur(8px) !important;
  }

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
const BoxDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1em;
`
const BoxOrganize = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1em;
`

const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};
  height: 100%;
  .box-media-view {
    display: flex;
    flex-direction: row;
  }
`

const StyleDropdown = styled.div`
  /* width: 50%; */
  max-width: 500px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    max-width: 500px;
    height: 40px;
    padding: 0 11px;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }

  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 8px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #ffeee7;
    /* width: 100%; */
  }

  .ant-select-disabled .ant-select-selector {
    background: #ffeee7 !important;
    border: 1px solid #ffeee7 !important;
    color: #f36b24 !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #f36b24;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }

  .ant-select,
  .ant-select-single,
  .ant-select-show-arrow,
  .ant-select-show-search {
    width: 100%;
  }

  .ant-select-selection-placeholder {
    color: #ffc4aa !important;
  }
  .big-input .ant-select-selection-placeholder {
    /* line-height: 37px; */
    padding-left: 0 !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffeee7;
    color: #f36b24;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
  }
  .ant-select-clear {
    margin-top: -8px !important;
    padding-right: 16px !important;
  }
`

// const ButtonSuccess = styled.div`
const ButtonSuccess = styled(Button)`
  &.ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    /* width: 162px; */
    /* width: ${props => (props.Btnwidth ? props.Btnwidth : '162')}; */
    width: ${props => (props.Btnwidth ? `${props.Btnwidth}` : '162px')};

    border-radius: 8px;
  }

  &.ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #c4c2c2;
    text-shadow: none;
    box-shadow: none;
  }
`

const Boxform = styled.div`
  margin-bottom: 20px;
  background: #fbfbfb;
  padding: 1em;

  align-items: center;
  border-radius: 20px;
  .ant-input::placeholder {
    font-size: 14px !important;
    color: var(--web-backend-extended-orange-700, var(--web-backend-orange-700, #ffc4aa)) !important;
  }
  .ant-input:focus {
    border-color: #f36b24;
    color: #f36b24;
  }
  label,
  input {
    font-size: 12px !important;
  }
  textarea {
    font-size: 14px !important;
    color: #f36b24;
  }

  .ant-form-item-explain-error {
    color: rgb(237, 65, 96);
    font-size: 12px;
    text-align: end;
  }
  .ant-input:disabled {
    color: #ffc4aa !important;
    background-color: #fff8f4 !important;
  }
  .ant-input {
    border: 1px solid #ffeee7;
  }

  .ant-input:hover {
    border: 1px solid #ffeee7;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  label {
    font-family: Prompt;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #150f0b;
  }
  .ant-input {
    min-height: 40px;
    border: 1px solid #ffeee7;
    border-radius: 8px;
    max-width: 824px;
    color: #150f0b;
    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      color: #fc6521;
      box-shadow: none;
      border: 1px solid #fc6521;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 48px;
    max-width: 824px;
    color: #fc6521;
    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
    //box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #ffeee7;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fbfbfb;
    border-color: #fbfbfb !important;
    color: #9baab1;
  }
`

export const ModalPreviewImage = styled(Modal)`
  font-family: 'IBM Plex Sans Thai', sans-serif;

  .close-ui {
    position: fixed;
    top: 35px;
    right: 90px;
    padding: 0px;
    /* background: #fbfbfb; */
    width: 35px;
    height: 35px;
    border-radius: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    font-weight: 800;
    color: #3f54d1;
    z-index: 100;
    transition: none;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    width: auto;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
    .ant-btn-default {
      border-radius: 12px;
      border-color: #00ada4 !important;
      color: #00ada4 !important;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #00ada4 !important;
      border-color: #00ada4;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

const ModelContent = styled.div`
  .vdo-content {
    position: relative;
    padding: 0;
  }
  .model-ui {
    font-style: normal;
    .header-ui {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 47px;
      color: #19263f;
      text-align: left;
    }
    .model-crop {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .controls {
      background: #f8e2e2;
      border-radius: 35px;
      width: 200px;
      padding: 0.2em 12px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      .slider {
        width: 160px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .button-zoom {
        line-height: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #1f3983;
        width: 20px;
        height: 20px;
        user-select: none;
      }
    }
  }
`

const Textttile = styled.div`
  color: #f36b24;
  display: -webkit-box;
  max-width: 700px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Prompt;
  font-size: 20px;
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 1em;
`
const HeaderboxView = styled.div`
  border-radius: 24px;
  border: 1px solid var(--web-backend-extended-orange-400, #ffeee7);
  padding: 2em;
  width: 100%;
`
