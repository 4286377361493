import React from 'react'
import { AutoComplete, Input } from 'antd'
import { ReactComponent as IconSearch } from 'assets/images/svg/icon-search-orange.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-orange.svg'
import { BoxSearch } from './AutoComplete.style'
const Index = ({
  options,
  placeholder,
  onSelectFunc,
  isSearchFunc,
  totalFunc,
  total,
  currentPageFunc,
  currentPage,
  onClearFunc,
  currentSearch,
  hideLabel,
  listHeight,
  value,
  isLogin,
  onChange,
  searchText,
}) => {
  const [controlDropdow, setControlDropdow] = React.useState(false)
  const [content, setContent] = React.useState(null)

  const newOptions =
    options &&
    options?.map(item => {
      return {
        value: item?.value,
        key: item?.key,
      }
    })
  let propsData = {}

  if (currentSearch) {
    propsData['value'] = currentSearch
  }

  const prefixInput = isLogin ? (
    <IconSearch />
  ) : !currentSearch ? (
    <IconSearch />
  ) : (
    <IconClear
      style={{ cursor: 'pointer', position: 'absolute', right: '16px', zIndex: 1 }}
      onClick={() => {
        onSelectFunc && onSelectFunc('')
        setControlDropdow(false)
        isSearchFunc(false)
        totalFunc(total)
        currentPageFunc(currentPage)
        onClearFunc(true)
      }}
    />
  )

  return (
    <BoxSearch isLogin={isLogin ? isLogin : false}>
      {!hideLabel && <div style={{ fontSize: '14px', color: '#001F34' }}>ค้นหา</div>}
      <AutoComplete
        defaultActiveFirstOption={currentSearch ? true : false}
        allowClear={isLogin ? true : false}
        clearIcon={isLogin && <IconClear />}
        open={controlDropdow}
        onFocus={e => {
          if (searchText) {
            if (searchText.length > 0) {
              setControlDropdow(true)
            } else {
              setControlDropdow(false)
            }
          } else {
            setControlDropdow(false)
          }
        }}
        onBlur={() => {
          setControlDropdow(false)
        }}
        onInputKeyDown={e => {
          setControlDropdow(true)
        }}
        onSelect={value => {
          setControlDropdow(false)
          onSelectFunc && onSelectFunc(value)
        }}
        onClear={() => {
          setControlDropdow(false)
          isSearchFunc && isSearchFunc(false)
          totalFunc && totalFunc(total)
          currentPageFunc && currentPageFunc(currentPage)
          onClearFunc && onClearFunc(true)
        }}
        filterOption={(inputValue, option) => option?.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1}
        options={newOptions}
        listHeight={listHeight ? listHeight : 280}
        listItemHeight={1}
        onChange={value => {
          setContent(value)
          onSelectFunc && onSelectFunc(value)
          onChange && onChange(value)
          if (value) {
            if (value?.length > 0) {
              setControlDropdow(true)
            } else {
              setControlDropdow(false)
            }
          } else {
            setControlDropdow(false)
          }
        }}
        value={value || undefined}
        notFoundContent={
          <div style={{ color: '#150F0B' }}>
            ไม่พบข้อมูลของ “{<span style={{ color: '#150F0B', fontWeight: 600 }}>{content}</span>}”
          </div>
        }
        getPopupContainer={triggerNode => triggerNode.parentElement}
        {...propsData}
      >
        <Input prefix={prefixInput} placeholder={placeholder} />
      </AutoComplete>
    </BoxSearch>
  )
}

export default Index
