import styled, { createGlobalStyle } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Prompt';

  p {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 25px;
    color: #3a57a1;
  }

  .title-top {
    font-weight: 700;
    font-size: 84px;
    margin-bottom: 0px;
    color: #4c4c53;
    line-height: 100px;
  }

  .title-bottom {
    font-weight: 400;
    font-size: 84px;
    margin-bottom: 0px;
    color: #4c4c53;
    line-height: 100px;
  }

  .detail-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    color: #4c4c53;
  }

  .detail-text {
    font-weight: 400;
    font-size: 16px;
    max-width: 375px;
    color: #4c4c53;
    margin-bottom: 74px;
  }

  .ant-divider {
    width: 388px !important;
    min-width: 388px !important;
    border-top: 1px solid #4c4c53 !important;
    margin: 30px 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  .logo-support {
    max-width: 256px;
  }

  .title {
    max-width: 615px;
  }

  .icon {
    max-width: 520px;
    position: absolute;
    right: 0;
    top: 22px;

    @media screen and (max-height: 820px) {
      max-width: 440px;
    }
    @media screen and (max-height: 768px) {
      top: 0px !important;
    }
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
        background: #E2E5F8;
        box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
        border-radius: 12px;
        width: 309px;
        height: 64px;
        align-items: center;
        justify-content: center;

    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .ant-notification-notice-message {
  font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 5px;
      display: flex;
    }
    .ant-notification-notice-close {
      padding-top: 8px;
    }
  }
`
