import * as Yup from 'yup'

export const defaultValues = {
  name: '',
  position: '',
  email: '',
  department: '',
  phone: '',
  line_id: '',
  role: '',
}

export const Schema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'รูปแบบของ E-mail ไม่ถูกต้อง')
    .required(''),
  mobile_number: Yup.string()
    .required('')
    // .matches(/^[0-9]*$/g, 'Please enter a valid Mobile Number')
    .min(12, 'รูปแบบของเบอร์โทรไม่ถูกต้อง')
    .max(12, 'รูปแบบของเบอร์โทรไม่ถูกต้อง'),
})

export const mockUserData = {
  name: 'ธนภัทร ศิริก้องดี',
  position: 'เจ้าหน้าที่ปฎิบัติงาน',
  email: 'test@gmail.com',
  department: 'สำนักงานสาธารณสุขและสิ่งแวดล้อม',
  phone: '0812345678',
  line_id: 'Admin01',
  role: 'Staff01',
}

export const MockMenuStraf = [
  {
    key_slug: 'dashboard-staff-static-complaint',
    name: 'สถิติเรื่องร้องเรียน',
    icon: 'dashboard',
    path: 'dashboard-staff-static-complaint',
    sort: 1,
  },
  {
    key_slug: 'e-service',
    name: 'ภาพรวมบริการสาธารณะ (E-service)',
    icon: 'e-service',
    path: '/e_service',
    sort: 2,
  },
  {
    key_slug: 'complaint-staff',
    name: 'ภาพรวมเรื่องร้องเรียน',
    icon: 'complaint',
    path: '/complaint-staff',
    sort: 3,
  },
  {
    key_slug: 'chat-staff',
    name: 'แชท (LineOA)',
    icon: 'chat',
    path: '/chat-staff',
    sort: 4,
  },
]
