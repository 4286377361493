import React from 'react'
import { PaginationCustom, Container, CustomText } from './pagination.style'
import { Tooltip } from 'antd'

const Paginations = props => {
  let { defaultCurrent, total, onChange, current } = props
  current = current || 1
  const pageFromCalculation = current => {
    return (current - 1) * 10 + 0 + 1
  }
  const pageToCalculation = (total, current) => {
    if (total / (10 * current) > 1) {
      return (current - 1) * 10 + 10
    }
    return total
  }

  return (
    <Container>
      {/* {total > 10 && (
        <CustomText>
          แสดงข้อมูล {pageFromCalculation(current)}-{pageToCalculation(total, current)} จากทั้งหมด {total} รายการ
        </CustomText>
      )} */}
      <PaginationCustom
        className="middle"
        hideOnSinglePage
        defaultCurrent={defaultCurrent}
        total={total}
        showSizeChanger={false}
        showLessItems={total <= 70 ? (total <= 60 ? false : true) : false}
        onChange={onChange}
        current={current}
        showTitle={false}
      />
      {/* {total > 10 && (
        <CustomText>
          หน้า {current.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}/
          {Math.ceil(total / 10).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
        </CustomText>
      )} */}
    </Container>
  )
}

export default Paginations
