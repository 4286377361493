import React, { useState, useEffect, useContext } from 'react'
import GoogleMapApi from 'components/GoogleMap'
import { Spin } from 'antd'
import { Polygon } from '@react-google-maps/api'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import NakhonRatchasima_provice from 'core/utils/NakhonRatchasima_provice.json'
import InfoDetail from './InfoDetail'
import { getData, getList, getMe } from 'core/action/collection'
import { ReactComponent as Pin } from './image/svg/Pin 32 px.svg'
import moment from 'moment'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { Water } from './container'
import { CustomCheckBox } from './map.style'
import SearchComponent from './search'
import UserContext from 'core/contexts/userContext'
import axios from 'axios'

export default function Index() {
  const [loadingmap, setLoadingmap] = useState(false)
  const [map, setMap] = useState(null)
  const [dataMap, setDataMap] = useState([])
  const [idInfor, setIdInfor] = useState('')
  const [showPinPlumbing, setShowPinPlumbing] = useState(false)
  const [plumbingData, setPlumbingData] = useState([])
  const [searchData, setSearchData] = useState({
    date: [moment().subtract(30, 'days'), moment()],
  })
  const [masterData, setMasterData] = useState([])
  const { meData } = useContext(UserContext)
  let checkRoleSupAdmin = meData?.position_data?.id === '1730459515121299456'
  let latlng = NakhonRatchasima?.features[0]?.geometry?.coordinates[0]
  let latlng_provice = NakhonRatchasima_provice?.features[0]?.geometry?.coordinates[0]
  useEffect(() => {
    let arr = []
    latlng?.map((item, index) => {
      arr.push({ lat: item[1], lng: item[0] })
    })
    let arrProvice = []
    latlng_provice?.map((item, index) => {
      arrProvice.push({ lat: item[1], lng: item[0] })
    })

    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arr,
      strokeColor: '#f36b24',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    const bermudaTriangleProvice = new window.google.maps.Polygon({
      paths: arrProvice,
      strokeColor: '#f00505',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    bermudaTriangleProvice.setMap(map)
    bermudaTriangle.setMap(map)
    return () => {
      bermudaTriangle.setMap(null)
      bermudaTriangleProvice.setMap(null)
    }
  }, [latlng, map])

  useEffect(() => {
    getMasterData()
    getPlumbingData()
  }, [])
  useEffect(() => {
    getAllDataComplant()
  }, [searchData])

  const onClickShowPinPlumbing = e => {
    setShowPinPlumbing(e.target.checked)
  }

  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })
    const resStatus = await getList({
      schema: '/master/api/v1/all/status',
      params: '',
    })
    const resUrgency = await getList({
      schema: '/master/api/v1/all/urgency',
      params: '',
    })
    const resDepartment = await getList({
      schema: '/master/api/v1/all/organizations',
      params: '',
    })
    const resChannel = await getList({
      schema: '/master/api/v1/all/channel',
      params: '',
    })
    setMasterData({
      categories: resCategories?.items,
      status: resStatus?.items,
      urgency: resUrgency?.items,
      department: resDepartment?.items,
      channel: resChannel?.items,
    })
  }

  const getPlumbingData = async () => {
    await axios
      .get(`${process.env.REACT_APP_OUTSIDE}`)
      .then(res => {
        setPlumbingData(res?.data?.data)
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const getAllDataComplant = async () => {
    const res = await getList({
      schema: '/complaint/api/v1/all/complaint',
      params: {
        filters: [
          searchData?.category ? `category_id:eq:${searchData?.category}` : undefined,
          searchData?.status ? `status_data.name:eq:${searchData?.status}` : undefined,
          searchData?.urgency ? `urgency_id:eq:${searchData?.urgency}` : 'urgency_id:isNotNull',
          searchData?.satisfaction ? `rating.rating:eqInt:${searchData?.satisfaction}` : undefined,
          searchData?.channel ? `channel:eq:${searchData?.channel}` : undefined,
          searchData?.date
            ? `created_at:between:${moment(searchData?.date[0]).startOf('day').unix()}|${moment(searchData?.date[1])
                .endOf('day')
                .unix()}`
            : undefined,
          searchData?.department && checkRoleSupAdmin
            ? `department_data:eq:${searchData?.department}`
            : localStorage.getItem('ugency-dashboard')
            ? `status_data.name:eq:over duedate`
            : undefined,
          checkRoleSupAdmin
            ? undefined
            : meData?.position_data?.id === '1730459515154853888'
            ? `department_data:eq:${meData?.organize_data?.org_id}`
            : `officer_data.staff_by:eq:${meData?.id}`,
          ,
        ],
        search: [
          searchData?.search ? `complaint_name:${searchData?.search}` : undefined,
          searchData?.search ? `complaint_no:${searchData?.search}` : undefined,
          searchData?.search ? `mobile_number:${searchData?.search}` : undefined,
          searchData?.search ? `name.first_name:${searchData?.search}` : undefined,
          searchData?.search ? `name.last_name:${searchData?.search}` : undefined,
        ],
      },
    })
    if (res?.code === 200) {
      setLoadingmap(false)
      setDataMap(res?.items)
    } else {
      setDataMap([])
    }
  }

  return (
    <div style={{ padding: '0% 1%' }}>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <SearchComponent
          masterData={masterData}
          setSearchData={setSearchData}
          searchData={searchData}
          conversDate={conversDate}
          checkRoleSupAdmin={checkRoleSupAdmin}
        />
      </div>

      {loadingmap ? (
        <div style={{ height: '40vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
          <Spin />
        </div>
      ) : (
        <GoogleMapApi height={'83vh'} zoom={14} setMap={setMap}>
          <Polygon
            paths={latlng}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }}
          />
          {dataMap?.map((value, index, array) => {
            return (
              <InfoDetail
                pin={
                  <Pin
                    style={{
                      cursor: 'pointer',
                      width: idInfor === value?.key ? '60px' : '40px',
                      height: idInfor === value?.key ? '60px' : '40px',
                    }}
                  />
                }
                key={index}
                id={value?.key}
                coordinates={{
                  lat: value?.coordinates?.latitude,
                  lng: value?.coordinates?.longitude,
                }}
                setIdInfor={setIdInfor}
                idInfor={idInfor}
                data={value}
                conversFullDate={conversFullDate}
              />
            )
          })}

          {showPinPlumbing &&
            plumbingData?.map((value, index, array) => {
              return (
                <InfoDetail
                  pin={
                    <Pin
                      style={{
                        cursor: 'pointer',
                        width: idInfor === value?.key ? '60px' : '40px',
                        height: idInfor === value?.key ? '60px' : '40px',
                      }}
                    />
                  }
                  key={index}
                  id={'outside'}
                  coordinates={{
                    lat: value?.coordinates?.latitude,
                    lng: value?.coordinates?.longitude,
                  }}
                  setIdInfor={setIdInfor}
                  idInfor={idInfor}
                  data={value}
                  conversFullDate={conversFullDate}
                />
              )
            })}

          {(searchData?.department === '1730459515049996288' || !searchData?.department) &&
            checkRoleSupAdmin &&
            Water?.map((value, index, array) => {
              return (
                <InfoDetail
                  pin={
                    <Pin
                      style={{
                        cursor: 'pointer',
                        width: idInfor === value?.id ? '60px' : '40px',
                        height: idInfor === value?.id ? '60px' : '40px',
                      }}
                    />
                  }
                  key={index}
                  id={value?.id}
                  coordinates={{
                    lat: value?.coordinates?.latitude,
                    lng: value?.coordinates?.longitude,
                  }}
                  setIdInfor={setIdInfor}
                  idInfor={idInfor}
                  data={value}
                  conversFullDate={conversFullDate}
                />
              )
            })}

          <div
            style={{
              display: checkRoleSupAdmin ? 'block' : 'none',
              position: 'absolute',
              top: '3px',
              right: '5px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              padding: '5px',
              border: '1px solid #f36b24',
            }}
          >
            <CustomCheckBox onChange={onClickShowPinPlumbing} checked={showPinPlumbing}>
              พิกัดชั้นข้อมูลประปา
            </CustomCheckBox>
          </div>
        </GoogleMapApi>
      )}
    </div>
  )
}
