import React, { useEffect, useState } from 'react'
import {
  Content,
  ContainerView,
  HeaderboxView,
  TextTile,
  TextStatus,
  Boxbg,
  BoxDescription,
  BoxIcon,
  ModalPreviewImage,
  ModelContent,
  BoxBtn,
  BoxOrganize,
  CustomModal,
} from './group.style'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import TableGroupCustom from 'components/TableGroupCustom/Row'
import { ReactComponent as Arrow_Active } from '../image/svg/Arrow_Active.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import {
  checkColorStatusBg,
  checkColorStatusText,
  checkRating,
  checkDueDate,
  checkStatusText,
  dePartMentIcon,
} from '../container'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { unixToDateThai } from 'util/date-format'
import { Button, Tooltip } from 'antd'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import Viewmap from '../viewmap'
export default function Index() {
  let location = useLocation()
  const navigate = useNavigate()
  const [dataList, setDataList] = useState([])
  const [dataListGroup, setDataListGroup] = useState([])
  const [previewVdo, setPreviewVdo] = useState(null)
  const [OpenModalVdo, setOpenModalVdo] = useState(false)
  const [OpenModalImage, setOpenModalImage] = useState(false)
  const [previewImg, setPreviewImg] = useState(null)
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([
    location?.state?.idGroup.length ? location?.state?.idGroup[0] : location?.state?.id[0],
  ])
  const [selectedRowKeys, setSelectedRowKeys] = useState([
    location?.state?.idGroup.length ? location?.state?.idGroup[0] : location?.state?.id[0],
  ])
  const [idAllGroup, setIdAllGroup] = useState([])

  useEffect(() => {
    getDataGroup()
    getDataIsGroup()
  }, [])
  const getDataIsGroup = () => {
    const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
    const compalintId = location?.state?.idGroup
    let token = localStorage.getItem('access_token')
    compalintId?.map((value, index, array) => {
      axios
        .get(`${shemadata}/${value}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        })
        .then(res => {
          res?.data?.data?.sub_group?.map((value, i, array) => {
            axios
              .get(`${shemadata}/${value?.id}`, {
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                },
              })
              .then(resp => {
                if (resp?.data?.code === 200) {
                  setDataListGroup(per => [
                    ...per,
                    {
                      key: index + 1,
                      data: resp?.data?.data,
                    },
                  ])
                  setIdAllGroup(per => [...per, value?.id])
                } else {
                  // console.log('value?.id', value?.id)
                }
              })
          })
        })
        .catch(err => {
          console.log('err', err)
        })
    })
  }
  const groupedData = dataListGroup?.reduce((acc, item) => {
    const existingGroup = acc?.find(group => group?.key === item?.key)
    if (existingGroup) {
      existingGroup?.data?.push(item?.data)
    } else {
      acc?.push({ key: item?.key, data: [item?.data] })
    }

    return acc
  }, [])

  const dataGrouped = groupedData?.map(group => ({
    key: group?.key,
    data: group?.data,
  }))

  const getDataGroup = async () => {
    const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
    const compalintId = location?.state?.id
    let token = localStorage.getItem('access_token')
    compalintId?.map((value, index, array) => {
      axios
        .get(`${shemadata}/${value}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        })
        .then(res => {
          setDataList(per => [...per, res?.data?.data])
          setIdAllGroup(per => [...per, value])
        })
        .catch(err => {
          setDataList([])
          console.log('err', err)
        })
    })
  }
  const columnsDataTable = [
    {
      title: 'เรื่องที่ร้องเรียน',
      dataIndex: 'complaint_name',
      render: text => (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100px' }}>
          <Tooltip title={text}>{text}</Tooltip>
        </div>
      ),
    },
    {
      title: 'เลขที่เรื่องร้องเรียน',
      dataIndex: 'complaint_no',
      render: (text, data) =>
        text ? (
          <div>
            <div>{text}</div>
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: 'ประเภทเรื่องร้องเรียน',
      dataIndex: 'category',
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {data?.category?.icon_url && <img src={data?.category?.icon_url} style={{ width: '14px', height: '14px' }} />}
          {data?.category?.name ? data?.category?.name : '-'}
        </div>
      ),
    },
    {
      title: 'ประเภทย่อย',
      dataIndex: 'sub_category_name',
      render: (text, data) => (
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100px',
          }}
        >
          <Tooltip title={data?.sub_category_data?.name}>
            {data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'หน่วยงานที่รับผิดชอบ',
      dataIndex: 'department_data',
      render: (text, data) => (
        <div>
          {text?.map((value, index, array) => {
            return (
              <div key={index} style={{ color: '#556A82' }}>
                {value?.name}
              </div>
            )
          })}
        </div>
      ),
    },
    {
      title: 'สถานะความเร่งด่วน',
      render: (text, data) =>
        data?.urgency?.name ? (
          <span
            style={{
              backgroundColor: data?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
              borderRadius: '10px',
              color:
                data?.urgency?.name === 'ปกติ'
                  ? '#13AFA6'
                  : data?.urgency?.name === 'ด่วน'
                  ? '#FFA643'
                  : data?.urgency?.name === 'ด่วนมาก'
                  ? '#EC82F8'
                  : data?.urgency?.name === 'ด่วนที่สุด'
                  ? '#F36B24'
                  : '#FFFFFF',
              padding: '8px',
              height: '40px',
            }}
          >
            {data?.urgency?.name}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'ความพึงพอใจ',
      render: (text, data) => <div>{checkRating(data?.rating) ? checkRating(data?.rating) : '-'}</div>,
    },
    {
      title: 'วันที่แจ้งเรื่อง',
      render: (text, data) => <div>{conversDate(data?.created_at)}</div>,
    },
    {
      title: 'วันที่กำหนดเสร็จ',
      render: (text, data) => <div>{data?.duedate_at ? checkDueDate(data?.duedate_at) : '-'}</div>,
    },
    {
      title: 'วันที่แล้วเสร็จ',
      render: (text, data) => <div>{data?.duedate_at ? checkDueDate(data?.duedate_at) : '-'}</div>,
    },
    {
      title: 'สถานะการร้องเรียน',
      render: (text, data) => (
        <div>
          {data?.status_data?.length ? (
            <span
              style={{
                width: 'auto',
                height: '24px',
                background: checkColorStatusBg(data?.status_data[data?.status_data?.length - 1]?.name),
                borderRadius: '4px',
                textAlign: 'center',
                padding: '6px',
                color: checkColorStatusText(data?.status_data[data?.status_data?.length - 1]?.name),
              }}
            >
              {data?.status_data[data?.status_data?.length - 1]?.name
                ? checkStatusText(data?.status_data[data?.status_data?.length - 1]?.name)
                : '-'}
            </span>
          ) : (
            '-'
          )}
        </div>
      ),
    },
  ]

  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '144px', height: '82px', borderRadius: '8px', margin: '1em' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <img
              style={{ borderRadius: '8px', width: '144px', height: '82px', margin: '10px 5px' }}
              className="show-image click"
              src={item}
              alt="image"
              onClick={() => {
                setOpenModalImage(true)
                setPreviewImg(item)
              }}
            />
          )
        }
      })
    return CardUi
  }

  const LastIndex = data => {
    return (
      <div>
        <TextStatus
          bg={data?.length ? checkColorStatusBg(data[data?.length - 1]?.name) : ''}
          text={data?.length ? checkColorStatusText(data[data?.length - 1]?.name) : ''}
        >
          {data?.length ? (data[data?.length - 1]?.name ? checkStatusText(data[data?.length - 1]?.name) : '-') : '-'}
        </TextStatus>
      </div>
    )
  }

  const expandedRowRender = record => {
    if (
      record.status_data[record.status_data.length - 1]?.name === 'done' &&
      record.status_data[record.status_data.length - 1]?.name !== 'outside the area'
    )
      return false
    if (selectedRowKeys?.includes(record.id) && expandedRowKeys?.includes(record.id)) {
      return (
        <div>
          <ContainerView>
            <HeaderboxView>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <TextTile style={{ marginLeft: '15px' }}>{record?.complaint_name}</TextTile>
                  <div style={{ margin: '15px' }}>{record?.description ? record?.description : ''}</div>
                </div>
                <div
                  style={{
                    textAlign: 'end',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'end' }}>{LastIndex(record?.status_data)}</div>
                  <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                    <span>แจ้งเมื่อ: {unixToDateThai(record?.created_at)}</span>
                    <hr style={{ border: '1px solid #FFC4AA', width: '15px', transform: 'rotate(90deg)' }} />
                    <span>เลขที่:{record?.complaint_no}</span>
                    <hr style={{ border: '1px solid #FFC4AA', width: '15px', transform: 'rotate(90deg)' }} />
                    <span>ร้องเรียน: ผ่านหน่วยงาน{record?.organization?.name}</span>
                  </div>
                </div>
              </div>
            </HeaderboxView>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '3%', marginTop: '2%' }}>
              <div>
                <Boxbg Bgcolor="#FBFBFB">
                  <BoxDescription>
                    <div className="text-title">ประเภทเรื่องร้องเรียน</div>
                    <div className="text-title">ประเภทย่อย</div>
                    <div className="text-title">สถานะความเร่งด่วน</div>
                  </BoxDescription>
                  <BoxDescription>
                    {record?.category?.name ? (
                      <div>
                        {record?.category?.icon_url && (
                          <img
                            src={`${record?.category?.icon_url}`}
                            style={{ width: '20px', height: '20px' }}
                            alt="cate"
                          />
                        )}
                        <span style={{ margin: '0 3px' }}>{record?.category?.name}</span>
                      </div>
                    ) : (
                      '-'
                    )}
                    <div className="to-right">{record?.sub_category_data?.name}</div>
                    <span
                      style={{
                        backgroundColor: record?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
                        borderRadius: '10px',
                        color:
                          record?.urgency?.name === 'ปกติ'
                            ? '#13AFA6'
                            : record?.urgency?.name === 'ด่วน'
                            ? '#FFA643'
                            : record?.urgency?.name === 'ด่วนมาก'
                            ? '#EC82F8'
                            : record?.urgency?.name === 'ด่วนที่สุด'
                            ? '#F36B24'
                            : '#FFFFFF',
                        padding: '10px 20px',
                        height: '40px',
                        textAlign: 'center',
                        width: '120px',
                      }}
                    >
                      {record?.urgency?.name}
                    </span>
                  </BoxDescription>
                  <BoxOrganize>
                    <div className="text-title">หน่วยงานที่รับผิดชอบ</div>
                  </BoxOrganize>
                  <BoxOrganize>
                    <BoxOrganize>
                      {record?.department_data ? (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {record &&
                            record?.department_data?.map((item, index) => {
                              return (
                                <BoxIcon key={index}>
                                  <div>{dePartMentIcon[item?.name]}</div>
                                  <div style={{ margin: '0 4px' }}> {item?.name}</div>
                                </BoxIcon>
                              )
                            })}
                        </div>
                      ) : (
                        '-'
                      )}
                    </BoxOrganize>
                  </BoxOrganize>
                  <BoxDescription>
                    {record?.department_data?.length === 1 && <div className="text-title">ผู้รับผิดชอบ</div>}
                    <div className="text-title">วันที่กำหนดเสร็จ</div>
                    <div className="text-title">วันที่แล้วเสร็จ</div>
                  </BoxDescription>
                  <BoxDescription>
                    {record?.department_data?.length === 1 && (
                      <div>
                        {record?.department_data[0]?.officer_data?.first_name}{' '}
                        {record?.department_data[0]?.officer_data?.last_name}
                      </div>
                    )}
                    <div>{record?.duedate_at ? checkDueDate(record?.duedate_at) : '-'}</div>
                    <div>{record?.duedate_at ? conversDate(record?.duedate_at) : '-'}</div>
                  </BoxDescription>
                  <BoxDescription>
                    <div className="text-title">ผู้ร้องเรียน</div>
                    <div className="text-title">เบอร์โทรศัพท์</div>
                  </BoxDescription>
                  <BoxDescription>
                    <div>{record?.name ? `${record?.name?.first_name} ${record?.name?.last_name}` : '-'}</div>
                    <div>{record?.mobile_number ? record?.mobile_number : '-'}</div>
                  </BoxDescription>
                </Boxbg>
              </div>
              <div>
                <div>
                  <div className="text-title">รูปภาพและวิดีโอ</div>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    {record?.media_url && Mapmedia(record?.media_url)}
                  </div>
                </div>
                <div>
                  <div className="text-title" style={{ marginBottom: '10px' }}>
                    สถานที่แจ้งเรื่อง
                  </div>
                  <div style={{ display: 'flex' }}></div>
                </div>
                <Viewmap latitude={record?.coordinates?.latitude} longitude={record?.coordinates?.longitude} />
              </div>
            </div>
          </ContainerView>
        </div>
      )
    }
  }

  const expandIcon = ({ expanded, onExpand, record }) => {
    if (
      record.status_data[record.status_data.length - 1]?.name !== 'done' &&
      record.status_data[record.status_data.length - 1]?.name !== 'outside the area'
    ) {
      return (
        <Arrow_Active
          onClick={e => onExpand(record, e)}
          style={{ transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: '0.5s', cursor: 'pointer' }}
        />
      )
    }
  }

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: (keys, rows) => {
      setSelectedRowKeys(keys)
      setExpandedRowKeys(keys)
    },
    getCheckboxProps: record => ({
      disabled:
        record.status_data[record.status_data.length - 1]?.name === 'done' ||
        record.status_data[record.status_data.length - 1]?.name === 'outside the area',
    }),
  }

  const handleExpand = (expanded, record) => {
    if (
      record?.status_data[record?.status_data?.length - 1]?.name === 'done' &&
      record?.status_data[record?.status_data?.length - 1]?.name === 'outside the area'
    ) {
      return false
    } else {
      if (expanded) {
        setExpandedRowKeys([record?.id])
        setSelectedRowKeys([record?.id])
      } else {
        setExpandedRowKeys([])
      }
    }
  }

  const handleCreateGroup = () => {
    let data = idAllGroup
    data = data.filter(item => item !== selectedRowKeys[0])
    data.unshift(selectedRowKeys[0])
    const body = {
      complaint_id: data,
      group_id: selectedRowKeys[0],
    }
    const token = localStorage.getItem('access_token')
    axios
      .post(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/group`, body, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      .then(res => {
        localStorage.setItem('isCreateGroup', true)
        navigate('/complaint/bof-overview-complaint')
      })
      .catch(err => {
        console.log('err', err)
      })
  }
  return (
    <Content>
      <p className="text-title-group">กรุณาเลือกเรื่องร้องเรียนหลัก ของกลุ่มเรื่องร้องเรียนนี้</p>
      <p className="text-sub-title-group">
        เรื่องร้องเรียนหลัก จะถูกนำข้อมูลหน่วยงาน และสถานะต่างๆ ไปใช้แทนที่เรื่องร้องเรียนที่เหลือทั้งหมด
      </p>
      <div>
        {dataList?.length ? (
          <div style={{ backgroundColor: '#FFFFFF', borderRadius: '8px', padding: '1%' }}>
            <div className="box-title-table">เรื่องร้องเรียนที่เพิ่ม</div>
            <TableGroupCustom
              columns={columnsDataTable}
              dataSource={dataList}
              rowSelection={rowSelection}
              rowKey="id"
              expandable={{
                expandedRowRender,
                expandIcon,
                defaultExpandedRowKeys: expandedRowKeys,
                expandedRowKeys: expandedRowKeys,
                expandIconColumnIndex: 12,
                expandRowByClick: true,
                onExpand: handleExpand,
                rowExpandable: record =>
                  record?.status_data[record?.status_data?.length - 1]?.name !== 'done' &&
                  record?.status_data[record?.status_data?.length - 1]?.name !== 'outside the area',
              }}
            />
          </div>
        ) : (
          ''
        )}
        {dataGrouped?.length
          ? dataGrouped?.map((value, index, array) => {
              return (
                <div
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '8px', padding: '1%', marginTop: '15px' }}
                  key={`dataGrouped` + index}
                >
                  <div className="box-title-table">กลุ่มเรื่องร้องเรียน</div>
                  <TableGroupCustom
                    columns={columnsDataTable}
                    dataSource={value?.data}
                    rowSelection={rowSelection}
                    rowKey="id"
                    expandable={{
                      expandedRowRender,
                      expandIcon,
                      defaultExpandedRowKeys: expandedRowKeys,
                      expandedRowKeys: expandedRowKeys,
                      expandIconColumnIndex: 12,
                      expandRowByClick: true,
                      onExpand: handleExpand,
                      rowExpandable: record =>
                        record.status_data[record.status_data.length - 1]?.name !== 'done' &&
                        record.status_data[record.status_data.length - 1]?.name !== 'outside the area',
                    }}
                  />
                </div>
              )
            })
          : ''}

        <BoxBtn>
          <Button className="btn-cancel-group" onClick={() => setLeaveModalOpen(true)}>
            ยกเลิก
          </Button>
          <Button className="btn-create-group" disabled={!selectedRowKeys?.length === 1} onClick={handleCreateGroup}>
            ยืนยัน
          </Button>
        </BoxBtn>
      </div>
      <CustomModal
        transitionName=""
        centered
        open={leaveModalOpen}
        onOk={() => {
          setLeaveModalOpen(false)
          navigate('/complaint/bof-overview-complaint')
        }}
        onCancel={() => setLeaveModalOpen(false)}
        closable={false}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p
          style={{
            color: '#001F34',
            fontSize: '16px',
            marginBottom: '2px',
            fontWeight: '400',
          }}
        >
          คุณต้องการออกจากหน้านี้?
        </p>
        <p
          style={{
            color: '#597081',
            fontSize: '14px',
            fontWeight: '300',
          }}
        >
          หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
        </p>
      </CustomModal>
      <ModalPreviewImage
        title=""
        centered
        transitionName=""
        closable={false}
        open={OpenModalImage}
        footer={false}
        onOk={() => {
          setPreviewImg(null)
          setOpenModalImage(false)
        }}
        onCancel={() => {
          setPreviewImg(null)
          setOpenModalImage(false)
        }}
      >
        <ModelContent>
          <div
            className="close-ui"
            onClick={() => {
              setOpenModalImage(false)
              setPreviewImg(null)
            }}
          ></div>
          {previewImg ? (
            <div className="vdo-content">
              <img src={previewImg} style={{ width: '100%', height: 'auto' }} />
            </div>
          ) : null}
        </ModelContent>
      </ModalPreviewImage>
    </Content>
  )
}
