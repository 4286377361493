import React from 'react'
import styled from 'styled-components'
import ViewPc from './ViewPc'
import ViewMobile from './ViewMobile'

const ViewEService = () => {
  return (
    <Content>
      <div className="view-pc">
        <ViewPc />
      </div>
      <div className="view-mobile">
        <ViewMobile />
      </div>
    </Content>
  )
}

export default ViewEService

const Content = styled.div`
  @media screen and (max-width: 900px) {
    .view-pc {
      display: none;
    }
    .view-mobile {
      display: block;
    }
  }
  @media screen and (min-width: 900px) {
    .view-mobile {
      display: none;
    }
    .view-pc {
      display: block;
    }
  }
`
