import React from 'react'
import styled from 'styled-components'
import Logo from 'assets/images/png/Logo.png'
import OpenlayerMap from 'components/OpenlayerMap'
import { conversFullMonth } from 'core/utils/DateTimeFormat'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
export default function ExportComplante({ componentRef, data, isPrint }) {
  let dep = data?.department_data?.map(item => item.name)

  return (
    <BoxExport ref={componentRef}>
      <BoxTitle>
        <div>
          <img src={Logo} />
        </div>
      </BoxTitle>
      <BoxTitle>
        <p style={{ fontSize: '20px', fontWeight: '600', color: '#24292F', marginTop: '20px' }}>ใบงานเรื่องร้องเรียน</p>
      </BoxTitle>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">เรื่องร้องเรียน</span>
        <span className="detail">{data?.complaint_name}</span>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">วันที่/เวลารับเรื่อง</span>
        <span className="detail">{conversFullMonth(data?.created_at)}</span>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">เลขที่เรื่องร้องเรียน</span>
        <span className="detail">{data?.complaint_no}</span>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">หน่วยงานที่รับผิดชอบ</span>
        <span className="detail">{dep?.toString() ? dep?.toString() : '-'}</span>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">ช่องทางการร้องเรียน</span>
        <span className="detail">{data?.channel}</span>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">ข้อมูลผู้ร้องเรียน</span>
        <span className="detail">
          ชื่อ {data?.name?.first_name} {data?.name?.last_name} หมายเลขโทรศัพท์ {data?.mobile_number}
        </span>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">ที่อยู่ผู้ร้อง</span>
        <span className="detail">{data?.address ? data?.address : '-'}</span>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr', marginBottom: '2%' }}>
        <span className="title">รายละเอียดการร้องเรียน</span>
        <span className="detail">{data?.description ? data?.description : '-'}</span>
      </div>
      <div style={{ marginBottom: '2%' }} className="title">
        เอกสารแนบอื่นๆ(ถ้ามี)
      </div>
      <div className="box-img">
        {data?.media_url?.length > 0
          ? data?.media_url?.map(r => {
              return r.includes('.mp4') ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <video className="show-image">
                    <source src={r} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    <IvdoPlay />
                  </div>
                </div>
              ) : (
                <img alt="show img" className="show-image" src={r} />
              )
            })
          : '-'}
      </div>
      <div style={{ marginBottom: '20px' }} className="title">
        ตำแหน่ง
      </div>
      <OpenlayerMap data={data} />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '5%' }}>
        <div>
          <p>ละติจูด</p>
          <p>{data?.coordinates?.latitude}° N</p>
        </div>
        <div>
          <p>ลองจิจูด</p>
          <p>{data?.coordinates?.longitude}° E</p>
        </div>
      </div>
    </BoxExport>
  )
}

const BoxExport = styled.div`
  padding: 5%;
  font-family: 'Prompt';
  @media print {
    @page {
      size: A4 portrait;
      margin: 0mm !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
  .bw {
    filter: grayscale(100%);
  }
  .title {
    font-weight: 600;
    font-size: 12px;
    color: #24292f;
  }
  .detail {
    font-weight: 400;
    font-size: 12px;
    color: #24292f;
  }
  .box-img {
    display: flex;
    gap: 2%;
    margin-bottom: 2%;
    video,
    img {
      width: 110px;
      height: 100px;
      border-radius: 10px;
    }
  }
  .print:last-child {
    page-break-after: auto;
  }

  .new-page {
    page-break-before: always;
  }
`

const BoxTitle = styled.div`
  display: flex;
  justify-content: center;
`
