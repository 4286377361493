import styled from 'styled-components'
import { Space, Modal, Input, Button, Checkbox } from 'antd'
export const Content = styled.div`
  @media screen and (max-width: 768px) {
    .box-role {
      padding: 5px 10px;
    }
    div {
      font-size: 10px;
    }
    label {
      font-size: 10px;
    }
  }
  @media screen and (min-width: 769px) {
    div {
      font-size: 14px;
    }
    .box-role {
      padding: 20px 40px;
    }
  }
`

export const BoxRolePermission = styled.div`
  @media screen and (max-width: 768px) {
    .body-ui-mobile {
      display: block;
    }
    .body-ui-pc {
      display: none;
    }
  }
  @media screen and (min-width: 769px) {
    .body-ui-mobile {
      display: none;
    }
    .body-ui-pc {
      display: block;
    }
  }
  .div-header-ui {
    background: rgba(63, 84, 209, 0.03);
    border-radius: 12px;
    padding: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #f36b24;
    background-color: #fff8f4;
    span.text {
      padding: 6px;
      @media screen and (max-width: 768px) {
        font-size: 10px;
      }
    }
    padding-bottom: 8px;
  }

  .div-subheader-ui {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #001f34;
    padding-bottom: 12px;
  }

  .div-hr-ui {
    color: #717171;
    /* border-bottom: 1px solid #FFEEE7; */
  }

  .icon-ui {
    width: 20px;
    height: auto;
  }
`

export const ButtonEdit = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid var(--web-backend-border-primary, #f36b24);
  border-color: #f36b24;
  color: black;
  :focus,
  :hover {
    color: black;
    border-color: transparent;
  }
  span {
    color: #f36b24;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 110px;
    height: 30px;
  }
  @media screen and (min-width: 769px) {
    font-size: 16px;
    width: 129px;
    height: 40px;
  }
`

export const ButtonDelete = styled(Button)`
  background: #ed4160;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border-color: transparent;
  color: #ffffff;
  :focus,
  :hover {
    color: #ffffff;
    border-color: transparent;
    background: #ed4160;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 110px;
    height: 30px;
  }
  @media screen and (min-width: 769px) {
    font-size: 16px;
    width: 113px;
    height: 40px;
  }
`

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b24 !important;
    border: 1px solid #f36b24;
  }
  .ant-checkbox-checked:after {
    border: 1px solid #f36b24 !important;
    border: none;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f36b24 !important;
    border: none;
    opacity: 0.2;
  }

  .ant-checkbox + span {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    color: #717171;
    align-self: center;
    padding-top: 3px;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
    @media screen and (min-width: 769px) {
      font-size: 14px;
    }
  }
  .ant-checkbox.ant-checkbox-disabled + span {
    color: #717171;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: rgb(255 255 255 / 100%);
  }

  .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 24px;
    height: 24px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #ffc4aa !important;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
    border-radius: 4px;
  }
  .ant-checkbox-inner:after {
    width: 6px;
    height: 12px;
    left: 25%;
    border-radius: 2px !important;
  }

  .ant-checkbox .ant-checkbox-checked {
    background-color: #f36b24 !important;
  }
`

export const Label = styled.div`
  color: #001f34;
  font-family: Prompt;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`

export const ButtonBack = styled(Button)`
  width: 40px;
  height: 40px;
  border: none !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    border-color: transparent;
    background: #fbfbfb !important;
  }
`

export const CustomSpace = styled(Space)`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  .ant-btn-ghost {
    color: #f36b24;
    border-color: #f36b24;
    height: 40px;
    width: 88px;
    border-radius: 12px;
  }
  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 88px;
    border-radius: 12px;
  }

  .ant-btn-primary:disabled {
    background-color: #fee0d3;
    border-color: #fee0d3;
    color: white;
  }
`
export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  .ant-modal-mask {
    background: rgba(21, 15, 11, 0.5) !important;
    backdrop-filter: blur(8px) !important;
  }

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const Title = styled.div`
  font-family: 'Prompt';
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  padding-top: 22px;
  color: #001f34;
`

export const Box = styled.div`
  background-color: white;
  min-height: 600px;
  align-items: center;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    .box-checkbox {
      padding: 10px;
    }
  }
  @media screen and (min-width: 769px) {
    .box-checkbox {
      padding: 2rem;
    }
  }
  .ant-form-item-explain-error {
    color: rgb(237, 65, 96);
    font-size: 12px;
    text-align: end;
  }
  .ant-input:disabled {
    color: #ffc4aa !important;
    background-color: #fff8f4 !important;
  }
  .ant-input {
    border: 1px solid #ffeee7;
  }

  .ant-input:hover {
    border: 1px solid #ffeee7;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  label {
    font-family: Prompt;
    font-style: normal;
    font-weight: 400;
    color: #150f0b;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
    @media screen and (min-width: 769px) {
      font-size: 12px;
    }
  }
  .ant-input {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    max-width: 824px;
    color: #150f0b;
    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      color: #fc6521;
      box-shadow: none;
      border: 1px solid #fc6521;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 48px;
    max-width: 824px;
    color: #fc6521;
    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
    //box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #ffeee7;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fbfbfb;
    border-color: #fbfbfb !important;
    color: #9baab1;
  }
`

export const CustomModal2 = styled(Modal)`
  font-family: 'Prompt';

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      font-style: normal;

      color: #c4c4c4 !important;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`
