import * as React from 'react'

const StatusCustom = ({ color, backgroundColor, text }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: backgroundColor,
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          boxShadow: `0px 0px 7.5px 0px ${backgroundColor}`,
        }}
      ></div>
      <p style={{ color: color, margin: 0, paddingLeft: '10px',fontSize:"14px",fontWeight:"400",fontFamily:"Prompt"}}>{text}</p>
    </div>
  )
}

export default StatusCustom
