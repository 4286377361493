import React, { useEffect, useState } from 'react'
import iconedit from 'assets/images/svg/icon-edit-org.svg'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import iconeMarker from 'assets/images/svg/markericon.svg'

const Viewmap = ({ latitude, longitude, dataComplaint }) => {
  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })

  const mapStyles = {
    height: '30vh',
    width: '100%',
    borderRadius: '25px',
  }

  useEffect(() => {
    const body = {
      lat: latitude,
      lng: longitude,
    }
    setMeLocation(body)
  }, [latitude, longitude])

  const defaultCenter = { lat: 15, lng: 100 }

  const CurrantLocation = ({ meLocation }) => <Marker position={meLocation} icon={iconeMarker} />

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={10}
        center={meLocation?.lat ? meLocation : defaultCenter}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          minZoom: 6,
          clickableIcons: false,
          restriction: {
            latLngBounds: {
              north: 20.856935,
              south: 5.612851,
              west: 97.337267,
              east: 105.638703,
            },
          },
        }}
      >
        {meLocation?.lat && <CurrantLocation meLocation={meLocation} />}
        {latitude && longitude && (
          <div
            style={{
              backgroundColor: '#fff',
              width: 'auto',
              maxWidth: '300px',
              padding: '10px',
              borderRadius: '10px',
              position: 'absolute',
              bottom: '4%',
              left: '2%',
            }}
          >
            {dataComplaint?.address ? dataComplaint?.address : '-'}
          </div>
        )}
      </GoogleMap>
    </>
  )
}

export default Viewmap
