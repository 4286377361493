import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import NoCom from 'assets/images/png/Group 48097700.png'
import { ReactComponent as LocationIcon } from 'assets/images/svg/Location.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/svg/arrow-icon.svg'
import { ReactComponent as IconRepair } from 'assets/images/svg/repair-icon.svg'
import { ReactComponent as IconBeforbox } from 'assets/images/svg/update-box.svg'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import IconUpdateOrg from 'assets/images/svg/gear-orange-update.svg'
import IconUpdateGreen from 'assets/images/svg/gear-green.svg'
import UserContext from 'core/contexts/userContext'
import { Button } from 'antd'
import { ReactComponent as IconSuccess } from '../../../image/svg/Success.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  checkColorStatusBg,
  checkColorStatusText,
  checkStatusText,
  checkDueDate,
  dePartMentIcon,
} from '../../../container'

import axios from 'axios'
const CardResultBox = ({
  complaintId,
  dataTranSaction,
  setPreviewImg,
  setPreviewVdo,
  setOpenModalImage,
  setOpenModalVdo,
  dataComplaint,
  path,
}) => {
  const [MeOrganize, setMeOrganize] = useState([])
  const navigate = useNavigate()
  const location = useLocation()

  const token = localStorage.getItem('access_token')
  const { meData } = useContext(UserContext)
  const organizeId = localStorage.getItem('organize')

  useEffect(() => {
    GetMedata()
  }, [])

  const GetMedata = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMedata = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if ((resMedata.status === 200) | (resMedata.status === 201)) {
        setMeOrganize(resMedata?.data?.data)
      }
    } catch (error) {}
  }

  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '144px', height: '82px', borderRadius: '8px', margin: '10px 5px' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    {/* นำ icon มาวางที่นี่ */}
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <div>
              <img
                style={{ borderRadius: '8px', width: '144px', height: '82px', margin: '10px 5px' }}
                className="show-image click"
                src={item}
                alt="image"
                onClick={() => {
                  setOpenModalImage(true)
                  setPreviewImg(item)
                }}
              />
            </div>
          )
        }
      })
    return CardUi
  }
  const onPageForm = (type, dataId) => {
    if (dataTranSaction?.length > 1) {
      navigate('/e-service/bof-eservice/update-form-status', {
        state: {
          type,
          complaintId,
          department_id: dataId?.department_id,
          manyOrganize: true,
          dataComplaint: dataComplaint,
          path: path,
        },
      })
    } else {
      navigate('/e-service/bof-eservice/update-form-status', {
        state: {
          type,
          complaintId,
          department_id: dataId?.department_id,
          manyOrganize: false,
          dataComplaint: dataComplaint,
          path: path,
        },
      })
    }
  }

  const onCheckMeOrg = itemCard => {
    if (itemCard?.department_id === MeOrganize?.organize_data?.org_id) {
      return false
    } else {
      return true
    }
  }

  const LastIndex = data => {
    return (
      <div>
        <div
          style={{
            backgroundColor: checkColorStatusBg(data),
            color: checkColorStatusText(data),
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {checkStatusText(data)}
        </div>
      </div>
    )
  }
  let checkBtnUpdate =
    (dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done' &&
      dataComplaint?.department_data?.filter((value, index, array) => {
        return value?.id === organizeId
      })[0]?.officer_data?.id === meData?.id &&
      dataComplaint?.is_group) ||
    (dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done' &&
      dataComplaint?.department_data?.filter((value, index, array) => {
        return value?.id === organizeId
      })[0]?.officer_data?.id &&
      meData?.position_data?.id !== '1730459515121299456' &&
      location?.state?.group &&
      dataComplaint?.is_group) ||
    (!location?.state?.group && !dataComplaint?.is_group)
  return (
    <>
      {dataTranSaction.length > 0 ? (
        dataTranSaction.map((itemBox, indexBox) => {
          if (itemBox.length === 2 && itemBox.status !== '') {
            return (
              <ContainerView>
                <div style={{ width: '100%', margin: '0' }}>
                  <CardHeader>
                    <Title>
                      <span style={{ margin: '0 10px' }}>ผลการปฎิบัติงาน</span>
                      <BoxIcon>
                        <div>{dePartMentIcon[itemBox[0]?.department_name]}</div>
                        <div style={{ margin: '0 8px' }}> {itemBox[0]?.department_name}</div>
                      </BoxIcon>
                    </Title>
                    {dataTranSaction?.length > 1 && (
                      <Statusbox>
                        <TextStatus
                          bg={itemBox?.length ? checkColorStatusBg(itemBox[itemBox?.length - 1]?.status) : ''}
                          text={itemBox?.length ? checkColorStatusText(itemBox[itemBox?.length - 1]?.status) : ''}
                        >
                          {itemBox?.length
                            ? itemBox[itemBox?.length - 1]?.status
                              ? checkStatusText(itemBox[itemBox?.length - 1]?.status)
                              : '-'
                            : '-'}
                        </TextStatus>
                        <div>
                          ผู้รับผิดชอบ : {itemBox && itemBox[0]?.officer_data?.first_name}{' '}
                          {itemBox && itemBox[0]?.officer_data?.last_name}{' '}
                        </div>
                      </Statusbox>
                    )}
                  </CardHeader>

                  <BoxMedia>
                    {itemBox?.map((itemCard, indexCard) => {
                      return (
                        <>
                          {indexCard === 1 && (
                            <ArrowIconBox>
                              <ArrowIcon />
                            </ArrowIconBox>
                          )}

                          <Boxbg
                            Bgcolor={indexCard === 0 ? '#F9F9F9' : '#F1FCFB'}
                            Padding="1.5em"
                            BdRadius="24px"
                            key={indexCard}
                          >
                            <BoxResult>
                              <HeadeResult>
                                {indexCard === 0 ? (
                                  <IconRepair style={{ width: '20px' }} />
                                ) : (
                                  <IconSuccess style={{ width: '20px' }} />
                                )}
                                <div className="header-result" style={{ marginLeft: '15px' }}>
                                  {indexCard === 0 ? 'ก่อนการปฏิบัติงาน' : 'หลังการปฏิบัติงาน'}
                                  <div style={{ fontSize: '10px', color: '#C4C4C4' }}>
                                    {indexCard === 0
                                      ? 'เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน'
                                      : 'เพิ่มข้อมูลส่วนของงานหลังการปฏิบัติงานเสร็จสิ้น'}
                                  </div>
                                </div>
                              </HeadeResult>
                              <div style={{ display: 'flex', marginTop: '15px', overflow: 'auto', maxWidth: '768px' }}>
                                {Mapmedia(itemCard?.media_url)}
                              </div>
                              <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                                <LocationIcon style={{ marginRight: '10px' }} />
                                <div>
                                  <p style={{ color: '#C4C4C4', margin: 0 }}>{itemCard?.address}</p>
                                </div>
                              </div>
                            </BoxResult>
                          </Boxbg>
                        </>
                      )
                    })}
                  </BoxMedia>
                </div>
              </ContainerView>
            )
          } else if (itemBox.find(itemx => itemx.type === 'befor')) {
            return (
              <ContainerView>
                <div style={{ width: '100%', margin: '0' }}>
                  <CardHeader>
                    <Title>
                      <span style={{ margin: '0 10px' }}>ผลการปฎิบัติงาน</span>
                      <BoxIcon>
                        <div>{dePartMentIcon[itemBox[0]?.department_name]}</div>
                        <div style={{ margin: '0 8px' }}> {itemBox[0]?.department_name}</div>
                      </BoxIcon>
                    </Title>
                    {dataTranSaction?.length > 1 && (
                      <Statusbox>
                        <TextStatus
                          bg={itemBox?.length ? checkColorStatusBg(itemBox[itemBox?.length - 1]?.status) : ''}
                          text={itemBox?.length ? checkColorStatusText(itemBox[itemBox?.length - 1]?.status) : ''}
                        >
                          {itemBox?.length
                            ? itemBox[itemBox?.length - 1]?.status
                              ? checkStatusText(itemBox[itemBox?.length - 1]?.status)
                              : '-'
                            : '-'}
                        </TextStatus>
                        <div>
                          ผู้รับผิดชอบ : {itemBox && itemBox[0]?.officer_data?.first_name}{' '}
                          {itemBox && itemBox[0]?.officer_data?.last_name}{' '}
                        </div>
                      </Statusbox>
                    )}
                  </CardHeader>

                  <BoxMedia>
                    {itemBox?.map((itemCard, indexCard) => (
                      <>
                        <Boxbg
                          Bgcolor={indexCard === 0 ? '#F9F9F9' : '#F1FCFB'}
                          Padding="1.5em"
                          BdRadius="24px"
                          key={indexCard}
                        >
                          <BoxResult>
                            <HeadeResult>
                              {indexCard === 0 ? (
                                <IconRepair style={{ width: '20px' }} />
                              ) : (
                                <IconSuccess style={{ width: '20px' }} />
                              )}
                              <div className="header-result" style={{ marginLeft: '15px' }}>
                                {indexCard === 0 ? 'ก่อนการปฏิบัติงาน' : 'หลังการปฏิบัติงาน'}
                                <div style={{ fontSize: '10px', color: '#C4C4C4' }}>
                                  {indexCard === 0
                                    ? 'เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน'
                                    : 'เพิ่มข้อมูลส่วนของงานหลังการปฏิบัติงานเสร็จสิ้น'}
                                </div>
                              </div>
                            </HeadeResult>
                            <div style={{ display: 'flex', marginTop: '15px', overflow: 'auto', maxWidth: '768px' }}>
                              {Mapmedia(itemCard?.media_url)}
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                              <LocationIcon style={{ marginRight: '10px' }} />
                              <div>
                                <p style={{ color: '#C4C4C4', margin: 0 }}>{itemCard?.address}</p>
                              </div>
                            </div>
                          </BoxResult>
                        </Boxbg>
                        <ArrowIconBox>
                          <ArrowIcon />
                        </ArrowIconBox>
                        <Boxbg Bgcolor="#ffff" Padding="2%" BdRadius="24px" className="shadow">
                          <BoxResult>
                            <HeadeResult>
                              <IconBeforbox style={{ width: '20px' }} />
                              <div className="header-result">
                                หลังการปฏิบัติงาน
                                <div style={{ fontSize: '10px', color: '#C4C4C4' }} className="title-color">
                                  {' '}
                                  เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน
                                </div>
                              </div>
                            </HeadeResult>
                            <ImageCard className="center-image">
                              <div>
                                <img src={IconUpdateGreen} alt="update-org" />
                              </div>
                            </ImageCard>
                            {checkBtnUpdate && (
                              <div className="center-image" onClick={() => onPageForm('after', itemCard)}>
                                <ButtonEdit Bgcolor="#13AFA6" disabled={onCheckMeOrg(itemCard)}>
                                  อัปเดต
                                </ButtonEdit>
                              </div>
                            )}
                          </BoxResult>
                        </Boxbg>
                      </>
                    ))}
                  </BoxMedia>
                </div>
              </ContainerView>
            )
          } else {
            return (
              <ContainerView>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Title>
                    ผลการปฎิบัติงาน
                    <span>
                      <BoxIcon>
                        <div>{dePartMentIcon[itemBox[0]?.department_name]}</div>
                        <div style={{ margin: '0 8px' }}> {itemBox[0]?.department_name}</div>
                      </BoxIcon>
                    </span>
                  </Title>
                  <div style={{ display: 'flex' }}>
                    {dataTranSaction.length > 1 && itemBox?.length
                      ? LastIndex(itemBox[itemBox?.length - 1]?.status)
                      : ''}
                  </div>
                </div>
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ color: '#C4C4C4', width: '300px' }}>
                      เพื่อให้การดำเนินงานเป็นไปอย่างโปร่งใส และสะดวกเรียบร้อย
                      เจ้าหน้าที่ต้องอัปเดตข้อมูลในขั้นตอนก่อนเริ่มปฏิบัติงาน และเมื่อปฏิบัติงานเสร็จสิ้น{' '}
                    </div>
                    <div>
                      {dataTranSaction.length > 1 && (
                        <>
                          ผู้รับผิดชอบ :{` `}
                          {itemBox?.length && itemBox[itemBox?.length - 1]?.officer_data?.id
                            ? `${itemBox?.length && itemBox[itemBox?.length - 1]?.officer_data?.first_name} ${
                                itemBox?.length && itemBox[itemBox?.length - 1]?.officer_data?.last_name
                              }`
                            : '-'}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <BoxMedia>
                  {itemBox.map((itemCard, indexCard) => {
                    return (
                      <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px">
                        <BoxResult>
                          <HeadeResult>
                            <IconRepair style={{ width: '20px' }} />
                            <div className="header-result">
                              ก่อนการปฏิบัติงาน
                              <div style={{ fontSize: '10px' }} className="title-color">
                                เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน
                              </div>
                            </div>
                          </HeadeResult>

                          <ImageCard className="center-image">
                            <div>
                              <img src={IconUpdateOrg} alt="update-org" />
                            </div>
                          </ImageCard>

                          {checkBtnUpdate && (
                            <div className="center-image" onClick={() => onPageForm('befor', itemCard)}>
                              <ButtonEdit disabled={onCheckMeOrg(itemCard)}>อัปเดต</ButtonEdit>
                            </div>
                          )}
                        </BoxResult>
                      </Boxbg>
                    )
                  })}

                  <ArrowIconBox>
                    <ArrowIcon />
                  </ArrowIconBox>

                  <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px">
                    <BoxResult>
                      <HeadeResult>
                        <IconRepair style={{ width: '20px' }} />
                        <div className="header-result">
                          หลังการปฏิบัติงาน
                          <div style={{ fontSize: '10px' }} className="title-color">
                            เพิ่มข้อมูลส่วนของงานหลังเริ่มการปฏิบัติงาน
                          </div>
                        </div>
                      </HeadeResult>

                      <ImageCard className="center-image">
                        <div>
                          <img src={NoCom} alt="update-org" />
                        </div>
                      </ImageCard>

                      {checkBtnUpdate && (
                        <div className="center-image">
                          <ButtonEdit disabled={true}>อัปเดต</ButtonEdit>
                        </div>
                      )}
                    </BoxResult>
                  </Boxbg>
                </BoxMedia>
              </ContainerView>
            )
          }
        })
      ) : (
        <ContainerView>
          <div style={{ width: '100%', margin: '0em' }}>
            <BoxMedia>
              <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px">
                <BoxResult>
                  <HeadeResult>
                    <IconRepair style={{ width: '20px' }} />
                    <div className="header-result">
                      ก่อนการปฏิบัติงาน
                      <div style={{ fontSize: '10px', color: '#C4C4C4' }}>
                        เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน
                      </div>
                    </div>
                  </HeadeResult>

                  <ImageCard className="center-image">
                    <div>
                      <img src={IconUpdateOrg} alt="update-org" />
                    </div>
                  </ImageCard>

                  {checkBtnUpdate && (
                    <div className="center-image" onClick={() => onPageForm('befor')}>
                      <ButtonEdit>อัปเดต</ButtonEdit>
                    </div>
                  )}
                </BoxResult>
              </Boxbg>

              <ArrowIconBox>
                <ArrowIcon />
              </ArrowIconBox>

              <Boxbg Bgcolor="#F9F9F9" Padding="2%" BdRadius="24px">
                <BoxResult>
                  <HeadeResult>
                    <IconRepair style={{ width: '20px' }} />
                    <div className="header-result">
                      หลังการปฏิบัติงาน
                      <div style={{ fontSize: '10px', color: '#C4C4C4' }} className="title-color">
                        เพิ่มข้อมูลส่วนของงานก่อนเริ่มการปฏิบัติงาน
                      </div>
                    </div>
                  </HeadeResult>

                  <ImageCard className="center-image">
                    <div>
                      <img src={NoCom} alt="update-org" />
                    </div>
                  </ImageCard>
                  {checkBtnUpdate && (
                    <div className="center-image">
                      <ButtonEdit disabled={true}>อัปเดต</ButtonEdit>
                    </div>
                  )}
                </BoxResult>
              </Boxbg>
            </BoxMedia>
          </div>
        </ContainerView>
      )}
    </>
  )
}

export default CardResultBox

const Title = styled.div`
  font-size: 12px;
  .small-name {
    margin-top: 5px;
    font-size: 14px;
    color: #556a82;
  }
`

const Statusbox = styled.div`
  display: flex;
  flex-direction: column;
  .status-color {
    background-color: green;
    padding: 4px;
  }
`

const BoxIcon = styled.div`
  background-color: #fff;
  padding: 5px;
  margin: 0 3px;
  display: flex;
  color: #556a82;
  font-size: 10px;
`

const TextStatus = styled.div`
  width: auto;
  height: 24px;
  background: ${props => (props?.bg ? props?.bg : '')};
  border-radius: 4px;
  text-align: center;
  padding: 3px;
  color: ${props => (props?.text ? props?.text : '')};
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

const ImageCard = styled.div`
  align-self: center;
  img {
    min-width: 180px;
    min-height: 120px;
  }
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 2em;
  margin-bottom: 20px;

  .title-color {
    color: #c4c4c4;
  }
`
const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};

  &.shadow {
    box-shadow: 0px 4px 30px 0px rgba(46, 60, 79, 0.08);
  }
`
const HeadeResult = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  /* margin: 0 1em; */
`

const BoxMedia = styled.div`
  justify-content: space-between;
  align-items: baseline;
  gap: 19px;
  margin-top: 10px;
`
const ArrowIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  transform: rotate(90deg);
`

const BoxResult = styled.div`
  display: flex;
  flex-direction: column;
  margin: -1 10px;

  .header-result {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    margin: 0 4px;
  }

  .center-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonEdit = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    /* background: #f36b24; */

    background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#f36b24')};
    width: 100px;
    height: 30px;
  }

  &.ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    border-radius: 6px;
    border: none;
    width: 140px;
    height: 40px;
  }
`
