import React from 'react';
import ReactApexChart from 'react-apexcharts';

function LineChart() {
  const series = [{
    data: [487, 321, 701, 739, 500]
  }];

  const options = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      zoom: { enabled: false },
      offsetX: -50,
    },
    colors: ['#FFA500'],
    stroke: {
      curve: 'straight',
      width: 2
      
    },
    markers: {
      size: 5,
      strokeColors: '#ffffff',
      strokeWidth: 2
    },
    grid: {
      borderColor: '#e7e7e7',
      strokeDashArray: 5,
      yaxis: {
        lines: { show: true }
      },
      
    },
    xaxis: {
      categories: ['1 คะแนน', '2 คะแนน', '3 คะแนน', '4 คะแนน', '5 คะแนน'],
      title: {
        text: 'คะแนน',
        rotate: 0, 
        floating: true, 
        offsetX: 170, 
        offsetY: -47,
        style: {
          color: '#C4C4C4',
          fontSize: '8px',
          fontFamily: 'Prompt',
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Prompt',
          fontWeight: 400,
        }
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        fontFamily: 'Prompt',
      },
      offsetY: 10,
      

   },

    yaxis: {
      min: 0, 
      max: 1000, 
      tickAmount: 4, 
      labels: {
        formatter: value => value.toFixed(0) 
      },
       title: {
      text: 'จำนวน (คน)',
      rotate: 0, 
      floating: true, 
      offsetX: 50, 
      offsetY: -130, 
      style: {
        color: '#C4C4C4',
        fontSize: '8px',
        fontFamily: 'Prompt',
        fontWeight: 400,

        }
      }
    },
     legend: {
    show: true,
    formatter: (seriesName, opts) => {
      const value = series[0].data[opts.seriesIndex];
      return [
        `<span style="color: #FFA500;">●</span>`,
        `<span style="font-weight: 500; font-size: 12px; color: #150F0B;">${value}</span>`,
        `<span style="font-size: 10px; color: #717171; font-weight: 400;">${opts.seriesIndex + 1} คะแนน</span>`
      ].join(' ');
    },
    itemMargin: {
      horizontal: 5,
      vertical: 20
    },
    labels: {
      colors: '#000',
      useSeriesColors: false
    },
    markers: {
      width: 10,
      height: 10
    }
  },
  };

  const CustomLegend = ({ series }) => (
  <div style={{ textAlign: 'left' }}>
    {series[0].data.map((value, index) => (
      <div key={index} style={{ 
        fontFamily: 'Prompt', 
        lineHeight: '14.4px',
        marginBottom: '24px'
        
      }}>
        <span style={{
          height: '10px',
          width: '10px',
          backgroundColor: '#FFA500', 
          borderRadius: '50%',
          display: 'inline-block',
          marginRight: '5px',
          verticalAlign: 'middle' 
        }} />
        <span style={{ 
          fontWeight: 500,
          fontSize: '12px', 
          color: '#150F0B',
          marginLeft: '8px',
          verticalAlign: 'middle' 
        }}>
          {value}
        </span>
        <span style={{
          fontSize: '10px', 
          color: '#717171', 
          marginLeft: '10px',
          fontWeight: 400
        }}>
          {`${index + 1} คะแนน`}
        </span>
      </div>
    ))}
  </div>
);




  return (
    <div style={{ display: 'flex'}}>
        <ReactApexChart options={options} series={series} type="line" width='420' height="300" />
      <CustomLegend series={series} />
    </div>
  );
}

export default LineChart;
