import React, { useState, useContext, useEffect } from 'react'
import { Button, notification, Tooltip, Dropdown, Menu, Row, Col } from 'antd'
import { BoxTableCustom, BoxTitle, Menucustom, DrawerFormInput, CustomModal } from './list.style'
import Table from 'components/Table/Row'
import { useNavigate, useLocation } from 'react-router-dom'
import IconImport from 'assets/images/svg/icon-import-complaint.svg'
import IconInputForm from 'assets/images/svg/icon-input-form-dropdown.svg'
import IconImportFile from 'assets/images/svg/icon-import-file.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import IconNoti from 'assets/images/svg/icon-noti.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import vDivider from 'assets/images/svg/vertical-divider.svg'
import IconArrowOrange from 'assets/images/svg/org-arrow.svg'
import FormInput from './FormInput/FormInput'
import Pagination from 'components/pagination'
import UserContext from 'core/contexts/userContext'
import styled, { createGlobalStyle } from 'styled-components'
import { getList } from 'core/action/collection'
import { unixToDateThai } from 'util/date-format'
import DrawerImportFile from '../ImportFile'

const Index = () => {
  const navigate = useNavigate()
  const { meData } = useContext(UserContext)
  console.log('meData', meData)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [record, setrecord] = React.useState()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [openDrawerFormInput, setOpenDrawerFormInput] = useState(false)
  const [dataTotal, setDatToal] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const [leaveModalOpen, setLeaveModalOpen] = useState(false)
  const [drawerImportFile, setDrawerImportFile] = useState(false)
  const isSizeMobile = 768
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    getListData(currentPage)
  }, [currentPage])
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    if (localStorage.getItem('isReceived')) {
      openNotificationSuccess()
      setTimeout(() => {
        localStorage.removeItem('isReceived')
      }, 5000)
    }
  }, [])

  const getListData = async Cpage => {
    let filter = ['urgency_id:isNull', 'officer_data:isNull', 'category_id:eq:1735509869739372551']
    let filterStaff = [
      'urgency_id:isNull',
      'officer_data:isNull',
      `department_data:eq:${meData?.organize_data?.org_id}`,
    ]
    let filterAdmin = ['urgency_id:isNull', 'officer_data:isNull']

    const res = await getList({
      schema: `/complaint/api/v1/complaint`,
      params: {
        page: Cpage,
        per_page: 10,
        sort: ['created_at:desc'],
        filters: meData?.position_data?.id === '1730459515154853888' ? filterStaff : filterAdmin,
      },
      url: '',
      baseURL: '',
    })
    if (res.dataLength > 0) {
      setrecord(res?.items)
      setDatToal(res.dataLength)
    }
  }

  const columns = [
    {
      title: <div style={{ color: '#717171' }}>ลำดับ</div>,
      dataIndex: 'no',
      width: '80px',
      render: text => <div style={{ textAlign: 'center' }}>{text}.</div>,
    },
    {
      title: <div style={{ color: '#717171' }}>เรื่องที่ร้องเรียน</div>,
      dataIndex: 'complaint_name',
      // width: 50,
      ellipsis: true,
      // className: 'gap',
      render: data => <>{data ? (data ? data : '-') : '-'}</>,
    },
    {
      title: <div style={{ color: '#717171' }}>เลขที่เรื่องร้องเรียน</div>,
      dataIndex: 'complaint_no',
      render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      title: <div style={{ color: '#717171' }}>ประเภทเรื่องร้องเรียน</div>,
      dataIndex: 'category',
      ellipsis: true,
      render: (text, data) => {
        return data.category ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src={data?.category?.icon_url} style={{ width: '14px', height: '14px' }} />
            {data?.category?.name}
          </div>
        ) : (
          <div>-</div>
        )
      },
    },
    {
      title: <div style={{ color: '#717171' }}>ประเภทย่อย</div>,
      dataIndex: 'sub_category_data',
      render: data => <>{data ? data ? data?.name : '-' : <span>-</span>}</>,
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      title: <div style={{ color: '#717171' }}>วันที่แจ้งเรื่องร้องเรียน</div>,
      dataIndex: 'updated_at',
      ellipsis: true,
      // className: 'font-w-400',
      render: data => {
        return <div>{unixToDateThai(data)}</div>
      },
    },

    {
      title: <div style={{ color: '#717171' }}>ช่องทาง</div>,
      dataIndex: 'channel',
      render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
      ellipsis: true,
    },
    {
      title: '',
      key: 'actions',
      width: 120,
      render: data => {
        return (
          <div>
            <img src={IconArrowOrange} alt="see" onClick={() => onPageView(data)} />
          </div>
        )
      },
    },
  ]

  const onPageView = data => {
    navigate(`/operation/bof-new-complaint/view`, {
      state: {
        record: data?.id,
      },
    })
  }

  const onPageChange = page => {
    setCurrentPage(page)
  }

  const openInputDrawer = () => {
    setOpenDrawerFormInput(true)
  }

  const closeInputDrawer = () => {
    setOpenDrawerFormInput(false)
  }

  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }

  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'inputForm') {
      openInputDrawer()
    } else if (e.key === 'importFile') {
      setDrawerImportFile(true)
    }
  }

  const menuImport = value => {
    return (
      <Menucustom onClick={handleMenuImportClick}>
        <Menucustom.Item key={`inputForm`}>
          {<img src={IconInputForm} alt="see" style={{ marginRight: '5px' }} />}
          กรอกฟอร์ม
        </Menucustom.Item>
        <Menucustom.Item key={`importFile`}>
          {<img src={IconImportFile} alt="edit" style={{ marginRight: '5px' }} />}
          นำเข้าไฟล์
        </Menucustom.Item>
      </Menucustom>
    )
  }

  const openNotificationSuccess = typeNoti => {
    notification.info({
      message: (
        <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>รับเรื่องร้องเรียนสำเร็จ</div>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 5,
    })
  }

  const openNotification = () => {
    api.open({
      key: 'success',
      message: (
        <>
          <span
            style={{
              fontStyle: 'normal',
              fontFamily: 'Prompt',
              paddingRight: '80px',
              fontSize: '16px',
              fontWeight: '400',
              color: '#13AFA6',
            }}
          >
            นำเข้าเรื่องร้องเรียนสำเร็จ
          </span>
          <a
            style={{
              color: '#13AFA6',
              cursor: 'pointer',
              fontWeight: '500',
              fontSize: '14px',
              fontFamily: 'Prompt',
              fontStyle: 'normal',
            }}
            onClick={() => {
              notification.close('success')
              if (meData?.position_data?.id === '1730459515121299456') {
                navigate('/complaint/bof-overview-complaint')
              } else {
                navigate('/complaint-staff/bof-overview-complaint')
              }
            }}
          >
            ดูเรื่องร้องเรียน
          </a>
          <img src={vDivider} alt="" />
        </>
      ),
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      className: 'custom-class',
      duration: 3,
    })
  }

  return (
    <>
      <NotiStyle />
      {contextHolder}
      <BoxContainer>
        <BoxTitle>
          <div style={{ fontSize: windowWidth <= isSizeMobile ? '18px' : '24px', fontWeight: 500, color: '#19263F' }}>
            เรื่องร้องเรียนใหม่
          </div>
          <Dropdown
            overlay={() => menuImport()}
            trigger={['click']}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onVisibleChange={handleDropdownVisibleChange}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '12px',
                width: '160px',
                backgroundColor: '#F36B24',
                borderRadius: '8px',
                height: '40px',
                cursor: 'pointer',
              }}
            >
              <img src={IconImport} alt="add" style={{ display: 'inline' }} />
              <span
                style={{
                  paddingLeft: windowWidth <= isSizeMobile ? '8px !important' : '4px',
                  paddingTop: '1px',
                  fontSize: windowWidth <= isSizeMobile ? '15px' : '16px',
                  color: '#ffffff',
                }}
              >
                นำเข้าข้อมูล
              </span>
              <img
                src={IconArrowDown}
                alt="add"
                style={{
                  display: 'inline',
                  transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
              />
            </div>
          </Dropdown>
        </BoxTitle>

        <BoxTableCustom>
          <Table
            columns={columns}
            dataSource={record}
            onRow={onPageView}
            //   noDataText={fnShowTextNotFound()}
          />
        </BoxTableCustom>

        <div style={{ padding: windowWidth <= isSizeMobile ? '40px 0' : '0' }}>
          <Pagination defaultCurrent={1} total={dataTotal} onChange={e => onPageChange(e)} current={currentPage} />
        </div>
        <DrawerImportFile
          drawerImportFile={drawerImportFile}
          setDrawerImportFile={setDrawerImportFile}
          openNotification={openNotification}
          getListData={getListData}
        />

        <DrawerFormInput
          placement="right"
          onClose={() => setLeaveModalOpen(true)}
          open={openDrawerFormInput}
          closable={false}
          width={800}
          zIndex={1050}
          key={openDrawerFormInput ? 'openD' : 'closeD'}
        >
          <FormInput
            closeInputDrawer={closeInputDrawer}
            getListData={getListData}
            openNotification={openNotification}
            setLeaveModalOpen={setLeaveModalOpen}
          />
        </DrawerFormInput>
      </BoxContainer>

      <CustomModal
        transitionName=""
        centered
        open={leaveModalOpen}
        onOk={() => {
          setLeaveModalOpen(false)
          closeInputDrawer()
        }}
        onCancel={() => setLeaveModalOpen(false)}
        closable={false}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        zIndex={1100}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p
          style={{
            color: '#001F34',
            fontSize: '16px',
            marginBottom: '2px',
            fontWeight: '400',
          }}
        >
          คุณต้องการออกจากหน้านี้?
        </p>
        <p
          style={{
            color: '#597081',
            fontSize: '14px',
            fontWeight: '300',
          }}
        >
          หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
        </p>
      </CustomModal>
    </>
  )
}

export default Index

const BoxContainer = styled.div`
  margin: 0 2%;
`

const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 4px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 3px;
    }
  }
`
