import styled from 'styled-components'

export const BoxSearch = styled.div`
  .ant-select-dropdown {
    background: #fff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 16px !important;
    z-index: 1;
  }
  .ant-select-arrow {
    left: 11px !important;
    font-size: 18px;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 25px;
    display: flex;
    align-items: center;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
    border: 1px solid #e2e5f8;
    width: 320px;
    height: 40px;
    align-items: center;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    display: flex;
    align-items: center;
    padding-left: 25px;
  }

  .ant-input-affix-wrapper {
    border-radius: 16px;
    border: 1px solid #e2e5f8;
    width: 320px;
    min-width: 320px;
    height: 40px;
    align-items: center;
  }

  [id*='rc_select_'].ant-input {
    padding-left: 5px;
    padding-right: 20px;
  }

  .ant-select {
    cursor: text;
    caret-color: #f36b24;
  }

  .ant-input-affix-wrapper:focus {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
    box-shadow: none !important;

    ${props =>
      props.isLogin &&
      `
   .ant-input-prefix {
      display: none;
    }
  `}
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24 !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffeee7;
    color: #f36b24;
  }

  .ant-select-item {
    color: #001f34;
    font-size: 14px;
  }

  .rc-virtual-list-holder-inner {
    display: block !important;
  }

  .ant-select-clear {
    margin-top: -8px !important;
    padding-right: 16px !important;
    background: transparent !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: rgba(63, 84, 209, 0.5) !important;
    width: 2px !important;
    left: 4px !important;
  }
`
