import { Input, Select, DatePicker, Space, Divider } from 'antd'
import React, { useState } from 'react'
import { BoxSearch, StyleDropdown } from './list.style'
import styled from 'styled-components'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker
export default function Search({ masterData, setSearchData, searchData, conversDate }) {
  let optionsCategories = masterData?.categories?.map(item => {
    return {
      label: (
        <div>
          <img src={item?.icon_url} style={{ marginRight: '10px', width: '14px', height: '14px' }} />
          {item.name}
        </div>
      ),
      value: item.id,
    }
  })
  let optionActivity = masterData?.activity?.map(item => {
    return {
      label: item.name_th,
      value: item.name_en,
    }
  })
  // const dataActivity = [{ nameEn: 'view', nameTh: 'ดูข้อมูล' }]
  // let optionActivity = dataActivity?.map(item => {
  //   return {
  //     label: item?.nameTh,
  //     value: item?.nameEn,
  //   }
  // })

  let optionsDepartment = masterData?.department?.map(item => {
    return {
      label: item?.name,
      value: item.name,
    }
  })

  let optionsSatisfaction = [
    {
      label: 'ยอดเยี่ยม',
      value: 5,
    },
    {
      label: 'ดีมาก',
      value: 4,
    },
    {
      label: 'ดี',
      value: 3,
    },
    {
      label: 'พอใช้',
      value: 2,
    },
    {
      label: 'ควรปรับปรุง',
      value: 1,
    },
  ]

  const handleChange = (value, type) => {
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  let data1 = searchData?.date && conversDate(moment(searchData?.date ? searchData?.date[0] : '')?.unix())
  let data2 = searchData?.date && conversDate(moment(searchData?.date ? searchData?.date[1] : '')?.unix())
  const thaiYearFormat = date => {
    if (date) {
      const thaiYear = date.year() + 543
      return moment(date).year(thaiYear)
    }
    return null
  }
  const startDate = thaiYearFormat(searchData?.date ? searchData.date[0] : null)
  const endDate = thaiYearFormat(searchData?.date ? searchData.date[1] : null)
  const disabledDate = current => {
    // Disable dates after the current day
    return current && current > moment().endOf('day')
  }

  return (
    <BoxSearch>
      <BoxSelect>
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 250px 1fr', gap: '10px' }}
          className="search-component"
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>ค้นหา</div>

            <Input
              placeholder="ค้นหาชื่อเจ้าหน้าที่"
              value={searchData?.search}
              onChange={val => handleChange(val, 'input')}
              allowClear={true}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>ชื่อหน่วยงาน</div>
            <Select
              placeholder="หน่วยงานที่รับผิดชอบ"
              options={optionsDepartment}
              value={searchData?.department}
              onSelect={(val, e) => {
                handleChange(e?.value, 'department')
              }}
              onClear={val => handleChange(val, 'department')}
              allowClear
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>ชื่อกิจกรรม</div>
            <Select
              placeholder="ชื่อกิจกรรม"
              options={optionActivity}
              value={searchData?.activity}
              onSelect={(val, e) => {
                handleChange(e?.value, 'activity')
              }}
              onClear={val => handleChange(val, 'activity')}
              allowClear
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>เลือกวันที่</div>

            <RangePicker
              placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
              value={[startDate, endDate]}
              onChange={val => handleChange(val, 'date')}
              format={'DD/MM/YYYY'}
              className="date-picker"
              disabledDate={disabledDate}
            />
          </div>
          <span
            style={{
              color: '#556A82',
              textDecorationLine: 'underline',
              paddingTop: '30px',
              cursor: 'pointer',
              width: '60px',
            }}
            onClick={() => setSearchData([])}
          >
            ล้างข้อมูล
          </span>
        </div>
      </BoxSelect>
    </BoxSearch>
  )
}
const BoxSelect = styled.div`
  margin-top: 5px;
  .date-picker,
  .ant-picker-input > input {
    color: #f36b24 !important;
  }
  .ant-picker-range .ant-picker-input > input::placeholder {
    /* Change the placeholder color to red */
    color: #ffc4aa !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`
