import React from 'react'
import { Table as TB } from 'antd'
import { BoxTable, BlankContainer } from './table.style'
import EmtyData from 'assets/images/svg/Search Empty.svg'
const TableCustom = props => {
  const {
    columns,
    dataSource,
    onRow,
    noData,
    noDataText,
    dataSourceDefault,
    expandedRowRender,
    pagination,
    getRowClassName,
    rowSelection,
    expandable,
    scroll,
    isLoading,
  } = props
  const CustomEmptyComponent = () => (
    <div>
      <img src={EmtyData} />
      <p>{noDataText}</p>
    </div>
  )
  const defaultFooter = () => pagination
  return (
    <>
      {dataSourceDefault && (
        <BoxTable>
          <TB
            className="custom-table"
            rowClassName="custom-row"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{
              x: 2000,
              y: 1000,
            }}
          />
        </BoxTable>
      )}
      {!noData && (
        <BoxTable>
          <TB
            className="custom-table"
            rowClassName={(record, index) => getRowClassName && getRowClassName(record, index)}
            columns={columns}
            dataSource={dataSource}
            locale={{ emptyText: <CustomEmptyComponent /> }}
            pagination={pagination ? pagination : false}
            rowKey="id"
            loading={isLoading && isLoading}
            rowSelection={rowSelection && rowSelection}
            expandable={expandable && expandable}
            onRow={(record, rowIndex) => ({
              onClick: event => {
                onRow && onRow(record, event)
              },
              ...onRow,
            })}
            footer={defaultFooter}
            scroll={scroll && scroll}
          />
        </BoxTable>
      )}
    </>
  )
}

export default TableCustom
