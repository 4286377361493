import React from 'react'
import styled from 'styled-components'

const ProgressChart = ({
  data,
  timeline,
  MaxwidthProgress,
  MinwidthProgress = '500px',
  NotdefaultColor,
  width,
  height,
  fontSize,
  minWidth,
  margin,
  isPrints,
  PgHeight,
}) => {
  const departMentColor = {
    สำนักช่าง: '#1D19EC',
    สำนักคลัง: '#FFA643',
    สำนักการประปา: '#56CCF2',
    สำนักการศึกษา: '#F2F2F2',
    กองสวัสดิการสังคม: '#27AE60',
    กองการเจ้าหน้าที่: '#EC82F8',
    สำนักงานปลัดเทศบาล: '#EB5757',
    กองยุทธศาตร์และงบประมาณ: '#F2C94C',
    สำนักสาธารณสุขและสิ่งแวดล้อม: '#9B51E0',
  }

  const AllProgessColor = {
    สำนักช่าง: '#CDCEFA',
    สำนักคลัง: '#FFF6EB',
    สำนักการประปา: '#D2EDF6',
    สำนักการศึกษา: '#F2F2F2',
    กองสวัสดิการสังคม: '#D9FFE9',
    กองการเจ้าหน้าที่: '#FDF2FE',
    สำนักงานปลัดเทศบาล: '#FECCCC',
    กองยุทธศาตร์และงบประมาณ: '#F9EDC7',
    สำนักสาธารณสุขและสิ่งแวดล้อม: '#EDF',
  }

  const checkColorStatusText = val => {
    if (val === 'received') {
      return '#915EFF'
    }
    if (val === 'in progress') {
      return '#FFBB0B'
    }
    if (val === 'done') {
      return '#13AFA6'
    }
    if (val === 'outside the area') {
      return '#3F54D1'
    }
    if (val === 'tracking') {
      return '#F36B24'
    }
    if (val === 'over duedate') {
      return '#F85858'
    }
    if (val === 'transfer') {
      return '#717171'
    }
  }

  const checkStatusText = val => {
    if (val === 'received') {
      return 'รับเรื่อง'
    }
    if (val === 'in progress') {
      return 'อยู่ระหว่างดำเนินการ'
    }
    if (val === 'done') {
      return 'ดำเนินการแล้วเสร็จ'
    }
    if (val === 'outside the area') {
      return 'ไม่อยู่ในความรับผิดชอบ'
    }
    if (val === 'tracking') {
      return 'ติดตาม'
    }
    if (val === 'over duedate') {
      return 'เกินระยะเวลากำหนด'
    }
    if (val === 'transfer') {
      return 'โอนย้ายหน่วยงาน'
    }
  }

  return (
    <>
      {timeline ? (
        <div>
          {data?.map((item, index) => {
            return (
              <div>
                <Boxflex
                  key={index}
                  isPrints={isPrints}
                  Margin={timeline ? '0' : '40px 0'}
                  className={timeline && 'time-line'}
                >
                  <div style={{ display: 'grid', gridTemplateColumns: isPrints ? '60px 50px' : '150px 50px' }}>
                    <Text fontSize={fontSize}>{checkStatusText(item?.status_name)}</Text>
                    <TimelineWrapper>
                      <TimelineEvent isPrints={isPrints} DtColor={checkColorStatusText(item?.status_name)} />
                      {/* {index !== data?.length - 1 && ( */}
                      <>
                        <LineBlack isShow={index !== data?.length - 1} />
                        <LineBlack isShow={index !== data?.length - 1} />
                        <LineBlack isShow={index !== data?.length - 1} />
                        <LineBlack isShow={index !== data?.length - 1} />
                      </>
                      {/* )} */}
                    </TimelineWrapper>
                  </div>
                  <Progress
                    PgHeight={height}
                    PgColor={AllProgessColor[item]}
                    MinwidthProgress={MinwidthProgress}
                    MaxwidthProgress={MaxwidthProgress}
                    PgWidth={width}
                  >
                    <Bar
                      className="men"
                      fontSize={10}
                      percen={item?.percent}
                      color={checkColorStatusText(item?.status_name)}
                    ></Bar>
                  </Progress>

                  <Boxnum fontSize={fontSize}>
                    {item?.total}{' '}
                    <span
                      style={{ fontSize: isPrints ? '10px' : '12px', color: checkColorStatusText(item?.status_name) }}
                    >
                      ({item?.percent}%)
                    </span>
                  </Boxnum>
                </Boxflex>
              </div>
            )
          })}
        </div>
      ) : (
        <div>
          {data?.map((item, index) => (
            <Boxflex key={index} Margin={timeline ? '0' : '40px 0'}>
              <BoxDepart minWidth={minWidth}>
                <Dotstar DtColor={NotdefaultColor ? NotdefaultColor[item] : departMentColor[item]} />
                <div>{item}</div>
              </BoxDepart>

              <Progress PgColor={NotdefaultColor ? NotdefaultColor[item] : AllProgessColor[item]} PgHeight={PgHeight}>
                <Bar
                  className="men"
                  fontSize={10}
                  percen={30}
                  color={NotdefaultColor ? NotdefaultColor[item] : departMentColor[item]}
                >
                  10%
                </Bar>
              </Progress>

              <Boxnum fontSize={fontSize}>4,096</Boxnum>
            </Boxflex>
          ))}
        </div>
      )}
    </>
  )
}

export default ProgressChart

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`

const TimelineEvent = styled.div`
  width: ${props => (props.isPrints ? '10px' : '18px')};
  height: ${props => (props.isPrints ? '10px' : '18px')};
  background: ${props => props.DtColor || '#ededed'};
  border-radius: 50%;
`
const LineBlack = styled.div`
  height: 10px;
  width: 2px;
  background-color: ${props => (!props?.isShow ? '#ffff' : '#aba9a8')};
  margin: 1px;
`

const Text = styled.div`
  display: flex;
  align-items: start;
  margin: 10px 10px 0 0;
  font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
`

const Boxnum = styled.div`
  margin: 0 10px;
  font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
`

const Dotstar = styled.div`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  margin: 0 10px;
  background: ${props => props.DtColor || '#ededed'};
`

const BoxDepart = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: ${props => (props.minWidth ? props.minWidth : '200px')};
`

const Boxflex = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => (props.Margin ? props.Margin : '40px 0')};
  &.time-line {
    display: grid;
    grid-template-columns: ${props => (props.isPrints ? '1fr 2fr 1fr' : '200px 3fr 1fr')};
  }
`

const Progress = styled.div`
  width: ${props => (props.PgWidth ? props.PgWidth : '100%')};
  height: ${props => (props.PgHeight ? props.PgHeight : '44px')};
  border-radius: 10px;
  box-shadow: 0 0 3px #b0b0;
  background-color: #fecccc;
  display: flex;
  overflow: hidden;
  background: ${props => props.PgColor || '#ededed'};
`

export const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${props => props.percen || 0}%;
  color: white;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || 10}px;
  line-height: 16px;
  background: ${props => props.color || '#ededed'};
`
