import React from 'react'
import ImportPc from './indexPc'
import ImportMobile from './indexMobile'
import styled from 'styled-components'

export default function Index(props) {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  console.log(width, height)
  return (
    <Content>
      {width < 900 ? <ImportMobile {...props} /> : <ImportPc {...props} />}
      {/* <div className="view-pc">
        <ImportPc {...props} />
      </div>
      <div className="view-mobile">
        <ImportMobile {...props} />
      </div> */}
    </Content>
  )
}
const Content = styled.div``
