import React, { useState, useEffect } from 'react'
import * as F from './FormInput.style'
import { CustomPopover } from './FormInput.style'
import { Input } from 'components/Inputs'
import InputFormUser from 'components/input/inputFormUser'
import { InputCustom } from 'components/input'
import { useFormik } from 'formik'
import { defaultValues, Schema, SchemaUpdate } from './container'
import DropdownForm from 'components/Inputs/DropdownForm'
import DropdownMultiple from 'components/Inputs/DropdownMultiple'
import UploadFile from 'components/Inputs/UploadFile'
import iconInfo from 'assets/images/svg/icon-info.svg'
import Viewmap from './viewmap'
import MapButton from './GoogleMap/Map'
import { getData, getMe, postData, putData } from 'core/action/collection'
import { masterdata, complaint } from 'core/schemas'
import { Select, Button, Space, Popover, Tooltip } from 'antd'
import DatePicker from 'components/input/DatePicker'
import ThaiDatePicker from './ThaiDatePicker'
import moment from 'moment'
import './viewmap.css'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'

import { ReactComponent as IconTitle } from 'assets/images/svg/icon-title-form-input-conplaint.svg'
import IconClear from 'assets/images/svg/icon-close-drawer.svg'
import styled from 'styled-components'

const FormInput = ({ closeInputDrawer, getListData, openNotification, setLeaveModalOpen }) => {
  const getToken = localStorage.getItem('access_token')

  const [userData, setUserData] = useState('')

  const [formattedDate, setFormattedDate] = useState('')
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategory] = useState([])
  const [urgency, setUrgency] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [categoryId, setCategoryId] = useState('')

  const [cover, setCover] = React.useState([])
  const [locationFromMap, setLocationFromMap] = React.useState({})
  const [submit, setsubmit] = React.useState({})
  const [createSuccess, setCreateSuccess] = useState(false)
  const [locationme, setlocationme] = useState({})

  const [complaintFromApi, setComplaintFromApi] = useState('')
  const [comfirmModalOpen, setConfirmModalOpen] = useState(false)

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const formikUpdate = useFormik({
    initialValues: { duedate_at: '', urgency_id: '', department_data: [] },
    validationSchema: SchemaUpdate,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  useEffect(() => {
    categoryId !== '' && getSubCategory()
  }, [categoryId])

  const getMeData = async () => {
    const res = await getMe({ token: getToken })

    if (res) {
      if (res?.code === 200) {
        setUserData(res.data)
      }
    }
  }

  const checkimg = cover.length > 0 ? true : false
  const checkmap = Object.keys(locationme).length === 0 || locationme == {} ? false : true
  const isValidForm = formik.isValid && formik.dirty && checkimg && checkmap

  const isValidUpdate = formikUpdate.isValid && formikUpdate.dirty

  const getCategories = async () => {
    const res = await getData({
      schema: `${masterdata}/categories`,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)
    if (res) {
      const newData = res.data
      setCategories(newData)
    }
  }

  const getSubCategory = async () => {
    const res = await getData({
      schema: `${masterdata}/subcategories/` + categoryId,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setSubCategory(newData)
    }
  }

  const getUrgency = async () => {
    const res = await getData({
      schema: `${masterdata}/urgency`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setUrgency(newData)
    }
  }

  const getOrganizations = async () => {
    const res = await getData({
      schema: `${masterdata}/organizations`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setOrganizations(newData)
    }
  }

  const optionsCategories = categories?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    icon_url: items?.icon_url,
    key: index,
  }))

  const optionsSubCategories = subCategories?.map((items, index) => ({
    value: items?.ID,
    label: items?.name,
    key: index,
  }))

  const optionsUrgency = urgency?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    key: index,
  }))

  const optionsOrganizations = organizations?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    key: index,
  }))

  const DatetimeNowThai = () => {
    const currentDate = new Date()

    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      eraDisplay: 'narrow',
    }

    const thaiLocale = 'th-TH'

    const formattedDate = new Intl.DateTimeFormat(thaiLocale, options).format(currentDate)
    setFormattedDate(formattedDate)
  }

  useEffect(() => {
    getMeData()
    DatetimeNowThai()
    getCategories()
    getUrgency()
    getOrganizations()
  }, [])

  useEffect(() => {
    categoryId !== '' && getSubCategory()
  }, [categoryId])

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')
    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const onClickNext = () => {
    // handlerSubmit()
    setCreateSuccess(true)
  }

  const handlerSubmit = async () => {
    const phone = formik.values.mobile_number.replace(/-/g, '')

    const {
      first_name,
      last_name,
      mobile_number,
      group_id,
      address,
      province_id,
      district_id,
      sub_district_id,
      postcode,
      category_id,
      complaint_name,
      description,
      sub_category_id,
    } = formik.values

    const dataurl = []

    cover.map(item => {
      dataurl.push('https://' + item.urlkey)
    })

    const tmpValues = {
      address: '',
      category_id: category_id,
      channel: formik.values.channel,
      complaint_name: complaint_name,
      coordinates: {
        latitude: locationme.lat,
        longitude: locationme.lng,
      },
      description: description,
      line_id: '',
      media_url: dataurl,
      mobile_number: phone,
      name: {
        first_name: first_name,
        last_name: last_name,
      },
      org_id: '19118133238380',
      status_data: [
        {
          description: '',
          name: 'received',
        },
      ],
      sub_category_id: sub_category_id,
    }
    const res = await postData(`${complaint}/complaint`, tmpValues)
    if (res.code === 201) {
      const complaintId = res?.data?.id
      setsubmit({ status: 1 })
      formik.resetForm()
      formikUpdate.resetForm()
      setlocationme({
        lat: '',
        lng: '',
      })
      setCover([])

      const getComplaintById = async () => {
        const resComplaintId = await getData({
          schema: `${complaint}/complaint/` + complaintId,
          params: {},
          url: '',
          baseURL: '',
        })

        if (resComplaintId) {
          if (resComplaintId.code === 200) {
            const payload = {
              address: resComplaintId?.data?.address,
              category_id: resComplaintId?.data?.category?.id,
              channel: resComplaintId?.data?.channel,
              complaint_name: resComplaintId?.data?.complaint_name,
              coordinates: {
                latitude: resComplaintId?.data?.coordinates?.latitude,
                longitude: resComplaintId?.data?.coordinates?.longitude,
              },
              department_data: formikUpdate?.values?.department_data,
              description: formikUpdate?.values?.description,
              district_id: resComplaintId?.data?.district_id,
              duedate_at: formikUpdate?.values?.duedate_at,
              line_id: resComplaintId?.data?.line_id,
              media_url: resComplaintId?.data?.media_url,
              mobile_number: resComplaintId?.data?.mobile_number,
              name: {
                first_name: resComplaintId?.data?.name?.first_name,
                last_name: resComplaintId?.data?.name?.last_name,
              },
              org_id: '19118133238380',
              province_id: resComplaintId?.data?.province_id,
              sub_category_id: resComplaintId?.data?.sub_category_data?.id,
              sub_district_id: resComplaintId?.data?.sub_district_id,
              urgency_id: formikUpdate?.values?.urgency_id,
            }

            const res = await putData(`${complaint}/complaint`, resComplaintId?.data?.id, payload)

            if (res) {
              if (res?.code === 201 || 200) {
                formik.resetForm()
                formikUpdate.resetForm()
                openNotification()
                closeInputDrawer()
                getListData()
              } else {
              }
            }
          }
        }
      }

      getComplaintById()
    }
  }

  const handlerSubmitUpdate = async () => {
    const payload = {
      address: complaintFromApi?.address,
      category_id: complaintFromApi?.category?.id,
      channel: complaintFromApi?.channel,
      complaint_name: complaintFromApi?.complaint_name,
      coordinates: {
        latitude: complaintFromApi?.coordinates?.latitude,
        longitude: complaintFromApi?.coordinates?.longitude,
      },
      department_data: formikUpdate?.values?.department_data,
      description: formikUpdate?.values?.description,
      district_id: complaintFromApi?.district_id,
      duedate_at: formikUpdate?.values?.duedate_at,
      line_id: complaintFromApi?.line_id,
      media_url: complaintFromApi?.media_url,
      mobile_number: complaintFromApi?.mobile_number,
      name: {
        first_name: complaintFromApi?.name?.first_name,
        last_name: complaintFromApi?.name?.last_name,
      },
      org_id: '19118133238380',
      province_id: complaintFromApi?.province_id,
      sub_category_id: complaintFromApi?.sub_category_data?.id,
      sub_district_id: complaintFromApi?.sub_district_id,
      urgency_id: formikUpdate?.values?.urgency_id,
    }

    const res = await putData(`${complaint}/complaint`, complaintFromApi?.id, payload)

    if (res) {
      if (res?.code === 201 || 200) {
        formik.resetForm()
        openNotification()
        closeInputDrawer()
        getListData()
      } else {
      }
    }
  }

  const handleDatePickerChange = (date, dateString) => {
    const unixTimestamp = moment(date).unix()

    formikUpdate.setFieldValue('duedate_at', unixTimestamp)
  }

  const onOpenConfirmModal = () => {
    setConfirmModalOpen(true)
  }

  const onCloseConfirmModal = () => {
    setConfirmModalOpen(false)
  }

  return (
    <>
      {!createSuccess ? (
        <>
          <div style={{ height: '4.5rem', position: 'relative' }}>
            <div className="close-icon" onClick={() => setLeaveModalOpen(true)}>
              <img src={IconClear} alt="" />
            </div>
          </div>
          <F.Box>
            <F.BoxTitle>
              <IconTitle />
              <F.TextTitle>นำเข้าเรื่องร้องเรียน</F.TextTitle>
            </F.BoxTitle>
            <InputFormUser
              label="วันที่"
              name="date"
              placeholder="กรอกชื่อ"
              type="text"
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={255}
              values={formattedDate}
              defaultValues={formattedDate}
              disabled={true}
              labelSize="12px"
            />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ width: '100%', marginRight: '24px' }}>
                <InputFormUser
                  label="ชื่อ"
                  name="first_name"
                  placeholder="โปรดระบุชื่อ ของผู้ร้องเรียน"
                  type="text"
                  required={true}
                  errors={formik.errors}
                  touched={formik.touched}
                  form={formik}
                  status={formik.status}
                  maxLength={255}
                  labelSize="12px"
                />
              </div>
              <div style={{ width: '100%' }}>
                <InputFormUser
                  label="นามสกุล"
                  name="last_name"
                  placeholder="โปรดระบุนามสกุล ของผู้ร้องเรียน"
                  type="text"
                  required={true}
                  errors={formik.errors}
                  touched={formik.touched}
                  form={formik}
                  status={formik.status}
                  maxLength={255}
                  labelSize="12px"
                />
              </div>
            </div>
            <InputFormUser
              label="เบอร์โทร"
              name="mobile_number"
              placeholder="0XX-XXX-XXXX"
              type="text"
              required={true}
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={12}
              onChange={e => onPhoneNumberChange(e)}
              labelSize="12px"
            />
            <div style={{ width: '90%', marginBottom: '1rem' }}>
              <F.LabelText>
                ประเภทเรื่องร้องเรียน
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </F.LabelText>
              <F.StyleDropdown>
                <DropdownForm
                  title="ประเภทเรื่องร้องเรียน"
                  placeholder="กรุณาเลือก"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('category_id', value)
                    setCategoryId(value)
                    formik.setFieldValue('sub_category_id', '')
                  }}
                  className="big-input"
                  options={optionsCategories}
                  value={formik?.values?.category_id}
                />
              </F.StyleDropdown>
            </div>
            <div style={{ width: '90%', marginBottom: '1rem' }}>
              <F.LabelText>
                ประเภทย่อย
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </F.LabelText>
              <F.StyleDropdown>
                <DropdownForm
                  title="ประเภทย่อย"
                  placeholder="กรุณาเลือก"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('sub_category_id', value)
                  }}
                  className="big-input"
                  options={optionsSubCategories}
                  value={formik?.values?.sub_category_id}
                />
              </F.StyleDropdown>
            </div>

            {formik.values.category_id === '1735509869718401030' && (
              <InputFormUser
                label="เลขที่ผู้ใช้น้ำ"
                name="number_user_water"
                placeholder="เลขที่ผู้ใช้น้ำ"
                type="text"
                required={true}
                errors={formik.errors}
                touched={formik.touched}
                form={formik}
                status={formik.status}
                maxLength={255}
                labelSize="12px"
              />
            )}

            <InputFormUser
              label="เรื่องร้องเรียน"
              name="complaint_name"
              placeholder="โปรดระบุเรื่องร้องเรียนสั้นๆ เช่น “น้ำไม่ไหล” ,“ต้นไม้เกี่ยวพันสายไฟ”"
              type="text"
              required={true}
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={255}
              labelSize="12px"
            />
            <InputCustom
              label="รายละเอียดเพิ่มเติม (ถ้ามี)"
              name="description"
              placeholder="กรอกรายละเอียด"
              type="textarea"
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              maxLength={255}
              // disabled
            />
            <div style={{ width: '90%', marginBottom: '1rem' }}>
              <F.LabelText>
                ช่องทางการนำเข้าข้อมูล
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </F.LabelText>
              <F.StyleDropdown>
                <DropdownForm
                  title="ช่องทางการนำเข้าข้อมูล"
                  placeholder="กรุณาเลือก"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('channel', value)
                  }}
                  className="big-input"
                  options={[
                    { label: 'ไลน์', value: 'line' },
                    { label: '1132', value: '1132' },
                    { label: 'ผ่านหน่วยงาน', value: 'office' },
                    { label: 'Traffy Fondue', value: 'traffy fondue' },
                    { label: 'เว็บไซต์', value: 'เว็บไซต์' },
                    { label: ' Walk in', value: 'walk in' },
                  ]}
                  value={formik?.values?.channel}
                />
              </F.StyleDropdown>
            </div>
            <div className="row-data">
              <div className="col-1">
                <div>
                  <F.LabelTextUpload>อัปโหลดรูปภาพหรือวิดีโอ</F.LabelTextUpload>
                  <span
                    style={{ fontSize: '10px', color: '#001f34', fontFamily: 'Prompt' }}
                  >{` (อัปโหลดได้ไม่เกิน 3 ไฟล์)`}</span>
                  <span className="red-star"> * </span>
                  <span className="" style={{ float: 'right' }}>
                    {/* <CustomPopover
                      placement="leftTop"
                      content={
                        <div style={{ marginTop: '6px' }}>
                          <p>ภาพที่อัปโหลดต้องเป็น “ภาพที่เกี่ยวข้องกับเหตุการณ์”</p>
                        </div>
                      }
                      trigger="click"
                    >
                      <img src={iconInfo} style={{ width: 16, height: 16, cursor: 'pointer' }} />
                    </CustomPopover> */}
                    <F.CustomTooltip
                      placement="leftTop"
                      title="ภาพที่อัปโหลดต้องเป็น “ภาพที่เกี่ยวข้องกับเหตุการณ์”"
                      // defaultOpen={true}
                      // open={true}
                    >
                      <img src={iconInfo} style={{ width: 16, height: 16, cursor: 'pointer' }} />
                    </F.CustomTooltip>
                  </span>
                  <F.CustomUploadfile>
                    <UploadFile
                      accept={{
                        'image/jpeg': [],
                        'image/png': [],
                        'video/mp4': [],
                      }}
                      keys={1}
                      files={cover}
                      isCrop={false}
                      maxFile={3}
                      maxSize={1024 * 1024 * 5}
                      typeUpload={'imagevdo'}
                      text={'ไฟล์ที่อัปโหลดต้องเป็นไฟล์นามสกุล JPEG , PNG , MP4 และ มีขนาดแต่ละไฟล์ไม่เกิน 10 MB'}
                      // abortController={abortController}
                      onFileRetuen={(files, file) => {
                        let _image = [...files]
                        _image.push(file)
                        setCover(_image)
                      }}
                      onFileRemove={(files, res) => {
                        // setAbortController(new AbortController())
                        let _image = [...files]
                        _image = _image.filter((r, i) => i !== res.index)
                        setCover(_image)
                      }}
                    />
                  </F.CustomUploadfile>
                </div>
              </div>
            </div>

            <div>
              <div style={{ marginBottom: '10px' }}>สถานที่แจ้งเรื่อง</div>
              <Viewmap setLocationFromMap={setlocationme} />
            </div>
            <div
              style={{ marginTop: '40px', width: '100%', height: 'auto', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                htmlType="submit"
                disabled={!isValidForm}
                // onClick={() => {
                //   setCreateSuccess(true)
                // }}
                className="bt-next"
                onClick={() => onClickNext()}
              >
                ถัดไป
              </Button>
            </div>
          </F.Box>
        </>
      ) : (
        <F.Box>
          <h2 className="title">กำหนดรายละเอียดเรื่องร้องเรียน</h2>
          <div style={{ width: '90%', marginBottom: '1rem' }}>
            <F.LabelText>
              วันที่กำหนดเสร็จ
              <div className="red-star" style={{ paddingLeft: '2px' }}>
                *
              </div>
            </F.LabelText>
            <F.StyleDropdown>
              <ThaiDatePicker onChange={handleDatePickerChange} />
            </F.StyleDropdown>
          </div>
          <div style={{ width: '90%', marginBottom: '1rem' }}>
            <F.LabelText>
              สถานะความเร่งด่วน
              <div className="red-star" style={{ paddingLeft: '2px' }}>
                *
              </div>
            </F.LabelText>
            <F.StyleDropdown>
              <DropdownForm
                title="สถานะความเร่งด่วน"
                placeholder="ด่วนมาก"
                showSearch={false}
                required={true}
                onChange={value => {
                  formikUpdate.setFieldValue('urgency_id', value)
                }}
                className="big-input"
                options={optionsUrgency}
              />
            </F.StyleDropdown>
          </div>
          <div style={{ width: '90%', marginBottom: '1rem' }}>
            <F.LabelText>
              หน่วยงานที่รับผิดชอบ
              <div className="red-star" style={{ paddingLeft: '2px' }}>
                *
              </div>
            </F.LabelText>
            <F.StyleDropdown>
              <DropdownMultiple
                title="หน่วยงานที่รับผิดชอบ"
                placeholder="กรุณาเลือกหน่วยงาน"
                mode="multiple"
                showSearch={false}
                required={true}
                onChange={value => {
                  formikUpdate.setFieldValue('department_data', value)
                }}
                className="big-input"
                options={optionsOrganizations}
              />
            </F.StyleDropdown>
          </div>
          {/* <div style={{ width: '90%', marginBottom: '1rem' }}>
            <F.LabelText>
              ผู้รับผิดชอบ
              <div className="red-star" style={{ paddingLeft: '2px' }}>
                *
              </div>
            </F.LabelText>
            <F.StyleDropdown>
              <DropdownForm
                title="ผู้รับผิดชอบ"
                placeholder="กรุณาเลือกผู้รับผิดชอบ"
                showSearch={false}
                required={true}
                //   onChange={value => {
                //     formik.setFieldValue('category_id', value)
                //   }}
                className="big-input"
                options={[{ value: '1', label: '1', key: 0 }]}
                //   value={formik?.values?.category_id}
              />
            </F.StyleDropdown>
          </div> */}
          <InputCustom
            label="รายละเอียดเพิ่มเติม (แสดงผลที่ Line OA ผู้ใช้งาน)"
            name="description"
            placeholder="ระบุเหตุผลเพื่อแสดงผลที่ผู้ใช้งาน"
            type="textarea"
            errors={formikUpdate.errors}
            touched={formikUpdate.touched}
            form={formikUpdate}
            maxLength={255}
            // disabled
          />

          <F.BoxButton>
            <Space>
              <BtSubmit onClick={() => setLeaveModalOpen(true)} bttype="cancel">
                ยกเลิก
              </BtSubmit>
              <BtSubmit onClick={() => onOpenConfirmModal()} disabled={!isValidUpdate}>
                ยืนยัน
              </BtSubmit>
            </Space>
          </F.BoxButton>
        </F.Box>
      )}

      <F.CustomModal
        transitionName=""
        centered
        open={comfirmModalOpen}
        onOk={() => {
          setConfirmModalOpen(false)
          handlerSubmit()
        }}
        onCancel={() => setConfirmModalOpen(false)}
        closable={false}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        zIndex={1100}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p
          style={{
            color: '#001F34',
            fontSize: '16px',
            marginBottom: '2px',
            fontWeight: '400',
          }}
        >
          ยืนยันการนำเข้าข้อมูลเรื่องร้องเรียน
        </p>
        <p
          style={{
            color: '#597081',
            fontSize: '12px',
            fontWeight: '300',
          }}
        >
          หากคุณนำเข้าข้อมูลเรื่องร้องเรียนนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
        </p>
      </F.CustomModal>
    </>
  )
}

export default FormInput

const BtSubmit = styled(Button)`
  background-color: ${props => (props.bttype === 'cancel' ? '#fff' : '#fc6521')};
  border: 1px solid #fc6521 !important;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${props => (props.bttype === 'cancel' ? '#fc6521' : 'white')};
  /* line-height: 20px; */
  /* padding: 18px 8px; */
  width: 120px;
  height: 48px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: #fc6521 !important;
    background-color: ${props => (props.bttype === 'cancel' ? 'white' : '#fc6521')} !important;
    color: ${props => (props.bttype === 'cancel' ? '#fc6521' : 'white')};
  }

  &:disabled {
    background-color: #fee0d3 !important;
    border-color: #fee0d3 !important;
    color: white !important;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background-color: #fee0d3 !important;
    border-color: #fee0d3 !important;
    color: white !important;
    cursor: not-allowed;
  }

  /* @media only screen and (max-width: 768px) {
    height: 48px;
  }

  @media only screen and (max-width: 375px) {
    height: 48px;
  } */
`
