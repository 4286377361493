import React from 'react'
import styled from 'styled-components'

import iconEmpryChat from 'assets/images/svg/empty-chat.svg'

const EmptyChat = () => {
  return (
    <>
      <MessageContainer>
        <img src={iconEmpryChat} alt="empty" />
        <Text>เลือกบทสนทนา เพื่อดูข้อมูล</Text>
      </MessageContainer>
    </>
  )
}

export default EmptyChat

const MessageContainer = styled.div`
  /* grid-column: 2; */
  background-color: #fffbf8;
  border-radius: 0px 24px 24px 0px;
  /* width: 780px; */
  height: 630px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Text = styled.div`
  font-family: 'Prompt', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #ffc4aa;
  margin-top: 20px;
`
