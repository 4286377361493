import List from './list'
import View from './view'
import Form from './form'
import UpdateStatusPc from './update-status/view'
import FormUpdateStatus from './update-status/form'
import FormUpdateStatusResponsive from './update-status-responsive/form'
import Group from './group'

const Complaint = { List, View, Form, UpdateStatusPc, FormUpdateStatus, FormUpdateStatusResponsive, Group }

export default Complaint
