import React from 'react'
import ReactApexChart from 'react-apexcharts'

const LinCharts = ({ xaxis, series, width, height }) => {
  const options = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: ['#FFA500'],
    stroke: {
      curve: 'straight',
      width: 2,
    },
    markers: {
      size: 5,
      strokeColors: '#ffffff',
      strokeWidth: 2,
    },
    grid: {
      borderColor: '#e7e7e7',
      strokeDashArray: 5,
      yaxis: {
        lines: { show: true },
      },
    },
    xaxis: {
      categories: xaxis,
      title: {
        text: 'คะแนน',
        rotate: 0,
        floating: true,
        offsetX: 180,
        offsetY: -47,
        style: {
          color: '#C4C4C4',
          fontSize: '8px',
          fontFamily: 'Prompt',
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Prompt',
          fontWeight: 400,
        },
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        fontFamily: 'Prompt',
      },
      offsetY: 10,
      tooltip: {
        enabled: false,
      },
    },

    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        formatter: value => value.toFixed(0),
      },
      title: {
        text: 'จำนวน (คน)',
        rotate: 0,
        floating: true,
        offsetX: 50,
        offsetY: -130,
        style: {
          color: '#C4C4C4',
          fontSize: '8px',
          fontFamily: 'Prompt',
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `
        <div style="padding: 10px; background-color: #fff;">
          <span style="color: #FFA500;">●</span>
        <span style="font-weight: 500; font-size: 12px; color: #150F0B;">จำนวน</span>
        <span style="font-size: 10px; color: #717171; font-weight: 400;">${series[0][dataPointIndex]} คะแนน</span>
        </div>
      
        `
      },
    },
    legend: {
      show: false,
    },
  }

  const CustomLegend = ({ series }) => (
    <div style={{ textAlign: 'left' }}>
      {series &&
        series[0]?.data?.map((value, index) => (
          <div
            key={index}
            style={{
              fontFamily: 'Prompt',
              lineHeight: '10px',
              marginBottom: '24px',
              display: 'flex',
            }}
          >
            <div
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: '#FFA500',
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '5px',
                verticalAlign: 'middle',
              }}
            />

            <span
              style={{
                fontSize: '10px',
                color: '#717171',
                marginLeft: '10px',
                fontWeight: 400,
              }}
            >
              {`${index + 1} คะแนน`}
            </span>
            <div
              style={{
                fontWeight: 500,
                fontSize: '10px',
                color: '#150F0B',
                marginLeft: '8px',
                verticalAlign: 'middle',
              }}
            >
              {value} คน
            </div>
          </div>
        ))}
    </div>
  )

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '480px 200px' }}>
      <ReactApexChart
        options={options}
        series={series ? series : []}
        type="line"
        width={width ? width : '420'}
        height={height ? height : '300'}
      />
      <CustomLegend series={series ? series : []} />
    </div>
  )
}

export default LinCharts
