import { Button, Modal } from 'antd'
import styled from 'styled-components'
import BgLoginMobile from 'assets/images/png/bg-login-mobile.png'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* min-height: 720px; */

  background-image: ${props => (props.url ? `url(${props.url})` : '#FCFCFC')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
  }

  @media only screen and (max-width: 375px) {
    background-image: url(${BgLoginMobile});
    background-size: cover;
  }

  @media only screen and (max-width: 768px) {
    background-image: url(${BgLoginMobile});
    background-size: cover;
  }
`

export const BoxContent = styled.div`
  width: 700px;
  height: 582px;
  border-radius: 24px;
  margin-top: 4em;
  padding: 40px;
  border: 1px solid;
  border-image-source: linear-gradient(232.66deg, #ffffff -7.67%, rgba(255, 255, 255, 0) 107%);
  background: linear-gradient(232.66deg, #ffffff -7.67%, rgba(255, 255, 255, 0) 107%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));

  @media only screen and (max-width: 768px) {
    width: 390px;
    height: 460px;
    padding: 40px 24px;

    .number-page {
      width: 32px;
      height: 32px;
    }

    .icon-mail {
      width: 40px;
      height: 40px;
    }
  }

  @media only screen and (max-width: 375px) {
    width: 390px;
    height: 460px;
    padding: 40px 24px;

    .number-page {
      width: 32px;
      height: 32px;
    }

    .icon-mail {
      width: 40px;
      height: 40px;
    }
  }
`

export const BoxInput = styled.div`
  width: 526px;
  height: 321px;
  /* background-color: aqua; */

  h3 {
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 24px;
    color: #150f0b;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .toemail {
    font-family: 'Prompt';
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;

    h3 {
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 1.438rem;
    }

    .toemail {
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 375px) {
    width: 100%;
    height: auto;

    h3 {
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 1.438rem;
    }

    .toemail {
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 1rem;
    }
  }
`

export const BtSubmit = styled(Button)`
  background: #fc6521;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  line-height: 20px;
  padding: 18px 8px;
  width: 100%;
  min-height: 56px;
  border: none;
  cursor: pointer;

  ${props =>
    props.type === 'primary' &&
    `
      background: #FC6521;

       &:hover,
        :focus {
         background: #FC6521 !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #e2e5f8;
    border-color: #e2e5f8;
    color: white;
    cursor: not-allowed;
  }

  &:hover,
  :focus {
    background: #fc6521;
    color: white;
  }
`

export const ModalConfirmEmail = styled(Modal)`
  font-family: 'Prompt';
  margin-top: auto;
  margin-bottom: auto;
  /* background-color: #333; */
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-wrap {
    top: 160px !important;
  }

  .ant-modal-content {
    background-color: #ffffff;
    width: 472px;
    height: 335px;
    border-radius: 40px;
    padding: 40px;
  }

  .ant-modal-body {
    padding: 0px;
    text-align: center;
    /* display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; */

    svg {
      width: 100px;
      height: 100px;
    }

    .title {
      color: #150f0b;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 20px;
    }

    .description {
      color: #b7b7b7;
      font-family: 'Prompt';
      font-weight: 300;
      font-size: 14px;
    }
  }
`

export const BtSubmitModal = styled(Button)`
  background: #f36b24;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 20px;
  width: 100%;
  min-height: 40px;
  border: none;
  cursor: pointer;
  margin-top: 25px;

  @media only screen and (max-width: 768px) {
    margin-top: 50px;
  }

  @media only screen and (max-width: 375px) {
    margin-top: 50px;
  }

  ${props =>
    props.type === 'primary' &&
    `
      background: #F36B24;

       &:hover,
        :focus {
         background: #F36B24 !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #fee0d3;
    border-color: #fee0d3;
    color: white;
    cursor: not-allowed;

    &:hover,
    :focus {
      background: #fee0d3;
      color: white;
    }
  }

  &:hover,
  :focus {
    background: #f36b24;
    color: white;
  }
`

export const TextErr = styled.div`
  color: red;
  font-size: 12px;
  font-weight: 400;
`

export const Textrender = styled.div`
  font-family: 'Prompt';
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #f36b24 !important;
  /* margin: 1em 150px 0 0; */
`

export const BoxtxtOtp = styled.div`
  margin-top: 10%;
  background: #9ac8bb0d;
  position: relative;
  padding: 20px;
  width: 100%;
  margin-bottom: 10%;
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  color: #9ac8bb;

  font-family: 'Plus Jakarta Sans';
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  .text-enter {
    font-weight: 400;
  }

  @media only screen and (max-width: 1600px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`

export const CustomBtn = styled.div`
  width: 100%;
  .ant-btn-primary {
    background: #00c08b;

    border-radius: 8px;
    border: none;
    color: white;
    width: 100%;

    height: 4.5vh;

    min-height: 40px;
    text-shadow: none;
    box-shadow: none;
  }
`

export const BoxVersion = styled.div`
  width: 30%;
  position: absolute;
  bottom: 3%;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #9ac8bb;
`

export const Textforgot = styled.div`
  color: #00c08b;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-weight: 400;
  text-align: right;
`

export const BoxFormLogin = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* padding: 0 4% 2% 4%; */
  padding: 0 5% 2% 5%;

  .ant-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #d82020;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }
`

export const BoxcustomStyleform = styled.div`
  label {
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #9ac8bb;

    &.label-checkbox {
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: #35746e;
    }
  }

  .ant-form-item-with-help .ant-form-item-explain {
    position: absolute;
    top: 5vh;
  }
  .ant-form-item-label {
    font-weight: 500;
    font-size: 0.83vw;
    line-height: 0.94vw;
    color: #407c76;
  }
  .ant-input {
    border: 1px solid #9ac8bb;

    width: 23vw;
    height: 4.5vh;
    min-height: 40px;
    background: transparent;
    border-radius: 10px;
    color: #407c76;

    padding: 4px 22px;
    :hover {
      border-color: #00c08b;
      border-right-width: 1px;
      z-index: 1;
    }
  }

  .ant-input-affix-wrapper {
    width: 23vw;
    color: #2e3c4f;
    min-height: 40px;

    height: 4.5vh;

    background: transparent;
    border: 1px solid #9ac8bb;
    border-radius: 10px;

    .ant-input {
      border: none !important;
    }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #00c08b;
    border-right-width: 1px;
    z-index: 1;
    box-shadow: none;
  }

  .ant-form-item-explain-error {
    color: #ff4d4f;
    position: absolute;
    top: 3px;
    right: 3px;
  }

  .ant-input::placeholder {
    color: #9ac8bb;
  }

  .ant-checkbox-inner {
    background-color: transparent;
    border: 1px solid #407c76 !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #35746e;
    border: 1px solid #407c76;
  }

  .ant-checkbox-wrapper {
    /* width: 150px; */
    color: #35746e;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 400;

    text-align: left;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: transparent;
    border-color: #ff4d4f;
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
      .ant-input-affix-wrapper-borderless
    ).ant-input-affix-wrapper:hover {
    background: transparent;
    border-color: #ff4d4f;
  }
`

export const BtSubmitOTP = styled(Button)`
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  font-family: 'Prompt';
`
