import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Title,
  ButtonBack,
  BoxTopView,
  ContainerView,
  HeaderboxView,
  TextTile,
  Boxbg,
  BoxDescription,
  BoxOrganize,
  BoxIcon,
  BtnSubmit,
  ModalPreviewImage,
  ModelContent,
  CustomImage,
  Content,
} from './view.style'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import { Button, Space, Tooltip, Form, Input } from 'antd'
import { Spin } from 'antd'
import Viewmap from '../../../viewmap'
import IconCloseModal from 'assets/images/svg/close-modal.svg'
import axios from 'axios'
import { unixToDateThai } from 'util/date-format'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import CardResultBox from './cardResult'
import CardTracking from './CardTracking'
import { checkColorStatusBg, checkColorStatusText, checkStatusText, checkDueDate } from 'pages/Complaint/container'
import Iconpreview from 'assets/images/svg/icon-preview.svg'
import { dePartMentIcon } from '../../../container'
import UserContext from 'core/contexts/userContext'

const UpdateStatuspc = () => {
  const navigate = useNavigate()
  const { meData } = useContext(UserContext)
  const [previewImg, setPreviewImg] = useState(null)
  const [previewVdo, setPreviewVdo] = useState(null)
  const [OpenModalImage, setOpenModalImage] = useState(false)
  const [OpenModalVdo, setOpenModalVdo] = useState(false)
  const [dataComplaint, setDatacomPlaint] = useState([])
  const [mediaShow, setMediaShow] = useState([])
  const [dataTranSaction, setDataTransaction] = useState([])
  const [loading, setLoading] = useState(true)
  const token = localStorage?.getItem('access_token')
  const location = useLocation()
  useEffect(() => {
    getDataId()
  }, [])

  const gropuData = data => {
    if (data) {
      const uniqueDepartmentIds = []
      const groupedData = []
      for (let i = 0; i < data?.length; i++) {
        const item = data[i]
        const { department_id } = item
        if (!uniqueDepartmentIds?.includes(department_id)) {
          uniqueDepartmentIds?.push(department_id)
          groupedData?.push([item])
        } else {
          const index = uniqueDepartmentIds?.indexOf(department_id)
          groupedData[index]?.push(item)
        }
      }
      setDataTransaction(groupedData)
    }
  }

  const getDataId = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
      const compalintId = location?.state?.record

      const resp = await axios.get(`${shemadata}/${compalintId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resp?.status === 200) {
        setDatacomPlaint(resp?.data?.data)
        const dataMedia = resp?.data?.data?.media_url

        setMediaShow(dataMedia)
        const dataTranSaction = resp?.data?.data?.transaction_data

        await gropuData(dataTranSaction)
        setLoading(false)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const LastIndex = data => {
    const NewArray = data && data[data?.length - 1]?.name

    return (
      <div>
        <div
          style={{
            backgroundColor: checkColorStatusBg(NewArray),
            color: checkColorStatusText(NewArray),
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {checkStatusText(NewArray)}
        </div>
      </div>
    )
  }

  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '144px', height: '82px', borderRadius: '8px', margin: '1em' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <img
              style={{ borderRadius: '8px', width: '144px', height: '82px', margin: '10px 5px' }}
              className="show-image click"
              src={item}
              alt="image"
              onClick={() => {
                setOpenModalImage(true)
                setPreviewImg(item)
              }}
            />
          )
        }
      })
    return CardUi
  }

  const CheckBtnSubmit = () => {
    const myData = dataComplaint?.transaction_data?.filter(
      item => item?.department_id === meData?.organize_data?.org_id
    )
    const checkData = myData?.find(item => item?.type === 'after')

    if (checkData) {
      return false
    } else {
      return true
    }
  }

  const onSuccessStatus = async () => {
    const bodyUpdateStatus = {
      description: '',
      name: 'done',
    }

    try {
      const resUpSuccess = await axios.put(
        `${process.env.REACT_APP_BASEURL}/complaint/api/v1/status/${dataComplaint?.id}`,
        bodyUpdateStatus,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if ((resUpSuccess.status === 200) | (resUpSuccess.status === 201)) {
        window.location.reload()
      }
    } catch (error) {}
  }

  const onSuccessStatusIfHaven = async () => {
    const bodyUpdateStatus = {
      description: '',
      name: 'done',
    }

    try {
      const resUpSuccess = await axios.put(
        `${process.env.REACT_APP_BASEURL}/complaint/api/v1/status/${dataComplaint?.id}`,
        bodyUpdateStatus,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if ((resUpSuccess.status === 200) | (resUpSuccess.status === 201)) {
        navigate('/complaint-staff/bof-overview-complaint')
      }
    } catch (error) {}
  }
  const chanelData = {
    ไลน์: 'ไลน์',
    1132: '1132',
    'damrongtham center': 'ศูนย์ดำรงธรรม',
    'traffy fondue': 'Traffy Fondue',
    เว็บไซต์: 'เว็บไซต์',
    'walk in': 'Walk in',
    facebook: 'Facebook',
    other: 'อื่นๆ',
  }

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <Content>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '5px' }}>
            <Space style={{ paddingLeft: '30px' }}>
              <ButtonBack
                shape="circle"
                icon={<IconArrowLeft />}
                onClick={() =>
                  location?.state?.path === 'previous-list'
                    ? navigate('/complaint-staff/bof-overview-complaint')
                    : location?.state?.path === 'previous-view'
                    ? navigate('/complaint-staff/bof-overview-complaint/view', {
                        state: { record: location?.state?.record },
                      })
                    : navigate('/complaint-staff/bof-overview-complaint')
                }
              />
              <Title>อัปเดตผลการดำเนินการ </Title>
            </Space>
          </div>
          <div style={{ margin: '1em 2em' }}>
            <BoxTopView>
              <ContainerView>
                <HeaderboxView>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <Tooltip placement="top" title={dataComplaint?.complaint_name}>
                      <TextTile>{dataComplaint?.complaint_name}</TextTile>
                    </Tooltip>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      {LastIndex(dataComplaint?.status_data)}
                    </div>
                  </div>
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: '500px',
                    }}
                  >
                    {dataComplaint?.description && (
                      <Tooltip placement="top" title={dataComplaint?.description}>
                        {dataComplaint?.description ? dataComplaint?.description : ''}
                      </Tooltip>
                    )}
                  </div>
                  <div>
                    <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                      <span>แจ้งเมื่อ : {unixToDateThai(dataComplaint?.created_at)}</span>
                      <hr style={{ border: '1px solid #FFC4AA', width: '10px', transform: 'rotate(90deg)' }} />
                      <span>เลขที่ : {dataComplaint?.complaint_no}</span>
                      <hr style={{ border: '1px solid #FFC4AA', width: '10px', transform: 'rotate(90deg)' }} />
                      <span>
                        ร้องเรียน : {chanelData[dataComplaint?.channel] ? chanelData[dataComplaint?.channel] : '-'}
                      </span>
                    </div>
                  </div>
                </HeaderboxView>
                <div>
                  <div>
                    <Boxbg Bgcolor="#FBFBFB">
                      <BoxDescription>
                        <div className="text-title">ประเภทเรื่องร้องเรียน</div>
                        <div className="text-title">ประเภทย่อย</div>
                      </BoxDescription>
                      <BoxDescription>
                        {dataComplaint?.category?.name ? (
                          <div>
                            {dataComplaint?.category?.icon_url && (
                              <img
                                src={`${dataComplaint?.category?.icon_url}`}
                                style={{ width: '20px', height: '20px' }}
                                alt="cate"
                              />
                            )}
                            <span style={{ margin: '0 3px' }}>{dataComplaint?.category?.name}</span>
                          </div>
                        ) : (
                          '-'
                        )}
                        <div className="to-right">{dataComplaint?.sub_category_data?.name}</div>
                      </BoxDescription>
                      <BoxDescription>
                        <div>
                          <div className="text-title">สถานะความเร่งด่วน</div>
                          <div
                            style={{
                              backgroundColor: dataComplaint?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
                              borderRadius: '10px',
                              color:
                                dataComplaint?.urgency?.name === 'ปกติ'
                                  ? '#13AFA6'
                                  : dataComplaint?.urgency?.name === 'ด่วน'
                                  ? '#FFA643'
                                  : dataComplaint?.urgency?.name === 'ด่วนมาก'
                                  ? '#EC82F8'
                                  : dataComplaint?.urgency?.name === 'ด่วนที่สุด'
                                  ? '#F36B24'
                                  : '#FFFFFF',
                              padding: '8px 10px',
                              height: '30px',
                              textAlign: 'center',
                              width: '80px',
                              fontSize: '10px',
                              marginTop: '10px',
                            }}
                          >
                            {dataComplaint?.urgency?.name}
                          </div>
                        </div>
                        {dataComplaint?.department_data?.length === 1 && (
                          <div>
                            <div className="text-title">ผู้รับผิดชอบ</div>
                            <div className="text-sub-title">
                              {dataComplaint?.department_data[0]?.officer_data
                                ? `${dataComplaint?.department_data[0]?.officer_data?.first_name} ${dataComplaint?.department_data[0]?.officer_data?.last_name}`
                                : '-'}
                            </div>
                          </div>
                        )}
                      </BoxDescription>

                      <BoxOrganize>
                        <div className="text-title">หน่วยงานที่รับผิดชอบ</div>
                      </BoxOrganize>
                      <BoxOrganize>
                        {dataComplaint?.department_data ? (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {dataComplaint &&
                              dataComplaint?.department_data?.map((item, index) => {
                                return (
                                  <BoxIcon key={index}>
                                    <div>{dePartMentIcon[item?.name]}</div>
                                    <div style={{ margin: '0 4px' }}> {item?.name}</div>
                                  </BoxIcon>
                                )
                              })}
                          </div>
                        ) : (
                          '-'
                        )}
                      </BoxOrganize>

                      <BoxDescription>
                        <div>
                          <div className="text-title">วันที่กำหนดเสร็จ</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.duedate_at ? checkDueDate(dataComplaint?.duedate_at) : '-'}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-title">วันที่แล้วเสร็จ</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name === 'done'
                                ? checkDueDate(
                                    dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.created_at
                                  )
                                : '-'}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-title">ผู้ร้องเรียน</div>
                          <div className="text-sub-title">
                            {dataComplaint?.name
                              ? `${dataComplaint?.name?.first_name} ${dataComplaint?.name?.last_name}`
                              : '-'}
                          </div>
                        </div>
                        <div>
                          <div className="text-title">เบอร์โทรศัพท์</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.mobile_number ? dataComplaint?.mobile_number : '-'}
                            </div>
                          </div>
                        </div>
                      </BoxDescription>
                      <BoxDescription>
                        {dataComplaint?.department_data?.map(val => {
                          return (
                            val?.id === meData?.organize_data?.org_id && (
                              <div>
                                <div className="text-title">เหตุผลการปฏิเสธการโอนย้าย</div>
                                <div>
                                  <div className="text-sub-title">
                                    {val?.transfer_reason ? val?.transfer_reason : '-'}
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        })}
                      </BoxDescription>
                    </Boxbg>
                  </div>
                  <div>
                    <div>
                      <div className="text-title">รูปภาพและวิดีโอ</div>
                      <div
                        style={{
                          display: 'flex',
                          overflow: 'auto',
                          maxWidth: '768px',
                        }}
                      >
                        {Mapmedia(dataComplaint?.media_url)}
                      </div>
                    </div>
                    <div>
                      <div className="text-title" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        สถานที่แจ้งเรื่อง
                      </div>
                      <div>
                        <Viewmap
                          latitude={dataComplaint?.coordinates?.latitude}
                          longitude={dataComplaint?.coordinates?.longitude}
                          dataComplaint={dataComplaint}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ContainerView>
            </BoxTopView>

            <CardResultBox
              dataTranSaction={dataTranSaction}
              setPreviewImg={setPreviewImg}
              setPreviewVdo={setPreviewVdo}
              setOpenModalImage={setOpenModalImage}
              setOpenModalVdo={setOpenModalVdo}
              complaintId={dataComplaint?.id}
              dataComplaint={dataComplaint}
              path={location?.state?.path}
            />

            <CardTracking
              dataComplaint={dataComplaint}
              complaintId={dataComplaint?.id}
              setPreviewImg={setPreviewImg}
              setPreviewVdo={setPreviewVdo}
              setOpenModalImage={setOpenModalImage}
              setOpenModalVdo={setOpenModalVdo}
            />

            {/* {(dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.description === 'ยังไม่ดำเนินการ' &&
              meData?.position_data?.id === '1730459515121299456') ||
            meData?.position_data?.id === '1730459515154853888' ? (
              <BtnSubmit>
                <Button onClick={onSuccessStatusIfHaven} type="primary">
                  เสร็จสิ้นการปฏิบัติงาน
                </Button>
              </BtnSubmit>
            ) : (
              ''
            )} */}
            {dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.description === 'ยังไม่ดำเนินการ' &&
            (meData?.position_data?.id === '1730459515121299456' ||
              meData?.position_data?.id === '1730459515154853888') ? (
              <BtnSubmit>
                <Button onClick={onSuccessStatusIfHaven} type="primary">
                  เสร็จสิ้นการปฏิบัติงาน
                </Button>
              </BtnSubmit>
            ) : (
              ''
            )}

            <div>
              <ModalPreviewImage
                title=""
                centered
                transitionName=""
                closable={false}
                open={OpenModalImage}
                footer={false}
                onOk={() => {
                  setPreviewImg(null)
                  setOpenModalImage(false)
                }}
                onCancel={() => {
                  setPreviewImg(null)
                  setOpenModalImage(false)
                }}
              >
                <ModelContent>
                  <div
                    className="close-ui"
                    onClick={() => {
                      setOpenModalImage(false)
                      setPreviewImg(null)
                    }}
                  >
                    <img src={Iconpreview} alt="dd" />
                  </div>
                  {previewImg ? (
                    <div className="vdo-content">
                      <CustomImage>
                        <img src={previewImg} style={{ width: '100%', height: 'auto' }} />
                      </CustomImage>
                    </div>
                  ) : null}
                </ModelContent>
              </ModalPreviewImage>
            </div>

            <div>
              <ModalPreviewImage
                title=""
                centered
                transitionName=""
                closable={false}
                open={OpenModalVdo}
                footer={false}
                onOk={() => {
                  setOpenModalVdo(false)
                }}
                onCancel={() => {
                  setOpenModalVdo(false)
                }}
              >
                <ModelContent>
                  <div
                    className="close-ui"
                    onClick={() => {
                      setOpenModalVdo(false)
                    }}
                  >
                    <img src={IconCloseModal} alt="dd" style={{ width: '80px', height: '70px' }} />
                  </div>
                  {previewVdo ? (
                    <div className="vdo-content">
                      <video width="370" height="250" controls>
                        <source src={previewVdo} type="video/mp4" />
                      </video>{' '}
                    </div>
                  ) : null}
                </ModelContent>
              </ModalPreviewImage>
            </div>

            {/* ////////////////////notไม่ แสดงยกเว้นกด  */}
          </div>
        </Content>
      )}
    </>
  )
}

export default UpdateStatuspc
