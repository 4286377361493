import React from 'react'
import { Form, DatePicker, Select, Row, Col, Input } from 'antd'
import styled from 'styled-components'
import th_TH from 'antd/lib/locale/th_TH'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import { ReactComponent as Iconsearch } from 'assets/images/svg/search-dashboard.svg'

const FormContainer = styled.div`
  width: 100%;
  overflow-x: auto;

  .ant-input-affix-wrapper-focused {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #f36b24;
    border-right-width: 1px;
    z-index: 1;
  }
  .ant-input-affix-wrapper {
    border-color: #ffeee7;
    border-radius: 8px;
    height: 40px;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
`

function FormSelect({ masterData, setSearchData, searchData, conversDate }) {
  const [form] = Form.useForm()
  let optionsStatus = masterData?.status?.map(item => {
    return {
      label: item.name,
      value: item.name_en,
    }
  })

  let optionsUrgency = masterData?.urgency?.map(item => {
    return {
      label: item?.name,
      value: item.id,
    }
  })

  let optionChannel = masterData?.chanel?.map(item => {
    return {
      label: item.name_th,
      value: item.name_en,
    }
  })

  let optionsDepartment = masterData?.department?.map(item => {
    return {
      label: item?.name,
      value: item.id,
    }
  })

  const handleChange = (value, type) => {
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  const disabledDate = current => {
    return current && current > moment().endOf('day')
  }

  const onClear = () => {
    form.resetFields()
    setSearchData([])
  }

  return (
    <FormContainer>
      <Form form={form} layout="vertical">
        <div style={{ color: '' }}> กรุณาเลือกคำคีย์เวิร์ด</div>
        <Input
          label="กรุณาเลือกคำคีย์เวิร์ด"
          style={{ width: '25vw', marginBottom: '10px' }}
          placeholder="ค้นหาจากคำคีย์เวิร์ด"
          prefix={<Iconsearch />}
          // value={searchData?.search}
          allowClear={true}
        />

        {/* <Row gutter={[8, 8]}> */}
        <BoxGrid>
          <ConfigProvider locale={th_TH}>
            <CompactFormItem label="เลือกเวลาข้อมูล" name="dateRange">
              <RangePicker
                allowClear={false}
                defaultValue={searchData.date}
                placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                onChange={val => handleChange(val, 'date')}
                disabledDate={disabledDate}
              />
            </CompactFormItem>
          </ConfigProvider>

          <CompactFormItem label="หน่วยงาน" name="unit">
            <StyledAntSelect
              placeholder="ทั้งหมด"
              options={optionsDepartment}
              value={searchData?.department}
              onSelect={(val, e) => {
                handleChange(e?.value, 'department')
              }}
              onClear={val => handleChange(val, 'department')}
              allowClear
            />
          </CompactFormItem>

          <CompactFormItem label="สถานะร้องเรียน" name="status">
            <StyledAntSelect
              placeholder="ทั้งหมด"
              options={optionsStatus}
              value={searchData?.status}
              onSelect={(val, e) => {
                handleChange(e?.value, 'status')
              }}
              onClear={val => handleChange(val, 'status')}
              allowClear
            />
          </CompactFormItem>

          <CompactFormItem label="ช่องทางการรับเรื่อง" name="channel">
            <StyledAntSelect
              placeholder="ทั้งหมด"
              options={optionChannel}
              value={searchData?.chanel}
              onSelect={(val, e) => {
                handleChange(e?.value, 'chanel')
              }}
              onClear={val => handleChange(val, 'chanel')}
              allowClear
            />
          </CompactFormItem>

          <CompactFormItem label="เลือกประเภทข้อมูล" name="dataType">
            <StyledAntSelect
              placeholder="ทั้งหมด"
              optionFilterProp="label"
              value={searchData?.urgency}
              options={optionsUrgency}
              onSelect={val => handleChange(val, 'urgency')}
              onClear={val => handleChange(val, 'urgency')}
              optionLabelProp="label"
              allowClear
            />
          </CompactFormItem>

          <ClearButton onClick={onClear} block>
            ล้างข้อมูล
          </ClearButton>
        </BoxGrid>

        {/* </Row> */}
      </Form>
    </FormContainer>
  )
}

export default FormSelect
const StyledAntSelect = styled(Select)`
  && .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #ffeee7;
    height: 40px;
    display: flex;
    align-items: center;
  }

  &&:hover .ant-select-selector {
    border-color: #f36b24;
    box-shadow: none;
  }

  &&.ant-select-focused .ant-select-selector,
  && .ant-select-selector:focus {
    border-color: #f36b24;
    box-shadow: none;
  }

  && .ant-select-arrow {
    color: #f36b24;
  }

  && .ant-select-selection-item {
    color: #f36b24;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  && .ant-select-selection-placeholder {
    color: #ffc4aa;
    font-size: 14px;
  }
`

const ClearButton = styled.p`
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  text-decoration-line: underline;
  padding-top: 30px;
  color: #a7a5a3;
  cursor: pointer;
`

const { RangePicker } = DatePicker

const CompactFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding-bottom: 0;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }

  .ant-form-item-control {
    margin-top: 0;
  }
`

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 2fr 2fr 2fr 2fr 2fr;
  gap: 10px;

  @media only screen and (max-width: 820px) {
    grid-template-columns: 250px 150px 150px 150px 150px 150px;
  }
`
