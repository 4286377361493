import styled from 'styled-components'

export const Content = styled.div`
  .text-breadcrumb-menu {
    font-size: 16px;
  }
  .text-breadcrumb-menu-sub {
    font-size: 16px;
    font-weight: 500;
    color: #150f0b;
    margin: 0;
    width: 300px;
  }
  @media screen and (max-width: 768px) {
    .text-breadcrumb-menu {
      font-size: 12px;
    }
    .text-breadcrumb-menu-sub {
      font-size: 12px;
    }
  }
`
