import styled from 'styled-components'
import { Menu } from 'antd'
export const BoxPointMap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.BgColor ? props.BgColor : '')};
  }

  span {
    font-size: 10px;
    color: #717171;
    font-weight: 300;
  }
`

export const Menucustom = styled(Menu)`
  padding: 8px !important;
  width: 160px !important;
  .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
  .ant-dropdown-menu-title-content:hover {
    background-color: #ffeee7;
    color: #f36b24;
    border-radius: 4px;
  }
`

export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 33px;
  border-radius: 8px;

  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 162px;
    border-radius: 8px;
  }
`

export const Numstatus = styled.div`
  ${props => {
    switch (props.colorType) {
      case 'ทำทันที':
        return 'color: #F85858'
      case 'ด่วนที่สุด':
        return 'color: #F36B24'
      case 'ด่วนมาก':
        return 'color: #EC82F8'
      case 'ด่วน':
        return 'color: #FFA643'

      default:
        return 'color: #13AFA6'
    }
  }}
`

export const FlexStatus = styled.div`
  display: flex;
`

export const BoxStatus = styled.div`
  border-radius: 8px;

  background: ${props => props.Bgstatus || '#DFF6F5'};

  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 10px;
`

export const BoxbgChart = styled.div`
  background-color: rgb(251, 251, 251);
  border-radius: 12px;
  padding: ${props => (props?.isPrints ? '10px' : '24px')};
  margin: 10px 10px;
`

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

export const BoxOverAll = styled.div`
  margin: 0 40px 40px 40px;

  @media only screen and (max-width: 820px) {
    margin: 0 20px 20px 20px !important;
  }
`

export const Stat = styled.div`
  display: inline-flex;
  align-items: flex-start;
`

export const StatPath = styled.p`
  color: #717171;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 20px;
`

export const StatNow = styled.p`
  color: #f36b24;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 20px;
`

export const ButtonBox = styled.button`
  width: 160px;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: #f36b24;
  border: none;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  margin-right: 16px;
  color: #fff;
`

export const ButtonPrint = styled.button`
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #f36b24;
  background: #fff;
  color: #f36b24;
`

export const BoxForm = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  border-radius: 12px;
  background: rgba(252, 101, 33, 0.03);
  margin: 16px 0px 16px 0px;
  padding: 16px;

  &.flex-column {
    display: flex;
    flex-direction: column;
  }
`

export const BoxAllState = styled.div`
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;

  @media only screen and (max-width: 820px) {
    padding: 18px;
  }
`

export const MonthText = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const FlexContainer = styled.div`
  display: flex;
  gap: 24px;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const FlexColumnBox = styled.div`
  display: flex;
  flex-direction: column;

  .big-text {
    font-size: 16px;
  }
`
export const StyledImage = styled.img`
  padding-bottom: 10px;
`

export const BoxNumOfComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border-radius: 12px;
  margin: 10px;
  background-color: rgba(252, 101, 33, 0.03) !important;
`

export const LeftBoxNumOfComp = styled.div`
  display: flex;
  padding-top: 15px;
  gap: 8px;
`

export const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: ${props => (props?.isPrints ? '10px' : '14px')};
  font-style: normal;
  font-weight: 500;
`

export const TextSatusStay = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: ${props => (props?.isPrints ? '10px' : '14px')};
  font-style: normal;
  font-weight: 300;
`

export const BoxSatisfaction = styled.div`
  /* height: 400px; */
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const RightBox = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const FlexOvEx = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`

export const BoxOverLoad = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const BoxExigent = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

export const FlexAgency = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

export const BottomBox = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

export const NumStyle = styled.p`
  font-family: Prompt;
  padding-top: 20px;
  font-size: ${props => (props?.isPrints ? '10px' : ' 20px')};
  font-style: normal;
  font-weight: 500;
`
export const BoxSelect = styled.div`
  margin-top: 5px;
  &.ant-picker {
  }

  .date-picker,
  .ant-picker-input > input {
    color: #f36b24 !important;
  }
  .ant-picker-range .ant-picker-input > input::placeholder {
    /* Change the placeholder color to red */
    color: #ffc4aa !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`
export const StyleDropdown = styled.div`
  /* width: 50%; */
  max-width: 500px;

  min-width: 300px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    max-width: 500px;
    height: 40px;
    padding: 0 11px;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }

  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 8px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #ffeee7;
    /* width: 100%; */
  }

  .ant-select-disabled .ant-select-selector {
    background: #ffeee7 !important;
    border: 1px solid #ffeee7 !important;
    color: #f36b24 !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #f36b24;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #f36b24 !important;
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }

  .ant-select,
  .ant-select-single,
  .ant-select-show-arrow,
  .ant-select-show-search {
    width: 100%;
  }

  .ant-select-selection-placeholder {
    color: #ffc4aa !important;
  }
  .big-input .ant-select-selection-placeholder {
    /* line-height: 37px; */
    padding-left: 0 !important;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #ffeee7;
    color: #f36b24;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
  }
  .ant-select-clear {
    margin-top: -8px !important;
    padding-right: 16px !important;
  }
`

export const HeaderTopic = styled.h1`
  font-family: Prompt;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  /* text-align: center; */
  margin-top: 33px;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    text-align: left;
  }

  @media only screen and (max-width: 635px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    text-align: left;
  }

  @media only screen and (max-width: 376px) {
    font-size: 14px;
    text-align: left;
  }
`
