import React, { useEffect, useState, useContext } from 'react'
import { Button, Space } from 'antd'
import { InputCustom } from 'components/input'
import { useFormik } from 'formik'
import { ArrowLeftOutlined } from '@ant-design/icons'

import useAllPermissions from 'core/hooks/useAllPermissions'

import iconbin from 'assets/images/svg/icon-bin-org-manage.svg'
import iconedit from 'assets/images/svg/icon-edit-org-manage.svg'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import { ReactComponent as IconAlert } from 'assets/images/svg/alert.svg'
import Alert from 'assets/images/svg/offline/alert.js'

import { useNavigate, useLocation } from 'react-router-dom'

import { Schema, defaultValues } from './container'
import { CustomSpace, Title, Box, ButtonEdit, ButtonDelete, ButtonBack, CustomModal, CustomModal2 } from './view.style'
import { getData, deleteData } from '../../../../core/action/collection'
import { category } from '../../../../core/schemas'
import { adminauth2 } from 'core/schemas'
import UserContext from 'core/contexts/userContext'

const Index = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const [deletionType, setDeletionType] = React.useState(null)
  const [defaultValueFromList, setDefaultValueFromList] = useState({
    name: '',
    description: '',
  })
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false)
  const { modal, setmodal } = useContext(UserContext)

  const formik = useFormik({
    initialValues: defaultValueFromList,
    // validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
  })

  const onDeleteData = async () => {
    const res = await deleteData(`${adminauth2}/group`, state?.record?.id, '')
    if (res) {
      if (res?.code === 200) {
      }
    } else {
    }
  }

  const onOkDelete = () => {
    setDeleteConfirmModalOpen(false)

    localStorage.setItem('modal', 'delete')
    navigate('/operation-tools/bof-group', {
      state: {
        showNotidelete: true,
        record: state.record,
        isDelete: true,
      },
    })
  }

  const getGroupById = async () => {
    const res = await getData({
      schema: `${adminauth2}/group/` + state?.record?.id,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const { name, description, status } = res?.data
        setDefaultValueFromList({ name, description: description === ' ' ? '' : description })
      } else {
        if (res?.code !== 401) setDefaultValueFromList({ name: '', description: '' })
      }
    }
  }

  useEffect(() => {
    state?.record?.id && getGroupById()
    if (state.isDelete) {
      setDeleteConfirmModalOpen(true)
      setDeletionType(state.record?.status)
    }
  }, [])

  const callfunction = state => {
    setDeleteConfirmModalOpen(true)
    setDeletionType(state.record?.status)
  }

  const canceldelete = () => {
    setDeleteConfirmModalOpen(false)
    navigate('/operation-tools/bof-group')
  }

  const renderDeleteModal = isOffline => {
    if (isOffline === 'inactive') {
      return (
        <CustomModal
          transitionName=""
          centered
          open={deleteConfirmModalOpen}
          onOk={onOkDelete}
          onCancel={canceldelete}
          closable={false}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <Alert />
          </div>
          <p style={{ color: '#001F34', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
            คุณต้องการลบข้อมูลนี้?
          </p>
          <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300' }}>
            หากคุณลบข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
          </p>
        </CustomModal>
      )
    } else if (isOffline === 'active') {
      return (
        <CustomModal2
          transitionName=""
          centered
          open={deleteConfirmModalOpen}
          closable={false}
          okText="ตกลง"
          footer={
            <Button type="primary" onClick={() => canceldelete()}>
              ตกลง
            </Button>
          }
        >
          <div style={{ paddingBottom: '10px' }}>
            <Alert />
          </div>
          <p style={{ color: '#001F34', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
            ไม่สามารถลบข้อมูลได้
          </p>
          <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300', lineHeight: '14.4px' }}>
            เนื่องจากข้อมูลถูกใช้งานอยู่ในเมนูจัดการผู้ใช้งาน
            <br />
            กรุณาลบข้อมูลที่ใช้งานจากเมนูข้างต้นก่อน
          </p>
        </CustomModal2>
      )
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '20px' }}>
        <Space style={{ paddingTop: '20px' }}>
          <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => navigate(-1)} />
          <Title>ข้อมูลกลุ่มผู้ใช้งาน</Title>
        </Space>
        <Space>
          {/* {allow.update && ( */}
          <ButtonEdit
            onClick={() => {
              const id = state.record.id || '#'
              navigate(`/operation-tools/bof-group/update/${id}`, {
                state: { record: state.record, isEdit: true },
              })
            }}
            icon={<img src={iconedit} alt="edit" style={{ paddingRight: '8px' }} />}
          >
            แก้ไขข้อมูล
          </ButtonEdit>
          {/* )} */}

          {/* {allow.delete && ( */}
          <ButtonDelete
            onClick={() => callfunction(state)}
            icon={<img src={iconbin} alt="bin" style={{ paddingRight: '8px' }} />}
          >
            ลบข้อมูล
          </ButtonDelete>
          {/* )} */}
        </Space>
      </div>

      <Box>
        <div style={{ padding: '2rem' }}>
          <InputCustom
            label="ชื่อกลุ่ม"
            name="name"
            placeholder="กรอกชื่อกลุ่ม"
            type="text"
            required={true}
            errors={formik.errors}
            touched={formik.touched}
            form={formik}
            disabled
          />
          <InputCustom
            label="รายละเอียด"
            name="description"
            placeholder="กรอกรายละเอียด"
            type="textarea"
            errors={formik.errors}
            touched={formik.touched}
            form={formik}
            disabled
          />
        </div>
      </Box>
      {renderDeleteModal(deletionType)}

      {/* <CustomModal
        transitionName=""
        centered
        open={deleteConfirmModalOpen}
        onOk={onOkDelete}
        onCancel={() => setDeleteConfirmModalOpen(false)}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
      >
        <div style={{ paddingBottom: '10px' }}>
          <Alert />
        </div>
        <p style={{ color: '#001F34', fontSize: '16px', fontWeight: 'bold', marginBottom: '2px' }}>
          คุณต้องการลบข้อมูลนี้?
        </p>
        <p style={{ color: '#597081', fontSize: '14px', fontWeight: '500' }}>
          หากคุณลบข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
        </p>
      </CustomModal> */}

      {/* <ModalError
        transitionName=""
        centered
        open={alertModalOpen}
        closable={false}
        okText="ตกลง"
        footer={
          <Button
            type="primary"
            onClick={() => {
              setAlertModalOpen(false)
              getAnnouncementTypeById()
            }}
          >
            ตกลง
          </Button>
        }
      >
        <IconAlert style={{ marginBottom: '10px' }} />
        <p style={{ color: '#001F34', fontSize: '16px', fontWeight: 'bold', marginBottom: '2px' }}>เกิดข้อผิดพลาด</p>
        <p style={{ color: '#597081', fontSize: '14px', fontWeight: '500' }}>กรุณาลองใหม่อีกครั้ง</p>
      </ModalError> */}
    </div>
  )
}

export default Index
