import React from 'react'
import ColumnsChart from 'components/Charts/ColumnsChart'
import DonutChart from 'components/Charts/DonutChart'
import LinCharts from 'components/Charts/LineChart'
import ProgressChart from 'components/Charts/ProgressChart'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'
import { checkColorDonut, checkColorDonutOvertTime, checkColorStatusPoint, convertStatusToThai } from '../container'
import styled from 'styled-components'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import OpenLayersHeatmap from 'components/OlHeatMap'
import { FlexAgency } from '../style'
import { unixToDateThai } from 'util/date-format'
const CardPdf = ({
  isPrint,
  dataSumDepartment,
  dataStatusDepart,
  dataOfficer,
  dataLine,
  dataDonutStatus,
  dataDonutOvertime,
  dataUrgency,
  dataTotal,
  dataHeatMap,
  searchData,
  meData,
}) => {
  const Donutlabels = [
    'รับเรื่อง',
    'อยู่ระหว่างดำเนินการ',
    'ดำเนินการแล้วเสร็จ',
    'ติดตาม',
    'ไม่อยู่ในความรับผิดชอบ',
    'เกินระยะเวลาที่กำหนด',
  ]
  const NotdefaultColor = {
    ด่วนมาก: '#EC82F8',
    ปกติ: '#27AE60',
    ด่วนที่สุด: '#1D19EC',
    ทำทันที: '#EB5757',
    ด่วน: '#FFA643',
  }
  const departMentColor = {
    สำนักช่าง: '#1D19EC',
    สำนักคลัง: '#FFA643',
    สำนักการประปา: '#56CCF2',
    สำนักการศึกษา: '#F2F2F2',
    กองสวัสดิการสังคม: '#27AE60',
    กองการเจ้าหน้าที่: '#EC82F8',
    สำนักปลัดเทศบาล: '#EB5757',
    กองยุทธศาตร์และงบประมาณ: '#F2C94C',
    สำนักสาธารณสุขและสิ่งแวดล้อม: '#9B51E0',
  }
  const AllProgessColor = {
    สำนักช่าง: '#CDCEFA',
    สำนักคลัง: '#FFF6EB',
    สำนักการประปา: '#D2EDF6',
    สำนักการศึกษา: '#F2F2F2',
    กองสวัสดิการสังคม: '#D9FFE9',
    กองการเจ้าหน้าที่: '#FDF2FE',
    สำนักงานปลัดเทศบาล: '#FECCCC',
    กองยุทธศาตร์และงบประมาณ: '#F9EDC7',
    สำนักสาธารณสุขและสิ่งแวดล้อม: '#EDF',
  }
  let latlng = NakhonRatchasima?.features[0]?.geometry?.coordinates[0]

  return (
    <>
      <BoxOverAll>
        <div className="page-1" style={{ padding: '2%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#150F0B' }}>
            <div>
              <div style={{ fontSize: '16px', fontWeight: 500 }}>
                แดชบอร์ดแสดงสถานะการทำงานของเจ้าหน้าที่สำนักปลัดเทศบาล
              </div>
              <div style={{ fontSize: '12px', fontWeight: 400 }}>
                วันที่ {searchData?.length > 0 && unixToDateThai(searchData?.date[0]?.unix())} -{' '}
                {searchData?.length && unixToDateThai(searchData?.date[1]?.unix())}
              </div>
            </div>
            <div style={{ textAlign: 'end', fontSize: '12px' }}>
              <div>
                หน้าที่ 01/02 พิมพ์ ณ วันที่ {searchData?.length > 0 && unixToDateThai(searchData?.date[0].unix())} -{' '}
                {searchData?.length > 0 && unixToDateThai(searchData?.date[1].unix())}{' '}
              </div>
              <div style={{ textAlign: 'end' }}>
                {meData && meData?.first_name?.th} {meData && meData?.last_name?.th}
              </div>
            </div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px', marginTop: '20px' }}>
            <div>
              <BoxNumOfComp>
                <LeftBoxNumOfComp>
                  <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                  <div style={{ fontSize: '12px' }}>จำนวนเรื่องร้องเรียนทั้งหมด</div>
                  <div style={{ fontSize: '12px' }}>(เรื่อง)</div>
                </LeftBoxNumOfComp>
                <div style={{ fontSize: '12px' }}>{dataTotal.toLocaleString()}</div>
              </BoxNumOfComp>
              <div style={{ backgroundColor: '#fbfbfb', padding: '10px', borderRadius: '10px' }}>
                <div>
                  <TextSatus>
                    จำนวนเรื่องร้องเรียนตามสถานะ <span style={{ fontSize: '12px' }}>(เรื่อง)</span>
                  </TextSatus>
                </div>
                <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                  <DonutChart
                    width="150px"
                    height="150px"
                    legendShow={'noShow'}
                    labels={dataDonutStatus?.donutXaxis}
                    series={dataDonutStatus?.donutSeries}
                    colors={dataDonutStatus?.donutColor}
                  />
                  <div>
                    <div>
                      {dataDonutStatus?.donutXaxis?.map((item, index) => {
                        return (
                          index < 5 && (
                            <BoxLegendChart BgColor={checkColorDonut(item)} key={index}>
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '20px 20px 1fr',
                                  fontSize: '10px',
                                  color: '#717171',
                                  marginBottom: '10px',
                                  alignItems: 'center',
                                }}
                              >
                                <div className="point" />
                                <div style={{ fontSize: '8px', fontWeight: 500, color: '#150F0B' }}>
                                  {dataDonutStatus?.donutSeries[index]}
                                </div>
                                <div> {convertStatusToThai(item)}</div>
                              </div>
                            </BoxLegendChart>
                          )
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '10px', padding: '10px', backgroundColor: '#fbfbfb', borderRadius: '10px' }}>
                <div>
                  <TextSatus>
                    ผลประเมินความพึงพอใจ <span>(เรื่อง)</span>
                  </TextSatus>
                </div>
                <div>
                  <LinCharts
                    series={dataLine?.dataLineSeries}
                    xaxis={dataLine?.dataLineXaxis}
                    width="350px"
                    height="200px"
                  />
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: '#fbfbfb', borderRadius: '10px', padding: '20px' }}>
              <div style={{ position: 'relative' }}>
                <TextSatus style={{ position: 'absolute', zIndex: 999 }}>
                  แผนที่แสดงความหนาแน่นของเรื่องร้องเรียนตามพื้นที่ <span>(เรื่อง)</span>
                </TextSatus>
                <div
                  style={{
                    borderRadius: '10px',
                    padding: '10px',
                    position: 'absolute',
                    zIndex: 999,
                    top: '30px',
                    left: '0px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    rowGap: '5px',
                    columnGap: '10px',
                  }}
                >
                  {Donutlabels?.map((item, index) => (
                    <BoxPointMap BgColor={checkColorStatusPoint(item)} key={index}>
                      <div className="point" />
                      <span>{item}</span>
                    </BoxPointMap>
                  ))}
                </div>
                {isPrint && (
                  <OpenLayersHeatmap mapData={dataHeatMap?.heatmapData} zooms={12} height={'450px'} isPrint={isPrint} />
                )}
              </div>
            </div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px', marginTop: '20px' }}>
            <div style={{ backgroundColor: '#fbfbfb', padding: '10px', borderRadius: '10px' }}>
              <TopicHeaderStatus>
                <TextSatus>
                  จำนวนเรื่องร้องที่อยู่ระหว่างดำเนินการและเกินระยะเวลาที่กำหนด <span>(เรื่อง)</span>
                </TextSatus>
              </TopicHeaderStatus>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <DonutChart
                  width="150px"
                  height="150px"
                  legendShow={'noShow'}
                  series={dataDonutOvertime.donutOverTimeSeries}
                  colors={dataDonutOvertime.donutOverTimeColor}
                  labels={dataDonutOvertime.donutOverTimeXaxis}
                />

                <div>
                  {dataDonutOvertime?.AllOvertime?.map((item, index) => {
                    return (
                      <BoxLegendChart BgColor={checkColorDonutOvertTime(item?.status_date)} key={index}>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '20px 40px 30px 1fr',
                            fontSize: '10px',
                            color: '#717171',
                            marginBottom: '15px',
                            alignItems: 'center',
                          }}
                        >
                          <div className="point" />
                          <div style={{ fontSize: '12px', fontWeight: 500, color: '#150F0B' }}>{item?.total_date}</div>
                          <div>{item?.percent_date} %</div>
                          <div>
                            {item?.status_date === 'overdate' ? 'เกินระยะเวลาที่กำหนด' : 'อยู่ระหว่างดำเนินการ'}
                          </div>
                        </div>
                      </BoxLegendChart>
                    )
                  })}
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: '#fbfbfb', padding: '10px', borderRadius: '10px' }}>
              <TopicHeaderStatus>
                <TextSatus>
                  สัดส่วนเรื่องร้องเรียนตามความเร่งด่วน <span>(เรื่อง)</span>
                </TextSatus>
              </TopicHeaderStatus>
              {dataUrgency?.map((item, index) => (
                <Boxflex key={index} Margin={'40px 0'}>
                  <BoxDepart>
                    <Dotstar DtColor={NotdefaultColor[item?.urgent_name]} />
                    <div>{item?.urgent_name}</div>
                  </BoxDepart>
                  <Progress PgColor={NotdefaultColor[item]}>
                    <Bar
                      className="men"
                      fontSize={10}
                      percen={item?.precent_urgent}
                      color={NotdefaultColor[item?.urgent_name]}
                    >
                      {item?.precent_urgent ? item?.precent_urgent?.toFixed() + ' %' : ''}
                    </Bar>
                  </Progress>
                  <Boxnum>{item?.total_urgent}</Boxnum>
                </Boxflex>
              ))}
            </div>
          </div>
          <div />
        </div>

        <div style={{ pageBreakBefore: 'always' }}></div>
        <div className="page-2" style={{ padding: '5% 2%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', color: '#150F0B' }}>
            <div>
              <div style={{ fontSize: '16px', fontWeight: 500 }}>
                แดชบอร์ดแสดงสถานะการทำงานของเจ้าหน้าที่สำนักปลัดเทศบาล
              </div>
              {/* <div style={{ fontSize: '12px', fontWeight: 400 }}>ประจำเดือนกันยายน 2566</div> */}
            </div>
            <div style={{ textAlign: 'end', fontSize: '12px' }}>
              <div>
                หน้าที่ 02/02 พิมพ์ ณ วันที่ {searchData?.length > 0 && unixToDateThai(searchData?.date[0].unix())} -{' '}
                {searchData?.length > 0 && unixToDateThai(searchData?.date[1].unix())}{' '}
              </div>
              <div style={{ textAlign: 'end' }}>
                ผู้พิมพ์ {meData && meData?.first_name?.th} {meData && meData?.last_name?.th}
              </div>
            </div>
          </div>
          <FlexAgency isPrint={isPrint}>
            <TextSatus>
              จำนวนเรื่องร้องเรียนของแต่ละสำนัก <span>(เรื่อง)</span>
            </TextSatus>
            <div>
              {dataSumDepartment &&
                dataSumDepartment?.map((item, index) => (
                  <Boxflex key={index} Margin={'20px 0'}>
                    <BoxDepart>
                      <Dotstar DtColor={departMentColor[item?.department_name]} />
                      <div>{item?.department_name}</div>
                    </BoxDepart>
                    <Progress PgColor={AllProgessColor[item?.department_name]}>
                      <Bar
                        className="men"
                        fontSize={10}
                        percen={item?.parcent}
                        color={departMentColor[item?.department_name]}
                      >
                        {item?.parcent?.toFixed()}%
                      </Bar>
                    </Progress>

                    <Boxnum>{item?.total}</Boxnum>
                  </Boxflex>
                ))}
            </div>
          </FlexAgency>
          <FlexAgency isPrint={isPrint}>
            <TextSatus>
              กราฟแสดงจำนวนสถานะของแต่ละหน่วยงาน <span>(เรื่อง)</span>
            </TextSatus>
            <div style={{ margin: '1%' }}>
              <ColumnsChart
                xaxis={dataStatusDepart?.dataStatusXaxis}
                series={dataStatusDepart?.dataStatusSeries}
                width={'700px'}
                height="220px"
              />
            </div>
          </FlexAgency>

          <div style={{ pageBreakBefore: 'always' }}>
            <FlexAgency>
              <TextSatus>จำนวนเจ้าหน้าที่และเรื่องร้องเรียนในความรับผิดชอบ</TextSatus>
              <div style={{ width: '100%' }}>
                <ColumnsChart
                  columnWidth={'80%'}
                  xaxis={dataOfficer.dataOfficersXaxis}
                  series={dataOfficer.dataOfficerSeries}
                  width={'700px'}
                  height="250px"
                />
              </div>
            </FlexAgency>

            <FlexAgency>
              <TextSatus>จำนวนการกดเปลี่ยนสถานะของเจ้าหน้าที่</TextSatus>
              <div style={{ width: '100%' }}>
                <ColumnsChart
                  columnWidth={'80%'}
                  xaxis={dataOfficer?.dataOfficersXaxis}
                  series={dataOfficer?.dataOfficerSeries}
                  width={'700px'}
                  height="250px"
                />
              </div>
            </FlexAgency>
          </div>
        </div>
      </BoxOverAll>
    </>
  )
}

export default CardPdf
const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`
const Boxnum = styled.div`
  margin: 0 10px;
  font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
`
export const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${props => props.percen || 0}%;
  color: white;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || 10}px;
  line-height: 16px;
  background: ${props => props.color || '#ededed'};
`

const Progress = styled.div`
  max-width: ${props => (props.MaxwidthProgress ? props.MaxwidthProgress : '')};
  min-width: ${props => (props.MinwidthProgress ? props.MinwidthProgress : '')};
  width: ${props => (props.PgWidth ? props.PgWidth : '100%')};
  height: ${props => (props.PgHeight ? props.PgHeight : '30px')};
  /* height: 44px; */
  border-radius: 10px;
  box-shadow: 0 0 3px #b0b0;
  background-color: #fecccc;
  display: flex;
  overflow: hidden;
  background: ${props => props.PgColor || '#ededed'};
`
const Dotstar = styled.div`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  margin: 0 10px;
  background: ${props => props.DtColor || '#ededed'};
`
const BoxDepart = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: ${props => (props.minWidth ? props.minWidth : '200px')};
`
const Boxflex = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => (props.Margin ? props.Margin : '30px 0')};
  &.time-line {
    display: flex;
    justify-content: start;
    align-items: start;
  }
`
const BoxOverAll = styled.div`
  padding: 2%;
  font-family: 'Prompt';
  background-color: #fff;
  @media print {
    @page {
      size: A4 portrait !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
  .print:last-child {
    page-break-after: auto;
  }

  .new-page {
    page-break-before: always;
  }
`

export const BoxLegendChart = styled.div`
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.BgColor ? props.BgColor : '')};
  }
`

export const BoxPointMap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  .point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => (props.BgColor ? props.BgColor : '')};
  }

  span {
    font-size: 10px;
    color: #717171;
    font-weight: 300;
  }
`

const StyledImage = styled.img`
  padding-bottom: 10px;
`

const BoxNumOfComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border-radius: 12px;
  background: #fffbf8;
`

const LeftBoxNumOfComp = styled.div`
  display: flex;
  padding-top: 15px;
  gap: 8px;
`

const TopicHeaderStatus = styled.div`
  display: flex;
  gap: 8px;
`
