export const adminAuth = '/api/v1'
export const pathLogin = `/admin/auth/api/v1`
export const authentication = `/authentication/oauth2/api/v1`
export const usagelog = `${adminAuth}/usagelog`
export const login = `${pathLogin}/login`
export const request = `${authentication}/request`
export const mohpic = `/mohpic`
export const sso = `/sso`
export const me = `/admin-authen/api/v1/admin/me`

export const category = '/category'
export const hospcode = '/hospcode'
export const chatbot = '/chatbot'
export const adminauth2 = '/admin-authen/api/v1'
export const authentication2 = `/authorization/api/v1`
export const masterdata = `/master/api/v1/all`
export const verifyEmail = `/sender/api/v1`
export const complaint = 'complaint/api/v1'
export const complaintmap = 'maps/api/v1'

export const fallback = '/fallback'
export const announcementType = 'announcement/type'
export const announcement = 'announcement'
export const alert = 'alert'
export const healthblog = 'healthblog'
export const hospital = 'hospital'
export const organization = 'organization'
export const roles = 'roles'
export const userGroup = 'user/group'
export const user = 'user'
export const logsActivity = 'logs/activity'
export const chatLog = 'chatbot/logs'
