import React, { useEffect, useRef, useState } from 'react'
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import Point from 'ol/geom/Point'
import { Feature } from 'ol'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { fromLonLat } from 'ol/proj'
import { ReactComponent as Add } from './Image/Add.svg'
import { ReactComponent as Minus } from './Image/Minus.svg'
import OLVectorLayer from 'ol/layer/Vector'
import { Icon, Style, Fill, Stroke } from 'ol/style'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import GeoJSON from 'ol/format/GeoJSON'
import IconPinGreen from 'assets/images/svg/circle-green.svg'
import IconPinOrange from 'assets/images/svg/pin-orange.svg'
import IconPinBlue from 'assets/images/svg/circle-blue.svg'
import IconPinRed from 'assets/images/svg/circle-red.svg'
import IconPinYellow from 'assets/images/svg/circle-yellowsvg.svg'
import IconPinPurple from 'assets/images/svg/circle-purple.svg'

const MapCirCle = ({ zooms, height, isPrint, heatmapData }) => {
  const transformedData = []
  const mapRef = useRef(null)
  const [zoom, setZoom] = useState(12)

  const zoomIn = () => {
    setZoom(prevZoom => Math.min(prevZoom + 0.2, 18)) // Limit max zoom level to 18
  }

  const zoomOut = () => {
    setZoom(prevZoom => Math.max(prevZoom - 0.2, 0)) // Limit min zoom level to 0
  }

  heatmapData?.forEach(item => {
    if (item?.coordinates_data) {
      item?.coordinates_data?.forEach(coordinate => {
        transformedData?.push({
          status_name: item?.status_name,
          latitude: coordinate?.latitude,
          longitude: coordinate?.longitude,
        })
      })
    } else {
      transformedData.push({
        status_name: item?.status_name,
        latitude: null,
        longitude: null,
      })
    }
  })

  const styles = feature => {
    return new Style({
      fill: new Fill({
        color: '#EAEAEB',
      }),
      stroke: new Stroke({
        color: '#fefeff',
        width: 2,
      }),
    })
  }
  const center = fromLonLat([102.0859614, 14.9742147])
  useEffect(() => {
    const points = transformedData.map(coord => {
      const point = new Point(fromLonLat([coord.longitude, coord.latitude])) // OpenLayers uses [lon, latitude]
      return new Feature(point)
    })
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(NakhonRatchasima, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }),
    })

    const vectorLayer = new OLVectorLayer({
      source: vectorSource,
      style: styles,
      //   background: '#FAFAFA',
    })

    const map = new Map({
      target: 'map',
      layers: [vectorLayer],
      view: new View({
        center: center,
        zoom: zoom ? zoom : 12,
      }),
      controls: [],
    })

    transformedData.map(data => {
      const iconFeature = new Feature({
        geometry: new Point(fromLonLat([data?.longitude, data?.latitude])),
      })
      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          crossOrigin: 'anonymous',
          src:
            data.status_name === 'รับเรื่อง' || data.status_name === 'received'
              ? IconPinPurple
              : data.status_name === 'อยู่ระหว่างดำเนินการ' || data.status_name === 'in progress'
              ? IconPinYellow
              : data.status_name === 'ดำเนินการแล้วเสร็จ' || data.status_name === 'done'
              ? IconPinGreen
              : data.status_name === 'ไม่อยู่ในความรับผิดชอบ' || data.status_name === 'outside the area'
              ? IconPinBlue
              : data.status_name === 'ติดตาม' || data.status_name === 'tracking'
              ? IconPinOrange
              : IconPinRed,
        }),
      })
      iconFeature.setStyle(iconStyle)
      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [iconFeature],
        }),
      })
      map.addLayer(vectorLayer)
    })

    return () => {
      map.dispose()
    }
  }, [heatmapData, zoom])

  return (
    <>
      <div
        id="map"
        ref={mapRef}
        style={{ width: isPrint ? '400px' : '100%', height: height ? height : '400px', position: 'relative' }}
      >
        {!isPrint && (
          <div
            style={{
              position: 'absolute',
              bottom: '2%',
              right: '2%',
              background: '#fff',
              borderRadius: '10px',
              padding: '10px',
              zIndex: 1,
            }}
          >
            <div style={{ cursor: 'pointer' }} onClick={() => zoomIn()}>
              <Add />
            </div>
            <hr style={{ margin: 0, border: '1px solid #FFC4AA' }} />
            <div style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => zoomOut()}>
              <Minus />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MapCirCle
