import React from 'react'
import { PaginationCustom, Container, CustomText } from './pagination.style'
import { Pagination } from 'antd'

const Paginations = props => {
  let { defaultCurrent, total, onChange, current, page, setPage, dataLength, pageSize } = props

  return (
    <Container>
      <Pagination
        className="middle"
        defaultCurrent={page && page}
        total={dataLength && dataLength}
        pageSize={pageSize && pageSize}
        current={page && page}
        onChange={setPage && setPage}
        showSizeChanger={false}
      />
    </Container>
  )
}

export default Paginations
