import styled from 'styled-components'

export const InputContainer = styled.div`
  /* padding-bottom: 16px; */
  min-height: 102px;

  label {
    font-family: 'Prompt';
    padding-bottom: 8px;
    font-weight: 400;
    font-size: ${props => (props.labelSize ? props.labelSize : '16px')};
    color: #150f0b;
  }

  .ant-input {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 40px;
    max-width: 824px;
    color: #150f0b;
    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      color: #fc6521;
      box-shadow: none;
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 48px;
    max-width: 824px;
    color: #fc6521;
    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
    //box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #ffeee7;
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #ffeee7;
    border-color: #ffc4aa !important;
    color: #ffc4aa;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  @media only screen and (max-width: 768px) {
    /* margin-bottom: 50px; */
    margin-bottom: ${props => (props.className === 'nobottom' ? '0px' : '50px')};

    label {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 375px) {
    /* margin-bottom: 50px; */

    margin-bottom: ${props => (props.className === 'nobottom' ? '0px' : '50px')};

    label {
      font-size: 12px;
    }
  }
`
