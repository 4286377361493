import React, { useState, useEffect, useContext } from 'react'
import { CustomDrawer, BtSubmit, LabelText, BoxLabel, StyleDropdown, CustomModalSubmitOfficer } from './list/list.style'
import DropdownForm from 'components/Inputs/DropdownForm'
import { Spin } from 'antd'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconTitleAddOfficer } from 'assets/images/svg/icon-title-add-officer.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getData, postData, putData } from 'core/action/collection'
import { complaint } from 'core/schemas'
import { dePartMentIcon } from './container'
import { Select, Form } from 'antd'
import UserContext from 'core/contexts/userContext'
const { Option } = Select
const EditOfficer = ({
  openDrawerEditOfficer,
  setLeaveModalOpen,
  loadingAddOfficer,
  deparmentData,
  organizeId,
  listAdmin,
  complaintId,
  onCloseEditOfficer,
  openNotification,
  getDataComplant,
  getAllDataComplant,
  getDataView,
  getDataId,
}) => {
  const { meData } = useContext(UserContext)
  const [openModal, setOpenModal] = useState(false)

  const selectedDepartment = deparmentData?.find(item => item?.id === organizeId)

  const userId = selectedDepartment?.officer_data?.id

  const officerId = selectedDepartment?.officer_data?.officer_id

  const filteredUsers = listAdmin.filter(user => user.organize_data.org_id === selectedDepartment?.id)

  const optionsUser = filteredUsers.map(user => ({
    label: `${user.first_name.th} ${user.last_name.th}`,
    value: user.id,
  }))

  const Schema = Yup.object().shape({
    staff_by: Yup.string().required(''),
  })

  const formik = useFormik({
    initialValues: { staff_by: userId ? userId : '' },
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  const isValidate = formik.dirty && formik.isValid

  const onChangeDropdwon = (id, value) => {
    formik.setFieldValue('staff_by', value)
  }

  const onSubmit = async () => {
    // const payload = {
    //   department_id: selectedDepartment.id,
    //   staff_by: formik.values.staff_by,
    // }

    // const res = await putData(`${complaint}/officer`, officerId, payload)

    // if (res) {
    //   if (res?.code === 200) {
    //     setOpenModal(false)
    //     formik.resetForm()
    //     onCloseEditOfficer()
    //     openNotification('editofficer')
    //     getDataComplant && getDataComplant()
    //     getAllDataComplant && getAllDataComplant()
    //     getDataId && getDataId()
    //     getDataView && getDataView()
    //   } else {
    //     console.log('Err', res)
    //     setOpenModal(false)
    //   }
    // }

    const payload = {
      complaint_id: complaintId,
      department_id: selectedDepartment.id,
      noti_type: 'wait_transfer',
      staff_receive: formik.values.staff_by,
      staff_send: meData?.id,
    }

    const res = await postData(`${complaint}/transfer/officer`, payload)

    if (res) {
      if (res?.code === 200 || 201) {
        setOpenModal(false)
        formik.resetForm()
        onCloseEditOfficer()
        openNotification('แก้ไขผู้รับผิดชอบเรียบร้อยแล้ว')
        getDataComplant && getDataComplant()
        getAllDataComplant && getAllDataComplant()
        getDataId && getDataId()
        getDataView && getDataView()
      } else {
        console.log('Err', res)
        setOpenModal(false)
      }
    }
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <CustomDrawer
        placement="right"
        open={openDrawerEditOfficer}
        onClose={() => setLeaveModalOpen(true)}
        closable={false}
        key={openDrawerEditOfficer ? 'open' : 'close'}
      >
        {loadingAddOfficer ? (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
            <Spin />
          </div>
        ) : (
          <>
            <div style={{ height: '6.5em', position: 'relative' }}>
              <div className="close-icon" onClick={() => setLeaveModalOpen(true)}>
                <IconClear />
              </div>
            </div>
            <div className="wrap-body">
              <div className="wrap-title">
                <IconTitleAddOfficer style={{ marginRight: '16px' }} />
                <div className="wrap-box-title">
                  <div className="title">กรุณาเลือกผู้รับผิดชอบ</div>
                </div>
              </div>

              <div style={{ marginBottom: '1rem' }}>
                <LabelText>
                  <BoxLabel>
                    <div style={{ marginRight: '8px' }}>{dePartMentIcon[selectedDepartment?.name]}</div>
                    <div>{selectedDepartment?.name}</div>
                  </BoxLabel>
                </LabelText>
                <StyleDropdown>
                  <Select
                    onChange={value => onChangeDropdwon(selectedDepartment?.value, value)}
                    placeholder="กรุณาเลือก"
                    value={formik.values.staff_by}
                    style={{ width: '100%' }}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    {optionsUser &&
                      optionsUser?.map((item, index) => (
                        <Option key={index} value={item?.value}>
                          {item?.label}
                        </Option>
                      ))}
                  </Select>
                </StyleDropdown>
              </div>

              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '4em',
                  marginBottom: '4em',
                }}
              >
                <BtSubmit disabled={!isValidate} onClick={() => showModal()}>
                  ยืนยัน
                </BtSubmit>
              </div>
            </div>
          </>
        )}
      </CustomDrawer>

      <CustomModalSubmitOfficer
        transitionName=""
        centered
        open={openModal}
        onOk={() => onSubmit()}
        onCancel={() => onCloseModal()}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">ยืนยันการแก้ไขข้อมูลเจ้าหน้าที่</p>
        <p className="modal-message">หากคุณแก้ไขข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้</p>
      </CustomModalSubmitOfficer>
    </>
  )
}

export default EditOfficer
