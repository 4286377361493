import styled from 'styled-components'
import { Space, Modal, Popover } from 'antd'
export const Content = styled.div`
  .text-title {
    font-size: 24px;
    font-weight: 500;
    color: #150f0b;
    margin-bottom: 10px;
  }
  .svg-go-back {
    margin-right: -10px;
    cursor: pointer;
  }
  .box-header-form {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    .text-title {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .svg-go-back {
      width: 80px;
    }
    .box-header-form {
      height: 20px;
    }
  }
`
export const BoxSuccess = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10% 5%;
  margin: 20px;
  font-family: Prompt, sans-serif;
  display: flex;
  justify-content: center;
  height: 80vh;
  .ant-btn {
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    background: #f36b24;
    width: 100%;
    height: 45px;
  }
`
export const BoxForm = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 2%;
  margin: 20px;
  font-family: Prompt, sans-serif;
  @media screen and (max-width: 768px) {
    div {
      font-size: 12px;
    }
  }
  .ant-input[disabled] {
    background-color: #ffeee7 !important;
    border: 1px solid #fff8f4 !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .chLLrG .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex !important;
    align-items: center !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #ffeee7 !important;
    border: 1px solid #fff8f4 !important;
    height: 40px;
    border-radius: 8px;
    color: rgb(243, 107, 36) !important;
  }
  .box-date-complaint {
    display: flex;
    background-color: #fff3ee;
    border-radius: 10px;
    padding: 10px;
    width: 250px;
    height: 45px;
    align-items: center;
    margin-bottom: 10px;
  }
  .ant-input {
    border-radius: 8px;
    border-color: #ffeee7;
    height: 40px;
    color: #f36b24 !important;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-input:focus {
    border-color: #f36b24;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #fff;
    border: 1px solid #ffeee7;
    color: #f36b24 !important;
    height: 40px;
    border-radius: 8px;
  }

  .ant-picker {
    background: #ffffff !important;
    border: 1px solid #ffeee7 !important;
    border-radius: 8px !important;
    height: 40px !important;
    width: 100% !important;
    color: #ffc4aa !important;
  }
  .ant-picker-input > input {
    color: #ffc4aa !important;
  }
  .ant-picker-input::placeholder > input {
    color: #ffc4aa !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #ffc4aa;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow {
    color: #f36b24;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 5px;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-color: #f36b24 !important;
  }
`
export const CustomUploadfile = styled.div`
  .upload {
    border: 1px dashed #ffc4aa !important;
  }
  .dest1 {
    font-family: 'Prompt';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px; /* 120% */
    color: #150f0b !important;
  }
  .dest2 {
    font-family: 'Prompt';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: #150f0b !important;
  }
  .underline {
    color: #f36b24 !important;
  }
`
export const BoxBtnInMap = styled.div`
  .ant-btn {
    box-shadow: none;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    background: #f36b24;
  }
`
export const BoxBtn = styled.div`
  display: flex;
  justify-content: end;
  padding: 2%;
  @media screen and (max-width: 768px) {
    padding: 2% 2% 15% 2%;
  }

  .ant-btn {
    box-shadow: none;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    background: #f36b24;
    width: auto;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    border: 1px solid #d9d9d9;
    background: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
`

export const BoxAutocomplate = styled.div`
  position: absolute !important;
  top: 20px;
  left: 20px;
  width: 300px;
  height: auto;
  background: #ffffff;
  border-radius: 16px;
  padding: 15px;
  font-family: Prompt, sans-serif;
  .ant-input {
    border-radius: 8px;
    border-color: #ffeee7;
    height: 35px;
    color: #f36b24 !important;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-input-affix-wrapper:hover {
    border-color: #f36b24 !important;
    border-right-width: 1px;
  }
  .ant-input-affix-wrapper {
    border-color: #ffeee7 !important;
    border-right-width: 1px;
    border-radius: 8px;
    height: 38px;
  }
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: #f36b24;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }
  .ant-input:focus {
    border-color: #f36b24;
    box-shadow: none;
  }
`
export const CustomModal = styled(Modal)`
  font-family: 'Prompt';
  .ant-modal-mask {
    background: rgba(21, 15, 11, 0.5) !important;
    backdrop-filter: blur(8px) !important;
  }

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      color: #c4c4c4 !important;
      font-style: normal;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 14px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomPopover = styled(Popover)`
  .ant-popover-inner {
    background: #001f34;
    box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
    backdrop-filter: blur(6px);
    border-radius: 24px;
  }

  .ant-popover-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fbfbfb;
  }
  .ant-popover-inner-content {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fbfbfb;
  }
`
