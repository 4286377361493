import React, { useState, useEffect, useRef } from 'react'
import iconarrowright from 'assets/images/svg/icon-arrow-right-org.svg'
import iconpinLocation from 'assets/images/svg/icon-pin-location.svg'
import iconcurrentLocation from 'assets/images/svg/icon-current-location.svg'
import iconnearLocation from 'assets/images/svg/icon-nearocation.svg'
import CurrantLocation from './CurrantLocation'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-orange.svg'
import InputSearch from 'components/Inputs/InputSearch/InputSearch'
import { postData, putData, getData, getList } from 'core/action/collection'
import { complaintmap } from 'core/schemas'
import AutoComplete from 'components/Inputs/AutoComplete'
import { ReactComponent as IconCurrentLocationright } from 'assets/images/svg/icon-current-location-org.svg'
import { ReactComponent as IconCurrentLocationchangeright } from 'assets/images/svg/icon-current-location-change.svg'

import {
  ButtonOpenMap,
  ModalMap,
  mapStyle,
  BoxSelect,
  BoxMaparea,
  BoxMapSearch,
  Boxtextlocation,
  Boxhead,
  Boxtextlocationarea,
  BtSubmit,
  Boxmapmaker,
  Boxsearchgoogle,
  DetailLocation,
} from './Map.style'
import axios from 'axios'
import { GoogleMap, Marker, InfoWindow, InfoWindowF, StandaloneSearchBox } from '@react-google-maps/api'

import { ReactComponent as IconZoomIn } from 'assets/images/svg/icon-zoom-in.svg'
import { ReactComponent as IconZoomOut } from 'assets/images/svg/icon-zoom-out.svg'
import Makercustom from 'assets/images/svg/markercustom.svg'
import { Button, Row, Col, Input, Collapse, Modal, Image, AutoComplete as AutoCompleteAntd } from 'antd'
import './Map.css'
import { logDOM } from '@testing-library/react'

const MapButton = props => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })
  const [loading, setLoading] = useState(true)
  const [confirmlocation, setconfirmlocation] = useState(false)
  const [confirmlocationdetail, setconfirmlocationdetail] = useState()
  const zoomInRef = useRef(null)
  const zoomOutRef = useRef(null)
  const [zoom, setZoom] = useState(10)
  const [center, setCenter] = useState({ lat: 0, lng: 0 })
  const [map, setMap] = useState(null)
  const [error, setError] = useState(null)
  const [clickmap, setclickmap] = useState(false)
  const [locationName, setLocationName] = useState('')
  const [searchBox, setSearchBox] = useState(null)
  const [current, setcurrent] = useState(false)

  useEffect(() => {
    props.submitcheck && setconfirmlocationdetail('')
  }, [props.submitcheck])

  const onSearchBoxLoad = ref => {
    setSearchBox(ref)
  }

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces()

      if (places.length > 0) {
        const selectedPlace = places[0]
        const { lat, lng } = selectedPlace.geometry.location.toJSON()
        setMeLocation({
          lat: lat,
          lng: lng,
        })

        setCenter({
          lat: lat,
          lng: lng,
        })
        covertTothai(lat, lng)
      }
    }
  }

  const handleOpenModal = () => {
    setIsModalVisible(true)
    setconfirmlocation(false)
    setclickmap(false)
  }

  const handleCloseModal = () => {
    setIsModalVisible(false)
    setconfirmlocation(false)
    setcurrent(false)
    // setsubmitsuccess(false)
  }

  const handleOpenMap = () => {
    if (meLocation) {
      handleOpenModal()
    } else {
      console.warn('Current position not available.')
    }
  }

  const covertTothai = async (latitude, longitude) => {
    const res = await getData({
      schema: `${complaintmap}/map`,
      params: {
        lat: latitude,
        lng: longitude,
        language: 'th',
      },
      url: '',
      baseURL: '',
    })

    if (res) {
      setLocationName(res.data.results[0].formatted_address)
    }
  }
  const getNearbyLocations = async location => {
    const locationcurrent = location.lat.toString() + ',' + location.lng.toString()

    const res = await getData({
      schema: `${complaintmap}/place`,
      params: {
        keyword: '',
        location: locationcurrent,
        radius: '500',
        type: '',
        language: 'th',
      },
      url: '',
      baseURL: '',
    })
    let listArealocation = []
    let resp = res.data.results

    listArealocation.push()
    if (res) {
      // setLocationName(res.data.results[0].formatted_address);
    }
  }
  const handleGetCurrentPosition = type => {
    setLoading(true)
    setError(null)
    if (type === 'currentlocation') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setMeLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })

            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            setZoom(16)
            covertTothai(position.coords.latitude, position.coords.longitude)
            setcurrent(true)
          },
          error => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.error('User denied the request for Geolocation.')
                break
              case error.POSITION_UNAVAILABLE:
                console.error('Location information is unavailable.')
                break
              case error.TIMEOUT:
                console.error('The request to get user location timed out.')
                break
              default:
                console.error('An unknown error occurred.')
                break
            }
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setMeLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })

            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            setZoom(16)
            covertTothai(position.coords.latitude, position.coords.longitude)
            setcurrent(false)
          },
          error => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.error('User denied the request for Geolocation.')
                break
              case error.POSITION_UNAVAILABLE:
                console.error('Location information is unavailable.')
                break
              case error.TIMEOUT:
                console.error('The request to get user location timed out.')
                break
              default:
                console.error('An unknown error occurred.')
                break
            }
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }

    setLoading(false)
  }

  const handleZoomIn = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1)
    }
  }

  const handleZoomOut = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1)
    }
  }

  const onLoad = map => {
    setMap(map)
  }

  const onClickMap = event => {
    const { latLng } = event
    const lat = latLng.lat()
    const lng = latLng.lng()

    setMeLocation({
      lat: lat,
      lng: lng,
    })
    setCenter({
      lat: lat,
      lng: lng,
    })
    covertTothai(lat, lng)
    setclickmap(true)
    setconfirmlocation(true)
    setcurrent(false)
  }

  useEffect(() => {
    handleGetCurrentPosition()
  }, [])

  const mapStyles = {
    height: '700px',
    width: '100%',
    borderRadius: '25px',
  }

  const OnClickLocation = () => {
    setconfirmlocationdetail(locationName)
    props.OnReturnLocation(meLocation?.lat, meLocation?.lng)
    handleCloseModal()
  }

  const GoogleMapRender = () => {
    return (
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoom}
        center={clickmap == false ? center : null}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          // draggableCursor: isClickDistance ? 'crosshair' : '',
          minZoom: 6,
          clickableIcons: false,
          styles: mapStyle,
          restriction: {
            latLngBounds: {
              north: 20.856935, // North boundary of Thailand
              south: 5.612851, // South boundary of Thailand
              west: 97.337267, // West boundary of Thailand
              east: 105.638703, // East boundary of Thailand
            },
          },
        }}
        onLoad={onLoad}
        onClick={e => onClickMap(e)}
      >
        <BoxMapSearch
          style={{
            height: confirmlocation == false ? '140px' : '180px',
          }}
        >
          <Boxsearchgoogle>
            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
              options={{
                // Restrict search to Thailand
                componentRestrictions: { country: 'th', types: ['(regions)'] },
              }}
            >
              <BoxSelect>
                <InputSearch placeholder="ระบุชื่อสถานที่ที่ต้องการ" />
              </BoxSelect>
            </StandaloneSearchBox>
          </Boxsearchgoogle>

          <Boxtextlocation
            onClick={() => {
              setconfirmlocation(true)
            }}
          >
            <div className="col-location-1">
              <img src={iconcurrentLocation} />
            </div>
            <div className="col-location-2">
              เลือกตำแหน่งที่ตั้ง
              <div className="detaillocation">{locationName.substring(0, 30)}...</div>
            </div>
          </Boxtextlocation>
          {confirmlocation == true && (
            <BtSubmit
              onClick={() => {
                OnClickLocation()
              }}
              style={{
                height: '40px !important',
                marginTop: '5px',
              }}
            >
              ยืนยันตำแหน่ง
            </BtSubmit>
          )}
        </BoxMapSearch>

        <div id={'option-button'}>
          <div style={{ paddingBottom: '8px' }}>
            <Button
              className="btn-get-location"
              onClick={() => handleGetCurrentPosition('currentlocation')}
              icon={
                meLocation?.lat && current == true ? <IconCurrentLocationchangeright /> : <IconCurrentLocationright />
              }
            />
          </div>
          <div>
            <Button className="btn-zoom-in" ref={zoomInRef} onClick={() => handleZoomIn()} icon={<IconZoomIn />} />
          </div>
          <div>
            <Button className="btn-zoom-out" ref={zoomOutRef} onClick={() => handleZoomOut()} icon={<IconZoomOut />} />
          </div>
        </div>
        {confirmlocation || clickmap ? (
          <Marker position={center} icon={Makercustom}>
            <InfoWindow
              position={center}
              options={{
                maxWidth: 400,
                zIndex: 1,
              }}
            >
              <Boxmapmaker>{locationName}</Boxmapmaker>
            </InfoWindow>
          </Marker>
        ) : (
          meLocation?.lat && <CurrantLocation meLocation={meLocation} />
        )}
      </GoogleMap>
    )
  }

  return (
    <div>
      <ButtonOpenMap onClick={handleOpenMap}>
        {/* <div className="col-location-1">
          <img src={iconpinLocation} alt="icon" />
        </div> */}
        <div className="col-location-2">
          <div>เลือกตำแหน่งที่ตั้ง</div>
          <DetailLocation text={confirmlocationdetail}>{confirmlocationdetail}</DetailLocation>
        </div>
        <div className="col-location-3">
          <img src={iconarrowright} alt="arrow" />
        </div>
      </ButtonOpenMap>

      <ModalMap
        title="เลือกตำแหน่งที่ตั้ง"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        className="model-map-create-complant"
        zIndex={1051}
        footer={null}
        header={null}
        centered
      >
        {loading ? <p>Loading...</p> : GoogleMapRender()}
      </ModalMap>
    </div>
  )
}

export default MapButton
