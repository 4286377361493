import * as Yup from 'yup'

export const defaultValues = {
  name: '',
  description: '',
}

export const Schema = Yup.object().shape({
  name: Yup.string()
    .max(35, 'กรุณากรอกเป็นตัวอักษร ไม่เกิน 35 ตัวอักษร')
    .required('')
    .matches(/^[a-zA-Z0-9\u0E00-\u0E7F]+$/, 'กรุณากรอกเป็นตัวอักษร ไม่เกิน 35 ตัวอักษร'),
  description: Yup.string().max(255, 'กรุณาพิมพ์ข้อความความยาวไม่เกิน 255 ตัวอักษร'),
})
