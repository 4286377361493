import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { ThaiFormattedDate } from './container'
export default function ExportPdf({ dataSource, componentRefAll, searchData }) {
  const chunkArray = (arr, size) => {
    const chunkedArray = []
    for (let i = 0; i < arr.length; i += size) {
      chunkedArray.push(arr.slice(i, i + size))
    }
    return chunkedArray
  }

  const pageSize = 12
  const subArrays = chunkArray(dataSource, pageSize)
  const colums = [
    {
      title: <div style={{ fontSize: '10px', textAlign: 'center' }}>ลําดับ</div>,
      dataIndex: 'sorts',
      width: '100px',
      render: (text, data) => <div style={{ fontSize: '10px', textAlign: 'center' }}>{text}</div>,
    },
    {
      title: <div style={{ fontSize: '10px' }}>ประเภท</div>,
      dataIndex: 'type_of_complaint',
      width: '150px',
      render: (text, data) => (
        <div style={{ fontSize: '10px', textAlign: 'left' }}>{data?.type_of_complaint?.category_name}</div>
      ),
    },
    {
      title: <div style={{ fontSize: '10px' }}>หัวข้อ</div>,
      width: '200px',
      dataIndex: 'title_complaint',
      render: (text, data) => <div style={{ fontSize: '10px', textAlign: 'left' }}>{text ? text : '-'}</div>,
    },
    {
      title: <div style={{ fontSize: '10px' }}>รายละเอียด</div>,
      width: '200px',
      dataIndex: 'description',
      render: (text, data) => <div style={{ fontSize: '10px', textAlign: 'left' }}>{text ? text : '-'}</div>,
    },
    {
      title: <div style={{ fontSize: '10px' }}>หน่วยงานที่รับผิดชอบ</div>,
      dataIndex: 'department',
      render: (text, data) => (
        <div style={{ fontSize: '10px', textAlign: 'left' }}>
          {text}
          {/* {data?.department_data?.map((value, index, array) => {
            return (
              <div key={index} style={{ color: '#556A82', fontSize: '12px' }}>
                {value?.department_name}
              </div>
            )
          })} */}
        </div>
      ),
    },
    {
      title: <div style={{ fontSize: '10px' }}>วันที่รับเรื่องร้องเรียน</div>,
      render: (text, data) => (
        <div style={{ fontSize: '10px', textAlign: 'left' }}>{conversDate(data?.created_at)}</div>
      ),
    },
    {
      title: <div style={{ fontSize: '10px' }}>หมายเหตุ</div>,
      dataIndex: 'หมายเหตุ',
      render: (text, data) => <div>-</div>,
    },
  ]

  return (
    <Box ref={componentRefAll}>
      <div>
        {subArrays.map((value, index, array) => {
          return (
            <div key={index} style={{ margin: '2%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <div>
                  <div style={{ textAlign: 'center' }}>รายงานบริการสาธารณะ</div>
                  <div style={{ textAlign: 'center' }}>
                    ประจำวันที่ {ThaiFormattedDate(searchData?.date[0])} ถึง {ThaiFormattedDate(searchData?.date[1])}
                  </div>
                </div>
              </div>
              <Table dataSource={value} columns={colums} pagination={false} />
              <div style={{ pageBreakAfter: 'always' }} />
            </div>
          )
        })}
      </div>
    </Box>
  )
}
export const Box = styled.div`
  padding: 2%;
  font-family: 'Prompt' !important;
  @media print {
    @page {
      size: A4 landscape;
      margin: 20mm !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    position: relative;
    padding: 8px !important;
    overflow-wrap: break-word;
  }
`
