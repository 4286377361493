import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { ReactComponent as Logo } from 'assets/images/svg/icon-korat-city-blacktext.svg'
import BackIcon from 'assets/images/svg/button-back-login.svg'
import BackIconMobile from 'assets/images/svg/button-back-login-mobile.svg'
import { ReactComponent as PageNumber1 } from 'assets/images/svg/number-page-1.svg'
import { ReactComponent as PageNumber2 } from 'assets/images/svg/number-page-2.svg'
import { ReactComponent as IconEmail } from 'assets/images/svg/icon-email.svg'
import { ReactComponent as VerifyEmail } from 'assets/images/svg/icon-verify-email.svg'
import BgLogin from 'assets/images/png/BackgroundPage-login.png'
import Error from 'assets/images/png/cross.png'

import { useFormik } from 'formik'
import { Input } from 'components/input'
import InputFormEmail from 'components/input/InputFormEmail'
import { Container, BoxContent, BoxInput, BtSubmit, ModalConfirmEmail, BtSubmitModal } from './formemail.style'
import { Space } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { getData, postData } from 'core/action/collection'
import { verifyEmail } from 'core/schemas'

const Index = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [isModalErrorOpen, setIsModalErrorOpen] = React.useState(false)
  const [isOtpError, setIsOtpError] = React.useState(false)

  const isSizeMobile = 768

  useEffect(() => {
    state?.line_id === null && navigate('/login')
  }, [])

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const defaultValues = {
    email: '',
  }

  const Schema = Yup.object().shape({
    email: Yup.string()
      .required('')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'รูปแบบอีเมลล์ไม่ถูกต้อง'),
  })

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const isValidForm = formik.isValid && formik.dirty

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCheckEmail = async () => {
    setIsModalOpen(false)

    const res = await getData({
      schema: `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/email/${formik.values.email}`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const payload = {
          channel: 'email',
          contact: formik.values.email,
          expire_time: 300,
          id: 'string',
          length_otp: 6,
          length_ref_code: 6,
          service_name: 'admin',
          verify_count: 3,
          line_id: state?.line_id,
          line_name: state?.line_name,
        }

        const resSentOtp = await postData(`${verifyEmail}/request`, payload)

        if (resSentOtp?.code === 200) {
          setIsModalOpen(false)
          navigate('/flow-login/otp', {
            state: {
              ref_code: resSentOtp.data.ref_code,
              email: formik.values.email,
              line_id: state?.line_id,
              line_name: state?.line_name,
            },
          })
        } else if (resSentOtp?.code === 422) {
          setIsOtpError(true)
        }
      } else {
        setIsModalErrorOpen(true)
      }
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handelSubmit = () => {
    showModal(true)
  }

  const checkEmail = async () => {
    const res = await getData(`${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/email/${formik.values.email}`)

    if (res) {
      if (res?.code === 200) {
      }
    }
  }

  const clickBack = () => {
    localStorage.clear()
    navigate('/login')
  }

  return (
    <>
      <Container url={BgLogin}>
        <Logo />
        <BoxContent>
          <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'space-between' }}>
            {/* <BackIcon
              // style={{ cursor: 'pointer',  }}
              onClick={() => navigate(-1)}
            /> */}
            <img
              src={windowWidth <= isSizeMobile ? `${BackIconMobile}` : `${BackIcon}`}
              alt="backicon"
              onClick={() => clickBack()}
              style={{ cursor: 'pointer' }}
            />
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <Space size={windowWidth <= isSizeMobile ? 8 : 13}>
                <PageNumber1 className="number-page" />
                <PageNumber2 className="number-page" />
              </Space>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: windowWidth <= isSizeMobile ? '2em' : '2.563em',
            }}
          >
            <BoxInput>
              <IconEmail className="icon-mail" />
              <h3>กรอกอีเมลล์เพื่อเริ่มต้นการใช้งาน</h3>
              <InputFormEmail
                label="อีเมลล์"
                name="email"
                placeholder="กรอกอีเมลล์ของคุณ"
                type="text"
                required={true}
                errors={formik.errors}
                touched={formik.touched}
                form={formik}
                status={formik.status}
                maxLength={50}
                requiredPadding={1}
                // uniqueFunc={uniqueFunc}
                // uniqueValidate={uniqueValidate}
                // onBlur={formik.handleBlur}
                // disabled={disabled}
              />
              <BtSubmit type="primary" onClick={handelSubmit} disabled={!isValidForm}>
                ยืนยัน
              </BtSubmit>
            </BoxInput>
          </div>
        </BoxContent>
        <ModalConfirmEmail
          open={isModalOpen}
          onOk={handleCheckEmail}
          onCancel={handleCancel}
          footer={false}
          closable={false}
        >
          <VerifyEmail />
          <h5 className="title">กรุณายืนยันอีเมลล์ของคุณ</h5>
          <p className="description">โปรดตรวจกล่องจดหมายของคุณ เพื่อยืนยันตัวตน</p>
          {/* <br /> */}
          <p className="description">{formik.values.email}</p>
          <BtSubmitModal onClick={handleCheckEmail}>ยืนยัน</BtSubmitModal>
        </ModalConfirmEmail>

        <ModalConfirmEmail
          open={isModalErrorOpen}
          onCancel={() => setIsModalErrorOpen(false)}
          footer={false}
          closable={false}
        >
          <img src={Error} alt="" style={{ width: '120px', height: '120px', marginBottom: '2rem' }} />
          <h5 className="title">ไม่พบอีเมลล์นี้ในระบบ</h5>
          <BtSubmitModal onClick={() => setIsModalErrorOpen(false)}>ยืนยัน</BtSubmitModal>
        </ModalConfirmEmail>

        <ModalConfirmEmail open={isOtpError} onCancel={() => setIsOtpError(false)} footer={false} closable={false}>
          <img src={Error} alt="" style={{ width: '120px', height: '120px', marginBottom: '2rem' }} />
          <h5 className="title">การเข้าสู่ระบบไม่สำเร็จ กรุณารอสักครู่</h5>
          <BtSubmitModal onClick={() => setIsOtpError(false)}>ยืนยัน</BtSubmitModal>
        </ModalConfirmEmail>
      </Container>
    </>
  )
}

export default Index
