import React, { useRef, useEffect, useState } from 'react'
import Search from './search'
import TableCustom from 'components/TableCustom/Row'
import { conversDate } from 'core/utils/DateTimeFormat'
import PaginationCustom from 'components/PaginationCustom'
import { Dropdown } from 'antd'
import { useReactToPrint } from 'react-to-print'
import { BoxHeader, Menucustom, BoxTableCustom, BoxSinglePrint, BoxPrintAll } from './list.style'
import IconImport from 'assets/images/svg/icon-import-complaint.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import ExportComplante from '../export'
import ExportAllComplaint from './exportPdf'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as View } from 'assets/images/svg/View.svg'
import { ReactComponent as Export } from 'assets/images/svg/Export.svg'
import moment from 'moment'
import qs from 'qs'
import axios from 'axios'
import { checkStatusText, checkRating } from './container'
import { MoreOutlined } from '@ant-design/icons'
import { ReactComponent as Arrow } from '../image/svg/Arrow.svg'
import { ReactComponent as Arrow_Active } from '../image/svg/Arrow_Active.svg'
const DropdownCustom = Dropdown
const Index = () => {
  const [searchData, setSearchData] = useState({
    date: [moment().subtract(1, 'month'), moment()],
  })
  const [dataTable, setDataTable] = useState({ data: [], total: 0 })
  const [curantPage, setCurantPage] = useState(1)
  const [dataCatagory, setDataCatagory] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isPrint, setIsPrint] = useState(false)
  const [isPrintAll, setIsPrintAll] = useState(false)
  const componentRef = useRef(null)
  const componentRefAll = useRef(null)
  const [dataExport, setDataExport] = useState([])
  const navigate = useNavigate()
  const isSizeMobile = 768
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [sortDate, setSortDate] = useState(false)

  useEffect(() => {
    getDataReport()
  }, [curantPage, isPrintAll, searchData, sortDate])

  useEffect(() => {
    getDataCatagory()
  }, [])

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const colums = [
    {
      title: <div style={{ color: '#717171' }}>ลำดับ</div>,
      dataIndex: 'no',
      width: '80px',
      render: text => <div style={{ textAlign: 'center' }}>{text}.</div>,
    },
    {
      title: 'เรื่องที่ร้องเรียน',
      dataIndex: 'complaint_name',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: text => (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100px' }}>{text}</div>
      ),
    },
    {
      title: 'เลขที่เรื่องร้องเรียน',
      dataIndex: 'complaint_no',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
    },
    {
      title: 'ประเภทเรื่องร้องเรียน',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {data?.category_data?.icon_url && (
            <img src={data?.category_data?.icon_url} style={{ width: '14px', height: '14px' }} />
          )}
          {data?.category_data?.name ? data?.category_data?.name : '-'}
        </div>
      ),
    },
    {
      title: 'ประเภทย่อย',
      dataIndex: 'sub_category_name',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}</div>,
    },
    {
      title: 'หน่วยงานที่รับผิดชอบ',
      dataIndex: 'department_data',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => (
        <div>
          {text
            ? text?.map((value, index, array) => {
                return (
                  <div key={index} style={{ color: '#556A82' }}>
                    {value?.department_name ? value?.department_name : '-'}
                  </div>
                )
              })
            : '-'}
        </div>
      ),
    },
    {
      title: 'สถานะความเร่งด่วน',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => (data?.urgency ? <span>{data?.urgency}</span> : '-'),
    },
    {
      title: 'ความพึงพอใจ',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{data?.rating ? checkRating(data?.rating) : 'รอการยืนยัน'}</div>,
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: sortDate ? '#F36B24' : '#717171' }}>
          วันที่แจ้งเรื่อง
          {sortDate ? (
            <Arrow_Active
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSortDate(!sortDate)
              }}
            />
          ) : (
            <Arrow
              style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
              onClick={() => {
                setSortDate(!sortDate)
              }}
            />
          )}
        </div>
      ),
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{conversDate(data?.created_at)}</div>,
    },

    {
      title: 'วันที่กำหนดเสร็จ',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) => <div>{data?.duedate_at ? conversDate(data?.duedate_at) : '-'}</div>,
    },
    {
      title: 'วันที่แล้วเสร็จ',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      dataIndex: 'finishdate_at',
      render: (text, data) => <div>{data?.finishdate_at ? conversDate(data?.finishdate_at) : '-'}</div>,
    },
    {
      title: 'สถานะการร้องเรียน',
      ellipsis: windowWidth <= isSizeMobile ? true : false,
      render: (text, data) =>
        data?.status_data?.length ? (
          <span>
            {data?.status_data[data?.status_data?.length - 1]?.name
              ? checkStatusText(data?.status_data[data?.status_data?.length - 1]?.name)
              : '-'}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: '',
      width: '40px',
      render: (_, data) => {
        return (
          <DropdownCustom overlay={() => menu(data)} trigger={['click']} zIndex={1000}>
            <MoreOutlined style={{ cursor: 'pointer', fontSize: '26px' }} />
          </DropdownCustom>
        )
      },
    },
  ]

  const handleMenuList = (e, data) => {
    const { key } = e
    if (key === 'view') {
      navigate(e.key, {
        state: {
          data: data,
        },
      })
    } else if (key === 'export') {
      setIsPrint(true)
      getDataId(data)
    }
  }

  const getDataId = async data => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
      const compalintId = data?.id
      const resp = await axios.get(`${shemadata}/${compalintId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`, // Include the token in the Authorization header
        },
      })
      if (resp?.data?.code === 200) {
        setDataExport(resp?.data?.data)
        setTimeout(() => {
          handlePrint()
        }, 2000)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const menu = value => {
    return (
      <Menucustom onClick={e => handleMenuList(e, value)}>
        <Menucustom.Item key={`view`}>
          <View style={{ marginRight: '5px' }} />
          ดูข้อมูล
        </Menucustom.Item>
        <Menucustom.Item key={'export'}>
          <Export style={{ marginRight: '5px' }} />
          ออกใบงาน
        </Menucustom.Item>
      </Menucustom>
    )
  }

  const onLoadFile = async typeFile => {
    let startDate = moment(searchData?.date ? searchData.date[0] : null)?.unix()
    let endDate = moment(searchData?.date ? searchData.date[1] : null)?.unix()
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/report/complaint/export?start_at=${startDate}&end_at=${endDate}&file_extension=${typeFile}`,
        {
          params: {
            filters: [
              searchData?.search ? `complaint_name:like:${searchData?.search}` : undefined,
              searchData?.category ? `category_id:eq:${searchData?.category}` : undefined, // ? `category_id:eq:${searchData?.category}` : undefined,
            ],
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
          responseType: 'blob',
        }
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `รายงานเรื่องร้องเรียน_${moment(Date.now()).format('DD_MM_YYYY_HH_mm_ss')}.${typeFile}`
      )
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {}
  }

  const getDataReport = async () => {
    let startDate = moment(searchData?.date ? searchData.date[0] : null)
      // .subtract(543, 'years')
      .startOf('day')
      .unix()
    let endDate = moment(searchData?.date ? searchData.date[1] : null)
      // .subtract(543, 'years')
      .endOf('day')
      .unix()

    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/report/complaint?start_at=${
          startDate ? startDate : ''
        }&end_at=${endDate ? endDate : ''}`,
        {
          params: {
            page: isPrintAll ? 1 : curantPage,
            per_page: isPrintAll ? dataTable?.total : 12,
            sorts: [sortDate ? `created_at:asc` : `created_at:desc`],
            filters: [
              searchData?.search ? `complaint_name:like:${searchData?.search}` : undefined,
              searchData?.category ? `category_id:eq:${searchData?.category}` : undefined, // ? `category_id:eq:${searchData?.category}` : undefined,
            ],
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        }
      )
      .then(res => {
        if (res?.data?.code === 200) {
          setDataTable({
            data: res?.data?.data?.report_data,
            total: res.headers['x-content-length'],
          })
          if (isPrintAll && res?.data?.data?.report_data) {
            setTimeout(() => {
              handlePrintAll()
            }, 2000)
          }
        } else {
          setDataTable([])
        }
      })
  }

  const getDataCatagory = async () => {
    axios.get(`${process.env.REACT_APP_BASEURL}/master/api/v1/all/categories`).then(res => {
      if (res?.data?.code === 200) {
        setDataCatagory(res?.data?.data)
      } else {
        setDataCatagory([])
      }
    })
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `ใบงานเรื่องร้องเรียน_${moment(Date.now()).format('DD_MM_YYYY_HH_mm_ss')}`,
    pageStyle: `
    @media print {
    @page {
      size: A4 landscape;
      margin: 0mm !important;
    }
    .page-break {
      page-break-before: auto !important;
    }
  }
  `,
    onAfterPrint: () => setIsPrint(false),
  })

  const handlePrintAll = useReactToPrint({
    content: () => componentRefAll.current,
    documentTitle: `รายงานเรื่องร้องเรียน_${moment(Date.now()).format('DD_MM_YYYY_HH_mm_ss')}`,
    pageStyle: `
    @media print {
    @page {
      size: A4 landscape;
      margin: 0mm !important;
    }
   
  }
  `,
    onAfterPrint: () => setIsPrintAll(false),
  })

  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'export-pdf') {
      setIsPrintAll(true)
    } else if (e.key === 'export-excel') {
      onLoadFile('xlsx')
    } else if (e.key === 'export-csv') {
      onLoadFile('csv')
    }
  }
  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }
  const menuImport = value => {
    return (
      <Menucustom onClick={e => handleMenuImportClick(e)}>
        <Menucustom.Item key={`export-pdf`}>PDF</Menucustom.Item>
        <Menucustom.Item key={`export-excel`}>XLS</Menucustom.Item>
        <Menucustom.Item key={`export-csv`}>CSV</Menucustom.Item>
      </Menucustom>
    )
  }

  return (
    <>
      <div style={{ padding: windowWidth <= isSizeMobile ? '10px 10px' : '10px 40px' }}>
        <BoxHeader
          style={{
            color: '#150F0B',
            fontSize: windowWidth <= isSizeMobile ? '18px' : '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>รายงานเรื่องร้องเรียน</p>
          <Dropdown
            overlay={() => menuImport()}
            trigger={['click']}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onVisibleChange={handleDropdownVisibleChange}
            disabled={dataTable?.length === 0}
          >
            <div className="dropdown-export-data">
              <img src={IconImport} alt="add" style={{ display: 'inline' }} />
              <span>ส่งออกข้อมูล</span>
              <img
                src={IconArrowDown}
                alt="add"
                style={{
                  display: 'inline',
                  transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
              />
            </div>
          </Dropdown>
        </BoxHeader>

        <div>
          <BoxTableCustom>
            <Search
              setSearchData={setSearchData}
              searchData={searchData}
              dataCatagory={dataCatagory}
              responsive={windowWidth <= isSizeMobile ? true : false}
              setCurantPage={setCurantPage}
            />
            <div style={{ padding: '20px', background: '#fff', borderRadius: '10px' }}>
              <TableCustom columns={colums} dataSource={dataTable?.data} />
            </div>
          </BoxTableCustom>

          <div style={{ marginTop: '20px', marginBottom: '10px' }}>
            <PaginationCustom page={curantPage} setPage={setCurantPage} dataLength={dataTable?.total} pageSize={12} />
          </div>
        </div>

        {isPrint && (
          <BoxSinglePrint>
            <div style={{ position: 'absolute', bottom: '100%' }}>
              <ExportComplante componentRef={componentRef} data={dataExport} />
            </div>
          </BoxSinglePrint>
        )}
        {isPrintAll && (
          <BoxPrintAll>
            <div style={{ position: 'absolute', bottom: '100%' }}>
              <ExportAllComplaint
                componentRefAll={componentRefAll}
                searchData={searchData}
                dataSource={dataTable?.data}
              />
            </div>
          </BoxPrintAll>
        )}
      </div>
    </>
  )
}

export default Index
