import React, { useEffect, useState } from 'react'
import { CustomDrawer, BtSubmit, LabelText, BoxLabel, StyleDropdown, CustomModal } from './list/list.style'
import DropdownForm from 'components/Inputs/DropdownForm'
import { Spin } from 'antd'
import { complaint } from 'core/schemas'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconTitleAddOfficer } from 'assets/images/svg/icon-title-add-officer.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { getMe, postData } from 'core/action/collection'
import { dePartMentIcon } from './container'
const AddOfficer = ({
  openDrawerAddOfficer,
  setLeaveModalOpen,
  loadingAddOfficer,
  deparmentData,
  organizeId,
  listAdmin,
  complaintId,
  onCloseAddOfficer,
  openNotification,
  getDataEservice,
  getAllDataEservice,
  getDataId,
}) => {
  const departmentid = localStorage.getItem('organize')

  const Schema = Yup.object().shape({
    staff_by: Yup.string().required(''),
  })

  const formik = useFormik({
    initialValues: { staff_by: '' },
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const [openModal, setOpenModal] = useState(false)

  const isValidate = formik.dirty && formik.isValid

  const onChangeDropdwon = (id, value) => {
    formik.setFieldValue('staff_by', value)
  }

  const onSubmit = async () => {
    const payload = {
      e_service_id: complaintId,
      department_id: departmentid,
      staff_by: formik.values.staff_by,
    }

    const res = await postData(`${complaint}/e-service/officer`, payload)

    if (res) {
      if (res?.code === 201) {
        setOpenModal(false)
        onCloseAddOfficer()
        openNotification('addofficer')
        getDataId()
      } else {
        console.log('Err', res)
        setOpenModal(false)
      }
    }
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const onCloseModal = () => {
    setOpenModal(false)
  }

  const filteredUsers = listAdmin.filter(user => user.organize_data.org_id === deparmentData?.id)

  const optionsUser = filteredUsers.map(user => ({
    label: `${user.first_name.th} ${user.last_name.th}`,
    value: user.id,
  }))

  return (
    <>
      <CustomDrawer
        placement="right"
        open={openDrawerAddOfficer}
        onClose={() => setLeaveModalOpen(true)}
        closable={false}
      >
        {loadingAddOfficer ? (
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
            <Spin />
          </div>
        ) : (
          <>
            <div style={{ height: '6.5em', position: 'relative' }}>
              <div className="close-icon" onClick={() => setLeaveModalOpen(true)}>
                <IconClear />
              </div>
            </div>
            <div className="wrap-body">
              <div className="wrap-title">
                <IconTitleAddOfficer style={{ marginRight: '16px' }} />
                <div className="wrap-box-title">
                  <div className="title">เพิ่มข้อมูลเจ้าหน้าที่</div>
                  <div className="description">กรุณาเลือกผู้รับผิดชอบ</div>
                </div>
              </div>
              <div style={{ marginBottom: '1rem' }}>
                <LabelText>
                  <BoxLabel>
                    <div style={{ marginRight: '8px' }}>{dePartMentIcon[deparmentData?.name]}</div>
                    <div>{deparmentData?.name}</div>
                  </BoxLabel>
                </LabelText>
                <StyleDropdown>
                  <DropdownForm
                    title="เจ้าหน้าที่"
                    placeholder="กรุณาเลือก"
                    showSearch={false}
                    required={true}
                    onChange={value => onChangeDropdwon(deparmentData?.id, value)}
                    className="big-input"
                    options={optionsUser}
                  />
                </StyleDropdown>
              </div>

              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '4em',
                  marginBottom: '4em',
                }}
              >
                <BtSubmit disabled={!isValidate} onClick={() => showModal()}>
                  ยืนยัน
                </BtSubmit>
              </div>
            </div>
          </>
        )}
      </CustomDrawer>

      <CustomModal
        transitionName=""
        centered
        open={openModal}
        onOk={() => onSubmit()}
        onCancel={() => onCloseModal()}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">ยืนยันการเพิ่มข้อมูลเจ้าหน้าที่</p>
        <p className="modal-message">หากคุณเพิ่มข้อมูลเจ้าหน้าที่นี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้</p>
      </CustomModal>
    </>
  )
}

export default AddOfficer
