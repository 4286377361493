import styled, { createGlobalStyle } from 'styled-components'

export const Container = styled.div`
  padding-top: 1rem;
  padding-bottom: ${props => (props.path === '/home' ? '60px' : '150px')};
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  /* overflow: ${props =>
    props.path === '/complaint-staff' || props.path === '/complaint/bof-overview-complaint' ? 'hidden' : 'auto'}; */
  background-color: rgb(246, 246, 246);
  background-image: ${props => (props.bg ? `url(${props.bg})` : '#f8f8f8')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
export const GlobalStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'IBM Plex Sans Thai', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`
