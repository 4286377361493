import styled from 'styled-components'
import { Space, Button, Modal } from 'antd'

export const CustomSpace = styled(Space)`
  padding-top: 20px;
  .ant-btn-ghost {
    color: #f36b24;
    border-color: #f36b24;
    height: 40px;
    width: 88px;
    border-radius: 12px;
  }
  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 88px;
    border-radius: 12px;
  }
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  padding-top: 22px;

  @media screen and (max-width: 900px) {
    font-size: 5vw;
  }
`
export const Box = styled.div`
  background-color: white;
  min-height: 600px;
  align-items: center;
  border-radius: 20px;
  input:disabled {
    background-color: #fff8f4 !important;
    color: #ffc4aa !important;
  }
  textarea:disabled {
    background-color: #fff8f4 !important;
    color: #ffc4aa !important;
  }
  label {
    color: #150f0b !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.4px;
  }
`
export const ButtonEdit = styled(Button)`
  font-size: 16px;
  width: 129px;
  height: 40px;

  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid var(--web-backend-border-primary, #f36b24);
  border-color: #f36b24;
  color: black;
  :focus,
  :hover {
    color: black;
    border-color: transparent;
  }
  @media screen and (max-width: 900px) {
    width: 120px;
    height: 40px;
    font-size: 14px;
    margin: 10px 2px;
  }

  span {
    color: #f36b24;
  }
`
export const ButtonDelete = styled(Button)`
  font-size: 16px;
  width: 113px;
  height: 40px;
  background: #ed4160;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border-color: transparent;
  color: #ffffff;
  :focus,
  :hover {
    color: #ffffff;
    border-color: transparent;
    background: #ed4160;
  }

  @media screen and (max-width: 900px) {
    width: 100px;
    height: 40px;
    font-size: 14px;
    margin: 10px 5px;
  }
`
export const ButtonBack = styled(Button)`
  width: 40px;
  height: 40px;
  border: none !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    border-color: transparent;
    background: #fbfbfb !important;
  }
`

export const CustomModal = styled(Modal)`
  font-family: 'Prompt';

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 20px;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      font-style: normal;

      color: #c4c4c4 !important;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomModal2 = styled(Modal)`
  font-family: 'Prompt';

  .ant-modal-content {
    border-radius: 16px;
    width: 420px;
    height: 226px;
    margin: 0 auto;
    .ant-modal-body {
      padding: 24px 24px 10px 24px !important;
    }
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    .modal-title {
      font-weight: 400 !important;
      font-size: 16px !important ;
      font-style: normal;
    }
    .modal-message {
      margin-top: 8px;
      font-weight: 300 !important;
      font-size: 12px !important ;
      font-style: normal;

      color: #c4c4c4 !important;
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #f36b24;
      color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #f36b24;
      border-color: #f36b24;
      font-size: 16px;
      width: 120px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const StyleDropdown = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #fee0d3;
    height: 40px;
    width: 100%;
    /* padding: 4px 11px; */

    @media only screen and (max-width: 768px) {
      /* padding: 12px 16px; */
    }

    @media only screen and (max-width: 375px) {
      padding: 12px 16px;
    }
  }

  .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
    background-color: #fff3ee !important;
    color: #ffc4aa !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #fff3ee;
  }
`

export const LabelText = styled.div`
  font-family: 'Prompt';
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #150f0b;
  padding-bottom: 8px;
`
