import { createRef, useState, useMemo, useEffect } from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import ProtectedRoutes from 'routers/private'
import UserContext from 'core/contexts/userContext'
import { getData, getMe } from 'core/action/collection'
import Content from 'components/Layout/Content'
import Sider from 'components/Layout/Sider'
import Breadcrumb from 'components/Layout/Breadcrumb'
import Header from 'components/Layout/Header'
import Homepage from 'pages/Homepage'
import Reportpage from 'pages/Report'
import ReportEservicepage from 'pages/ReportEservice'
import MapComplaintPage from 'pages/MapComplaint'
import MapE_ServicePage from 'pages/MapE_Service'

import Chatpage from 'pages/Chat'
import Complaintpage from 'pages/Complaint'
import ComplaintAdmin from 'pages/ComplaintAdmin'
import E_Servicespage from 'pages/E_Services'
import StaticComplant from 'pages/Dashboard/StaticComplant'
import Managepage from 'pages/Manage/Managegroup'
import Managerole from 'pages/Manage/Managerole'
import ManageUser from 'pages/Manage/ManageUser'
import Notification from 'components/Notification'
import LoginPage from 'pages/Login'
import ResultPage from 'pages/ResultPage'
import SeparateUserGroupPage from 'pages/SeparateUserGroup'
import Newcomplaints from 'pages/Newcomplaints'
import ActivityLog from 'pages/Activitylog/list'
import DashboardStatusAdmin from 'pages/DashboardStatus'
import DashboardEservice from 'pages/DashboardEservice'
import DashboardExecutiveReport from 'pages/DashboadExecusiveReport'
import NewTransfer from 'pages/NewTransfer'
import DashboardOverviewAdmin from 'pages/DashboardOverviewAdmin'
import './App.css'

function App() {
  const [user, setUser] = useState(localStorage.user || null)
  const [userPermission, setUserPermission] = useState()
  const [roleId, setRoleId] = useState()
  const [modal, setmodal] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [map, setMap] = useState(null)
  const [meData, setMeData] = useState()
  const getToken = localStorage.getItem('access_token')

  const isSizeMobile = 900
  const body = createRef(null)
  const valueUser = {
    user,
    setUser,
    userPermission,
    setUserPermission,
    roleId,
    setRoleId,
    modal,
    setmodal,
    setMap,
    map,
    setMeData,
    meData,
  }

  useEffect(() => {
    if (!getToken) return
    getMeData()
  }, [])

  const getMeData = async () => {
    const res = await getMe({ token: getToken })
    if (res) {
      if (res?.code === 200) {
        localStorage.setItem('organize', res?.data?.organize_data?.org_id)
        setMeData(res.data)
      }
    }
  }

  const CoreLayout = ({ subMenu = [], offContainer = false }) => {
    const defaultMenu = [...subMenu]
    const handleScroll = () => {
      const heder = document.getElementById('sticky')
      const { current } = body
      if (!current) return
      const { scrollTop } = current
      if (heder) {
        if (scrollTop > 60) {
          heder.classList.remove('heder-out')
          heder.classList.add('heder-background')
        } else {
          heder.classList.remove('heder-background')
          heder.classList.add('heder-out')
        }
      }
    }

    return (
      <div>
        {!offContainer && (
          <>
            <div style={{ width: '100%', display: windowWidth <= 900 ? 'block' : 'flex', height: '100vh' }}>
              {/* <div style={{ width: '100%', display: 'flex', height: '100vh' }}> */}
              {windowWidth < isSizeMobile ? <Header /> : <Sider dataMenu={defaultMenu}></Sider>}
              <Content ref={body} onScroll={handleScroll}>
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumb />
                    <Notification />
                  </div>

                  <Outlet />
                </div>
              </Content>
            </div>
          </>
        )}
        {offContainer && (
          <div style={{ width: '100%', display: 'flex', height: '100vh' }}>
            {windowWidth <= isSizeMobile ? <Header /> : <Sider dataMenu={defaultMenu}></Sider>}
            <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
              <Outlet />
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <UserContext.Provider value={valueUser}>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate to="home" />} />
          <Route path="/" element={<CoreLayout />}>
            <Route path="/home" element={<Homepage />} />
            <Route path="/dashboard">
              <Route path="bof-overview" element={<DashboardOverviewAdmin />} />
              <Route path="bof-overview-eservice" element={<DashboardEservice />} />
              <Route path="bof-executive" element={<DashboardExecutiveReport />} />
              <Route path="bof-process-status" element={<DashboardStatusAdmin />} />
            </Route>
            <Route path="/dashboard">
              <Route path="/dashboard" element={<StaticComplant />} />
            </Route>
            <Route path="/operation">
              <Route path="bof-new-complaint" element={<Newcomplaints.List />} />
              <Route path="bof-new-complaint/view" element={<Newcomplaints.View />} />
              <Route path="bof-transfer" element={<NewTransfer.List />} />
              <Route path="bof-transfer/view" element={<NewTransfer.View />} />
            </Route>

            <Route path="/operation-tools">
              <Route path="bof-group" element={<Managepage.List />} />
              <Route path="bof-group/view" element={<Managepage.View />} />
              <Route path="bof-group/create" element={<Managepage.Form />} />
              <Route path="bof-group/update/:id" element={<Managepage.Form />} />
              <Route path="bof-group/delete/:id" element={<Managepage.Form />} />

              <Route path="bof-role" element={<Managerole.List />} />
              <Route path="bof-role/view" element={<Managerole.Form />} />
              <Route path="bof-role/create" element={<Managerole.Form />} />
              <Route path="bof-role/update/:id" element={<Managerole.Form />} />

              <Route path="bof-users" element={<ManageUser.List />} />
              <Route path="bof-users/create" element={<ManageUser.Form />} />
              <Route path="bof-users/update/:id" element={<ManageUser.Form />} />
              <Route path="bof-users/view" element={<ManageUser.View />} />
            </Route>

            <Route path="/chat">
              <Route path="bof-lineoa" element={<Chatpage.View />} />
            </Route>
            <Route path="/report">
              <Route path="bof-report" element={<Reportpage.List />} />
              <Route path="bof-report/view" element={<Reportpage.View />} />
              <Route path="bof-report-eservice" element={<ReportEservicepage.List />} />
              <Route path="bof-report-eservice/view" element={<ReportEservicepage.View />} />
            </Route>

            <Route path="/usage">
              <Route path="bof-usage-logs" element={<ActivityLog />} />
            </Route>
            <Route path="/complaint">
              <Route path="bof-overview-complaint" element={<ComplaintAdmin.List />} />
              <Route path="bof-overview-complaint/view" element={<ComplaintAdmin.View />} />
              <Route path="bof-overview-complaint/group" element={<ComplaintAdmin.Group />} />
              <Route path="bof-overview-complaint/view-update-status" element={<ComplaintAdmin.UpdateStatusPc />} />
              <Route path="bof-overview-complaint/update-form-status" element={<ComplaintAdmin.FormUpdateStatus />} />
              <Route path="bof-overview-complaint/edit" element={<ComplaintAdmin.Form />} />
              <Route path="bof-map-overview-complaint" element={<MapComplaintPage />} />
            </Route>
            {/* Staff */}
            <Route path="/operation-staff">
              <Route path="/operation-staff" element={<Newcomplaints.List />} />
              <Route path="/operation-staff/view" element={<Newcomplaints.View />} />
            </Route>
            <Route path="/user-group/bof-user-group" element={<SeparateUserGroupPage />} />
            <Route path="/complaint-staff">
              <Route path="bof-overview-complaint" element={<Complaintpage.List />} />
              <Route path="bof-overview-complaint/group" element={<Complaintpage.Group />} />
              <Route path="bof-overview-complaint/view" element={<Complaintpage.View />} />
              <Route path="bof-overview-complaint/create" element={<Complaintpage.Form />} />
              <Route path="bof-overview-complaint/view-update-status" element={<Complaintpage.UpdateStatusPc />} />
              <Route path="bof-overview-complaint/update-form-status" element={<Complaintpage.FormUpdateStatus />} />
              <Route path="bof-map-overview-complaint" element={<MapComplaintPage />} />
            </Route>
            <Route path="/e-service">
              <Route path="/e-service/bof-eservice" element={<E_Servicespage.List />} />
              <Route path="/e-service/bof-eservice/create-e-service" element={<E_Servicespage.Form />} />
              <Route path="/e-service/bof-eservice/view" element={<E_Servicespage.View />} />
              <Route
                path="/e-service/bof-eservice/view-update-status"
                element={<E_Servicespage.UpdateStatusEservicePc />}
              />
              <Route
                path="/e-service/bof-eservice/update-form-status"
                element={<E_Servicespage.FormUpdateStatusEservice />}
              />
              <Route path="/e-service/bof-map-eservice" element={<MapE_ServicePage />} />
            </Route>

            <Route path="/chat">
              <Route path="/chat" element={<Chatpage.View />} />
            </Route>
            {/* Staff */}
            <Route path="*">
              <Route path="*" element={<div style={{ textAlign: 'center', fontSize: '30px' }}>Page not flond</div>} />
            </Route>
          </Route>
        </Route>
        <Route path="/login" element={<LoginPage />} />

        <Route path="/complaint-staff/update-form-status" element={<Complaintpage.FormUpdateStatusResponsive />} />
        {/* <Route path="/separate-user-groups" element={<SeparateUserGroupPage />} /> */}
        <Route path="/flow-login/:idpath" element={<ResultPage />} />
      </Routes>
    </UserContext.Provider>
  )
}
export default App
