import React, { useState } from 'react'
import { Container } from './header.style'
import { ReactComponent as HamburgerIcon } from 'assets/images/svg/hamburIcon.svg'
import { ReactComponent as Xicon } from 'assets/images/svg/xicon.svg'
import styled from 'styled-components'
import SiderMobile from '../Sider/SiderMobile'
import Sider from '../Sider'
import { useLocation } from 'react-router-dom'

const Index = () => {
  const [openSiderMobile, setOpenSiderMobile] = useState(false)
  const checkLocation = useLocation()

  const filLocateData = checkLocation?.pathname
    ?.split('/')
    .some(item => ['view', 'update', 'create', 'delete'].includes(item))

  const ShowSider = () => {
    setOpenSiderMobile(!openSiderMobile)
  }

  const ClosSider = () => {
    setOpenSiderMobile(false)
  }

  return (
    <>
      {!filLocateData && (
        <Container>
          <BoxHeader>
            <HamburgerIcon style={{ margin: '1em' }} onClick={ShowSider} />
            {/* <Xicon style={{ margin: '1em' }} onClick={ClosSider} /> */}
          </BoxHeader>
          <CustomPosition>{openSiderMobile && <Sider setOpenSiderMobile={setOpenSiderMobile} />}</CustomPosition>
        </Container>
      )}
    </>
  )
}

export default Index

const CustomPosition = styled.div`
  position: fixed;
  top: 0;
  z-index: 99999999;
`

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
