import { Button } from 'antd'
import styled from 'styled-components'
export const TextStatus = styled.div`
  width: auto;
  height: 24px;
  background: ${props => (props?.bg ? props?.bg : '')};
  border-radius: 4px;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  color: ${props => (props?.text ? props?.text : '')};
`
export const Content = styled.div`
  .no-noti {
    margin-top: -10px;
  }

  .ant-popover-inner-content {
    padding: 0px !important;
    max-height: 700px !important;
  }
  .ant-popover-inner {
    border-radius: 10px !important;
    min-width: 350px !important;
  }

  .box-noti {
    :hover {
      background-color: #f6fcff;
    }
  }
`
export const BoxNoti = styled.div`
  background: ${props => props.BgNoti || '#fff'};

  :hover {
    background-color: #fff8f4;
  }

  /* .box-noti {
    :hover {
      background-color: #fff8f4;
    }
  } */
`

export const ButtonEdit = styled(Button)`
  font-size: 16px;
  width: 129px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid var(--web-backend-border-primary, #f36b24);
  border-color: #f36b24;
  color: black;
  :focus,
  :hover {
    color: black;
    /* border-color: transparent; */
    border-color: #f36b24;
  }
  span {
    color: #f36b24;
  }
`

export const ButtonSuccess = styled(Button)`
  &.ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    /* width: 162px; */
    /* width: ${props => (props.Btnwidth ? props.Btnwidth : '162')}; */
    width: ${props => (props.Btnwidth ? `${props.Btnwidth}` : '129px')};

    border-radius: 8px;
  }

  &.ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #c4c2c2;
    text-shadow: none;
    box-shadow: none;
  }
`
