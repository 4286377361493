import React, { useEffect, useState, useContext } from 'react'
import { Button, Space } from 'antd'
import { useFormik } from 'formik'
import iconbin from 'assets/images/svg/icon-bin-org-manage.svg'
import iconedit from 'assets/images/svg/icon-edit-org-manage.svg'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import { ReactComponent as IconAlert } from 'assets/images/svg/alert.svg'
import Alert from 'assets/images/svg/offline/alert.js'

import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { Schema, defaultValues } from './container'
import {
  CustomSpace,
  Title,
  Box,
  ButtonEdit,
  ButtonDelete,
  ButtonBack,
  CustomModal,
  CustomModal2,
  StyleDropdown,
  LabelText,
} from './view.style'
import { getData, deleteData } from '../../../../core/action/collection'
import { category, authentication2 } from 'core/schemas'
import { adminauth2, masterdata } from 'core/schemas'
import DropdownForm from 'components/Inputs/DropdownForm'
import Loader from 'components/Layout/Loader'
import InputFormUser from 'components/input/inputFormUser'
import styled from 'styled-components'

const Index = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const { id } = state?.record?.id
  const [organizeData, setOrganizeData] = useState([])

  // const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deletionType, setDeletionType] = React.useState(null)
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false)
  const [roleData, setRoleData] = useState([])
  const [groupData, setGroupData] = useState([])
  const [adminDataById, setAdminDataById] = useState('')
  const [originalDataById, setOriginalDataById] = useState('')
  const [positionData, setPositionData] = useState([])

  const formik = useFormik({
    initialValues: state?.record ? adminDataById : defaultValues,
    enableReinitialize: true,
  })

  useEffect(() => {
    id && getAdminById()
    getMasterData()
    getListOrganize()
  }, [])

  const getMasterData = async () => {
    await getListRole()
    await getListGroup()
    await getAllPosition()
    await getListOrganize()
    setLoading(false)
  }

  const getListRole = async () => {
    const res = await getData({
      schema: `${authentication2}/roles`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistRole = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setRoleData(maplistRole)
      } else {
        console.log('Error', res)
      }
    }
  }

  const getListGroup = async () => {
    const res = await getData({
      schema: `${adminauth2}/group`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistGroup = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setGroupData(maplistGroup)
      } else {
        console.log('Error', res)
      }
    }
  }
  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')
    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }
  const getAdminById = async () => {
    const res = await getData({
      schema: `${adminauth2}/admin/` + state?.record?.id,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const data = res?.data
        setOriginalDataById(data)
        const adminData = {
          first_name: data.first_name.th,
          last_name: data.last_name.th,
          mobile_number: phoneNumberAutoFormat(data.mobile_number),
          email: data.email,
          group_id: data.group_data.id,
          position_id: data.position_data.id,
          role_id: data.roles_data[0].id,
          organize_id: data.organize_data.org_id,
        }
        setAdminDataById(adminData)
      } else {
        console.log('Error', res)
      }
    }
  }

  const getAllPosition = async () => {
    const res = await getData({
      schema: `${masterdata}/position`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistPosition = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setPositionData(maplistPosition)
      } else {
        console.log('Error', res)
      }
    }
  }

  const onOkDelete = () => {
    setDeleteConfirmModalOpen(false)

    localStorage.setItem('modal', 'delete')
    navigate('/operation-tools/bof-users', {
      state: {
        showNotidelete: true,
        record: originalDataById,
        isDelete: true,
      },
    })
  }

  useEffect(() => {
    state?.record?.id && getAdminById()
    if (state.isDelete) {
      setDeleteConfirmModalOpen(true)
      setDeletionType(state.record?.status)
    }
  }, [])

  const callfunction = state => {
    setDeleteConfirmModalOpen(true)
    setDeletionType(state.status)
  }

  const canceldelete = () => {
    setDeleteConfirmModalOpen(false)
    navigate('/operation-tools/bof-users')
  }

  const getListOrganize = async () => {
    const res = await getData({
      schema: `${masterdata}/organizations`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistRole = res?.data?.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setOrganizeData(maplistRole)
      } else {
        console.log('Error', res)
      }
    }
  }

  const renderDeleteModal = isOffline => {
    if (isOffline === 'inactive') {
      return (
        <CustomModal
          transitionName=""
          centered
          open={deleteConfirmModalOpen}
          onOk={onOkDelete}
          onCancel={canceldelete}
          closable={false}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <Alert />
          </div>
          <p style={{ color: '#001F34', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
            คุณต้องการลบข้อมูลนี้?
          </p>
          <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300' }}>
            หากคุณลบข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
          </p>
        </CustomModal>
      )
    } else if (isOffline === 'active') {
      return (
        <CustomModal2
          transitionName=""
          centered
          open={deleteConfirmModalOpen}
          closable={false}
          okText="ตกลง"
          footer={
            <Button type="primary" onClick={() => canceldelete()}>
              ตกลง
            </Button>
          }
        >
          <div style={{ paddingBottom: '10px' }}>
            <Alert />
          </div>
          <p style={{ color: '#001F34', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
            ไม่สามารถลบข้อมูลได้
          </p>
          <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300', lineHeight: '14.4px' }}>
            เนื่องจากข้อมูลถูกใช้งานอยู่ในเมนูจัดการผู้ใช้งาน
            <br />
            กรุณาลบข้อมูลที่ใช้งานจากเมนูข้างต้นก่อน
          </p>
        </CustomModal2>
      )
    }
  }

  return (
    <Loader loading={loading}>
      <div style={{ padding: '20px 40px' }}>
        <ContentHeader>
          <div className="view-pc">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Space style={{ paddingTop: '20px' }}>
                <ButtonBack
                  shape="circle"
                  icon={<IconArrowLeft />}
                  onClick={() => navigate('/operation-tools/bof-users')}
                />
                <Title>ดูข้อมูลบัญชีผู้ใช้งาน</Title>
              </Space>
              <Space>
                <ButtonEdit
                  onClick={() => {
                    navigate(`/operation-tools/bof-users/update/${state?.record?.id}`)
                  }}
                  icon={<img src={iconedit} alt="edit" style={{ paddingRight: '8px' }} />}
                >
                  แก้ไขข้อมูล
                </ButtonEdit>

                <ButtonDelete
                  onClick={() => callfunction(originalDataById)}
                  icon={<img src={iconbin} alt="bin" style={{ paddingRight: '8px' }} />}
                >
                  ลบข้อมูล
                </ButtonDelete>
              </Space>
            </div>
          </div>
          <div className="view-mobile">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Space style={{ paddingTop: '20px' }}>
                <ButtonBack
                  shape="circle"
                  icon={<IconArrowLeft />}
                  onClick={() => navigate('/operation-tools/bof-users')}
                />
                <Title>ดูข้อมูลบัญชีผู้ใช้งาน</Title>
              </Space>
              <Space style={{ display: 'flex', justifyContent: 'end' }}>
                <ButtonEdit
                  onClick={() => {
                    navigate(`/operation-tools/bof-users/update/${state?.record?.id}`)
                  }}
                  icon={<img src={iconedit} alt="edit" style={{ paddingRight: '0px' }} />}
                >
                  แก้ไขข้อมูล
                </ButtonEdit>

                <ButtonDelete
                  onClick={() => callfunction(originalDataById)}
                  icon={<img src={iconbin} alt="bin" style={{ paddingRight: '0px' }} />}
                >
                  ลบข้อมูล
                </ButtonDelete>
              </Space>
            </div>
          </div>
        </ContentHeader>

        <Box>
          <div style={{ padding: '2rem' }}>
            <InputFormUser
              className="nobottom"
              label="ชื่อ"
              name="first_name"
              placeholder="กรอกชื่อ"
              type="text"
              required={true}
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={255}
              disabled={true}
            />
            <InputFormUser
              className="nobottom"
              label="นามสกุล"
              name="last_name"
              placeholder="กรอกนามสกุล"
              type="text"
              required={true}
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={255}
              disabled={true}
            />
            <InputFormUser
              className="nobottom"
              label="หมายเลขโทรศัพท์"
              name="mobile_number"
              placeholder="กรอกหมายเลขโทรศัพท์"
              type="text"
              required={true}
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={35}
              // uniqueFunc={uniqueFunc}
              // uniqueValidate={uniqueValidate}
              // onBlur={formik.handleBlur}
              disabled={true}
            />
            <InputFormUser
              className="nobottom"
              label="อีเมลล์"
              name="email"
              placeholder="กรอกอีเมลล์"
              type="text"
              required={true}
              errors={formik.errors}
              touched={formik.touched}
              form={formik}
              status={formik.status}
              maxLength={255}
              // uniqueFunc={uniqueFunc}
              // uniqueValidate={uniqueValidate}
              // onBlur={formik.handleBlur}
              disabled={true}
            />

            <div style={{ width: '50%', marginBottom: '1rem' }}>
              <LabelText>
                ชื่อกลุ่ม
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </LabelText>
              <StyleDropdown>
                <DropdownForm
                  title="ชื่อกลุ่ม"
                  placeholder="เลือกชื่อกลุ่ม"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('group_id', value)
                  }}
                  className="big-input"
                  options={groupData}
                  value={formik?.values?.group_id}
                  disabled={true}
                />
              </StyleDropdown>
            </div>
            <div style={{ width: '50%', marginBottom: '1rem' }}>
              <LabelText>
                รูปแบบการปฏิบัติงาน
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </LabelText>
              <StyleDropdown>
                <DropdownForm
                  title="รูปแบบการปฏิบัติงาน"
                  placeholder="เลือกรูปแบบการปฏิบัติงาน"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('position_id', value)
                  }}
                  className="big-input"
                  options={positionData}
                  value={formik?.values?.position_id}
                  disabled={true}
                />
              </StyleDropdown>
            </div>
            <div style={{ width: '50%', marginBottom: '1rem' }}>
              <LabelText>
                สิทธิ์การใช้งาน
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </LabelText>

              <ContentHeader>
                <div className="view-pc">
                  <StyleDropdown>
                    <DropdownForm
                      title="สิทธิ์การใช้งาน"
                      placeholder="เลือกสิทธิ์การใช้งาน"
                      showSearch={false}
                      required={true}
                      onChange={value => {
                        formik.setFieldValue('role_id', value)
                      }}
                      className="big-input"
                      options={roleData}
                      value={formik?.values?.role_id}
                      disabled={true}
                    />
                  </StyleDropdown>
                </div>
                <div className="view-mobile">
                  <StyleDropdown style={{ width: '40vw' }}>
                    <DropdownForm
                      title="สิทธิ์การใช้งาน"
                      placeholder="เลือกสิทธิ์การใช้งาน"
                      showSearch={false}
                      required={true}
                      onChange={value => {
                        formik.setFieldValue('role_id', value)
                      }}
                      className="big-input"
                      options={roleData}
                      value={formik?.values?.role_id}
                      disabled={true}
                    />
                  </StyleDropdown>
                </div>
              </ContentHeader>
            </div>
            <div style={{ width: '50%' }}>
              <LabelText>
                ชื่อหน่วยงาน
                <div className="red-star" style={{ paddingLeft: '2px' }}>
                  *
                </div>
              </LabelText>
              <StyleDropdown>
                <DropdownForm
                  title="ชื่อหน่วยงาน"
                  placeholder="เลือกชื่อหน่วยงาน"
                  showSearch={false}
                  required={true}
                  onChange={value => {
                    formik.setFieldValue('organize_id', value)
                  }}
                  className="big-input"
                  options={organizeData}
                  value={formik?.values?.organize_id}
                  disabled={true}
                />
              </StyleDropdown>
            </div>
          </div>
        </Box>
        {renderDeleteModal(deletionType)}
        {/* {renderMode()} */}
        {/* <CustomModal
          transitionName=""
          centered
          open={leaveModalOpen}
          onOk={() => {
            setLeaveModalOpen(false)
            navigate('/bof-user')
          }}
          onCancel={() => setLeaveModalOpen(false)}
          closable={false}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <div style={{ paddingBottom: '10px' }}>
            <ExclamationCircle />
          </div>
          <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
          <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
        </CustomModal> */}
      </div>
    </Loader>
  )
}

export default Index

const ContentHeader = styled.div`
  @media screen and (max-width: 900px) {
    .view-pc {
      display: none;
    }
    .view-mobile {
      display: block;
    }
  }
  @media screen and (min-width: 900px) {
    .view-mobile {
      display: none;
    }
    .view-pc {
      display: block;
    }
  }
`
