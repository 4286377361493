import React from 'react';
import ReactApexChart from 'react-apexcharts';

export function HorizontalBarChart({ categories, series, colors }) {
  const options = {
    chart: {
      type: 'bar',
      stacked: true
    },
    colors: colors, 
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '8px', 
      }
    },
    xaxis: {
      categories: categories,
    tickAmount: 5, 
    min: 0,
    max: 200, 
    labels: {
      show: true,
    }
  },


  fill: {
    opacity: 1
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    offsetX: 40
  },
  dataLabels: {
    enabled: false,
  }
};

   return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series} 
        type="bar"
        height={600}
        width={'95%'}
      />
    </div>
  );
};


