import React, { useState, useEffect } from 'react'
import { notification, Divider } from 'antd'

import iconHome from 'assets/images/png/icon-homepage.png'
import support from 'assets/images/png/logo-support.png'

// import { ReactComponent as Support } from 'assets/images/svg/logo-support.svg'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'

import { Container, NotiStyle } from './HomePage.style'

const HomePage = () => {
  const [api, contextHolder] = notification.useNotification()
  const [notiStyle, setNotiStyle] = useState(false)

  useEffect(() => {
    localStorage.removeItem('subMenuTitle')
    localStorage.removeItem('mainMenu')
  }, [])

  const openNotification = placement => {
    api.info({
      message: 'เข้าสู่ระบบสำเร็จ',
      placement,
      icon: <IconSurprised />,
      closeIcon: <IconClose />,
      duration: 3,
    })
  }

  return (
    <>
      {notiStyle && <NotiStyle />}
      {contextHolder}
      <Container>
        {/* <p>เลือกเมนูด้านซ้ายเพื่อเริ่มต้นใช้งาน</p>
        <h1 className="title-top">ระบบเฝ้าระวังโรค</h1>
        <h1 className="title-bottom">และภัยสุขภาพ</h1>
        <Divider />
        <p className="detail-title">ได้รับทุนสนับสนุนจาก</p>
        <p className="detail-text">
          กองทุนวิจัยและพัฒนากิจการกระจายเสียงกิจการโทรทัศน์ และกิจการโทรคมนาคม เพื่อประโยชน์สาธารณะ
        </p>
        <img src={support} alt="icon homepage" className="logo-support " />
        <img src={iconHome} alt="icon homepage" className="icon" /> */}
      </Container>
    </>
  )
}

export default HomePage
