import { Drawer } from 'antd'
import styled from 'styled-components'

export const BoxSearch = styled.div`
  /* padding-bottom: 24px; */

  .rc-virtual-list-scrollbar {
    width: 6px !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: #ffe6db !important;
    width: 6px !important;
    border-radius: 8px !important;
    height: 96px !important;
  }

  /* .rc-virtual-list-holder {
    max-height: 320px !important;
    min-height: 320px !important;
  } */

  .ant-select {
    color: #fe814a;
  }
  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 8px !important;
  }

  /* .ant-select-dropdown div {
    height: 320px;
  } */

  .ant-select-arrow {
    left: 11px !important;
    font-size: 18px;
    justify-content: end;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    /* padding-left: 10px;
    padding-top: 4px; */
    line-height: 31px !important;

    @media only screen and (max-width: 768px) {
      line-height: 16px !important;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #fee0d3;
    height: 40px;
    /* width: 100%; */

    svg {
      visibility: hidden !important;
    }

    img {
      display: none !important;
    }

    @media only screen and (max-width: 820px) {
      height: 40px;
    }

    @media only screen and (max-width: 375px) {
      height: 40px;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
  }

  .ant-select-disabled .ant-select-selector {
    background: #fff3ee !important;
    border: 1px solid #fff3ee !important;
    color: #fe814a !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #ffc4aa;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #fe814a;
    box-shadow: none;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #fe814a;
    box-shadow: none;
  }

  .ant-select,
  .ant-select-single,
  .ant-select-show-arrow,
  .ant-select-show-search {
    /* width: 100%; */
  }

  .ant-select-selection-placeholder {
    color: #fee0d3 !important;
    font-weight: 400;
    line-height: 40px !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(254, 129, 74, 0.08);
    color: #fe814a;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
  }

  .ant-select-selection-item {
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 375px) {
      font-size: 16px;
    }
  }

  /* .ant-select .big-input .ant-select-single .ant-select-show-arrow {
    svg {
      display: block;
    }
  } */
  .ant-select {
    svg {
      display: block !important;
    }
  }
`

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    height: 90% !important;
  }

  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px;
  }

  .ant-drawer-body {
    padding: 0px;
  }
`

export const HeaderDrawer = styled.div`
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 32px; */
  width: 100%;
  position: sticky; /* Change to sticky */
  height: 79px;
  top: 0; /* Stick it to the top */
  background-color: #fff;
  z-index: 1000;
  padding: 32px 24px; /* Adjust the padding as needed */
`

export const TextType = styled.span`
  font-family: 'Prompt';
  font-size: 16px;
  font-weight: 500;
  color: #ffc4aa;
`

export const CloseText = styled.span`
  font-family: 'Prompt';
  font-size: 16px;
  font-weight: 500;
  color: #fe814a;
`

export const BoxOption = styled.div`
  height: 48px;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 8px;
  border-bottom: ${props => (props.select ? '1px solid #FFF3EE' : '1px solid #ece9e9')};
  background-color: ${props => (props.select ? '#FFF3EE' : '#ffffff')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-family: 'Prompt';
    font-size: 16px;
    font-weight: 400;
    color: #150f0b;
  }
  overflow-y: auto; /* Allow scrolling if the content exceeds the height */
`
