import { Select, Tooltip } from 'antd'

export const columns = (
  masterData,
  handleCategoryChange,
  handleSubCategoryChange,
  getSubCategories,
  selectedCategoryID
) => {
  let categories = masterData?.categories?.map((value, index, array) => {
    return { label: value?.name, value: value?.id }
  })
  let subCategories = masterData?.subCategories?.map((value, index, array) => {
    return { label: value.name, value: value.ID }
  })

  return [
    {
      title: 'เรื่องร้องเรียน',
      dataIndex: 'comment',
      render: text => (
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '200px', whiteSpace: 'nowrap' }}>
          <Tooltip title={text} placement="top">
            {text}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'ประเภทเรื่องร้องเรียน (TF)',
      dataIndex: 'type',
    },
    {
      title: 'พิกัดเรื่องฯ',
      dataIndex: 'coords',
    },
    {
      title: 'ประเภทเรื่องร้องเรียน*',
      render: (_, data) => {
        return (
          <Select
            style={{ width: '100%' }}
            className="select-import-file"
            options={categories || []}
            onChange={e => {
              handleCategoryChange(e, data)
            }}
          />
        )
      },
    },
    {
      title: 'ประเภทย่อย*',
      render: (_, data) => {
        return (
          <Select
            disabled={!data?.category_id}
            id="select-sub-category"
            style={{ width: '100%' }}
            className="select-import-file"
            options={subCategories || []}
            onChange={e => handleSubCategoryChange(e, data)}
            onFocus={() => getSubCategories(data?.category_id)}
          />
        )
      },
    },
  ]
}

const data = [
  {
    key: Date.now(),
    channel: '1',
    status: true,
  },
  {
    key: Date.now() + 2,
    channel: '2',
    status: false,
  },
  {
    key: Date.now() + 3,
    channel: '3',
    status: false,
  },
  {
    key: Date.now() + 4,
    channel: '4',
    status: false,
  },
  {
    key: Date.now() + 5,
    channel: '5',
    status: false,
  },
  {
    key: Date.now() + 6,
    channel: '6',
    status: false,
  },
  {
    key: Date.now() + 7,
    channel: '7',
    status: false,
  },
  {
    key: Date.now() + 8,
    channel: '8',
    status: false,
  },
  {
    key: Date.now() + 9,
    channel: '9',
    status: false,
  },
  {
    key: Date.now() + 10,
    channel: '10',
    status: false,
  },
  {
    key: Date.now() + 11,
    channel: '11',
    status: false,
  },
  {
    key: Date.now() + 12,
    channel: '12',
    status: false,
  },

  {
    key: Date.now() + 13,
    channel: '13',
    status: false,
  },
  {
    key: Date.now() + 14,
    channel: '14',
    status: false,
  },
  {
    key: Date.now() + 15,
    channel: '15',
    status: false,
  },
  {
    key: Date.now() + 16,
    channel: '16',
    status: false,
  },
  {
    key: Date.now() + 17,
    channel: '17',
    status: false,
  },
]

const provinces = [
  {
    idProvinces: '1',
    name: 'Province 1',
  },
  {
    idProvinces: '2',
    name: 'Province 2',
  },
] // Example province data

const districts = [
  {
    idDistricts: '1-1',
    name: 'District 1',
    idProvinces: '1',
  },
  {
    idDistricts: '1-2',
    name: 'District 2',
    idProvinces: '1',
  },
  {
    idDistricts: '2-1',
    name: 'District 3',
    idProvinces: '2',
  },
  {
    idDistricts: '2-2',
    name: 'District 4',
    idProvinces: '2',
  },
]
