export const tableText = {
  noData: 'ไม่มีข้อมูล',
  noDataSearch: 'ไม่พบข้อมูลที่ต้องการค้นหา',
  noDataFilter: 'ไม่พบข้อมูลที่เลือกในช่องกรอง',
  noDataDate: 'ไม่พบข้อมูลในวันที่ดังกล่าว',
}

export const tableText2 = {
  noData: 'ไม่มีข้อมูล',
  noDataSearch: 'ไม่พบข้อมูลที่ต้องการค้นหา',
  noDataFilter: 'ไม่พบข้อมูลที่เลือกในช่องกรอง',
  noDataDate: 'ไม่พบข้อมูลในวันที่ดังกล่าว',
}
