import React from 'react';
import ReactApexChart from 'react-apexcharts';

export function StackedBarChart  ({ labels, colors }) { 
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: 'normal' 
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '32px',
      },
    },
    xaxis: {
      categories: ['สำนักปลัดเทศบาล', 'สำนักคลัง', 'สำนักช่าง', 'สำนักการประปา', 'สำนักการศึกษา', 'กองสวัสดิการสังคม']
    },
    yaxis: {
      min: 0, 
      tickAmount: 4, 
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      style: {
      fontSize: '10px',
      fontFamily: 'Prompt',
      fontWeight: 400,
      colors:'#717171',
    },
      
    },
    dataLabels: {
      enabled: false 
    },
    colors: colors,
    labels: labels,
  };
  const series = [
    {
      name: 'รับเรื่อง',
      data: [144, 255, 141, 164, 212, 130]
    },
    {
      name: 'อยู่ระหว่างดำเนินการ',
      data: [153, 32, 133, 152, 113, 125]
    },
    {
      name: 'ดำเนินการแล้วเสร็จ',
      data: [112, 127, 121, 219, 115, 167]
    },
    {
      name: 'ติดตาม',
      data: [112, 217, 161, 99, 115, 223]
    },
    {
      name: 'ไม่อยู่ในความรับผิดชอบ',
      data: [112, 172, 221, 129, 115, 113]
    },
    {
      name: 'เกินระยะเวลาที่กำหนด',
      data: [112, 137, 111,129, 135, 130]
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        width={'90%'}
      />
    </div>
  );
}


