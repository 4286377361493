import usePermission from './usePermission'

const UseAllPermissions = permission => {
  const { allow: allowList } = usePermission(permission + '-list')
  const { allow: allowCreate } = usePermission(permission + '-create')
  const { allow: allowUpdate } = usePermission(permission + '-update')
  const { allow: allowRead } = usePermission(permission + '-read')
  const { allow: allowDelete } = usePermission(permission + '-delete')
  const { allow: allowAll } = usePermission(permission + '-all')

  return {
    list: allowList,
    create: allowCreate,
    read: allowRead,
    update: allowUpdate,
    delete: allowDelete,
    all: allowAll,
  }
}

export default UseAllPermissions
