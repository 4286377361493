import React, { useState, useContext, useEffect } from 'react'
import { Button, notification, Tooltip, Dropdown, Menu, Row, Col } from 'antd'
import Table from 'components/Table/Row'
import { useFormik } from 'formik'
import StatusCustom from 'components/output/Status'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from 'react-router-dom'
import iconbin from 'assets/images/svg/icon-bin-org.svg'
import iconsee from 'assets/images/svg/icon-see-org.svg'
import iconedit from 'assets/images/svg/icon-edit-org.svg'
import iconAdd from 'assets/images/svg/icon-add-org.svg'
import IconImport from 'assets/images/svg/icon-import-complaint.svg'
import IconInputForm from 'assets/images/svg/icon-input-form-dropdown.svg'
import IconImportFile from 'assets/images/svg/icon-import-file.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import IconClear from 'assets/images/svg/icon-close-drawer.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import IconNoti from 'assets/images/svg/icon-noti.svg'

import vDivider from 'assets/images/svg/vertical-divider.svg'

import IconArrowOrange from 'assets/images/svg/org-arrow.svg'
import { getData } from 'core/action/collection'
import { adminauth2 } from 'core/schemas'
import moment from 'moment'
import Pagination from 'components/pagination'
import styled, { createGlobalStyle } from 'styled-components'
import { getList } from 'core/action/collection'
import { unixToDateThai } from 'util/date-format'
import { conversDate } from 'core/utils/DateTimeFormat'
import Search from '../listPc/search'
import { convertAtivityIcon, convertAtivityThai } from '../listPc/container'
import IconClose from 'assets/images/svg/icon-close.svg'
import IconHambur from 'assets/images/svg/hambur-log.svg'
import PaginationsMobile from 'components/PeginationMobile'
import SearchMobile from './searchMobile'

const ListMobile = () => {
  const navigate = useNavigate()

  const [dataExt, setDataExt] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1)
  const [recordId, setRecordId] = React.useState(null)
  const [record, setrecord] = React.useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [openDrawerFormInput, setOpenDrawerFormInput] = useState(false)
  const [nextForm, setNextForm] = useState(false)
  const [dataTotal, setDatToal] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const [masterData, setMasterData] = useState([])
  const [searchData, setSearchData] = useState([])

  useEffect(() => {
    // window.onload = function () {
    //   document.body.style.overflowY = 'hidden'
    // }
    getMasterData()
    getListData()
  }, [currentPage, searchData])

  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })
    const resStatus = await getList({
      schema: '/master/api/v1/all/status',
      params: '',
    })
    const resUrgency = await getList({
      schema: '/master/api/v1/all/urgency',
      params: '',
    })
    const resDepartment = await getList({
      schema: '/master/api/v1/all/organizations',
      params: '',
    })

    const resActivity = await getList({
      schema: '/master/api/v1/all/activity-log',
      params: '',
    })

    setMasterData({
      categories: resCategories?.items,
      status: resStatus?.items,
      urgency: resUrgency?.items,
      department: resDepartment?.items,
      activity: resActivity?.items,
    })
  }

  const getListData = async () => {
    const res = await getList({
      schema: `/complaint/api/v1/activitylog`,
      // schema: `/complaint/api/v1/complaint`,
      params: {
        page: currentPage,
        per_page: 8,
        filters: [
          searchData?.activity ? `activity_type:eq:${searchData?.activity}` : undefined,
          searchData?.category ? `category_id:eq:${searchData?.category}` : undefined,
          searchData?.department ? `department_data.name:eq:${searchData?.department}` : undefined,
          searchData?.status ? `status_data.name:eq:${searchData?.status}` : undefined,
          searchData?.urgency ? `urgency_id:eq:${searchData?.urgency}` : undefined,
          searchData?.satisfaction ? `rating.rating:eqInt:${searchData?.satisfaction}` : undefined,
          searchData?.date
            ? `created_at:between:${moment(searchData?.date[0]).startOf('day').unix()}|${moment(searchData?.date[1])
                .endOf('day')
                .unix()}`
            : undefined,
          searchData?.search ? `staff_data.first_name:like:${searchData?.search}` : undefined,
        ],
      },
      url: '',
      baseURL: '',
    })

    if (res.dataLength > 0) {
      setrecord(res?.items)
      setDatToal(res.dataLength)
    } else {
      setrecord([])
    }
  }

  const columns = [
    {
      title: <div style={{ color: '#717171' }}>เรื่องที่ร้องเรียน</div>,
      dataIndex: 'complaint_name',
      ellipsis: true,
      // className: 'gap',
      render: data => <>{data ? (data ? data : '-') : '-'}</>,
    },
    {
      title: <div style={{ color: '#717171' }}>เลขที่เรื่องร้องเรียน</div>,
      dataIndex: 'complaint_id',
      render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      // title: 'กิจกรรม',
      title: <div style={{ color: '#717171' }}>กิจกรรม</div>,
      dataIndex: '',
      render: (text, data) => {
        return data.activity_type ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src={convertAtivityIcon(data?.activity_type)} style={{ width: '14px', height: '14px' }} />
            {/* {data?.category?.name} */}
            {convertAtivityThai(data.activity_type)}
          </div>
        ) : (
          <div>-</div>
        )
      },
    },
    {
      title: <div style={{ color: '#717171' }}>หน่วยงานที่รับผิดชอบ</div>,
      dataIndex: '',
      render: data => (
        <>
          {data ? (
            data ? (
              data?.department_data?.map(item => {
                return <div style={{ color: '#556A82' }}>{item.name}</div>
              })
            ) : (
              '-'
            )
          ) : (
            <span>-</span>
          )}
        </>
      ),
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      title: <div style={{ color: '#717171' }}>ผู้รับผิดชอบ</div>,
      dataIndex: '',
      render: data => (
        <>
          {data ? (
            data ? (
              <div>
                <span>{data?.staff_data?.first_name}</span> <span>{data?.staff_data?.last_name}</span>
              </div>
            ) : (
              '-'
            )
          ) : (
            <span>-</span>
          )}
        </>
      ),
      ellipsis: true,
      // className: 'gap-40',
    },
    {
      title: <div style={{ color: '#717171' }}>วันที่แจ้งเรื่องร้องเรียน</div>,
      dataIndex: 'created_at',
      // className: 'font-w-400',
      render: data => {
        return <div>{unixToDateThai(data)}</div>
      },
    },
  ]

  const onPageChange = page => {
    setCurrentPage(page)
    // setState({ showNoti: false })
    // getDataList(currentSearch, page)
  }

  return (
    <div className="ken-table">
      <SearchMobile
        masterData={masterData}
        setSearchData={setSearchData}
        searchData={searchData}
        conversDate={conversDate}
      />

      <BoxTableCustom>
        <Table columns={columns} dataSource={record} minHeightTb={'700px'} />
      </BoxTableCustom>

      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <PaginationsMobile defaultCurrent={1} total={dataTotal} onChange={e => onPageChange(e)} current={currentPage} />
      </div>
    </div>
  )
}

export default ListMobile

export const BoxTableCustom = styled.div`
  padding: 10px;
  .ant-table-tbody tr {
    height: 60px !important;
    td {
      background: #ffffff !important;
      font-weight: var(--text-weight-regular);
    }
  }
`
