import React, { useEffect, useState, useRef } from 'react'
import { Marker, Autocomplete } from '@react-google-maps/api'
import iconeMarker from 'assets/images/svg/markericon.svg'
import { ReactComponent as Iconsearch } from './image/svg/Search.svg'
import { ReactComponent as IconClear } from './image/svg/Cancel.svg'
import { ReactComponent as IconArrow } from './image/svg/Arrow.svg'
import CurrentLocation from './image/png/current location.png'
import CurrantMeLocation from './CurrantLocation'
import GoogleMapApi from 'components/GoogleMap'
import { Button, Input } from 'antd'
import { BoxAutocomplate, BoxBtnInMap } from './form.style'
const Viewmap = ({
  isModalOpen,
  clickedLatLng,
  meLocation,
  address,
  arrArear,
  handleOk,
  handleClickMap,
  setClickedLatLng,
  checkArear,
  setIsModalOpenError,
  setIsModalOpen,
  centerMap,
  setCenterMap,
  setZoomMap,
  autocompleteValue,
  setAutocompleteValue,
  setSearchValue,
  searchValue,
}) => {
  const [autocomplete, setAutocomplete] = useState(null)
  const [map, setMap] = useState(null)

  const mapStyles = {
    height: isModalOpen ? '100%' : '30vh',
    width: '100%',
    borderRadius: '25px',
  }

  const handleOnLoad = autocomplete => {
    setAutocomplete(autocomplete)
  }
  const handleOnPlaceChanged = () => {
    const place = autocomplete?.getPlace()
    let check = checkArear({ lat: place?.geometry?.location.lat(), lng: place?.geometry?.location.lng() })
    if (!check) {
      setIsModalOpenError(true)
      setSearchValue('')
    } else {
      setClickedLatLng({ lat: place?.geometry?.location.lat(), lng: place?.geometry?.location.lng() })
      setCenterMap({ lat: place?.geometry?.location.lat(), lng: place?.geometry?.location.lng() })
      setZoomMap(14)
      setAutocompleteValue(place?.formatted_address)
      setSearchValue(place?.formatted_address)
    }
  }

  useEffect(() => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
      strokeColor: '#f36b24',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    if (map) {
      const newCenter = centerMap // Replace with the desired new center
      const animationDuration = 1000 // Animation duration in milliseconds
      map.panTo(newCenter, animationDuration)
    }
    bermudaTriangle.setMap(map)
    window.google.maps.event.addListener(bermudaTriangle, 'click', handleClickMap)
    return () => {
      window.google.maps.event.clearListeners(bermudaTriangle, 'click')
      bermudaTriangle.setMap(null)
    }
  }, [arrArear, map, centerMap])
  return (
    <>
      <GoogleMapApi
        center={centerMap ? centerMap : meLocation}
        setMap={setMap}
        zoom={13}
        mapContainerStyle={mapStyles}
        handleClickMap={handleClickMap}
      >
        {meLocation && <CurrantMeLocation meLocation={meLocation} />}
        {meLocation?.lat !== clickedLatLng?.lat && meLocation?.lng !== clickedLatLng?.lng && (
          <Marker position={clickedLatLng} icon={iconeMarker} />
        )}
        {isModalOpen ? (
          <BoxAutocomplate>
            <Autocomplete onLoad={handleOnLoad} onPlaceChanged={handleOnPlaceChanged}>
              <Input
                type="text"
                value={autocompleteValue ? autocompleteValue : searchValue}
                placeholder="ระบุชื่อสถานที่ที่ต้องการ"
                prefix={<Iconsearch />}
                onChange={e => setSearchValue(e.target.value)}
                suffix={
                  searchValue && (
                    <IconClear
                      style={{ color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }}
                      onClick={() => {
                        setSearchValue('')
                        setAutocompleteValue('')
                        setClickedLatLng(null)
                      }}
                    />
                  )
                }
              />
            </Autocomplete>
            <div style={{ padding: '10px', backgroundColor: '#FFF8F4', borderRadius: '10px', marginTop: '10px' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '40px 1fr', alignItems: 'center' }}>
                <img src={CurrentLocation} />
                <div> {autocompleteValue ? autocompleteValue : address}</div>
              </div>
            </div>
            <BoxBtnInMap>
              <Button
                style={{ marginTop: '10px' }}
                onClick={() => {
                  handleOk()
                }}
              >
                ยืนยันตำแหน่ง
              </Button>
            </BoxBtnInMap>
          </BoxAutocomplate>
        ) : (
          <div
            style={{
              backgroundColor: '#fff',
              maxWidth: '300px',
              height: 'auto',
              borderRadius: '10px',
              padding: '10px',
              cursor: 'pointer',
              position: 'absolute',
              bottom: '10px',
              left: '10px',
            }}
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div> {autocompleteValue ? autocompleteValue : address}</div>
              <div>
                <IconArrow style={{ transform: 'rotate(90deg)' }} />
              </div>
            </div>
          </div>
        )}
      </GoogleMapApi>
    </>
  )
}

export default Viewmap
