import React from 'react'
import { Breadcrumb } from 'antd'
import { useLocation } from 'react-router-dom'
import { Content } from './Breadcrumb.style'
const Index = () => {
  const location = useLocation()
  const pathName = location.pathname.split('/')
  let path = []

  if (pathName && pathName.length === 5 && pathName[3] === 'view') {
    path = pathName.filter((item, index) => index < 4)
  } else {
    path = pathName
  }

  path.shift()
  if (path.includes('update')) {
    path.pop()
  }

  const dataMenu = {
    dashboard: 'สถิติเรื่องร้องเรียน',
    'bof-overview': 'สถิติและภาพรวม',
    'bof-process-status': 'สถานะการทำงานของเจ้าหน้าที่',
    'e-service': 'ภาพรวมบริการสาธารณะ (E-service)',
    'bof-eservice': 'ภาพรวมบริการสาธารณะ',
    complaint: 'ภาพรวมเรื่องร้องเรียน',
    'bof-overview-complaint': 'ภาพรวมเรื่องร้องเรียน',
    operation: 'จัดการและโอนย้าย',
    'bof-new-complaint': 'เรื่องร้องเรียนใหม่',
    'bof-transfer': 'การโอนย้ายใหม่',
    'operation-tools': 'การจัดการผู้ใช้งาน',
    'bof-role': 'การจัดการสิทธิ์',
    'bof-group': 'การจัดการกลุ่มผู้ใช้งาน',
    'bof-users': 'การจัดการผู้ใช้',
    chat: 'แชท (LineOA)',
    'bof-lineoa': 'Line OA',
    'bof-lineoa': 'บันทึกการใช้งาน',
    'bof-lineoa': 'Line OA',
    'create-complant': 'ข้อมูลเรื่องร้องเรียน',
    create: 'เพิ่มข้อมูล',
    update: 'แก้ไขข้อมูล',
    view: 'ดูข้อมูล',
    group: 'จัดกลุ่ม',
    'view-group': 'ดูข้อมูลกลุ่ม',
    'complaint-staff': 'ภาพรวมเรื่องร้องเรียน',
    'bof-executive': 'รายงานผู้บริหาร',
    'bof-overview-eservice': 'ภาพรวมบริการสาธารณะ (E-service)',
    'bof-map-eservice': 'แผนที่ภาพรวมบริการสาธารณะ',
    'bof-map-overview-complaint': 'แผนที่ภาพรวมเรื่องร้องเรียน',
    'bof-report': 'รายงานเรื่องร้องเรียน',
    'bof-report-eservice': 'รายงานบริการสาธารณะ',
    report: 'รายงาน',
    'user-group': 'กลุ่มผู้ใช้งาน',
    'bof-user-group': 'ดูข้อมูล',
    'view-update-status': 'อัปเดตผลการดำเนินการ',
    'update-form-status':
      location?.state?.type === 'befor'
        ? 'อัปเดตข้อมูลก่อนการปฏิบัติงาน'
        : location?.state?.type === 'after'
        ? 'อัปเดตข้อมูลหลังการปฏิบัติงาน'
        : 'อัปเดตข้อมูลติดตามการปฎิบัติงาน',
  }

  return (
    <Content>
      <Breadcrumb style={{ paddingBottom: '10px', color: '#597081', paddingLeft: '20px' }}>
        {path.map((item, index) => {
          if (path.length === index + 1) {
            return (
              item !== 'bof-system-report' && (
                <Breadcrumb.Item key={index}>
                  <span className="text-breadcrumb-menu-sub">{dataMenu[item]}</span>
                </Breadcrumb.Item>
              )
            )
          } else {
            return (
              <Breadcrumb.Item key={index}>
                <span className="text-breadcrumb-menu">{dataMenu[item]}</span>
              </Breadcrumb.Item>
            )
          }
        })}
      </Breadcrumb>
    </Content>
  )
}

export default Index
