import React, { Children, useEffect } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { fromLonLat } from 'ol/proj'
import { Icon, Style } from 'ol/style'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import TileImage from 'ol/source/TileImage'
import IconPin from 'assets/images/png/IconPin.png'
export default function Index({ data, children }) {
  useEffect(() => {
    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          className: 'bw',
          source: new TileImage({
            url: 'https://mt1.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}',
            crossOrigin: 'anonymous',
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([data?.coordinates?.longitude, data?.coordinates?.latitude]),
        zoom: 18,
        extent: [10429679.636457605, 479413.04140462546, 12132085.129423175, 2436200.9655051385],
      }),
      controls: [],
    })

    const iconFeature = new Feature({
      geometry: new Point(fromLonLat([data?.coordinates?.longitude, data?.coordinates?.latitude])),
    })
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        crossOrigin: 'anonymous',
        src: IconPin,
      }),
    })

    iconFeature.setStyle(iconStyle)
    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [iconFeature],
      }),
    })
    map.addLayer(vectorLayer)
    return () => {
      map.setTarget(undefined)
    }
  }, [data])

  return (
    <div id="map" style={{ width: '100%', height: '200px' }}>
      {children}
    </div>
  )
}
