import React, { useEffect, useState, useContext } from 'react'
import { Button, Tooltip, Space, Form, Input } from 'antd'
import { InputCustom } from 'components/input'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import UserContext from 'core/contexts/userContext'
import { postData, putData, getList, getData } from 'core/action/collection'
import { Schema, defaultValues } from './container'
import { CustomSpace, Title, Box, CustomModal, ButtonBack } from './form.style'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import { adminauth2 } from 'core/schemas'

const Index = () => {
  const [formAntd] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const { modal, setmodal } = useContext(UserContext)
  const id = state?.record?.id
  const name = state?.record?.name
  const description = state?.record?.description
  const [defaultValueFromList, setDefaultValueFromList] = React.useState(
    { name, description } || {
      name: '',
      description: '',
    }
  )

  const [leaveModalOpen, setLeaveModalOpen] = React.useState(false)

  const [isValidForm, setIsValidForm] = React.useState(false)
  const [isValidFormEdit, setIsValidFormEdit] = React.useState(false)
  const [nameGroup, setNameGroup] = useState('')
  const [descriptionGroup, setDescriptionGroup] = useState('')
  const [editModalOpen, setEditModalOpen] = React.useState(false)
  const [isUnique, setIsUnique] = React.useState(false)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })
  const initialValues = formik.initialValues.name
  const [isFormError, setIsFormError] = React.useState(formik.errors)
  useEffect(() => {
    id && getGroupById()
  }, [])

  React.useEffect(() => {
    setIsFormError(formik.errors)
    formik.setStatus(isFormError)
  }, [formik.errors])

  const isNameEmpty = formik.getFieldMeta('name').value === undefined || formik.getFieldMeta('name').value === ''
  const isDescriptionEmpty =
    formik.getFieldMeta('description').value === undefined || formik.getFieldMeta('description').value === ''

  const getGroupById = async () => {
    const res = await getData({
      schema: `${adminauth2}/group/` + state?.record?.id,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const { name, description, status } = res?.data
        setNameGroup(name)
        setDescriptionGroup(description)
        formAntd.setFieldValue('nameGroup', name)
        formAntd.setFieldValue('description', description)
      } else {
        if (res?.code !== 401) setDefaultValueFromList({ name: '', description: '' })
      }
    }
  }

  const uniqueValidate = async value => {
    if (formAntd?.getFieldValue('nameGroup') === nameGroup) return false
    const sendToList = {
      schema: `${adminauth2}/group`,
      params: { page: 1, per_page: 10, filters: [`name:eq:${formAntd.getFieldValue('nameGroup')}`] },
      url: '',
      baseURL: '',
    }
    const res = await getList(sendToList).catch(err => {
      console.log('err', err)
    })
    if (state?.isEdit) {
      if (res?.code === 200 && res?.items?.[0]?.name === initialValues) {
        setIsUnique(false)
        return
      }
    }
    if (res?.code === 200) {
      formAntd.setFields([
        {
          name: 'nameGroup', // required
          value: formAntd.getFieldValue('nameGroup'), //optional
          errors: ['กรุณาพิมพ์คำใหม่ เนื่องจากมีรายการที่ใช้คำดังกล่าวแล้ว'],
        },
      ])
      setIsUnique(value)
      setIsValidFormEdit(false)
      setIsValidForm(false)
    } else {
      formAntd.setFields([
        {
          name: 'nameGroup', // required
          value: formAntd.getFieldValue('nameGroup'), //optional
          errors: '',
        },
      ])
    }
  }

  const handlerSubmit = async () => {
    const payload = {
      name: formAntd.getFieldValue('nameGroup'),
      description: formAntd.getFieldValue('description'),
    }
    if (!state?.isEdit) {
      const res = await postData(`${adminauth2}/group`, payload)
      if (res.code === 201) {
        localStorage.setItem('modal', 'create')
        navigate('/operation-tools/bof-group', {
          state: {
            showNoti: true,
          },
        })
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
      if (res.errors?.[0].code === 'unique') {
        formik.setErrors({ name: 'กรุณาพิมพ์คำใหม่ เนื่องจากมีรายการที่ใช้คำดังกล่าวแล้ว' })
        setIsUnique(true)
      }
    } else {
      const res = await putData(`${adminauth2}/group`, id, payload)
      if (res?.code === 200) {
        localStorage.setItem('modal', 'update')
        navigate('/operation-tools/bof-group', {
          state: {
            showNoti: true,
          },
        })
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
      if (res?.errors?.[0]?.code === 'unique') {
        formik.setErrors({ name: 'กรุณาพิมพ์คำใหม่ เนื่องจากมีรายการที่ใช้คำดังกล่าวแล้ว' })
        setIsUnique(true)
      }
    }
  }

  const renderTooltipCreate = () => {
    return (
      <React.Fragment>
        {!isValidForm ? (
          <Tooltip
            overlayInnerStyle={{
              backgroundColor: '#597081',
              borderRadius: '12px',
              fontSize: '10px',
              width: '212px',
              height: '36px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 6px 10px 12px',
            }}
            overlayStyle={{ padding: '4px' }}
            showArrow={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValidForm}
              onClick={handlerSubmit}
              style={{ fontSize: '16px' }}
            >
              บันทึก
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValidForm}
            onClick={handlerSubmit}
            style={{ fontSize: '16px' }}
          >
            บันทึก
          </Button>
        )}
      </React.Fragment>
    )
  }

  const renderTooltipEdit = () => {
    return (
      <React.Fragment>
        {!isValidFormEdit ? (
          <Tooltip
            // title={!isUnique ? 'กดบันทึกไม่ได้ เนื่องจากกรอกข้อมูลไม่ครบถ้วน' : undefined}
            overlayInnerStyle={{
              backgroundColor: '#597081',
              borderRadius: '12px',
              fontSize: '10px',
              width: '212px',
              height: '36px',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 6px 10px 12px',
            }}
            overlayStyle={{ padding: '4px' }}
            showArrow={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isValidFormEdit}
              onClick={handlerSubmit}
              style={{ fontSize: '16px' }}
            >
              บันทึก
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={!isValidFormEdit}
            onClick={() => {
              setEditModalOpen(true)
            }}
            style={{ fontSize: '16px' }}
          >
            บันทึก
          </Button>
        )}
      </React.Fragment>
    )
  }

  const renderMode = () => {
    if (state?.isEdit) {
      // Edit Mode
      return (
        <React.Fragment>
          {!isValidFormEdit ? (
            <CustomSpace>
              {renderTooltipEdit()}

              <CustomModal
                transitionName=""
                centered
                open={leaveModalOpen}
                onOk={() => {
                  setLeaveModalOpen(false)
                  navigate(-1)
                }}
                onCancel={() => setLeaveModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p
                  style={{
                    color: '#001F34',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginBottom: '2px',
                  }}
                >
                  คุณต้องการออกจากหน้านี้?
                </p>
                <p
                  style={{
                    color: '#597081',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
                </p>
              </CustomModal>
            </CustomSpace>
          ) : (
            <CustomSpace>
              {renderTooltipEdit()}
              <CustomModal
                transitionName=""
                centered
                open={editModalOpen}
                onOk={() => {
                  setEditModalOpen(false)
                  handlerSubmit()
                }}
                onCancel={() => setEditModalOpen(false)}
                closable={false}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p
                  style={{
                    color: '#001F34',
                    fontSize: '16px',
                    marginBottom: '2px',
                    fontWeight: '400',
                  }}
                >
                  ยืนยันการแก้ไขข้อมูล
                </p>
                <p style={{ color: '#C4C4C4', fontSize: '12px', fontWeight: '300' }}>
                  หากคุณแก้ไขข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้
                </p>
              </CustomModal>

              <CustomModal
                transitionName=""
                centered
                open={leaveModalOpen}
                onOk={() => {
                  setLeaveModalOpen(false)
                  navigate(-1)
                }}
                onCancel={() => setLeaveModalOpen(false)}
                closable={false}
                okText="ยกเลิก"
                cancelText="ตกลง"
              >
                <div style={{ paddingBottom: '10px' }}>
                  <ExclamationCircle />
                </div>
                <p
                  style={{
                    color: '#001F34',
                    fontSize: '16px',
                    marginBottom: '2px',
                    fontWeight: '400',
                  }}
                >
                  คุณต้องการออกจากหน้านี้?
                </p>
                <p
                  style={{
                    color: '#597081',
                    fontSize: '14px',
                    fontWeight: '300',
                  }}
                >
                  หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก
                </p>
              </CustomModal>
            </CustomSpace>
          )}
        </React.Fragment>
      )
    } else {
      // Create Mode
      return (
        <React.Fragment>
          {!isValidForm && isDescriptionEmpty && isNameEmpty ? (
            <CustomSpace align="end">{renderTooltipCreate()}</CustomSpace>
          ) : (
            <CustomSpace align="end">{renderTooltipCreate()}</CustomSpace>
          )}
        </React.Fragment>
      )
    }
  }
  const handleChange = () => {
    if (!formAntd.getFieldValue('nameGroup')?.length) {
      setIsValidForm(false)
      setIsValidFormEdit(false)
    } else {
      setIsValidForm(formAntd.getFieldValue('nameGroup')?.length && formAntd.getFieldsError()[0]?.errors.length === 0)
      let checkName = formAntd.getFieldValue('nameGroup') !== nameGroup
      let checkDiscription = formAntd.getFieldValue('description') !== descriptionGroup
      let btn =
        formAntd.getFieldValue('nameGroup') === nameGroup && formAntd.getFieldValue('description') === descriptionGroup
      setIsValidFormEdit((checkName || checkDiscription) && formAntd.getFieldsError()[0]?.errors.length === 0)
    }
  }

  return (
    <div style={{ padding: '20px 40px' }}>
      <form onSubmit={formik.handleSubmit} onChange={handleChange}>
        {state?.isEdit ? (
          <Space style={{ paddingTop: '20px' }}>
            <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => setLeaveModalOpen(true)} />
            <Title>แก้ไขข้อมูลกลุ่มผู้ใช้งาน</Title>
          </Space>
        ) : (
          <Space style={{ paddingTop: '20px' }}>
            <ButtonBack shape="circle" icon={<IconArrowLeft />} onClick={() => setLeaveModalOpen(true)} />
            <Title>เพิ่มข้อมูลกลุ่มผู้ใช้งาน</Title>
          </Space>
        )}

        <Box>
          <div style={{ padding: '2rem' }}>
            <Form
              form={formAntd}
              layout="vertical"
              onValuesChange={handleChange}
              style={{ paddingBottom: '0px', height: '80px', maxWidth: '824px' }}
            >
              <Form.Item
                name="nameGroup"
                label={
                  <div>
                    ชื่อกลุ่ม <span style={{ color: '#F85858' }}>*</span>
                  </div>
                }
              >
                <Input
                  placeholder="กรอกชื่อกลุ่ม"
                  onBlur={uniqueValidate}
                  onMouseLeave={uniqueValidate}
                  onChange={() => {
                    formAntd.setFields([
                      {
                        name: 'nameGroup', // required
                        value: formAntd.getFieldValue('nameGroup'), //optional
                        errors: '',
                      },
                    ])
                  }}
                />
              </Form.Item>

              <Form.Item name="description" label={'รายละเอียด'}>
                <Input
                  placeholder="กรอกรายละเอียด"
                  maxLength={255}
                  onChange={() => {
                    formAntd.setFields([
                      {
                        name: 'description', // required
                        value: formAntd.getFieldValue('description'), //optional
                        errors: '',
                      },
                    ])
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </Box>
        {renderMode()}
      </form>
      <CustomModal
        transitionName=""
        centered
        open={leaveModalOpen}
        onOk={() => {
          setLeaveModalOpen(false)
          navigate(-1)
        }}
        onCancel={() => setLeaveModalOpen(false)}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">คุณต้องการออกจากหน้านี้?</p>
        <p className="modal-message">หากคุณออกจากหน้านี้ข้อมูลจะไม่ถูกบันทึก</p>
      </CustomModal>
    </div>
  )
}

export default Index
