import { Input, Select, DatePicker, Space, Divider } from 'antd'
import React, { useCallback } from 'react'
import { BoxSearch } from './list.style'
import styled from 'styled-components'
import moment from 'moment'
const { RangePicker } = DatePicker
export default function Search({ masterData, setSearchData, searchData, conversDate }) {
  let optionsStatus = masterData?.status?.map(item => {
    return {
      label: item.name,
      value: item.name_en,
    }
  })
  let optionsChannel = [
    {
      label: 'Web application',
      value: 'Web application',
    },
    {
      label: 'Line OA',
      value: 'LineOA',
    },
  ]
  const handleChange = (value, type) => {
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  const thaiYearFormat = date => {
    if (date) {
      const thaiYear = date.year() + 543
      return moment(date).year(thaiYear)
    }
    return null
  }

  const disabledDate = current => {
    return current && current > moment().endOf('day')
  }

  const RangPickerCallBack = useCallback(() => {
    return (
      <RangePicker
        disabledDate={disabledDate}
        defaultValue={[searchData.date[0], searchData.date[1]]}
        onChange={val => handleChange(val, 'date')}
        format={'DD/MM/YYYY'}
        allowClear={false}
      />
    )
  }, [searchData])
  return (
    <BoxSearch>
      <BoxSelect>
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 250px 1fr', gap: '10px' }}
          className="search-component"
        >
          <Input
            placeholder="ค้นหาจากบริการสาธารณะและเลขที่บริการสาธารณะ"
            value={searchData?.search}
            onChange={val => handleChange(val, 'input')}
            allowClear={true}
          />

          <Select
            placeholder="สถานะทั้งหมด"
            options={optionsStatus}
            value={searchData?.status}
            onSelect={(val, e) => {
              handleChange(e?.value, 'status')
            }}
            onClear={val => handleChange(val, 'status')}
            allowClear
          />
          <Select
            placeholder="ประเภทช่องทางบริการสาธารณะ"
            optionFilterProp="label"
            value={searchData?.channel}
            options={optionsChannel}
            onSelect={val => handleChange(val, 'channel')}
            onClear={val => handleChange(val, 'channel')}
            allowClear
          />

          <RangPickerCallBack />

          <span
            style={{
              color: '#556A82',
              textDecorationLine: 'underline',
              paddingTop: '10px',
              cursor: 'pointer',
              width: '60px',
            }}
            onClick={() =>
              setSearchData({
                date: [moment().subtract(30, 'days'), moment()],
              })
            }
          >
            ล้างข้อมูล
          </span>
        </div>
      </BoxSelect>
    </BoxSearch>
  )
}
const BoxSelect = styled.div`
  margin-top: 5px;
  .date-picker,
  .ant-picker-input > input {
    color: #f36b24 !important;
  }
  .ant-picker-range .ant-picker-input > input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`
