import React, { useEffect, useRef, useState } from 'react'
import { Button, notification, Tooltip, Dropdown, Menu, Row, Col, DatePicker } from 'antd'
import styled from 'styled-components'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'
import ColumnsChart from 'components/Charts/ColumnsChart'
import DonutChart from 'components/Charts/DonutChart'
import LinCharts from 'components/Charts/LineChart'
import ProgressChart from 'components/Charts/ProgressChart'
import { useReactToPrint } from 'react-to-print'
import ReportPdfEservice from './ReportPdf'
import moment from 'moment'

import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { useDownloadExcel } from 'react-export-table-to-excel'
import IconImport from 'assets/images/svg/icon-import-complaint.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import axios from 'axios'
import { CheckStatusText, checkColorStatusDonutEservice } from './container'
import dayjs from 'dayjs'
import EmtyData from 'assets/images/svg/Search Empty.svg'

const DashboardEservice = () => {
  const { RangePicker } = DatePicker
  const token = localStorage?.getItem('access_token')
  const ReportRef = useRef(null)
  const [searchData, setSearchData] = useState({
    date: [moment(new Date()).subtract(1, 'months'), moment(new Date())?.endOf('day')],
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const NotdefaultColor = {
    received: '#915EFF',
    'in progress': '#FFBB0B',
    done: '#13AFA6',
  }

  const [dataTotal, setDataTotal] = useState(0)
  const [meData, setMedata] = useState([])

  const [dataChartMany, setDataChartMany] = useState({
    chartManySeries: [],
    chartManyTimeXaxis: [],
    ChartManyTimeColor: [],
  })

  const [dataDonutOvertime, setDataDonutOvertime] = useState({
    donutOverTimeSeries: [],
    donutOverTimeXaxis: [],
    donutOverTimeColor: [],
    AllOvertime: [],
  })

  const [dataChartSlack, setDataChartSlack] = useState({
    seriesChartSlack: [],
    xaxisChartSlack: [],
  })

  const [dataStatus, setDataStatus] = useState([])
  const [Nodata, setNodata] = useState(false)

  useEffect(() => {
    getDataDashboardEserivce()
    GetMeData()
  }, [searchData, Nodata])

  const GetMeData = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMeData = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resMeData.status === 200 || resMeData.status === 201) {
        setMedata(resMeData?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getDataDashboardEserivce = async () => {
    try {
      const checkStartDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[0]).startOf('day')?.unix()}`
          : moment(new Date()).subtract(1, 'months').unix()
      const checkEndDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[1]).endOf('day')?.unix()}`
          : moment(new Date())?.endOf('day')?.unix()

      // const response = await axios.get(
      //   `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/dashboard/e-service?start_at=1704042000&end_at=1707238799`
      // )

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/dashboard/e-service?start_at=${checkStartDate}&end_at=${checkEndDate}`
      )
      if (response?.status === 200 || response?.status === 201) {
        const dataGraph = response?.data?.data?.dashboard_data

        const donutOverTimeSeries = dataGraph?.type_e_service?.map(item => item?.total)
        const donutOverTimeXaxis = dataGraph?.type_e_service?.map(item => item?.type_name)
        const donutOverTimeColor = dataGraph?.type_e_service?.map(item =>
          checkColorStatusDonutEservice(item?.type_name)
        )
        const chartManySeries = dataGraph?.month_data?.series
        const chartManyTimeXaxis = dataGraph?.month_data?.categories

        const seriesChartSlack = dataGraph?.urgency_data?.series
        const xaxisChartSlack = dataGraph?.urgency_data?.categories

        setDataTotal(dataGraph?.total_all)

        setDataStatus(dataGraph?.status_data)

        setDataChartSlack({
          ...dataChartSlack,
          seriesChartSlack,
          xaxisChartSlack,
        })

        setDataChartMany({
          ...dataChartMany,
          chartManySeries,
          chartManyTimeXaxis,
        })

        setDataDonutOvertime({
          ...dataDonutOvertime,
          donutOverTimeSeries,
          donutOverTimeXaxis,
          donutOverTimeColor,
        })
      } else {
        setNodata(true)

        setDataDonutOvertime({
          donutOverTimeSeries: [],
          donutOverTimeXaxis: [],
          donutOverTimeColor: [],
          AllOvertime: [],
        })
      }
    } catch (error) {}
  }

  const handlePrint = useReactToPrint({
    content: () => ReportRef.current,
    documentTitle: `reportdash`,
  })

  const CardColumnPdf = () => {
    return (
      <div style={{ width: '100%', padding: '20px' }}>
        <ColumnsChart
          xaxis={dataChartMany?.chartManyTimeXaxis}
          series={dataChartMany?.chartManySeries}
          width={'1000px'}
          height="300px"
          maxWidthPrint={'1000px'}
          dataLabels={true}
        ></ColumnsChart>
      </div>
    )
  }

  const CardColumnPdfSlack = () => {
    return (
      <>
        <ColumnsChart
          stacked={true}
          xaxis={dataChartSlack?.xaxisChartSlack}
          series={dataChartSlack?.seriesChartSlack}
          width={'1000px'}
          height="300px"
          maxWidthPrint={'1000px'}
          columnWidth="10%"
        />
      </>
    )
  }

  const CardDonutPdf = () => {
    return (
      <>
        <DonutChart
          labels={dataDonutOvertime?.donutOverTimeXaxis}
          series={dataDonutOvertime?.donutOverTimeSeries}
          colors={dataDonutOvertime?.donutOverTimeColor}
          legendPosition={'right'}
          width="500px"
          height="220px"
        />
      </>
    )
  }

  const CardProgressPdf = () => {
    return (
      <div style={{ alignSelf: 'center' }}>
        {/* <ProgressChart
          data={datamockProgess222}
          timeline
          NotdefaultColor={NotdefaultColor}
          MinwidthProgress={'700px'}
        /> */}
        {dataStatus &&
          dataStatus?.map((item, index) => {
            return (
              <div>
                <Boxflex key={index} Margin={'0'} className={'time-line'}>
                  <div style={{ display: 'flex', margin: '0px' }}>
                    <Text fontSize={'12px'}>{CheckStatusText(item?.status_name)}</Text>
                    <TimelineWrapper>
                      <TimelineEvent DtColor={NotdefaultColor[item?.status_name]} />
                      {index !== dataStatus?.length - 1 && (
                        <>
                          <LineBlack />
                          <LineBlack />
                          <LineBlack />
                          <LineBlack />
                        </>
                      )}
                    </TimelineWrapper>
                  </div>
                  <Progress
                    MaxwidthProgress="700px"
                    PgColor={item.percen > 0 ? NotdefaultColor[item?.status_name] : '#ededed'}
                  >
                    <Bar
                      className="men"
                      fontSize={10}
                      percen={item?.percent}
                      color={NotdefaultColor[item?.status_name]}
                    >
                      {item?.percent}
                    </Bar>
                  </Progress>

                  <Boxnum fontSize={'12px'}>{item?.total}</Boxnum>
                </Boxflex>
              </div>
            )
          })}{' '}
      </div>
    )
  }

  const handleChange = (value, type) => {
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  const thaiYearFormat = date => {
    if (date) {
      const thaiYear = date.year() + 543
      return moment(date).year(thaiYear)
    }
    return null
  }

  // const startDate = thaiYearFormat(searchData?.date ? searchData.date[0] : null)
  // const endDate = thaiYearFormat(searchData?.date ? searchData.date[1] : null)
  const disabledDate = current => {
    // Disable dates after the current day
    return current && current > moment().endOf('day')
  }

  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }
  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'export-pdf') {
      handlePrint()
    } else if (e.key === 'export-excel') {
      onLoadXsls('xlsx')
    } else if (e.key === 'export-excel-csv') {
      onLoadXsls('csv')
    }
  }

  const onLoadXsls = async typeFile => {
    try {
      const checkStartDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[0]).startOf('day')?.unix()}`
          : moment(new Date()).subtract(1, 'months').unix()
      const checkEndDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[1]).endOf('day')?.unix()}`
          : moment(new Date())?.endOf('day')?.unix()

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/dashboard/e-service/export?start_at=${checkStartDate}&end_at=${checkEndDate}&file_extension=${typeFile}`,
        {
          responseType: 'blob', // Important
        }
      )
      // Create a blob from the response
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `dashboard-eservice.${typeFile}`)

      // Append the link to the body
      document.body.appendChild(link)

      // Click the link to start download
      link.click()

      // Clean up: remove the link and revoke the URL
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {}
  }

  const menuImport = value => {
    return (
      <Menucustom onClick={handleMenuImportClick}>
        <Menucustom.Item key={`export-pdf`}>PDF</Menucustom.Item>
        <Menucustom.Item key={`export-excel`}>XLSX</Menucustom.Item>
        <Menucustom.Item key={`export-excel-csv`}>CSV</Menucustom.Item>
      </Menucustom>
    )
  }

  return (
    <>
      <BoxOverAll>
        <HeaderTopic>
          <TextTitle>แดชบอร์ดแสดงภาพรวมของบริการสาธารณะ</TextTitle>

          <BoxTitle>
            <div style={{ fontSize: '24px', fontWeight: 500, color: '#19263F' }}></div>
            <Dropdown
              overlay={() => menuImport()}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px',
                  width: '160px',
                  backgroundColor: '#F36B24',
                  borderRadius: '8px',
                  height: '40px',
                  cursor: 'pointer',
                }}
              >
                <img src={IconImport} alt="add" style={{ display: 'inline' }} />
                <span style={{ paddingLeft: '4px', paddingTop: '1px', fontSize: '16px', color: '#ffffff' }}>
                  ส่งออกข้อมูล
                </span>
                <img
                  src={IconArrowDown}
                  alt="add"
                  style={{
                    display: 'inline',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </div>
            </Dropdown>
          </BoxTitle>
        </HeaderTopic>

        <HeaderBox></HeaderBox>

        <BoxForm>
          <BoxSelect>
            <div style={{ width: '300px', display: 'flex', flexDirection: 'column' }}>
              <div> เลือกช่วงวันที่ข้อมูล</div>
              <RangePicker
                width="200px"
                placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                value={searchData?.date}
                onChange={val => handleChange(val, 'date')}
                format={'DD/MM/YYYY'}
                className="date-picker"
                disabledDate={disabledDate}
                allowClear={false}
              />
            </div>
          </BoxSelect>
        </BoxForm>

        {Nodata ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
              flexDirection: 'column',
            }}
          >
            <div>
              <img src={EmtyData} />
            </div>
            <div>ไม่พบข้อมูล</div>
          </div>
        ) : (
          <BoxAllState id={`capture`}>
            {/* <MonthText>ประจำเดือนกันยายน 2566</MonthText> */}

            <CardGrid>
              <WrapBox>
                <BoxNumOfComp>
                  <LeftBoxNumOfComp>
                    <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                    <TextSatus>จำนวนเรื่องร้องเรียนทั้งหมด</TextSatus>
                    <TextSatusStay>(เรื่อง)</TextSatusStay>
                  </LeftBoxNumOfComp>
                  <NumStyle>{dataTotal}</NumStyle>
                </BoxNumOfComp>
                <BoxbgChart>
                  {dataStatus &&
                    dataStatus?.map((item, index) => {
                      return (
                        <div>
                          <Boxflex key={index} Margin={'0'} className={'time-line'}>
                            <div style={{ display: 'flex', margin: '0px' }}>
                              <Text fontSize={'12px'}>{CheckStatusText(item?.status_name)}</Text>
                              <TimelineWrapper>
                                <TimelineEvent DtColor={NotdefaultColor[item?.status_name]} />
                                {index !== dataStatus?.length - 1 && (
                                  <>
                                    <LineBlack />
                                    <LineBlack />
                                    <LineBlack />
                                    <LineBlack />
                                  </>
                                )}
                              </TimelineWrapper>
                            </div>
                            <Progress PgColor={item.percen > 0 ? NotdefaultColor[item?.status_name] : '#ededed'}>
                              <Bar
                                className="men"
                                fontSize={10}
                                percen={item?.percent}
                                color={NotdefaultColor[item?.status_name]}
                              >
                                {item?.percent > 0 && `${item?.percent?.toFixed()} %`}
                              </Bar>
                            </Progress>

                            <Boxnum fontSize={'12px'}>{item?.total}</Boxnum>
                          </Boxflex>
                        </div>
                      )
                    })}
                </BoxbgChart>
              </WrapBox>

              <BoxbgChart>
                <div>ประเภทเรื่องที่ขอบริการสาธารณะ</div>
                <DonutChart
                  labels={dataDonutOvertime?.donutOverTimeXaxis}
                  series={dataDonutOvertime?.donutOverTimeSeries}
                  colors={dataDonutOvertime?.donutOverTimeColor}
                  legendPosition={'right'}
                />
              </BoxbgChart>
            </CardGrid>

            {/* ///////card 1111111 */}
            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus>ประเภทเรื่องที่ร้องขอรายเดือน</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <ColumnsChart
                xaxis={dataChartMany?.chartManyTimeXaxis}
                series={dataChartMany?.chartManySeries}
                // toolTipChart={toolTipChart}
                dataLabels={true}
              ></ColumnsChart>
            </FlexAgency>
            {/* ///////card 1111111 */}

            {/* ///////card 1111111 */}
            {/* <FlexAgency>
            <TopicHeaderStatus>
              <TextSatus>ลำดับความสำคัญเรื่องที่ร้องขอรายเดือน</TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>

            <ColumnsChart
              columnWidth="10%"
              stacked={true}
              xaxis={dataChartSlack?.xaxisChartSlack}
              series={dataChartSlack?.seriesChartSlack}
              // colorChart={colorChartSlack}
            ></ColumnsChart>
          </FlexAgency> */}
          </BoxAllState>
        )}
      </BoxOverAll>

      <div>
        <ReportPdfEservice
          meData={meData}
          searchData={searchData}
          ReportRef={ReportRef}
          // CardColumnPdfSlack={CardColumnPdfSlack()}
          CardProgressPdf={CardProgressPdf()}
          CardDonutPdf={CardDonutPdf()}
          CardColumnPdf={CardColumnPdf()}
        />
      </div>
    </>
  )
}

export default DashboardEservice

const Text = styled.div`
  display: flex;
  align-items: start;
  margin: 10px 10px 0 0;
  font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
  width: 80px;
`

const LineBlack = styled.div`
  height: 10px;
  width: 2px;
  background-color: #aba9a8;
  margin: 1px;
`

const TimelineEvent = styled.div`
  width: 18px;
  height: 18px;
  background: ${props => props.DtColor || '#ededed'};
  /* background-color: #3498db; */
  border-radius: 50%;
`

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`

const Dotstar = styled.div`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  margin: 0 10px;
  background: ${props => props.DtColor || '#ededed'};
`

const Boxnum = styled.div`
  margin: 0 10px;
  font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
`

const Menucustom = styled(Menu)`
  padding: 8px !important;
  width: 160px !important;
  .ant-dropdown-menu-item:hover {
    background-color: #ffffff;
  }
  .ant-dropdown-menu-title-content:hover {
    background-color: #ffeee7;
    color: #f36b24;
    border-radius: 4px;
  }
`

const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  /* padding-top: 22px; */
  border-radius: 8px;

  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 162px;
    border-radius: 8px;
  }
`

const BtnSubmit = styled.div`
  display: flex;
  justify-content: end;

  /* padding: 10px 10px 15px 10px; */

  .ant-btn-primary {
    border-radius: 8px;
    background-color: #f36b24;
    border-color: #f36b24;
    font-size: 14px;
    width: 180px;
    height: 40px;
    font-weight: 500;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
`

const BoxSelect = styled.div`
  margin-top: 5px;
  &.ant-picker {
  }

  .date-picker,
  .ant-picker-input > input {
    color: #f36b24 !important;
  }
  .ant-picker-range .ant-picker-input > input::placeholder {
    /* Change the placeholder color to red */
    color: #ffc4aa !important;
  }
  .ant-select-dropdown {
    min-width: 155px !important;
    width: 155px !important;
  }
  .ant-select-item-option:hover {
    background-color: red !important; /* Change the background color on hover */
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: red !important; /* Change the background color of the active item */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffeee7;
  }
`

const BoxbgChart = styled.div`
  background-color: rgb(251, 251, 251);
  border-radius: 12px;
  padding: 24px;
  margin: 0 10px;
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`

const BoxOverAll = styled.div`
  margin: 0 40px 40px 40px;

  @media only screen and (max-width: 820px) {
    margin: 0 20px 20px 20px;
  }
`

const Stat = styled.div`
  display: inline-flex;
  align-items: flex-start;
`

const StatPath = styled.p`
  color: #717171;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 20px;
`

const StatNow = styled.p`
  color: #f36b24;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 20px;
`

const HeaderBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  /* background-color: #cce415; */
`

const HeaderTopic = styled.h1`
  font-family: Prompt;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  text-align: center;
  margin-top: 33px;
  justify-content: space-between;

  @media only screen and (max-width: 720px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 635px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    text-align: left;
  }
`

const ButtonBox = styled.button`
  width: 160px;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: #f36b24;
  border: none;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  margin-right: 16px;
  color: #fff;
`

const ButtonPrint = styled.button`
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #f36b24;
  background: #fff;
  color: #f36b24;
`

const BoxForm = styled.div`
  display: flex;
  flex: 1;
  /* height: 90px; */
  border-radius: 12px;
  background: rgba(252, 101, 33, 0.03);
  margin: 16px 0px 16px 0px;
  padding: 16px;
`

const BoxAllState = styled.div`
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
`

const MonthText = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 24px;
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`

const StyledImage = styled.img`
  padding-bottom: 10px;
`

const BoxNumOfComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border-radius: 12px;
  margin: 10px;
  background-color: rgba(252, 101, 33, 0.03) !important;

  /* @media only screen and (max-width: 450px) {
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    padding: 0;
    align-items: flex-start;
  } */
`

const LeftBoxNumOfComp = styled.div`
  display: flex;
  padding-top: 15px;
  gap: 8px;
`

const BoxsSatus = styled.div`
  /* height: 400px; */
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const TopicHeaderStatus = styled.div`
  display: flex;
  gap: 8px;
`

const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const TextSatusStay = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
`

const BoxSatisfaction = styled.div`
  /* height: 400px; */
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const RightBox = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const FlexOvEx = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`

const BoxOverLoad = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const BoxExigent = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const FlexAgency = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

const BottomBox = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

const NumStyle = styled.p`
  font-family: Prompt;
  padding-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`

const Progress = styled.div`
  max-width: ${props => (props.MaxwidthProgress ? props.MaxwidthProgress : '')};
  min-width: ${props => (props.MinwidthProgress ? props.MinwidthProgress : '')};
  width: ${props => (props.PgWidth ? props.PgWidth : '100%')};
  height: ${props => (props.PgHeight ? props.PgHeight : '44px')};
  /* height: 44px; */
  border-radius: 10px;
  box-shadow: 0 0 3px #b0b0;
  background-color: #fecccc;
  display: flex;
  overflow: hidden;
  background: ${props => props.PgColor || '#ededed'};
`

export const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* width: ${props => props.percen || 0}%; */
  width: ${props => props.percen || 0}%;
  color: white;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || 10}px;
  line-height: 16px;
  background: ${props => props.color || '#ededed'};
`

const BoxDepart = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: ${props => (props.minWidth ? props.minWidth : '200px')};
`

const Boxflex = styled.div`
  display: flex;
  align-items: center;
  margin: ${props => (props.Margin ? props.Margin : '40px 0')};
  &.time-line {
    display: flex;
    justify-content: start;
    align-items: start;
  }
`

export const TextTitle = styled.div`
  font-style: 20px;
`

export const WrapBox = styled.div`
  display: flex;
  flex-direction: column;
`
