import { Table } from 'antd'
import styled from 'styled-components'

export const BlankContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 700px;
  background: none;

  p {
    text-align: center;
    color: #597081;
    font-weight: 400;
    font-size: 16px;
  }
`

export const BoxTable = styled.div`
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f36b24;
  }

  .ant-table-cell {
    font-size: 12px !important;
    color: #150f0b !important;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none !important;
  }
  .ant-table-container::after {
    box-shadow: none !important;
  }
  .ant-table-container::before {
    box-shadow: none !important;
  }
  .ant-table-tbody tr {
    height: 48px !important;
  }
  .ant-table-tbody > tr.ant-table-row > td:first-child {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  .ant-table-tbody > tr.ant-table-row > td:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .ant-table-thead tr {
    height: 40px;
    th {
      :last-child {
        padding-right: 26px;
      }
      :first-child {
        padding-left: 26px;
      }
    }
  }
  .ant-table-cell {
    background: none;
    color: #597081;
  }
  .ant-table {
    background: none;
    /* min-height: 800px; */
    min-height: ${props => (props.minHeightTb ? props.minHeightTb : '800px')};
  }
  table {
    border-collapse: separate;
    border-spacing: 0px 10px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #fff !important;
  }

  .ant-table-tbody > tr.ant-table-row > td:first-child {
    border-radius: 8px 0 0 0px !important;
  }

  .ant-table-tbody > tr.ant-table-row > td:last-child {
    border-radius: 0 8px 0px 0 !important;
  }

  .ant-table-tbody > tr.ant-table-row:nth-child(10) > td {
    border-bottom: 0;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td:first-child {
    border-radius: 8px 0 0 8px !important;
    border-color: #c8d3da;
    border-top: 1px solid #c8d3da;
    border-left: 1px solid #c8d3da;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td:last-child {
    border-radius: 0 8px 8px 0 !important;
    border-color: #c8d3da;

    border-top: 1px solid #c8d3da;
    border-right: 1px solid #c8d3da;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    border-top: 1px solid #c8d3da;
    border-bottom: 1px solid #c8d3da;
    border-color: #c8d3da;
  }
  .ant-table-tbody > tr.ant-table-row:hover {
    border-color: #c8d3da;
    border-radius: 8px 0 0 8px !important;
    box-shadow: 0px 4px 16px 0px rgba(200, 211, 218, 0.5);
  }
`
