import moment from 'moment'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import { ReactComponent as IconGear } from 'assets/images/svg/department/gear-icon.svg'
import { ReactComponent as IconMoney2 } from 'assets/images/svg/department/Component 50.svg'
import { ReactComponent as IconMoney } from 'assets/images/svg/department/money.svg'
import { ReactComponent as IconStudy } from 'assets/images/svg/department/study.svg'
import { ReactComponent as IconHuman } from 'assets/images/svg/department/human.svg'
import { ReactComponent as IconStaff } from 'assets/images/svg/department/staff.svg'
import { ReactComponent as IconMunicipality } from 'assets/images/svg/department/IconMunicipality .svg'
import { ReactComponent as IconHospital } from 'assets/images/svg/department/hospital.svg'
import { ReactComponent as Waterworks } from 'assets/images/svg/department/Waterworks.svg'
// import WaterIcon from 'assets/images/svg/WaterIcon.svg'
import WaterIcon from 'assets/images/png/WaterIcon.png'
export const dePartMentIcon = {
  สำนักช่าง: <IconGear />,
  สำนักการประปา: <Waterworks />,
  สำนักคลัง: <IconMoney />,
  สำนักการศึกษา: <IconStudy />,
  กองสวัสดิการสังคม: <IconHuman />,
  กองการเจ้าหน้าที่: <IconStaff />,
  สำนักปลัดเทศบาล: <IconMunicipality />,
  กองยุทธศาตร์และงบประมาณ: <IconMoney2 />,
  สำนักสาธารณสุขและสิ่งแวดล้อม: <IconHospital />,
}
export const checkColorStatusBg = val => {
  if (val === 'received') {
    return '#EFE9FE'
  }
  if (val === 'in progress') {
    return '#FDF5DD'
  }
  if (val === 'done') {
    return '#DFF6F5'
  }
  if (val === 'outside the area') {
    return '#E2E5F8'
  }
  if (val === 'tracking') {
    return '#FFE6DB'
  }
  if (val === 'over duedate') {
    return '#FFE4E4'
  }
  if (val === 'transfer') {
    return '#EAEAEB'
  }
}

export const checkColorStatusText = val => {
  if (val === 'received') {
    return '#915EFF'
  }
  if (val === 'in progress') {
    return '#FFBB0B'
  }
  if (val === 'done') {
    return '#13AFA6'
  }
  if (val === 'outside the area') {
    return '#3F54D1'
  }
  if (val === 'tracking') {
    return '#F36B24'
  }
  if (val === 'over duedate') {
    return '#F85858'
  }
  if (val === 'transfer') {
    return '#717171'
  }
}

export const checkStatusText = val => {
  if (val === 'received') {
    return 'รับเรื่อง'
  }
  if (val === 'in progress') {
    return 'อยู่ระหว่างดำเนินการ'
  }
  if (val === 'done') {
    return 'ดำเนินการแล้วเสร็จ'
  }
  if (val === 'outside the area') {
    return 'ไม่อยู่ในความรับผิดชอบ'
  }
  if (val === 'tracking') {
    return 'ติดตาม'
  }
  if (val === 'over duedate') {
    return 'เกินระยะเวลากำหนด'
  }
  if (val === 'transfer') {
    return 'โอนย้ายหน่วยงาน'
  }
}

export const checkRating = val => {
  if (val === 1) {
    return 'ควรปรับปรุง'
  }
  if (val === 2) {
    return 'พอใช้'
  }
  if (val === 3) {
    return 'ดี'
  }
  if (val === 4) {
    return 'ดีมาก'
  }
  if (val === 5) {
    return 'ยอดเยี่ยม'
  }
}

export const checkDueDate = val => {
  let currant = moment().unix() * 1000
  let due = val * 1000
  const date1 = moment(due)
  const date2 = moment(currant)
  const diffInDays = date1.diff(date2, 'days') + 1
  const formattedDate1 = date1.format('DD/MM/YYYY')
  const isDueDatePassed = moment(date1).isBefore(date2)
  if (isDueDatePassed) {
    const dateMoment = moment(formattedDate1, 'DD/MM/YYYY')
    const unixTimestamp = dateMoment.valueOf() / 1000 // Convert milliseconds to seconds
    return formatThaiDate(conversDate(unixTimestamp))
  } else {
    if (diffInDays > 0 && diffInDays <= 3) {
      return 'เหลืออีก ' + diffInDays + ' วัน'
    } else {
      const dateMoment = moment(formattedDate1, 'DD/MM/YYYY')
      const unixTimestamp = dateMoment.valueOf() / 1000 // Convert milliseconds to seconds
      return formatThaiDate(conversDate(unixTimestamp))
    }
  }
}
export const formatThaiDate = dateString => {
  const [day, month, year] = dateString?.split(' ')
  const formattedDay = day?.length === 1 ? `0${day}` : day // Add leading zero if the day is single-digit
  return `${formattedDay} ${month} ${year}`
}

export const Water = [
  {
    id: '1758193767589867520',
    complaint_no: '1602256707896',
    name: {
      first_name: '-',
      last_name: '-',
    },
    mobile_number: '0000000000',
    line_id: '',
    channel: 'other',
    organization: null,
    category: {
      id: '1735509869680652288',
      name: 'ประปา',
      icon_url: WaterIcon,
      bg_icon_url: 'https://gateway.koratcity.go.th/sit/media/images/48261944-abcd-4ea9-941f-181ccc77f17c.png',
      icon_line: 'https://gateway.koratcity.go.th/sit/media/images/afe2f2bb-d583-437e-9ea5-38dfaae70cfb.png',
      bg_icon_line: 'https://gateway.koratcity.go.th/sit/media/images/896a626a-487e-4f28-a2d0-ecd309b673b8.png',
    },
    sub_category_data: {
      id: '1735509869680652289',
      name: 'ประปา',
    },
    type: 'water',
    complaint_name: 'ประปาแตก',
    description: '',
    media_url: [
      'https://storage.googleapis.com/traffy_public_bucket/attachment/2023-08/3597ae47004f7bdc8826ca35b182d9c90be374bb.jpg',
    ],
    address: '100 ถนน ประจักษ์ ตำบลในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
    province_id: '',
    district_id: '',
    sub_district_id: '',
    zib_code: '',
    coordinates: {
      latitude: 14.97703,
      longitude: 102.077444,
    },
    status_data: [
      {
        name: 'received',
        description: 'รับเรื่อง',
        staff_data: null,
        created_at: 1708021034,
        created_by: '1745645709895397376',
      },
    ],
    transaction_data: [
      {
        no: 0,
        type: '',
        department_id: '1730459515045801985',
        department_name: 'สำนักปะปา',
        description: '',
        media_url: null,
        coordinates: null,
        address: '',
        status: 'received',
        process_time: 0,
        officer_data: null,
        created_at: 0,
        created_by: '',
      },
    ],
    urgency: {
      id: '1744666975885324289',
      name: 'ทำทันที',
      sort: 5,
    },
    process_time: 0,
    duedate_at: 1708193834,
    department_data: [
      {
        id: '1730459515045801985',
        name: 'สำนักงานปะปา',
        officer_data: null,
      },
    ],
    rating: null,
    created_at: 1708021034,
    created_by: '1745645709895397376',
    updated_at: 1708021153,
    updated_by: '1754744174374023168',
    is_group: false,
  },
  {
    id: '1758137971766194176',
    complaint_no: '1502256700010',
    name: {
      first_name: 'sakeezt',
      last_name: 'test',
    },
    mobile_number: '0923737833',
    line_id: 'Ubbb7da4f8410eb70946ec4ba99439e3c',
    channel: 'line',
    organization: null,
    category: {
      id: '1735509869730983944',
      name: 'ปะปา',
      icon_url: WaterIcon,
      bg_icon_url: 'https://gateway.koratcity.go.th/sit/media/images/45ff21e2-3ac7-4d6f-93df-27113f02d962.png',
      icon_line: 'https://gateway.koratcity.go.th/sit/media/images/51d4cb8a-45bf-4bd1-8631-0e8cb61ba4ea.png',
      bg_icon_line: 'https://gateway.koratcity.go.th/sit/media/images/e7f25099-a3d1-4020-9458-369a81521de0.png',
    },
    sub_category_data: {
      id: '1735509869735178242',
      name: 'ประปาแตก',
    },
    type: 'water',
    complaint_name: 'สวัสดี',
    description: 'สวัสดี',
    media_url: ['https://gateway.koratcity.go.th/sit/media/images/4b170e46-be16-4a39-9e65-e9039b392831.jpg'],
    address: '22 ถ. สุรนารายณ์ ตำบลในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
    province_id: '1',
    district_id: '26',
    sub_district_id: '102601',
    zib_code: '',
    coordinates: {
      latitude: 14.992690741328572,
      longitude: 102.11454111148997,
    },
    status_data: [
      {
        name: 'received',
        description: 'กำลังประสานงาน ไปยังหน่วยงานที่เกี่ยวข้อง',
        staff_data: null,
        created_at: 1708007731,
        created_by: '1725355166355419136',
      },
    ],
    transaction_data: [
      {
        no: 0,
        type: '',
        department_id: '1730459515049996289',
        department_name: 'สำนักงานปะปา',
        description: '',
        media_url: null,
        coordinates: null,
        address: '',
        status: 'received',
        process_time: 0,
        officer_data: null,
        created_at: 0,
        created_by: '',
      },
    ],
    urgency: {
      id: '1744666975885324288',
      name: 'ด่วนที่สุด',
      sort: 4,
    },
    process_time: 0,
    duedate_at: 1708180531,
    department_data: [
      {
        id: '1730459515049996289',
        name: 'สำนักงานปะปา',
        officer_data: null,
      },
    ],
    rating: null,
    created_at: 1708007731,
    created_by: '1725355166355419136',
    updated_at: 1708020907,
    updated_by: '1745645709895397376',
    is_group: false,
  },
  {
    id: '1758155188595585024',
    complaint_no: '1502256700014',
    name: {
      first_name: '-',
      last_name: '-',
    },
    mobile_number: '0000000000',
    line_id: '',
    channel: 'traffy fondue',
    organization: null,
    category: {
      id: '1735509869722595328',
      name: 'ปะปา',
      icon_url: WaterIcon,
      bg_icon_url: 'https://gateway.koratcity.go.th/sit/media/images/c8cc6bd5-9781-4dc2-b294-7eb57b5b4f9f.png',
      icon_line: 'https://gateway.koratcity.go.th/sit/media/images/2ff826e8-cdae-4359-b918-249cf7c46b16.png',
      bg_icon_line: 'https://gateway.koratcity.go.th/sit/media/images/7b925c5c-89e4-49c4-8bed-df38a5e4faca.png',
    },
    type: 'water',
    sub_category_data: {
      id: '1735509869722595331',
      name: 'ท่อแตก',
    },
    complaint_name:
      '1มีนร.เทคนิคจำนวนมาก มาใช้บริการร้านน้ำกระท่อมแถวบริเวณนี้ และมีการจอดรถขวางทางเข้าออก ทำให้เกิดความรำคาญ และมีการมียืนฉี่ริมกำแพงบ้านเช่าห้องอื่นๆ ส่งกลิ่นรบกวน',
    description: '',
    media_url: [
      'https://storage.googleapis.com/traffy_public_bucket/attachment/2023-08/9a8a5724cd21468685835588031e6c9c57d2180d.jpg',
    ],
    address: '46 ซอย กิ่งสวายเรียง ในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
    province_id: '',
    district_id: '',
    sub_district_id: '',
    zib_code: '',
    coordinates: {
      latitude: 14.97633,
      longitude: 102.08258,
    },
    status_data: [
      {
        name: 'received',
        description: 'รับเรื่อง',
        staff_data: null,
        created_at: 1708011836,
        created_by: '1745645709895397376',
      },
    ],
    transaction_data: [
      {
        no: 0,
        type: '',
        department_id: '1730459515049996289',
        department_name: 'สำนักงานปะปา',
        description: '',
        media_url: null,
        coordinates: null,
        address: '',
        status: 'received',
        process_time: 0,
        officer_data: null,
        created_at: 0,
        created_by: '',
      },
    ],
    urgency: {
      id: '1744666975885324289',
      name: 'ทำทันที',
      sort: 5,
    },
    process_time: 0,
    duedate_at: 1708184636,
    department_data: [
      {
        id: '1730459515049996289',
        name: 'สำนักงานปะปา',
        officer_data: null,
      },
    ],
    rating: null,
    created_at: 1708011836,
    created_by: '1745645709895397376',
    updated_at: 1708020887,
    updated_by: '1745645709895397376',
    is_group: false,
  },
  {
    id: '1758166489321435139',
    complaint_no: '1502256700021',
    name: {
      first_name: '-',
      last_name: '-',
    },
    mobile_number: '0000000000',
    line_id: '',
    channel: 'other',
    organization: null,
    category: {
      id: '1735509869680652288',
      name: 'ปะปา',
      icon_url: WaterIcon,
      bg_icon_url: 'https://gateway.koratcity.go.th/sit/media/images/48261944-abcd-4ea9-941f-181ccc77f17c.png',
      icon_line: 'https://gateway.koratcity.go.th/sit/media/images/afe2f2bb-d583-437e-9ea5-38dfaae70cfb.png',
      bg_icon_line: 'https://gateway.koratcity.go.th/sit/media/images/896a626a-487e-4f28-a2d0-ecd309b673b8.png',
    },
    sub_category_data: {
      id: '1735509869680652289',
      name: 'ไฟฟ้าสาธารณะ',
    },
    type: 'water',
    complaint_name:
      'เอากรวยมากั้นไว้เป็นเดือนแล้ว ควรเร่งซ่อมและเอาออกครับ อยากให้เร่งปูถนนให้เสร็จครับ วันนี้เกิดอุบัติเหตุ',
    description: '',
    media_url: [
      'https://storage.googleapis.com/traffy_public_bucket/attachment/2023-08/59372ae069897c3a463b08debf3fa3bba2933bdb.jpg',
    ],
    address: '100 ถนน ประจักษ์ ตำบลในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
    province_id: '',
    district_id: '',
    sub_district_id: '',
    zib_code: '',
    coordinates: {
      latitude: 14.97813,
      longitude: 102.10557,
    },
    status_data: [
      {
        name: 'received',
        description: 'รับเรื่อง',
        staff_data: null,
        created_at: 1708014530,
        created_by: '1754744174374023168',
      },
    ],
    transaction_data: [
      {
        no: 0,
        type: '',
        department_id: '1730459515045801985',
        department_name: 'สำนักงานปะปา',
        description: '',
        media_url: null,
        coordinates: null,
        address: '',
        status: 'received',
        process_time: 0,
        officer_data: null,
        created_at: 0,
        created_by: '',
      },
    ],
    urgency: {
      id: '1744666975876935680',
      name: 'ด่วน',
      sort: 2,
    },
    process_time: 0,
    duedate_at: 1708187330,
    department_data: [
      {
        id: '1730459515045801985',
        name: 'สำนักงานปะปา',
        officer_data: null,
      },
    ],
    rating: null,
    created_at: 1708014530,
    created_by: '1754744174374023168',
    updated_at: 1708020619,
    updated_by: '1754744174374023168',
    is_group: false,
  },
]
