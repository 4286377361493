import moment from 'moment'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'

export const checkColorStatusBg = val => {
  if (val === 'received') {
    return '#EFE9FE'
  }
  if (val === 'in progress') {
    return '#FDF5DD'
  }
  if (val === 'done') {
    return '#DFF6F5'
  }
  if (val === 'outside the area') {
    return '#E2E5F8'
  }
  if (val === 'tracking') {
    return '#FFE6DB'
  }
  if (val === 'over duedate') {
    return '#FFE4E4'
  }
}

export const checkColorStatusText = val => {
  if (val === 'received') {
    return '#915EFF'
  }
  if (val === 'in progress') {
    return '#FFBB0B'
  }
  if (val === 'done') {
    return '#13AFA6'
  }
  if (val === 'outside the area') {
    return '#3F54D1'
  }
  if (val === 'tracking') {
    return '#F36B24'
  }
  if (val === 'over duedate') {
    return '#F85858'
  }
}

export const checkColorStatusPoint = val => {
  if (val === 'รับเรื่อง') {
    return '#915EFF'
  }
  if (val === 'อยู่ระหว่างดำเนินการ') {
    return '#FFBB0B'
  }
  if (val === 'ดำเนินการแล้วเสร็จ') {
    return '#13AFA6'
  }
  if (val === 'ไม่อยู่ในความรับผิดชอบ') {
    return '#3F54D1'
  }
  if (val === 'ติดตาม') {
    return '#F36B24'
  }
  if (val === 'เกินระยะเวลาที่กำหนด') {
    return '#F85858'
  }
}
export const checkColorStatusLegend = val => {
  if (val === 'โทรศัพท์') {
    return '#915EFF'
  }
  if (val === 'แอปพลิเคชัน') {
    return '#21ADFC'
  }
  if (val === 'ไลน์') {
    return '#915EFF'
  }
  if (val === 'เว็บไซต์') {
    return '#13AFA6'
  }
  if (val === 'เข้ามาโดยตรง') {
    return '#9EBBD1'
  }
  if (val === 'facebook') {
    return '#3F54D1'
  }
  if (val === 'E-Service') {
    return '#F36B24'
  }
  if (val === 'หนังสือ') {
    return '#FFA643'
  }
  if (val === '1132') {
    return '#21ADFC'
  }
  if (val === 'ศูนย์ดำรงธรรม') {
    return '#556A82'
  }
  if (val === 'traffy fondue') {
    return '#F85858'
  }

  if (val === 'walk in') {
    return '#9EBBD1'
  }
  if (val === 'อื่นๆ') {
    return '#f208c3'
  }
}

export const checkRating = val => {
  if (val === 1) {
    return 'ควรปรับปรุง'
  }
  if (val === 2) {
    return 'พอใช้'
  }
  if (val === 3) {
    return 'ดี'
  }
  if (val === 4) {
    return 'ดีมาก'
  }
  if (val === 5) {
    return 'ยอดเยี่ยม'
  }
}

export const checkDueDate = val => {
  let currant = moment().unix() * 1000
  let due = val * 1000
  const date1 = moment(due)
  const date2 = moment(currant)
  const diffInDays = date1.diff(date2, 'days') + 1
  const formattedDate1 = date1.format('DD/MM/YYYY')
  if (diffInDays > 0 && diffInDays <= 3) {
    return 'เหลืออีก ' + diffInDays + ' วัน'
  } else {
    const dateMoment = moment(formattedDate1, 'DD/MM/YYYY')
    const unixTimestamp = dateMoment.valueOf() / 1000 // Convert milliseconds to seconds
    return conversDate(unixTimestamp)
  }
}
