import styled from 'styled-components'

export const BlankContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 700px;
  background: none;

  p {
    text-align: center;
    color: #597081;
    font-weight: 400;
    font-size: 16px;
  }
`

export const BoxTable = styled.div`
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f36b24;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f36b24;
  }
  table {
    border-spacing: 0px 5px;
  }
  .custom-table td {
    padding-top: 5px; /* Add padding to the top of cell content */
    padding-bottom: 5px; /* Add padding to the bottom of cell content */
  }
  .ant-table-thead > tr > th {
    color: #717171;
    font-weight: 400;
    border-bottom: none;
    font-size: 12px;
  }
  .custom-table td {
    border: 1px solid #ffeee7;
    border-right: none;
    border-left: none;
    height: 45px;
  }
  .custom-table td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid #ffeee7;
  }
  .custom-table td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid #ffeee7;
  }
  .custom-table tr:nth-child(2n + 2) {
    width: 100% !important;
    border: 1px solid #ffeee7;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: #ffeee7;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ant-table-footer {
    display: none !important;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container:before {
    box-shadow: none !important;
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container:after {
    box-shadow: none !important;
  }
  .ant-table-footer {
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);
    background: #ffffff !important;
  }
  .ant-table-tbody > tr > td {
    padding: 5px !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first:after,
  .ant-table-ping-right .ant-table-cell-fix-right-last:after,
  .ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container:after {
    box-shadow: none !important;
  }
  .ant-table-selection {
    display: none !important;
  }
  /* .ant-table-tbody > tr:nth-child(2n + 2) {
    background-color: lightgray;
  } */
`
