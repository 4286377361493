import { Button, Modal } from 'antd'
import styled from 'styled-components'
import BgLoginMobile from 'assets/images/png/bg-login-mobile.png'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* min-height: 720px; */

  background-image: ${props => (props.url ? `url(${props.url})` : '#FCFCFC')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
  }

  @media only screen and (max-width: 375px) {
    background-image: url(${BgLoginMobile});
    background-size: cover;
  }

  @media only screen and (max-width: 768px) {
    background-image: url(${BgLoginMobile});
    background-size: cover;
  }
`

export const BoxContent = styled.div`
  width: 700px;
  height: 582px;
  border-radius: 24px;
  margin-top: 4em;
  padding: 40px;
  border: 1px solid;
  border-image-source: linear-gradient(232.66deg, #ffffff -7.67%, rgba(255, 255, 255, 0) 107%);
  background: linear-gradient(232.66deg, #ffffff -7.67%, rgba(255, 255, 255, 0) 107%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));

  @media only screen and (max-width: 768px) {
    width: 390px;
    height: 460px;
    padding: 40px 24px;

    .number-page {
      width: 32px;
      height: 32px;
    }

    .icon-mail {
      width: 40px;
      height: 40px;
    }
  }

  @media only screen and (max-width: 375px) {
    width: 390px;
    height: 460px;
    padding: 40px 24px;

    .number-page {
      width: 32px;
      height: 32px;
    }

    .icon-mail {
      width: 40px;
      height: 40px;
    }
  }
`

export const BoxInput = styled.div`
  width: 353px;
  height: 179px;
  /* background-color: aqua; */

  h3 {
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 24px;
    color: #150f0b;
    margin-top: 0.5rem;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;

    h3 {
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 22px;
    }
  }

  @media only screen and (max-width: 375px) {
    width: 100%;
    height: auto;

    h3 {
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 22px;
    }
  }
`

export const BtSubmit = styled(Button)`
  background: #fc6521;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: white;
  /* line-height: 20px; */
  /* padding: 18px 8px; */
  width: 100%;
  height: 56px;
  border: none;
  cursor: pointer;

  &.ant-btn-primary:focus,
  &.ant-btn-primary:hover {
    border-color: #fc6521 !important;
    background-color: #fc6521 !important;
    color: white;
  }

  /* ${props =>
    props.type === 'primary' &&
    `
      background: #FC6521;

       &:hover,
        :focus {
         background: #FC6521 !important;
         color: white;
        }
    `} */

  /* .ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    background-color: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
    color: white !important;
    cursor: not-allowed;
  } */

  &:disabled {
    background-color: #fee0d3;
    border-color: #fee0d3;
    color: white;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background-color: #fee0d3 !important;
    border-color: #fee0d3 !important;
    color: white !important;
    cursor: not-allowed;
  }

  @media only screen and (max-width: 768px) {
    height: 48px;
  }

  @media only screen and (max-width: 375px) {
    height: 48px;
  }
`

export const ModalConfirmEmail = styled(Modal)`
  font-family: 'Prompt';
  margin-top: auto;
  margin-bottom: auto;
  /* background-color: #333; */
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-wrap {
    top: 160px !important;
  }

  .ant-modal-content {
    background-color: #ffffff;
    width: 472px;
    height: 335px;
    border-radius: 40px;
    padding: 40px;
  }

  .ant-modal-body {
    padding: 0px;
    text-align: center;
    /* display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; */

    svg {
      width: 100px;
      height: 100px;
    }

    .title {
      color: #150f0b;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 20px;
    }

    .description {
      color: #b7b7b7;
      font-family: 'Prompt';
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 0rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .ant-modal-content {
      background-color: #ffffff;
      width: 304px;
      height: 218px;
      border-radius: 16px;
      padding: 16px 13px;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        width: 64px !important;
        height: 64px !important;
      }

      .title {
        font-size: 18px !important;
        margin-bottom: 0.1rem;
      }

      .description {
        font-size: 12px !important;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .ant-modal-content {
      background-color: #ffffff;
      width: 304px;
      height: 218px;
      border-radius: 16px;
      padding: 16px 13px;
    }

    .ant-modal-body {
      svg {
        width: 64px !important;
        height: 64px !important;
      }

      .title {
        font-size: 18px !important;
        margin-bottom: 0.1rem;
      }

      .description {
        font-size: 12px !important;
      }
    }
  }
`

export const BtSubmitModal = styled(Button)`
  background: #f36b24;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 20px;
  width: 100%;
  height: 40px;
  border: none;
  cursor: pointer;
  margin-top: 25px;

  ${props =>
    props.type === 'primary' &&
    `
      background: #F36B24;

       &:hover,
        :focus {
         background: #F36B24 !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #e2e5f8;
    border-color: #e2e5f8;
    color: white;
    cursor: not-allowed;
  }

  &:hover,
  :focus {
    background: #f36b24;
    color: white;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    width: 104px;
    height: 32px;
    margin-top: 23px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 12px;
    width: 104px;
    height: 32px;
    margin-top: 23px;
  }
`
