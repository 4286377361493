import React, { useState, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, notification, Tooltip, Dropdown, Menu, Row, Col } from 'antd'
import {} from 'antd'

import {
  BoxTitle,
  BoxSearch,
  BoxIcon,
  NotiStyle,
  NotiStyle2,
  CustomModal,
  CustomModal2,
  CustomModal3,
  BoxNoData,
  StyleDropdown,
  Menucustom,
  Tablecustom,
  BoxTableCustom,
} from './list.style'
import DropdownSearch from 'components/Inputs/DropdownSearch'

import useAllPermissions from 'core/hooks/useAllPermissions'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { ReactComponent as IconNodataTable } from 'assets/images/svg/nosearch-table.svg'

import Table from 'components/Table/Row'
import StatusCustom from 'components/output/Status'
import Pagination from 'components/pagination'
import AutoComplete from 'components/Inputs/AutoComplete'

import statusActive from 'assets/images/svg/status-active.svg'
import statusInactive from 'assets/images/svg/status-inactive.svg'
import iconbin from 'assets/images/svg/icon-bin-org.svg'
import iconsee from 'assets/images/svg/icon-see-org.svg'
import iconedit from 'assets/images/svg/icon-edit-org.svg'
import Alert from 'assets/images/svg/offline/alert.js'
import iconAdd from 'assets/images/svg/icon-add-org.svg'
import vDivider from '../../../../assets/images/svg/vertical-divider.svg'
import IconNoti from 'assets/images/svg/icon-noti.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import UserContext from 'core/contexts/userContext'
import moment from 'moment'
import 'moment/locale/th'

import { getList, getData, deleteData } from 'core/action/collection'
import { tableText2 } from '../../../../core/constants/text'
import { adminauth2, authentication2 } from 'core/schemas'
import { convertLegacyProps } from 'antd/es/button/button'

const DropdownCustom = Dropdown

const url = process.env.REACT_APP_MANAGRE_GROUP
let orderDirection = 'desc'
const Index = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const allow = useAllPermissions(props.permissions)
  const { modal } = useContext(UserContext)
  const [api, contextHolder] = notification.useNotification()
  const [dataExt, setDataExt] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1)
  // const [isSearch, setIsSearch] = React.useState(false)
  const [total, setTotal] = React.useState(0)
  const [exactTotalPage, setExactTotalPage] = React.useState(0)
  const [exactCurrentPage, setExactCurrentPage] = React.useState(1)
  const [state, setState] = React.useState(location.state || {})
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = React.useState(false)
  const [alertModalOpen, setAlertModalOpen] = React.useState(false)
  const [alertModalReLoadOpen, setAlertModalReLoadOpen] = React.useState(false)
  const [deletionType, setDeletionType] = React.useState(null)
  const [recordId, setRecordId] = React.useState(null)
  const [abortController, setAbortController] = React.useState(null)
  const [tempRow, setTempRow] = React.useState(null)
  const [notiStyle, setNotiStyle] = React.useState(false)
  const [orderSerie, setOrderSerie] = React.useState(1)
  const [isAscend, setIsAscend] = React.useState(false)
  const [currentSearch, setCurrentSearch] = React.useState(null)
  const [isOpenTooltipUpdatedAt, setIsOpenTooltipUpdatedAt] = React.useState(false)
  const [options, setOptions] = useState([])
  const [groupFilter, setGroupFilter] = React.useState(null)
  const [roleFilter, setRoleFilter] = React.useState(null)
  const isInitFilter = React.useRef(true)
  const [role, setRole] = React.useState(state && state.role ? state.role : 'SuperAdministrator')
  const [groupData, setGroupData] = useState([])
  const [roleData, setRoleData] = useState([])
  const isSizeMobile = 768
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const filterList = () => {
    const filtersArr = []
    const rolds = roleFilter?.value || roleFilter
    const status = groupFilter?.value || groupFilter
    const statusParam = status && status !== 'all' ? `group_id:eq:${status}` : []
    const roldsParam = rolds && rolds !== 'all' ? `role_id:eq:${rolds}` : []

    if (roldsParam !== null && roldsParam !== '' && roldsParam !== true) {
      filtersArr.push(roldsParam)
    }

    if (statusParam !== null && statusParam !== '' && statusParam !== true) {
      filtersArr.push(statusParam)
    }

    // if (roldsParam !== null && roldsParam !== '' && roldsParam !== true) {
    //   filtersArr.push(roldsParam)
    // }

    return filtersArr
  }
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  React.useEffect(() => {
    orderDirection = 'desc'
    getDataList('', currentPage)
    getListGroup()
    getListRole()
    getAllData()
    if (localStorage.getItem('modal')) {
      if (localStorage.getItem('modal') == 'create') {
        openNotification('top')
      }
      if (localStorage.getItem('modal') == 'update') {
        openNotification('top')
      }
      if (localStorage.getItem('modal') == 'delete') {
        onOkDelete()
      }
      localStorage.removeItem('modal')
    }
  }, [])

  React.useEffect(() => {
    if (isInitFilter.current) {
      isInitFilter.current = false
    } else {
      getDataList(currentSearch, 1)
      setCurrentPage(1)
    }
  }, [groupFilter, roleFilter])

  React.useEffect(() => {
    if (state?.isDelete) {
      setDeleteConfirmModalOpen(true)
      setAbortController(new AbortController())
      setDeletionType(state?.record?.status)
      setRecordId(state?.record?.id)
      setTempRow(state?.record)
    }
  }, [state?.isDelete])

  React.useEffect(() => {
    if (state?.isError) {
      setAlertModalOpen(true)
    }
  }, [state?.isError])

  const onRow = (record, rowIndex) => {
    return {
      onClick: event => {
        navigate('/announcement/bof-announcement-type/view', { state: record })
      },
    }
  }
  const changeOrder = direction => {
    orderDirection = direction
  }
  const loopOrder = () => {
    let serie = orderSerie
    serie += 1
    if (serie > 1) {
      serie = 0
    }
    setOrderSerie(serie)
  }

  const onOkDelete = () => {
    setDeleteConfirmModalOpen(false)
    deleteManipulate()
    handleRemoveData()
  }

  const changeOrderSerie = serie => {
    switch (serie) {
      case 0:
        return 'desc'
      case 1:
        return 'asc'
      default:
        return ''
    }
  }

  const toBuddhistYear = (unixTimestamp, format) => {
    const formattedDateTimeValue = moment.unix(unixTimestamp).add(543, 'years').format('DD MMM YYYY, HH:mm')
    return formattedDateTimeValue
  }

  const openNotification = placement => {
    api.info({
      message: <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>บันทึกสำเร็จแล้ว</div>,
      placement,
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 5,
    })
  }

  const openDeleteNotification = placement => {
    api.open({
      key: 'delete',
      message: (
        <>
          <span
            style={{
              fontStyle: 'normal',
              fontFamily: 'Prompt',
              paddingRight: '80px',
              fontSize: '16px',
              fontWeight: '400',
              color: '#13AFA6',
            }}
          >
            ลบข้อมูลสำเร็จ
          </span>
          <a
            style={{
              color: '#13AFA6',
              cursor: 'pointer',
              fontWeight: '500',
              fontSize: '14px',
              fontFamily: 'Prompt',
              fontStyle: 'normal',
            }}
            onClick={() => {
              // abortController.abort()
              notification.close('delete')
              rollbackData()
            }}
          >
            เลิกทำ
          </a>
          <img src={vDivider} alt="" />
        </>
      ),
      placement,
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" onClick={() => notification.close('delete')} />,
      className: 'with-undo',
      duration: 50,
    })
  }
  const renderIcon = type => {
    switch (type) {
      case 'asc':
        return (
          <>
            <span className="chevron-highlight" alt="ascend">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.00195 9.99902L8.00195 5.99902L13.002 9.99902"
                  stroke="#C8D3DA"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </span>
          </>
        )
      default:
        return (
          <>
            <span className="chevron-highlight" alt="descend">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 6L8 10L3 6" stroke="#C8D3DA" stroke-width="2" stroke-linecap="round" />
              </svg>
            </span>
          </>
        )
    }
  }
  const onHandleDelete = async record => {
    // e.stopPropagation()
    if (recordId) {
      openDeleteNotification('top')
      notification.close('delete')
      onDeleteData()
    }
    setDeleteConfirmModalOpen(true)
    setAbortController(new AbortController())
    setDeletionType(record?.status)
    setRecordId(record?.id)
    setTempRow(record)
  }
  const handleSort = e => {
    e.preventDefault()
    changeOrder(changeOrderSerie(orderSerie))
    loopOrder()
    setIsAscend(!isAscend)
    getDataList(currentSearch, currentPage)
  }
  const onPageChange = page => {
    setCurrentPage(page)
    setState({ showNoti: false })
    getDataList(currentSearch, page)
  }

  const getDataList = async (data, cPage) => {
    const firstName = ''
    const lastName = ''

    const group = groupFilter?.value
    const role = roleFilter?.value

    const groupForFilter = group && `group_id:eq:${group}`
    const roleForFilter = role && `role_id:eq:${role}`

    const combinedFilter =
      groupForFilter && roleForFilter ? `${groupForFilter}&${roleForFilter}` : groupForFilter || roleForFilter || null

    // const searchList = () => {
    //   if (data) {
    //     if (data.includes(' ')) {
    //       const [firstname, lastname] = data.split(' ')
    //       firstName.push(firstname)
    //       lastName.push(lastName)

    //       return `first_name.th:eq:${firstname}&last_name.th:eq:${lastname}`
    //     }
    //   } else if (data && groupForFilter && roleForFilter) {
    //     return `first_name.th:eq:${firstName}&last_name.th:eq:${firstName}&${groupForFilter}&${roleForFilter}`
    //   } else {
    //     return []
    //   }
    // }
    const searchList = () => {
      if (data) {
        if (data.includes(' ')) {
          const [firstname, lastname] = data.split(' ')

          return `first_name.th:eq:${firstname}&last_name.th:eq:${lastname}${
            groupForFilter && groupForFilter !== undefined ? `&${groupForFilter}` : ''
          }${roleForFilter && roleForFilter !== undefined ? `&${roleForFilter}` : ''}`
        } else {
          return `email:eq:${data}${groupForFilter && groupForFilter !== undefined ? `&${groupForFilter}` : ''}${
            roleForFilter && roleForFilter !== undefined ? `&${roleForFilter}` : ''
          }`
        }
      } else if (groupForFilter || roleForFilter) {
        let filterString = ''

        if (groupForFilter && groupForFilter !== undefined) {
          filterString += `${groupForFilter}`
        }

        if (roleForFilter && roleForFilter !== undefined) {
          filterString += `${filterString.length > 0 ? '&' : ''}${roleForFilter}`
        }

        return filterString
      }
    }

    const res = await getList({
      schema: `${adminauth2}/admin`,
      params: {
        page: cPage,
        per_page: 10,
        sorts: [`updated_at:${orderDirection}`],
        // search: data ? [`first_name.th:${data}`, `last_name.th:${data}`, `email:${data}`] : [],
        search: [],
        // filters: combinedFilter ? [combinedFilter] : [],
        filters: searchList(),
      },
      url: '',
      baseURL: '',
    })
    if (res) {
      if (res?.code === 200) {
        setAlertModalOpen(false)
        setDataExt(res?.items)
        setTotal(res?.dataLength)
        setExactTotalPage(res?.dataLength)
      } else if (res?.code === 204) {
        setDataExt([])
        setTotal(0)
      } else {
        setDataExt([])
        setTotal(0)
        if (res?.code !== 401) setAlertModalOpen(true)
      }
    } else {
      setAlertModalOpen(true)
    }
  }

  const onSelectFunc = async data => {
    setCurrentSearch(data)
    getDataList(data, 1)
    setCurrentPage(1)
    setExactCurrentPage(currentPage)
  }
  const isSearchFunc = data => {
    // setIsSearch(data)
  }
  const totalFunc = data => {
    setTotal(data)
  }
  const currentPageFunc = data => {
    setCurrentPage(data)
  }
  const onClearFunc = data => {
    // setTimeout(() => {
    //   setIsSearch(false)
    // }, 1)
  }
  const onDeleteData = async () => {
    const res = await deleteData(`${adminauth2}/admin`, state.record?.id)
    if (res) {
      if (res?.code === 200) {
        if (dataExt.length === 1) {
          getDataList('', currentPage - 1)
          setCurrentPage(currentPage - 1)
        } else {
          getDataList('', currentPage)
        }
        // getAllData()
      }
    } else {
      setAlertModalOpen(true)
    }
  }

  const deleteManipulate = () => {
    openDeleteNotification('top')
    const timeoutId = setTimeout(() => {
      onDeleteData()
      notification.close('delete')
    }, 5000)
    localStorage.setItem('timeOut', timeoutId)
  }

  const handleRemoveData = () => {
    let dataExtTemp = dataExt.filter(item => item.id !== recordId)
    setDataExt(dataExtTemp)
    // fix delete last page & this last item
    if (dataExtTemp.length === 0) {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1)
        setState({ showNoti: false })
        getDataList(currentSearch, currentPage - 1)
      }
    }
  }
  const rollbackData = async () => {
    let timeout = localStorage.getItem('timeOut')
    clearTimeout(timeout)
    localStorage.removeItem('timeOut')
    const res = await getList({
      schema: `${adminauth2}/admin`,
      params: {
        page: 1,
        per_page: 10,
        sorts: [`updated_at:${orderDirection}`],
        search: currentSearch ? [`name:${currentSearch}`] : [],
      },
      url: '',
      baseURL: '',
    })
    if (res) {
      if (res?.code === 200) {
        setAlertModalOpen(false)
        setDataExt(res?.items)
        setTotal(res?.dataLength)
        setExactTotalPage(res?.dataLength)
      } else if (res?.code === 204) {
        setDataExt([])
        setTotal(0)
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
    } else {
      setAlertModalOpen(true)
    }
    // if (currentPage !== 1) {
    //   await getList({
    //     schema: `${adminauth2}/group`,
    //     params:
    //      {
    //       page: 1,
    //       per_page: 10,
    //       sorts: [`updated_at:${orderDirection}`],
    //       search: currentSearch ? [`name:${currentSearch}`] : [],
    //     },
    //     url: '',
    //     baseURL: url,
    //   }).then(res => {
    //     for (let i = res.items.length - 2; i >= 0; i--) {
    //       res.items[i + 1] = res.items[i]
    //     }
    //     res.items.shift()
    //     setDataExt([tempRow, ...res.items])
    //     setCurrentPage(1)
    //   })
    // } else {
    //   const data = dataExt.filter(item => item?.id !== recordId)
    //   setDataExt([tempRow, ...data])
    // }
    // setState({ showNoti: true })
  }
  // const fnShowTextNotFound = () => {
  //   let returnText = tableText.noData
  //   if (currentSearch) {
  //     returnText = tableText.noDataSearch
  //   }
  //   return returnText
  // }
  const fnShowTextNotFound = () => {
    const filter1 = filterList()[0]
    let returnText = tableText2.noData
    if (currentSearch) {
      returnText = tableText2.noDataSearch
    }
    if (filter1 && filter1.length > 0) {
      returnText = tableText2.noDataSearch
    }
    return returnText
  }

  const getAllData = async () => {
    const res = await getData({
      schema: `${adminauth2}/admin`,
      params: {},
      url: '',
      baseURL: '',
    })
    if (res) {
      if (res?.code === 200) {
        setAlertModalOpen(false)

        let listCategory
        listCategory = res?.data.reduce((previous, current) => {
          if (!previous.includes(current.first_name.th && current.last_name.th)) {
            previous.push(`${current.first_name.th} ${current.last_name.th}`)
          }

          if (!previous.includes(current.email)) {
            previous.push(current.email)
          }

          // if (!previous.includes(current.last_name.th)) {
          //   previous.push(current.last_name.th)
          // }

          return previous
        }, [])

        let categoryData
        categoryData = listCategory.map((item, index) => {
          return {
            value: item,
            key: index,
          }
        })

        setOptions(categoryData)
      } else if (res?.code === 204) {
        setOptions([])
      } else {
        if (res?.code !== 401) setAlertModalOpen(true)
      }
    } else {
      setAlertModalOpen(true)
    }
  }

  const handleDelete = async id => {
    // const res = await deleteData(admin, id)
    // if (res?.code === 200) {
    //   // await ConfirmSuccess(() => getDataAdmin())
    // } else {
    //   showDeleteErrorModal()
    // }
  }

  const handleMenuList = e => {
    const key = e?.key.split('/')
    if (key[3] === 'view') {
      navigate(e.key, {
        state: {
          record: record,
        },
      })
    } else if (key[3] === 'update') {
      navigate(e.key)
    } else if (key[3] === 'delete') {
      navigate('/operation-tools/bof-users/view', {
        state: {
          record: record,
          isDelete: true,
        },
      })
    }
  }

  const optionStatus = [
    {
      value: 'all',
      label: 'ทั้งหมด',
    },
    {
      value: 'active',
      label: 'เจ้าหน้าที่ทั่วไป',
    },
    {
      value: 'inactive',
      label: 'Admin',
    },
    {
      value: 'inactive',
      label: 'Staff',
    },
  ]

  const getListGroup = async () => {
    const res = await getData({
      schema: `${adminauth2}/group`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistGroup = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setGroupData(maplistGroup)
      } else {
        console.log('Error', res)
      }
    }
  }

  const getListRole = async () => {
    const res = await getData({
      schema: `${authentication2}/roles?updated_at:${orderDirection}`,
      params: `updated_at:${orderDirection}`,
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        const maplistRole = res.data.map(item => ({
          value: item.id,
          label: item.name,
        }))

        setRoleData(maplistRole)
      } else {
        console.log('Error', res)
      }
    }
  }

  const [record, setrecord] = React.useState()

  const menu = value => {
    setrecord(value)
    return (
      <Menucustom onClick={handleMenuList}>
        <Menucustom.Item key={`/operation-tools/bof-users/view`}>
          {<img src={iconsee} alt="see" style={{ marginRight: '5px' }} />}
          ดูข้อมูล
        </Menucustom.Item>
        <Menucustom.Item key={`/operation-tools/bof-users/update/${value?.id}`}>
          {<img src={iconedit} alt="edit" style={{ marginRight: '5px' }} />}
          แก้ไขข้อมูล
        </Menucustom.Item>
        <Menucustom.Item key={`/operation-tools/bof-users/delete/${value?.id}`}>
          {<img src={iconbin} alt="delete" style={{ marginRight: '5px' }} />}
          ลบข้อมูล
        </Menucustom.Item>
      </Menucustom>
    )
  }
  const getPopupContainer = triggerNode => {
    return triggerNode.parentNode
  }

  const columns = [
    {
      title: <div style={{ color: '#717171' }}>ลำดับ</div>,
      render: (_, __, index) => `${(currentPage - 1) * 10 + index + 1}.`,
      width: 128,
      className: 'gap',
    },
    {
      title: <div style={{ color: '#717171' }}>ชื่อผู้ใช้</div>,
      // dataIndex: 'name',
      ellipsis: true,
      className: 'gap',
      render: data => <>{data ? (data ? `${data.first_name.th + ' ' + data.last_name.th}` : '-') : '-'}</>,
    },
    {
      title: <div style={{ color: '#717171' }}>อีเมลล์</div>,
      dataIndex: 'email',
      ellipsis: true,
      className: 'gap',
      render: data => <>{data ? (data ? data : '-') : '-'}</>,
    },
    {
      title: <div style={{ color: '#717171' }}>ชื่อกลุ่ม</div>,
      dataIndex: 'group_data',
      ellipsis: true,
      className: 'gap',
      render: data => <>{data ? (data ? data.name : '-') : '-'}</>,
    },
    {
      title: <div style={{ color: '#717171' }}>ชื่อกลุ่มสิทธิ์การใช้งาน</div>,
      dataIndex: 'role_data',
      ellipsis: true,
      // className: 'gap',
      render: data => <>{data ? (data.length > 0 ? data[0].name : '-') : '-'}</>,
    },
    // {
    //   title: <div style={{ color: '#717171' }}>รายละเอียด</div>,
    //   dataIndex: 'description',
    //   render: data => <>{data ? data ? data : '-' : <span>-</span>}</>,
    //   ellipsis: true,
    //   className: 'gap-40',
    // },
    {
      title: <div style={{ color: '#717171' }}>สถานะ</div>,
      dataIndex: 'is_online',
      render: data =>
        data === true ? (
          <StatusCustom color={'#00ada4'} backgroundColor="#00ada4" text="ออนไลน์" />
        ) : (
          // <>
          //   <img src={statusActive} alt="status-active" />
          // </>
          <StatusCustom color={'#C4C4C4'} backgroundColor="#C4C4C4" text="ออฟไลน์" />

          // <img src={statusInactive} alt="status-inactive" />
        ),
    },
    {
      title: <div style={{ color: '#717171' }}>วันที่อัปเดตล่าสุด</div>,
      dataIndex: 'updated_at',
      className: 'font-w-400',
      ellipsis: true,
      render: data => toBuddhistYear(moment(data), 'lll').replace('เวลา', ',') + ' น.',
    },

    {
      title: '',
      key: 'actions',
      width: 120,
      render: data => {
        return (
          <DropdownCustom overlay={() => menu(data)} trigger={['click']} getPopupContainer={getPopupContainer}>
            <MoreOutlined style={{ cursor: 'pointer', fontSize: '26px' }} />
          </DropdownCustom>
        )
      },
    },
  ]
  return (
    <div style={{ padding: windowWidth <= isSizeMobile ? '10px 10px' : '10px 40px' }}>
      {notiStyle ? <NotiStyle2 /> : <NotiStyle />}
      {contextHolder}
      <BoxTitle>
        <div style={{ fontSize: windowWidth <= isSizeMobile ? '18px' : '24px', fontWeight: 500, color: '#19263F' }}>
          จัดการผู้ใช้
        </div>
        {/* {allow.create && ( */}
        <Button
          icon={<img src={iconAdd} alt="add" style={{ display: 'inline', paddingBottom: '5px' }} />}
          type="primary"
          onClick={() => {
            navigate('/operation-tools/bof-users/create')
            setNotiStyle(false)
          }}
        >
          <span
            style={{
              paddingLeft: windowWidth <= isSizeMobile ? '8px !important' : '4px',
              paddingTop: '1px',
              fontSize: windowWidth <= isSizeMobile ? '15px' : '16px',
            }}
          >
            เพิ่มผู้ใช้
          </span>
        </Button>
        {/* )} */}
      </BoxTitle>

      <BoxSearch style={{ display: 'flex' }}>
        <AutoComplete
          placeholder="ค้นหาชื่อผู้ใช้, อีเมลล์"
          options={options}
          onSelectFunc={onSelectFunc}
          isSearchFunc={isSearchFunc}
          totalFunc={totalFunc}
          total={exactTotalPage}
          currentPageFunc={currentPageFunc}
          currentPage={exactCurrentPage}
          onClearFunc={onClearFunc}
          currentSearch={currentSearch}
        />
        <div style={{ marginLeft: '16px' }}>
          <StyleDropdown>
            <DropdownSearch
              options={groupData}
              name="ชื่อกลุ่ม"
              setStatusFilter={setGroupFilter}
              placeholder={<div style={{ color: '#FFC4AA' }}>กลุ่มทั้งหมด</div>}
              showSearch={false}
              allowClear={true}
              // disabled={
              //   !dataExt?.length &&
              //   (fnShowTextNotFound() === tableText.noData || fnShowTextNotFound() === tableText.noDataSearch)
              // }
            />
          </StyleDropdown>
        </div>
        <div
          style={{
            marginLeft: windowWidth <= isSizeMobile ? '0px' : '16px',
            marginTop: windowWidth <= isSizeMobile ? '5px' : '0px',
          }}
        >
          <StyleDropdown>
            <DropdownSearch
              options={roleData}
              name="ชื่อสิทธิ์"
              setStatusFilter={setRoleFilter}
              placeholder={<div style={{ color: '#FFC4AA' }}>สิทธิ์ทั้งหมด</div>}
              showSearch={false}
              allowClear={true}
              // disabled={
              //   !dataExt?.length &&
              //   (fnShowTextNotFound() === tableText.noData || fnShowTextNotFound() === tableText.noDataSearch)
              // }
            />
          </StyleDropdown>
        </div>
      </BoxSearch>

      {/* {renderDeleteModal(deletionType)} */}

      <CustomModal3
        transitionName=""
        centered
        open={alertModalReLoadOpen}
        closable={false}
        okText="ตกลง"
        footer={
          <Button
            type="primary"
            onClick={() => {
              setAlertModalReLoadOpen(false)
              getDataList('', 1)
              // getAllData()
            }}
          >
            ตกลง
          </Button>
        }
      >
        <div style={{ paddingBottom: '10px' }}>
          <Alert />
        </div>
        <p style={{ color: '#001F34', fontSize: '16px', fontWeight: 'bold', marginBottom: '2px' }}>เกิดข้อผิดพลาด</p>
        <p style={{ color: '#597081', fontSize: '14px', fontWeight: '500' }}>กรุณาลองใหม่อีกครั้ง</p>
      </CustomModal3>

      {!dataExt?.length && fnShowTextNotFound() === tableText2.noData ? (
        <BoxNoData>
          <IconNodataTable />
          <p>{fnShowTextNotFound()}</p>
        </BoxNoData>
      ) : (
        <BoxTableCustom>
          <Table
            columns={columns}
            dataSource={dataExt}
            isAfterCreate={state.showNoti}
            noDataText={fnShowTextNotFound()}
          />
        </BoxTableCustom>
      )}
      <div style={{ padding: windowWidth <= isSizeMobile ? '40px 0' : '20px 0' }}>
        <Pagination defaultCurrent={1} total={total} onChange={e => onPageChange(e)} current={currentPage} />
      </div>
    </div>
  )
}

export default Index
