import React from 'react'
import { Form, DatePicker, Select, Row, Col } from 'antd'
import styled from 'styled-components'
import th_TH from 'antd/lib/locale/th_TH'
import { ConfigProvider } from 'antd'

const FormContainer = styled.div`
  width: 100%;
`

const { Option } = Select

const StyledAntSelect = styled(Select)`
  && .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #ffeee7;
    height: 40px;
    display: flex;
    align-items: center;
  }

  &&:hover .ant-select-selector {
    border-color: #f36b24;
    box-shadow: none;
  }

  &&.ant-select-focused .ant-select-selector,
  && .ant-select-selector:focus {
    border-color: #f36b24;
    box-shadow: none;
  }

  && .ant-select-arrow {
    color: #f36b24;
  }

  && .ant-select-selection-item {
    color: #f36b24;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  && .ant-select-selection-placeholder {
    color: #ffc4aa;
    font-size: 14px;
  }
`

const ClearButton = styled.p`
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  text-decoration-line: underline;
  padding-top: 30px;
  color: #a7a5a3;
  cursor: pointer;
`

const { RangePicker } = DatePicker

const CompactFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding-bottom: 0;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }

  .ant-form-item-control {
    margin-top: 0;
  }
`

function MyForm() {
  const [form] = Form.useForm()

  const onClear = () => {
    form.resetFields()
  }

  return (
    <FormContainer>
      <Form form={form} layout="vertical">
        <Row gutter={[8, 8]}>
          <Col span={5}>
            <ConfigProvider locale={th_TH}>
              <CompactFormItem label="เลือกวันที่" name="dateRange">
                <RangePicker placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']} />
              </CompactFormItem>
            </ConfigProvider>
          </Col>

          <Col span={4}>
            <CompactFormItem label="เลือกหน่วยงาน" name="unit">
              <StyledAntSelect placeholder="เลือกหน่วยงาน">
                <Option value="unit1">สำนักปลัดเทศบาล</Option>
                <Option value="unit2">สำนักคลัง</Option>
                <Option value="unit3">สำนักช่าง</Option>
                <Option value="unit4">สำนักการประปา</Option>
                <Option value="unit5">สำนักการศึกษา</Option>
                <Option value="unit6">กองสวัสดิการสังคม</Option>
              </StyledAntSelect>
            </CompactFormItem>
          </Col>

          <Col span={4}>
            <CompactFormItem label="เลือกสถานะร้องเรียน" name="status">
              <StyledAntSelect placeholder="เลือกสถานะ">
                <Option value="status1">รับเรื่อง</Option>
                <Option value="status2">อยู่ระหว่างดำเนินการ</Option>
                <Option value="status3">ดำเนินการแล้วเสร็จ</Option>
                <Option value="status4">ติดตาม</Option>
                <Option value="status5">ไม่อยู่ในความรับผิดชอบ</Option>
                <Option value="status6">เกินระยะเวลาที่กำหนด</Option>
              </StyledAntSelect>
            </CompactFormItem>
          </Col>

          <Col span={4}>
            <CompactFormItem label="เลือกช่องทางการรับเรื่อง" name="channel">
              <StyledAntSelect placeholder="เลือกช่องทาง">
                <Option value="channel1">ช่องทาง 1</Option>
                <Option value="channel2">ช่องทาง 2</Option>
              </StyledAntSelect>
            </CompactFormItem>
          </Col>

          <Col span={4}>
            <CompactFormItem label="เลือกประเภทข้อมูล" name="dataType">
              <StyledAntSelect placeholder="เลือกประเภท">
                <Option value="type1">ประเภท 1</Option>
                <Option value="type2">ประเภท 2</Option>
              </StyledAntSelect>
            </CompactFormItem>
          </Col>

          <Col span={3}>
            <ClearButton onClick={onClear} block>
              ล้างข้อมูล
            </ClearButton>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  )
}

export default MyForm
