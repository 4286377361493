import qs from 'qs'

import { login, me } from 'core/schemas'

import { httpClient } from './httpClient'
import dayjs from 'dayjs'

const BASE_URL = process.env.REACT_APP_BASEURL

export const getTokenStorage = baseURL => {
  // fix token for test internal mm
  let token = localStorage.getItem('access_token')
  return token

  // if (token) {
  //   if (typeof JSON.parse(token) === 'object') {
  //     return JSON.parse(token)['access_token']
  //   } else {
  //     return token
  //   }
  // } else {
  //   return ''
  // }
}

const setHttpClient = (baseURL, token, ip) => {
  if (baseURL) {
    httpClient.defaults.baseURL = baseURL
  } else {
    httpClient.defaults.baseURL = BASE_URL
  }
  if (token) {
    httpClient.defaults.headers = {
      'Accept-Language': '*',
      Authorization: `Bearer ${token}`,
      'X-FORWARDED-FOR': ip ? ip : '',
    }
  } else {
    httpClient.defaults.headers = {
      'Accept-Language': '*',
      'X-FORWARDED-FOR': ip ? ip : '',
    }
  }
  return httpClient
}

export const loginAuthen = async ({ body, token, path, ip }) => {
  try {
    const schema = path ? path : login
    if (token) {
      let http = await setHttpClient(BASE_URL, token, ip)
      const response = await http.post(`${schema}`, body)
      return response.data
    } else {
      let http = await setHttpClient('', '', ip)
      const response = await http.post(`${schema}`, body)
      localStorage.setItem('token', JSON.stringify(response?.data?.data))
      return response.data
    }
  } catch (err) {
    return { code: err.response?.status, body, err: err.response?.data?.errors }
  }
}

export const getList = async ({ schema, params, url, baseURL }) => {
  let http = httpClient
  let schemas = url ? `${schema}?${url}` : `${schema}`

  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }
    const response = await http.get(schemas, {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      headers: {
        Authorization: `Bearer ${getTokenStorage(baseURL)}`,
      },
    })

    if (response.status === 200 || response.status === 201) {
      let tmpData = response?.data?.data?.lists || response?.data?.data
      tmpData = tmpData.map((item, index) => {
        return {
          key: index + 1,
          order: params && Object.keys(params).length > 0 ? params.per_page * (params.page - 1) + index + 1 : index + 1,
          ...item,
        }
      })
      let dataLength = 0

      if (response.headers['x-content-length'] || response.headers['X-Content-Length']) {
        dataLength = +response.headers['x-content-length'] || +response.headers['X-Content-Length']
      }

      if (!dataLength) {
        if (response.data.data && response.data.data.option && response.data.data.option.total) {
          dataLength = +response.data.data.option.total
        }
      }

      let newData = response?.data?.data
      delete newData.lists

      return {
        // ...newData,
        items: tmpData,
        dataLength: dataLength,
        code: response?.status,
      }
    } else {
      return { dataLength: 0, code: response?.status }
    }
  } catch (err) {
    console.log(`err`, err)
    return { dataLength: 0, err }
  }
}

export const getById = async (schema, id, baseURL) => {
  let http = httpClient

  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }

    const response = await httpClient.get(`${schema}/${id}`)

    const acceptedStatus = [200, 201, 204]

    if (acceptedStatus.includes(response?.status)) {
      let data = {
        ...response?.data,
        code: response?.status,
      }
      return data
    } else {
      return response?.data
    }
  } catch (err) {
    return err?.response?.data
  }
}

export const postData = async (schema, params, baseURL, ip) => {
  let http = httpClient
  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }

    const response = await httpClient.post(
      `${schema}`,
      { ...params },
      {
        headers: {
          Authorization: `Bearer ${getTokenStorage(baseURL)}`,
          ...(ip ? { 'X-FORWARDED-FOR': ip } : {}),
        },
      }
    )
    if (response?.status === 201) {
      let data = response?.data
      return data
    } else {
      return response?.data
    }
  } catch (err) {
    console.log(`err`, err?.response)
    return err?.response?.data
  }
}

export const putData = async (schema, id, params, baseURL) => {
  let http = httpClient
  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }
    let urlSchema = `${schema}`
    if (id) {
      urlSchema = `${schema}/${id}`
    }
    const response = await httpClient.put(
      urlSchema,
      { ...params },
      {
        headers: {
          Authorization: `Bearer ${getTokenStorage(baseURL)}`,
        },
      }
    )
    if (response?.status === 200) {
      let data = response?.data
      return data
    } else {
      return response?.data
    }
  } catch (err) {
    return { err: err?.response?.data, err_code: err?.code }
  }
}

export const deleteData = async (schema, params, baseURL, abortSignal) => {
  let http = httpClient
  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }
    let urlSchema = `${schema}`
    if (params) {
      urlSchema = `${schema}/${params}`
    }
    const response = await httpClient.delete(urlSchema, {
      signal: abortSignal,
      headers: {
        Authorization: `Bearer ${getTokenStorage(baseURL)}`,
      },
    })

    if (response?.status === 200 || response?.status === 201) {
      let data = response?.data

      return data
    } else {
      return response?.data
    }
  } catch (err) {
    return { err: err?.response?.data, err_code: err?.code }
  }
}
export const getMe = async ({ token, baseURL }) => {
  const http = await setHttpClient(baseURL, token)
  try {
    const response = await http.get(`${me}`)
    return response.data
  } catch (error) {
    console.error(error)
    return false
  }
}
export const getData = async ({ schema, params, url, baseURL, responseType, ip }) => {
  let http = httpClient
  let schemas = url ? `${schema}?${url}` : `${schema}`

  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }
    if (responseType) {
      http.defaults.responseType = responseType
    }
    const response = await http.get(schemas, {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      headers: {
        Authorization: `Bearer ${getTokenStorage(baseURL)}`,
        ...(ip ? { 'X-FORWARDED-FOR': ip } : {}),
      },
    })
    if (response?.status === 200 || response?.status === 201) {
      let tmpData = response?.data

      return tmpData
    } else {
      return { dataLength: 0, code: response?.status }
    }
  } catch (err) {
    return { dataLength: 0, code: err?.response?.data?.code || err?.response?.status }
  }
}

export const getInfo = async ({ url }) => {
  // console.log('url', url)
  let http = httpClient
  try {
    return await http.get(url, {
      method: 'HEAD',
    })
  } catch (err) {
    console.log(`err`, err)

    return { dataLength: 0, code: err?.response?.data?.code }
  }
}

export const exportActivityLogs = async ({ schema, params, url, baseURL }) => {
  let http = httpClient
  let schemas = url ? `${schema}?${url}` : `${schema}`
  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }
    const response = await http.get(schemas, {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getTokenStorage(baseURL)}`,
      },
    })

    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      // the filename you want
      a.download = 'บันทึกการใช้งาน_' + dayjs().locale('th').format('BBBBMMDDHHmmss') + '.xls'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    }
  } catch (err) {
    console.log(`getFile err`, err)
  }
}

export const exportChatLogs = async ({ schema, params, url, baseURL }) => {
  let http = httpClient
  let schemas = url ? `${schema}?${url}` : `${schema}`
  try {
    if (baseURL) {
      http.defaults.baseURL = baseURL
    } else {
      http.defaults.baseURL = BASE_URL
    }
    const response = await http.get(schemas, {
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getTokenStorage(baseURL)}`,
      },
    })

    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'ChatLogs_' + dayjs().locale('th').format('BBBBMMDD_HHmmss') + '.zip'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    }
  } catch (err) {
    console.log(`getFile err`, err)
  }
}
