import React, { useState, useEffect } from 'react'
import ViewPc from './viewPc/view'
import ViewMobile from './viewMobile/viewMobile'

const Index = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // return <>{windowWidth > 1366 ? <ViewPc /> : <ViewMobile />}</>

  return <>{windowWidth > 768 ? <ViewPc /> : <ViewMobile />}</>
}

export default Index
