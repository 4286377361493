import React from 'react'
import get from 'lodash.get'
import SelectANT from 'antd/lib/select'

import Message from 'components/output/Message'

import * as S from './select.style'

export const { Option } = SelectANT

export const SelectComponent = props => {
  const {
    children,
    name,
    placeholder,
    mode,
    setFieldValue,
    setFieldTouched,
    values,
    onChange,
    onSearch,
    disabled,
    onFocus,
    notFoundContent,
    disableFilter,
  } = props

  const value = get(values, name, '')

  const initialValues = () => {
    if (typeof value === 'boolean') {
      return value
    } else {
      if (value !== '') {
        return value
      } else {
        return mode !== 'multiple' ? null : []
      }
    }
  }

  return (
    <S.Select
      name={name}
      mode={mode}
      onChange={e => {
        onChange && onChange(name, e)
        setFieldValue && setFieldValue(name, e)
      }}
      onBlur={() => {
        setFieldTouched && setFieldTouched(name)
      }}
      optionFilterProp="children"
      filterOption={
        onSearch && !disableFilter
          ? (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          : false
      }
      bordered={false}
      placeholder={placeholder}
      defaultValue={initialValues()}
      value={initialValues()}
      showSearch={true}
      onSearch={onSearch}
      disabled={disabled}
      data-testid="test-select-field"
      onFocus={onFocus}
      notFoundContent={notFoundContent}
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      {children}
    </S.Select>
  )
}

const Select = props => {
  const { label, name, errors, touched: touches, form, children, required, onChange } = props

  const error = get(errors, name, '')
  const touched = get(touches, name, '')

  return (
    <S.SelectContainer data-testid="test-select">
      <label htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      <div className="input">
        <SelectComponent {...props}>{children}</SelectComponent>

        {error && touched ? <Message>{error}</Message> : null}
      </div>
    </S.SelectContainer>
  )
}

SelectComponent.defaultProps = {
  placeholder: 'Select options',
  required: false,
  mode: null,
  onSearch: false,
}

export default Select
