import styled from 'styled-components'
import { Space, Button, Modal } from 'antd'

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
`

export const ButtonBack = styled(Button)`
  width: 40px;
  height: 40px;
  border: none !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    border-color: transparent;
    background: #fbfbfb !important;
  }
`
