import ReactApexChart from 'react-apexcharts'
import styled from 'styled-components'

const ColumnsCharts = ({
  columnWidth = '40%',
  stacked = false,
  xaxis,
  series,
  colorChart,
  height = '350',
  width,
  maxWidthPrint,
  toolTipChart,
  Nolegend,
  dataLabels,
}) => {
  let defaultXaxis = [
    'สำนักปลัดเทศบาล',
    'สำนักคลัง',
    'สำนักช่าง',
    'สำนักการประปา',
    'สำนักสาธารณสุขและสิ่งแวดล้อม',
    'สำนักการศึกษา',
    'กองสวัสดิการสังคม',
    'กองยุทธศาสตร์และงบประมาณ',
    'กองการเจ้าหน้าที่',
  ]
  // let tooltipOptions = {}

  // if (toolTipChart) {
  //   tooltipOptions = {
  //     custom:     function ({ series, seriesIndex, dataPointIndex, w }) {
  //       return toolTipChart({ series, seriesIndex, dataPointIndex, w })
  //     },
  //   }
  // } else {
  //   tooltipOptions = {
  //     y: {
  //       formatter: function (val) {
  //         return val
  //       },
  //     },
  //   }
  // }

  const options = {
    chart: {
      type: 'bar',
      stacked: stacked,
      toolbar: {
        show: false,
      },
    },
    colors: colorChart ? colorChart : ['#F85858', '#FFA643', '#915EFF', '#21ADFC', '#13AFA6'],
    legend: {
      show: Nolegend ? false : true,
      position: 'top',
      horizontalAlign: 'right',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: columnWidth,
        endingShape: 'rounded',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: dataLabels ? true : false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '12px', // ปรับขนาดตัวอักษรตามที่คุณต้องการ
          fontFamily: 'Prompt, sans-serif',
          fontWeight: 400,
        },
      },
      categories: xaxis ? xaxis : defaultXaxis,
    },
    yaxis: {
      title: {
        text: '',
      },
    },
    fill: {
      opacity: 1,
    },

    tooltip: toolTipChart
      ? {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return toolTipChart ? toolTipChart({ series, seriesIndex, dataPointIndex, w }) : ''
          },
        }
      : {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
  }

  return (
    <div style={{ maxWidth: maxWidthPrint }}>
      <ColorLabel isTitleLeft={true}></ColorLabel>

      <CustomChart>
        <ReactApexChart
          options={options}
          series={series ? series : []}
          type={options.chart.type}
          height={height}
          width={width}
        />
      </CustomChart>
    </div>
  )
}

export default ColumnsCharts

const ColorLabel = styled.div`
  position: relative;
  display: flex;
  margin: ${props => (props.isTitleLeft === true ? `10px 0 0 0` : '-22px 10px 0 0')};
  justify-content: ${props => (props.isTitleLeft === true ? `flex-start` : 'flex-end')};

  span {
    font-weight: 400;
    font-size: 14px;
    color: #434645;

    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  :not(span:last-child) {
    margin-right: 24px;
  }
`

const CustomChart = styled.div`
  .custom-tooltip {
    /* background-color: red; */
    margin-top: 10px !important;
  }
`

const ColorDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => '#' + color};
  display: inline-block;
  margin-right: ${({ marginRight }) => marginRight || '8px'};
  border-radius: 100%;
`
