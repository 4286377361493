import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Title, ButtonBack } from './view.style'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import { Button, Space, Modal, Form, Input, Tooltip } from 'antd'
import styled from 'styled-components'
import { Spin } from 'antd'
import Viewmap from '../../viewmap'
import { checkDueDate } from '../../container'

import IconCloseModal from 'assets/images/svg/close-modal.svg'
import axios from 'axios'
import { unixToDateThai } from 'util/date-format'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import CardResultBox from './cardResult'
import CardTracking from './CardTracking'
import { checkColorStatusBg, checkColorStatusText, checkStatusText } from 'pages/Complaint/container'
import Iconpreview from 'assets/images/svg/icon-preview.svg'
import { dePartMentIcon } from '../../container'
const UpdateStatuspc = () => {
  const navigate = useNavigate()
  const [previewImg, setPreviewImg] = useState(null)
  const [previewVdo, setPreviewVdo] = useState(null)
  const [OpenModalImage, setOpenModalImage] = useState(false)
  const [OpenModalVdo, setOpenModalVdo] = useState(false)
  const [dataComplaint, setDatacomPlaint] = useState([])
  const [mediaShow, setMediaShow] = useState([])
  const [dataTranSaction, setDataTransaction] = useState([])
  const [loading, setLoading] = useState(true)
  const [Medata, setMeData] = useState([])
  const token = localStorage?.getItem('access_token')
  const location = useLocation()

  useEffect(() => {
    getDataId()
    GetMeData()
  }, [])

  useEffect(() => {
    handleCheckBtnSubmit()
  }, [dataComplaint])

  const GetMeData = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMeData = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resMeData.status === 200 || resMeData.status === 201) {
        setMeData(resMeData?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const gropuData = data => {
    if (data) {
      const uniqueDepartmentIds = []
      const groupedData = []
      for (let i = 0; i < data?.length; i++) {
        const item = data[i]
        const { department_id } = item
        // ถ้า department_id ไม่เคยปรากฏมาก่อน
        if (!uniqueDepartmentIds?.includes(department_id)) {
          uniqueDepartmentIds?.push(department_id)
          groupedData?.push([item])
        } else {
          const index = uniqueDepartmentIds?.indexOf(department_id)
          // เพิ่มรายการปัจจุบันลงในอาร์เรย์ที่มี department_id เดียวกัน
          groupedData[index]?.push(item)
        }
      }
      setDataTransaction(groupedData)
    }
  }

  const getDataId = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint`
      const compalintId = location?.state?.record
      const resp = await axios.get(`${shemadata}/${compalintId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resp?.status === 200) {
        setDatacomPlaint(resp?.data?.data)
        const dataMedia = resp?.data?.data?.media_url
        setMediaShow(dataMedia)
        const dataTranSaction = resp?.data?.data?.transaction_data
        await gropuData(dataTranSaction)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const LastIndex = data => {
    const NewArray = data && data[data?.length - 1]?.name
    return (
      <div>
        <div
          style={{
            backgroundColor: checkColorStatusBg(NewArray),
            color: checkColorStatusText(NewArray),
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {checkStatusText(NewArray)}
        </div>
      </div>
    )
  }

  const Mapmedia = dataMedia => {
    const CardUi =
      dataMedia &&
      dataMedia.map((item, index) => {
        if (item.includes('mp4')) {
          return (
            <>
              <div
                className="show-image-div click"
                onClick={() => {
                  setPreviewVdo(item)
                  setOpenModalVdo(true)
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                  <video style={{ width: '250px', height: '150px', borderRadius: '8px', margin: '1em' }}>
                    <source src={item} type="video/mp4" />
                  </video>
                  <div style={{ position: 'absolute' }}>
                    {/* นำ icon มาวางที่นี่ */}
                    <IvdoPlay />
                  </div>
                </div>
              </div>
            </>
          )
        } else {
          return (
            <div>
              <img
                style={{ borderRadius: '8px', width: '180px', height: '120px', margin: '10px 5px' }}
                className="show-image click"
                src={item}
                alt="image"
                onClick={() => {
                  setOpenModalImage(true)
                  setPreviewImg(item)
                }}
              />
            </div>
          )
        }
      })
    return CardUi
  }

  const onSuccessStatus = async () => {
    const bodyUpdateStatus = {
      description: '',
      name: 'done',
    }
    try {
      const resUpSuccess = await axios.put(
        `${process.env.REACT_APP_BASEURL}/complaint/api/v1/status/${dataComplaint?.id}`,
        bodyUpdateStatus,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if ((resUpSuccess.status === 200) | (resUpSuccess.status === 201)) {
        navigate('/complaint/bof-overview-complaint/')
      }
    } catch (error) {}
  }

  const handleCheckBtnSubmit = () => {
    let a = dataComplaint?.transaction_data?.filter(
      (value, index, array) => value?.type === 'befor' || value?.type === ''
    )
    let b = dataComplaint?.transaction_data?.filter((value, index, array) => value?.type === 'befor')
    if (a?.length === b?.length) {
      return false
    } else {
      return true
    }
  }

  let checkBtn =
    (dataComplaint?.department_data?.length > 1 &&
      dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name !== 'done') ||
    (dataComplaint?.status_data?.length > 1 &&
      dataComplaint?.status_data[dataComplaint?.status_data?.length - 2]?.name === 'done' &&
      dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name === 'in progress')
  const chanelData = {
    ไลน์: 'ไลน์',
    1132: '1132',
    'damrongtham center': 'ศูนย์ดำรงธรรม',
    'traffy fondue': 'Traffy Fondue',
    เว็บไซต์: 'เว็บไซต์',
    'walk in': 'Walk in',
    facebook: 'Facebook',
    other: 'อื่นๆ',
  }
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '20px' }}>
            <Space style={{ paddingTop: '20px', paddingLeft: '30px' }}>
              <ButtonBack
                shape="circle"
                icon={<IconArrowLeft />}
                onClick={() =>
                  location?.state?.path === 'previous-list'
                    ? navigate('/complaint/bof-overview-complaint')
                    : location?.state?.path === 'previous-view'
                    ? navigate('/complaint/bof-overview-complaint/view', { state: { record: location?.state?.record } })
                    : navigate('/complaint/bof-overview-complaint')
                }
              />
              <Title>อัปเดตผลการดำเนินการ </Title>
            </Space>
            <Space></Space>
          </div>
          {/* {Mapvdo()} */}
          <div style={{ margin: '1em 2em' }}>
            <BoxTopView>
              <ContainerView>
                <HeaderboxView>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                    <div>
                      <Tooltip placement="top" title={dataComplaint?.complaint_name}>
                        <TextTile style={{ marginLeft: '15px' }}>{dataComplaint?.complaint_name}</TextTile>
                      </Tooltip>
                      <div
                        style={{
                          margin: '15px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '500px',
                        }}
                      >
                        <Tooltip placement="top" title={dataComplaint?.description}>
                          {dataComplaint?.description ? dataComplaint?.description : ''}
                        </Tooltip>
                      </div>
                    </div>

                    <div
                      style={{
                        textAlign: 'end',
                      }}
                    >
                      {/* รับเรื่อง */}
                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        {LastIndex(dataComplaint?.status_data)}
                      </div>
                      <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                        <span>แจ้งเมื่อ : {unixToDateThai(dataComplaint?.created_at)}</span>
                        <hr style={{ border: '1px solid #FFC4AA', width: '15px', transform: 'rotate(90deg)' }} />
                        <span>เลขที่ : {dataComplaint?.complaint_no}</span>
                        <hr style={{ border: '1px solid #FFC4AA', width: '15px', transform: 'rotate(90deg)' }} />

                        <span>
                          ร้องเรียน : {chanelData[dataComplaint?.channel] ? chanelData[dataComplaint?.channel] : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                </HeaderboxView>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '3%', marginTop: '2%' }}>
                  <div>
                    <Boxbg Bgcolor="#FBFBFB">
                      <BoxDescription>
                        <div className="text-title">ประเภทเรื่องร้องเรียน</div>
                        <div className="text-title">ประเภทย่อย</div>
                        <div className="text-title">สถานะความเร่งด่วน</div>
                      </BoxDescription>
                      <BoxDescription>
                        {dataComplaint?.category?.name ? (
                          <div>
                            {dataComplaint?.category?.icon_url && (
                              <img
                                src={`${dataComplaint?.category?.icon_url}`}
                                style={{ width: '20px', height: '20px' }}
                                alt="cate"
                              />
                            )}
                            <span style={{ margin: '0 3px' }}>{dataComplaint?.category?.name}</span>
                          </div>
                        ) : (
                          '-'
                        )}
                        <div className="to-right">{dataComplaint?.sub_category_data?.name}</div>
                        <span
                          style={{
                            backgroundColor: dataComplaint?.urgency?.name === 'ทำทันที' ? '#F85858' : '#F3F3F3',
                            borderRadius: '10px',
                            color:
                              dataComplaint?.urgency?.name === 'ปกติ'
                                ? '#13AFA6'
                                : dataComplaint?.urgency?.name === 'ด่วน'
                                ? '#FFA643'
                                : dataComplaint?.urgency?.name === 'ด่วนมาก'
                                ? '#EC82F8'
                                : dataComplaint?.urgency?.name === 'ด่วนที่สุด'
                                ? '#F36B24'
                                : '#FFFFFF',
                            padding: '10px 20px',
                            height: '40px',
                            textAlign: 'center',
                            width: '120px',
                          }}
                        >
                          {dataComplaint?.urgency?.name}
                        </span>
                      </BoxDescription>
                      <BoxOrganize>
                        <div className="text-title">หน่วยงานที่รับผิดชอบ</div>
                      </BoxOrganize>
                      <BoxOrganize>
                        {dataComplaint?.department_data ? (
                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {dataComplaint &&
                              dataComplaint?.department_data?.map((item, index) => {
                                return (
                                  <BoxIcon key={index}>
                                    <div>{dePartMentIcon[item?.name]}</div>
                                    <div style={{ margin: '0 4px' }}> {item?.name}</div>
                                  </BoxIcon>
                                )
                              })}
                          </div>
                        ) : (
                          '-'
                        )}
                      </BoxOrganize>
                      <BoxDescription>
                        {dataComplaint?.department_data?.length === 1 && (
                          <div>
                            <div className="text-title">ผู้รับผิดชอบ</div>
                            <div className="text-sub-title">
                              {dataComplaint?.department_data[0]?.officer_data?.first_name}{' '}
                              {dataComplaint?.department_data[0]?.officer_data?.last_name}
                            </div>
                          </div>
                        )}
                        <div>
                          <div className="text-title">วันที่กำหนดเสร็จ</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.duedate_at ? checkDueDate(dataComplaint?.duedate_at) : '-'}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-title">วันที่แล้วเสร็จ</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.status_data
                                ? dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.name === 'done'
                                  ? checkDueDate(
                                      dataComplaint?.status_data[dataComplaint?.status_data?.length - 1]?.created_at
                                    )
                                  : '-'
                                : '-'}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-title">ผู้ร้องเรียน</div>
                          <div className="text-sub-title">
                            {dataComplaint?.name
                              ? `${dataComplaint?.name?.first_name} ${dataComplaint?.name?.last_name}`
                              : '-'}
                          </div>
                        </div>
                        <div>
                          <div className="text-title">เบอร์โทรศัพท์</div>
                          <div>
                            <div className="text-sub-title">
                              {dataComplaint?.mobile_number ? dataComplaint?.mobile_number : '-'}
                            </div>
                          </div>
                        </div>
                      </BoxDescription>
                      <BoxDescription>
                        {dataComplaint?.department_data?.map(val => {
                          return (
                            <div>
                              <div className="text-title">เหตุผลการปฏิเสธการโอนย้าย</div>
                              <div>
                                <div className="text-sub-title">
                                  {val?.transfer_reason ? val?.transfer_reason : '-'}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </BoxDescription>
                    </Boxbg>
                  </div>
                  <div>
                    <div>
                      <div className="text-title">รูปภาพและวิดีโอ</div>
                      <div style={{ display: 'flex' }}>
                        {dataComplaint?.media_url && Mapmedia(dataComplaint?.media_url)}
                      </div>
                    </div>
                    <div>
                      <div className="text-title" style={{ marginBottom: '10px' }}>
                        สถานที่แจ้งเรื่อง
                      </div>
                      <div style={{ display: 'flex' }}></div>
                    </div>
                    <Viewmap
                      latitude={dataComplaint?.coordinates?.latitude}
                      longitude={dataComplaint?.coordinates?.longitude}
                    />
                  </div>
                </div>
              </ContainerView>
            </BoxTopView>

            <CardResultBox
              dataTranSaction={dataTranSaction}
              setPreviewImg={setPreviewImg}
              setPreviewVdo={setPreviewVdo}
              setOpenModalImage={setOpenModalImage}
              setOpenModalVdo={setOpenModalVdo}
              complaintId={dataComplaint?.id}
              dataComplaint={dataComplaint}
              path={location?.state?.path}
            />

            <CardTracking
              dataComplaint={dataComplaint}
              complaintId={dataComplaint?.id}
              setPreviewImg={setPreviewImg}
              setPreviewVdo={setPreviewVdo}
              setOpenModalImage={setOpenModalImage}
              setOpenModalVdo={setOpenModalVdo}
            />
            <BtnSubmit>
              {checkBtn && (
                <Button onClick={onSuccessStatus} disabled={handleCheckBtnSubmit()} type="primary">
                  เสร็จสิ้นการปฏิบัติงาน
                </Button>
              )}
            </BtnSubmit>
            {/* {dataComplaint?.status_data?.slice(-1)[0]?.name != 'done' && dataTranSaction.length === 1 && (
              <BtnSubmit>
                <Button disabled={CheckBtnSubmit()} onClick={onSuccessStatus} type="primary">
                  เสร็จสิ้นการปฏิบัติงาน
                </Button>
              </BtnSubmit>
            )} */}
            {/* {dataComplaint?.department_data?.length === 1 &&
              dataTranSaction?.length === 1 &&
              dataTranSaction[0]?.length === 2 && (
                <BtnSubmit>
                  <Button disabled={CheckBtnSubmit()} onClick={onSuccessStatus} type="primary">
                    เสร็จสิ้นการปฏิบัติงาน
                  </Button>
                </BtnSubmit>
              )} */}

            <div>
              <ModalPreviewImage
                title=""
                centered
                transitionName=""
                closable={false}
                open={OpenModalImage}
                footer={false}
                onOk={() => {
                  setPreviewImg(null)
                  setOpenModalImage(false)
                }}
                onCancel={() => {
                  setPreviewImg(null)
                  setOpenModalImage(false)
                }}
              >
                <ModelContent>
                  <div
                    className="close-ui"
                    onClick={() => {
                      setOpenModalImage(false)
                      setPreviewImg(null)
                    }}
                  >
                    <img src={Iconpreview} alt="dd" />
                  </div>
                  {previewImg ? (
                    <div className="vdo-content">
                      <CustomImage>
                        <img src={previewImg} style={{ width: '100%', height: 'auto' }} />
                      </CustomImage>
                    </div>
                  ) : null}
                </ModelContent>
              </ModalPreviewImage>
            </div>

            <div>
              <ModalPreviewImage
                title=""
                centered
                transitionName=""
                closable={false}
                open={OpenModalVdo}
                footer={false}
                onOk={() => {
                  setOpenModalVdo(false)
                }}
                onCancel={() => {
                  setOpenModalVdo(false)
                }}
              >
                <ModelContent>
                  <div
                    className="close-ui"
                    onClick={() => {
                      setOpenModalVdo(false)
                    }}
                  >
                    <img src={IconCloseModal} alt="dd" style={{ width: '80px', height: '70px' }} />
                  </div>
                  {previewVdo ? (
                    <div className="vdo-content">
                      <video width="750" height="500" controls>
                        <source src={previewVdo} type="video/mp4" />
                      </video>{' '}
                    </div>
                  ) : null}
                </ModelContent>
              </ModalPreviewImage>
            </div>

            {/* ////////////////////notไม่ แสดงยกเว้นกด  */}
          </div>
        </div>
      )}
    </>
  )
}

export default UpdateStatuspc

const TextTile = styled.p`
  color: #f36b24;
  display: -webkit-box;
  max-width: 700px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Prompt;
  font-size: 20px;
`
const CustomImage = styled.div`
  img {
    max-height: 800px;
  }
`

const BoxIcon = styled.div`
  background-color: #fff;
  padding: 5px;
  margin: 0 3px;
  display: flex;
  color: #556a82;
  /* max-width: 100px; */
`

export const ModalPreviewImage = styled(Modal)`
  font-family: 'IBM Plex Sans Thai', sans-serif;

  .close-ui {
    position: fixed;
    top: 35px;
    right: 90px;
    padding: 0px;

    /* background: #fbfbfb; */

    width: 35px;
    height: 35px;
    border-radius: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    font-weight: 800;
    color: #3f54d1;
    z-index: 100;
    transition: none;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    width: auto;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
    .ant-btn-default {
      border-radius: 12px;
      border-color: #00ada4 !important;
      color: #00ada4 !important;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #00ada4 !important;
      border-color: #00ada4;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

const ModelContent = styled.div`
  .vdo-content {
    position: relative;
    padding: 0;
  }
  .model-ui {
    font-style: normal;
    .header-ui {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 47px;
      color: #19263f;
      text-align: left;
    }
    .model-crop {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .controls {
      background: #f8e2e2;
      border-radius: 35px;
      width: 200px;
      padding: 0.2em 12px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      .slider {
        width: 160px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .button-zoom {
        line-height: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #1f3983;
        width: 20px;
        height: 20px;
        user-select: none;
      }
    }
  }
`

const BoxTopView = styled.div`
  margin-bottom: 20px;
  .text-title {
    color: #c4c4c4;
    font-size: 12px;
  }
  .text-sub-title {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .to-right {
    font-size: 12px;
  }
`

const BoxDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1em;
`
export const BoxOrganize = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1em;
`

const Boxbg = styled.div`
  background-color: ${props => (props.Bgcolor ? props.Bgcolor : '#ffff')};
  border-radius: ${props => (props.BdRadius ? `${props.BdRadius}` : '16px')};
  padding: ${props => (props.Padding ? `${props.Padding}` : '1em')};
  width: ${props => (props.Bwidth ? `${props.Bwidth}` : '100%')};
  height: 100%;
`

const Textttile = styled.div`
  color: #f36b24;

  font-family: Prompt;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
`

const ContainerView = styled.div`
  background-color: #ffff;
  border-radius: 24px;
  padding: 2em;
  margin-bottom: 20px;

  .head-color {
    color: #c4c4c4;
  }
`
const HeaderboxView = styled.div`
  border-radius: 24px;
  border: 1px solid var(--web-backend-extended-orange-400, #ffeee7);
  padding: 2em;
  width: 100%;
`
const BtnSubmit = styled.div`
  display: flex;
  justify-content: end;

  margin: 20px;
  padding: 10px 10px 15px 10px;

  .ant-btn-primary {
    border-radius: 8px;
    background-color: #f36b24;
    border-color: #f36b24;
    font-size: 14px;
    width: 180px;
    height: 40px;
    font-weight: 500;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
`
