import * as React from 'react'
import styled from 'styled-components'

const Marker = styled.div`
  width: 100px;
  height: 100px;
`

const Pin = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  top: 20px;
  left: 35px;
  background: #f36b24;
  border: 3px solid #fff;
  border-radius: 50%;
  z-index: 1000;
`

const PinEffect = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  top: 38px;
  left: 17px;
  display: block;
  background: rgba(243, 107, 36, 0.3);
  border-radius: 50%;
  border: solid 1px #f36b24;
  /* opacity: 0; */
  animation: pulsate 2400ms ease-out infinite;
  @keyframes pulsate {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    120% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
`

const PulseMarker = () => {
  return (
    <div>
      <Marker>
        <Pin />
        <PinEffect />
      </Marker>
    </div>
  )
}

export default PulseMarker
