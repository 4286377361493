import React from 'react'
import styled from 'styled-components'
import { ApexChartDonut } from './DonutChart'
import LineChart from './LineChart'
import { StackedBarChart } from './StackBarChart'
import { HorizontalBarChart } from './HorizontalBar'
import MyForm from './FormDashBoard'
// import MegaphoneIcon from '/MegaphoneIcon.svg'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'

const BoxOverAll = styled.div`
  margin: 0 40px 40px 40px;
`

const Stat = styled.div`
  display: inline-flex;
  align-items: flex-start;
`

const StatPath = styled.p`
  color: #717171;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 20px;
`

const StatNow = styled.p`
  color: #f36b24;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 20px;
`

const HeaderBox = styled.div`
  display: flex;
  align-items: end;
  background-color: #cce415;
`

const HeaderTopic = styled.h1`
  font-family: Prompt;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  text-align: center;
  margin-top: 33px;
`

const ButtonBox = styled.button`
  width: 160px;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: #f36b24;
  border: none;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  margin-right: 16px;
  color: #fff;
`

const ButtonPrint = styled.button`
  display: inline-flex;
  height: 40px;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #f36b24;
  background: #fff;
  color: #f36b24;
`

const BoxForm = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  border-radius: 12px;
  background: rgba(252, 101, 33, 0.03);
  margin: 16px 0px 16px 0px;
  padding: 16px;
`

const BoxAllState = styled.div`
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
`

const MonthText = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 24px;
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`

const StyledImage = styled.img`
  padding-bottom: 10px;
`

const BoxNumOfComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  border-radius: 12px;
  background: #fffbf8;
`

const LeftBoxNumOfComp = styled.div`
  display: flex;
  padding-top: 15px;
  gap: 8px;
`

const BoxsSatus = styled.div`
  height: 400px;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const TopicHeaderStatus = styled.div`
  display: flex;
  gap: 8px;
`

const TextSatus = styled.h2`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const TextSatusStay = styled.p`
  color: #150f0b;
  font-family: Prompt;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
`

const BoxSatisfaction = styled.div`
  height: 400px;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const RightBox = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const FlexOvEx = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`

const BoxOverLoad = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const BoxExigent = styled.div`
  flex: 1;
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
`

const FlexAgency = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

const BottomBox = styled.div`
  border-radius: 12px;
  background: #fbfbfb;
  padding: 24px;
  margin-top: 24px;
  flex: 1;
  min-height: 360px;
`

const NumStyle = styled.p`
  font-family: Prompt;
  padding-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`

function Index() {
  const labels = [
    'รับเรื่อง',
    'อยู่ระหว่างดำเนินการ',
    'ดำเนินการแล้วเสร็จ',
    'ติดตาม',
    'ไม่อยู่ในความรับผิดชอบ',
    'เกินระยะเวลาที่กำหนด',
  ]
  const colors = ['#915EFF', '#FFBB0B', '#13AFA6', '#F36B24', '#3F54D1', '#F85858']
  const series = [3956, 2943, 2150, 1830, 2026, 1830]

  const statusselectedIndexes = [0, 1, 2, 3, 4]
  const statusselectedLabels = labels.filter((_, index) => statusselectedIndexes.includes(index))
  const statusselectedColors = colors.filter((_, index) => statusselectedIndexes.includes(index))
  const statusselectedSeries = series.filter((_, index) => statusselectedIndexes.includes(index))

  const selectedIndexes = [1, 5]
  const selectedLabels = labels.filter((_, index) => selectedIndexes.includes(index))
  const selectedColors = colors.filter((_, index) => selectedIndexes.includes(index))
  const selectedSeries = series.filter((_, index) => selectedIndexes.includes(index))

  const selectedColorsHoriz = [colors[1], colors[2], colors[4], colors[3], colors[5]]

  const categoriesData = [
    'สมรรถชัย เหล่าอรุณ',
    'ฉัตรชยา เจริญกิจธารา',
    'ปิยรมย์ จรัสวงศ์',
    'กาญจนี วัฒนศักดิ์มนตรี',
    'ธรรมนิตย์ พงศ์พัฒนา',
    'ชมพูนุท ป้องภัย',
    'เจิมจันทร์ ปรีชาวงศ์',
    'ธารา งามขวัญ',
    'สรวิทย์ สิงห์ขาว',
    'ธีรโชติ มานะตน',
    'ณฐพร วรารักษ์',
    'อิธิพล รามคำแหง',
    'ถิรพล จันทรเกียรติ',
    'โสพล ผ่องรักษา',
    'ธีรยา ศิริวรภัทร',
    'สุชาดา วัชรจิระกุล',
    'วีรพล วัชรโสภณ',
    'กัญจนพร ศิริภาพันธ์',
  ]

  const seriesData = [
    {
      name: 'อยู่ระหว่างดำเนินการ',
      data: [109, 50, 45, 20, 43, 30, 75, 20, 20, 20, 20, 20, 35, 5, 5, 5, 9, 12],
    },
    {
      name: 'ดำเนินการแล้วเสร็จ',
      data: [14, 15, 11, 14, 12, 13, 11, 11, 13, 12, 14, 11, 11, 13, 12, 14, 11, 11],
    },
    {
      name: 'ไม่อยู่ในความรับผิดชอบ',
      data: [14, 15, 11, 14, 12, 13, 11, 11, 13, 12, 14, 11, 11, 13, 12, 14, 11, 11],
    },
    {
      name: 'ติดตาม',
      data: [14, 15, 11, 14, 12, 13, 11, 11, 13, 12, 14, 11, 11, 13, 12, 14, 11, 11],
    },
    {
      name: 'เกินระยะเวลาที่กำหนด',
      data: [14, 15, 11, 14, 12, 13, 11, 11, 13, 12, 14, 11, 11, 13, 12, 14, 11, 11],
    },
  ]

  return (
    <BoxOverAll>
      <Stat>
        <StatPath>สถิติเรื่องร้องเรียน /</StatPath>
        <StatNow>สถานะการทำงานของเจ้าหน้าที่สำนักปลัดเทศบาล</StatNow>
      </Stat>

      <HeaderTopic>แดชบอร์ดแสดงสถานะการทำงานของเจ้าหน้าที่สำนักปลัดเทศบาล</HeaderTopic>

      <HeaderBox></HeaderBox>

      <BoxForm>
        <MyForm />
      </BoxForm>

      <BoxAllState>
        <MonthText>ประจำเดือนกันยายน 2566</MonthText>
        <FlexContainer>
          <BoxContainer>
            <BoxNumOfComp>
              <LeftBoxNumOfComp>
                <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                <TextSatus>จำนวนเรื่องร้องเรียนทั้งหมด</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </LeftBoxNumOfComp>
              <NumStyle>15987</NumStyle>
            </BoxNumOfComp>
            <BoxsSatus>
              <TopicHeaderStatus>
                <TextSatus>จำนวนเรื่องร้องเรียนตามสถานะ</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <ApexChartDonut
                labels={statusselectedLabels}
                colors={statusselectedColors}
                series={statusselectedSeries}
              />
            </BoxsSatus>

            <BoxSatisfaction>
              <TopicHeaderStatus>
                <TextSatus>ผลประเมินความพึงพอใจ</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <LineChart />
            </BoxSatisfaction>
          </BoxContainer>

          <RightBox>
            <TopicHeaderStatus>
              <TextSatus>แผนที่แสดงเรื่องร้องเรียนจำแนกตามสถานะ</TextSatus>
            </TopicHeaderStatus>
          </RightBox>
        </FlexContainer>

        <FlexOvEx>
          <BoxOverLoad>
            <TopicHeaderStatus>
              <TextSatus>จำนวนเรื่องร้องที่อยู่ระหว่างดำเนินการและเกินระยะเวลาที่กำหนด</TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>
            <ApexChartDonut labels={selectedLabels} colors={selectedColors} series={selectedSeries} />
          </BoxOverLoad>
          <BoxExigent>
            <TopicHeaderStatus>
              <TextSatus>สัดส่วนเรื่องร้องเรียนตามความเร่งด่วน</TextSatus>
              <TextSatusStay>(เรื่อง)</TextSatusStay>
            </TopicHeaderStatus>
          </BoxExigent>
        </FlexOvEx>
        <FlexAgency>
          <TopicHeaderStatus>
            <TextSatus>กราฟแสดงจำนวนสถานะของแต่ละหน่วยงาน</TextSatus>
            <TextSatusStay>(เรื่อง)</TextSatusStay>
          </TopicHeaderStatus>
          <StackedBarChart labels={labels} colors={colors} />
        </FlexAgency>
        <BottomBox>
          <HorizontalBarChart categories={categoriesData} series={seriesData} colors={selectedColorsHoriz} />
        </BottomBox>
      </BoxAllState>
    </BoxOverAll>
  )
}

export default Index
