import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import FormEmail from './FormEmail'
import FormOTP from './FormOTP/FormOTP'

function ResultPage({ type, onClick }) {
  const { idpath } = useParams()

  return (
    // <Styled.Container>
    //   <Styled.BoxImage>
    //     <div className="text-touch">touch</div>

    //     <div className="box-health-gate">
    //       <img src={Helgatewaylogo} alt="helth" className="healtgate-image" />
    //       <div className="img-addmin">Administrator’s Tools</div>
    //     </div>
    //   </Styled.BoxImage>

    //   {idpath === 'forgot-password' ? (
    //     <Forgotpass />
    //   ) : idpath === 'login-otp' ? (
    //     <Otppage />
    //   ) : idpath === 'forgot-otp' ? (
    //     <Otppage />
    //   ) : idpath === 'set-password' ? (
    //     <Resetpass />
    //   ) : (
    //     <Resetpass />
    //   )}
    // </Styled.Container>

    <>{idpath === 'email' ? <FormEmail /> : idpath === 'otp' ? <FormOTP /> : ''}</>
  )
}

export default ResultPage
