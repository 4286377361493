import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 64px;
  background: linear-gradient(91.52deg, #ffe2d6 -29.32%, #ffebe2 17.81%, #f9f9f9 109.98%);
  /* display: flex; */
  /* flex-direction: column; */
  display: block;
`
