import React from 'react'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/th_TH'
import moment from 'moment'
import 'moment/locale/th'
import styled from 'styled-components'

const ThaiDatePicker = props => {
  const { onChange } = props

  const dateFormat = 'DD MMM YYYY'

  const disabledDate = current => {
    // กำหนดเงื่อนไขว่าวันที่ต้องไม่น้อยกว่าวันปัจจุบัน
    return current && current < moment().startOf('day')
  }

  const handleChange = (date, dateString) => {
    const unixTimestamp = moment(date).unix()
  }

  return (
    <WrapDatePicker>
      <DatePicker
        locale={locale}
        format={dateFormat}
        placeholder="เลือกวันที่กำหนดเสร็จ"
        onChange={onChange}
        disabledDate={disabledDate}
      />
    </WrapDatePicker>
  )
}

export default ThaiDatePicker

const WrapDatePicker = styled.div`
  .ant-picker {
    border-radius: 8px !important;
    border: 1px solid #ffeee7 !important;
    height: 40px !important;
    font-size: 14px !important;
  }

  .ant-picker-input > input {
    color: rgb(254, 129, 74) !important;

    &::placeholder {
      color: #ffc4aa !important;
    }
  }
`
