import { Pagination } from 'antd'
import styled from 'styled-components'

export const PaginationCustom = styled(Pagination)`
  .ant-pagination-item {
    background-color: none !important;
    font-family: 'Prompt', sans-serif;
  }

  .ant-pagination-item a {
    color: #150f0b;
  }
  .ant-pagination-item-active {
    a {
      color: white !important;
    }
    font-weight: 600;
    background: #f36b24 !important;
    border-color: white;
    border-radius: 4px !important;
    color: white;
  }

  .ant-pagination-item {
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    &:hover {
      background-color: #e2e5f8 !important;
    }
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #717171;
  }

  .ant-pagination-next .ant-pagination-item-link {
    background-color: #f8f8f8;
    border: none;
    svg {
      fill: #f36b24;
    }
  }
  .ant-pagination-prev .ant-pagination-item-link {
    background-color: #f8f8f8;
    border: none;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  .middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
`
export const CustomText = styled.div`
  color: #48708e;
  font-weight: 400;
`
