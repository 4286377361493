import { Button, Input } from 'antd'
import styled from 'styled-components'

export const RedDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
`
export const ChatContainer = styled.div`
  border-radius: 24px;
  font-family: Prompt;
  display: grid;
  grid-template-columns: 1fr 2fr;
  /* height: 670px; */
  padding: 32px;
  justify-content: center;
  background-color: #fff;
  border-radius: 24px;
`

export const SearchContainer = styled.div`
  grid-column: 1;
`

export const StyledInput = styled(Input)`
  display: flex;
  align-items: center;
  width: 95%;
  height: 40px;
  border-radius: 8px;
  padding: 0px 8px 0px 0px;
  font-weight: 400;
  gap: 8px;
  font-size: 14px;
  border: ${props => (props.hasContent ? '1px solid #F36B24' : '1px solid #FFEEE7')};
  box-shadow: none;

  .ant-input-prefix {
    width: 16px;
    height: 16px;
  }

  .ant-input-suffix {
    display: flex;
    align-items: center;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #f36b24 !important;
    box-shadow: none !important;
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    box-shadow: none !important;
    border: 1px solid #f36b24 !important;
  }

  .ant-input {
    font-size: 16px;
    font-family: 'Prompt';
    font-weight: 400;
    border: none;
    box-shadow: none;
    outline: none;
    color: #150f0b;
    flex-grow: 1;
  }
`

// export const MessageContainer = styled.div`
//   grid-column: 2;
//   background-color: #fffbf8;
//   border-radius: 0px 24px 24px 0px;
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `

export const ChatList = styled.div`
  margin-right: 8px;
  margin-top: 24px;
  max-height: 550px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    margin-right: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #ffcc99;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ff9933;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cc7a00;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`

export const ChatListItem = styled.div`
  /* width: 352px; */
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 0.5px solid #ffeee7;
  position: relative;
  /* border-radius: 12px; */
  margin-right: 8px;
  cursor: pointer;

  :hover {
    background-color: #ffeee7;
    border-radius: 12px;
  }
`

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  object-fit: cover;
  margin-right: 16px;
`

export const ChatInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`

export const ChatTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ChatBottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`

export const ChatName = styled.div`
  color: #150f0b;
  font-family: 'Prompt', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`

export const ChatPreview = styled.div`
  color: #717171;
  font-family: 'Prompt', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const NotificationBadge = styled.div`
  color: white;
  display: inline-flex;
  padding: 5px 6.5px 6px 5.5px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #f36b24;
  font-family: 'Prompt', sans-serif;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  width: 20px;
  height: 20px;
`

export const TimeStamp = styled.span`
  color: #c4c4c4;
  text-align: right;
  font-family: 'Prompt', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
`

export const TextStyledUnder = styled.p`
  color: #ffc4aa;
  font-family: 'Prompt', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const MessageContainer = styled.div`
  grid-column: 2;
  background-color: #fffbf8;
  border-radius: 0px 24px 24px 0px;
  /* width: 780px; */
  height: 630px;
`

export const TopHistoryChatStyle = styled.div`
  height: 88px;
  width: 100%;
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 24px 24px 0px;
  background: #ffeee7;
  justify-content: space-between;
  gap: 24px;
`

export const HistoryAvatar = styled.img`
  border-radius: 50%;
  width: 56px;
  height: 56px;
  gap: 8px;
  object-fit: cover;
`

export const HistoryChatName = styled.span`
  font-family: Prompt;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #150f0b;
  margin-left: 24px;
`

export const MainHistoryChat = styled.div`
  height: 630px;
  padding: 0px 8px 32px 32px;
`

export const StyledInputChat = styled(Input)`
  display: flex;
  align-items: center;
  font-weight: 400;
  /* width: 644px; */
  height: 52px;
  padding: 8px 16px;
  background: #fff;
  border-radius: 8px;
  margin-top: 24px;
  color: #f36b24;
  font-family: 'Prompt', sans-serif;
  border: 1px solid #ffc4aa;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #f36b24 !important;
    box-shadow: none !important;
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    box-shadow: none !important;
    border: 1px solid #f36b24 !important;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  .ant-input {
    font-size: 16px;
    font-family: 'Prompt';
    font-weight: 400;
    border: none;
    box-shadow: none;
    outline: none;
    color: #150f0b;
    flex-grow: 1;
  }
`

export const AreaChat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const TimeHistoryChat = styled.p`
  text-align: center;
  font-family: Prompt;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14.4px;
  color: #717171;
`

export const CardImage = styled.img`
  width: 240px;
  height: auto;
`

export const CardContent = styled.p`
  padding: 12px 16px;
  background: #f36b24;
  color: #fff;
  font-family: 'Prompt', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  /* width: 380px; */
  max-width: 35%;
  border-radius: 2px 16px 16px 16px;
  display: flex;
  align-items: flex-start;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const HistoryTimeContent = styled.div`
  font-family: Prompt;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  color: #9baab1;
  padding: 8px 0px 16px 0px;
`

export const WrapContentStaff = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

export const BoxContentStaff = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 50%;
`

export const CardContentStaff = styled.div`
  padding: 12px 16px;
  background: #ffffff;
  color: #150f0b;
  font-family: 'Prompt', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  width: auto;
  max-width: 100%;
  border-radius: 2px 16px 16px 16px;
  display: flex;
  margin: 0;
`

export const NameStaff = styled.div`
  font-family: 'Prompt';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: #ffc4aa;
  text-align: right;
  margin-bottom: 8px;
`

export const HistoryTimeContentStaff = styled.div`
  font-family: 'Prompt';
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  color: #9baab1;
  padding: 8px 5px 16px 0px;
  text-align: right;
`
export const HistoryTimeContentUser = styled.div`
  font-family: 'Prompt';
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  color: #9baab1;
  padding: 8px 5px 16px 0px;
  text-align: left;
`

export const MainChat = styled.div`
  height: 430px;
  margin-top: 30px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    margin-right: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #ffcc99;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ff9933;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cc7a00;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`

export const CustomButton = styled(Button)`
  font-family: 'Prompt' !important;
  background-color: #f36b24;
  border-color: #f36b24 !important;
  height: 40px;
  width: 160px;
  border-radius: 8px;

  &.ant-btn:focus,
  &.ant-btn:hover {
    border-color: #f36b24 !important;
    background-color: #f36b24 !important;
    /* box-shadow: none !important;
      color: transparent !important; */
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
  }
`
