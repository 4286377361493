import React, { useState, useEffect } from 'react'
import { GoogleMap } from '@react-google-maps/api'

export default function Index({ children, zoom, center, mapContainerStyle, height, handleClickMap, setMap, id }) {
  const centers = {
    lat: 14.9742147,
    lng: 102.0859614,
  }

  return (
    <GoogleMap
      id={id}
      center={center ? center : centers}
      zoom={zoom ? zoom : 10}
      clickableIcons={false}
      mapContainerStyle={
        mapContainerStyle
          ? mapContainerStyle
          : {
              width: '100%',
              height: height ? height : '100vh',
              borderRadius: '10px',
              borderColor: '#fff',
              transition: '1s',
            }
      }
      options={{
        mapId: '4504f8b37365c3d0',
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        minZoom: 6,
        clickableIcons: false,
        restriction: {
          latLngBounds: {
            north: 20.856935, // North boundary of Thailand
            south: 5.612851, // South boundary of Thailand
            west: 97.337267, // West boundary of Thailand
            east: 105.638703, // East boundary of Thailand
          },
        },
      }}
      onLoad={map => setMap && setMap(map)}
      onClick={handleClickMap && handleClickMap}
    >
      {children}
    </GoogleMap>
  )
}
