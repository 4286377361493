import React, { useState, useEffect } from 'react'
import { Button, Drawer, Select } from 'antd'
import { CustomUploadfile, Content, BoxDrawer, BoxNameColums, BoxColums, TextTH, CustomModal } from './ImportFile.style'
import UploadFileExcel from 'components/Inputs/UploadFileExcel'
import ImportFile from 'assets/images/png/Import Complaint.png'
import { ReactComponent as Close } from 'assets/images/svg/Close.svg'
import { columns } from './container'
import TableCustom from 'components/TableCustom/Row'
import IconBlack from 'assets/images/png/Back.png'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle.js'
import TextComponents from './text'
import * as XLSX from 'xlsx'
import { getList, postData } from 'core/action/collection'
import axios from 'axios'
export default function IndexPc(props) {
  const [cover, setCover] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [section, setSection] = useState(1)
  const [jsonData, setJsonData] = useState(null)
  const [dataSelect, setDataSelect] = useState([])
  const [dataSelectFromFile, setDataSelectFromFile] = useState('')
  const [selectedCategoryID, setSelectedCategoryID] = useState('')
  const [modelConfirmeCreate, setModelConfirmeCreate] = useState(false)
  const [masterData, setMasterData] = useState([])
  const nameColums = ['Ticket_id', 'Comment', 'Type', 'Coords', 'Timestamp', 'State', 'Image']
  const headerColums = ['ชื่อคอลัมน์บน .CSV', 'ข้อมูลแถวแรก']

  useEffect(() => {
    getMasterData()
  }, [])

  useEffect(() => {
    getSubCategories()
  }, [selectedCategoryID])

  const onSelectChange = selectedKeys => {
    const checkKey = selectedRowKeys.includes(selectedKeys?.id)
    if (checkKey) {
      setSelectedRowKeys(prevArrRow => prevArrRow.filter(id => id !== selectedKeys?.id))
      setDataSelect(prevArrRow => prevArrRow.filter(val => val?.id !== selectedKeys?.id))
    } else {
      const lastSelectedKey = selectedKeys?.id
      setSelectedRowKeys(prevArrRow => [...prevArrRow, lastSelectedKey])
      setDataSelect(prevArrRow => [...prevArrRow, selectedKeys])
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectChange,
  }

  const handleCategoryChange = (idValue, idData) => {
    setSelectedCategoryID(idValue)
    let filterJson = jsonData?.filter(val => val.id === idData?.id)
    let filterDataSelect = dataSelect?.filter(val => val.id === idData?.id)

    if (filterJson?.length) {
      let data = {
        category_id: idValue,
        ...filterJson[0],
      }
      setJsonData(pre => {
        const newData = [...pre]
        const index = newData.findIndex(item => item.id === idData.id)
        if (index !== -1) {
          newData[index] = data
        }
        return newData
      })
      if (filterDataSelect?.length) {
        let dataS = {
          category_id: idValue,
          ...filterJson[0],
        }
        setDataSelect(pre => {
          const newData = [...pre]
          const index = newData.findIndex(item => item.id === idData.id)
          if (index !== -1) {
            newData[index] = dataS
          }
          return newData
        })
      }
    } else {
      let data = {
        category_id: idValue,
        ...idData,
      }
      setJsonData(pre => [...pre, data])
      if (filterDataSelect?.length) {
        let dataS = {
          category_id: idValue,
          ...filterJson[0],
        }
        setDataSelect(pre => {
          const newData = [...pre]
          const index = newData.findIndex(item => item.id === idData.id)
          if (index !== -1) {
            newData[index] = dataS
          }
          return newData
        })
      }
    }
  }

  const handleSubCategoryChange = (idValue, idData) => {
    let filterJson = jsonData?.filter(val => val?.id === idData?.id)
    let filterDataSelect = dataSelect?.filter(val => val.id === idData?.id)

    if (filterJson?.length) {
      let data = {
        sub_category_id: idValue,
        ...filterJson[0],
      }
      setJsonData(pre => {
        const newData = [...pre]
        const index = newData.findIndex(item => item.id === idData.id)
        if (index !== -1) {
          newData[index] = data
        }
        return newData
      })
      if (filterDataSelect?.length) {
        let dataS = {
          sub_category_id: idValue,
          ...filterJson[0],
        }
        setDataSelect(pre => {
          const newData = [...pre]
          const index = newData.findIndex(item => item.id === idData.id)
          if (index !== -1) {
            newData[index] = dataS
          }
          return newData
        })
      }
    } else {
      let data = {
        sub_category_id: idValue,
        ...idData,
      }
      setJsonData(pre => [...pre, data])
      if (filterDataSelect?.length) {
        let dataS = {
          sub_category_id: idValue,
          ...filterJson[0],
        }
        setDataSelect(pre => {
          const newData = [...pre]
          const index = newData.findIndex(item => item.id === idData.id)
          if (index !== -1) {
            newData[index] = dataS
          }
          return newData
        })
      }
    }
  }

  const handleBase64String = (files, file) => {
    if (file?.url.includes('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,')) {
      let xlsx = file?.url?.split('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,')
      const workbook = XLSX.read(xlsx[1], { type: 'base64' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(sheet).map((item, index) => ({ ...item, id: index + 1 }))
      if (jsonData && jsonData.length > 0) {
        const lowercaseNameColumns = nameColums.map(column => column.toLowerCase())
        const allObjectsHaveKeys = jsonData.every(obj => {
          const objectKeys = Object.keys(obj).map(key => key.toLowerCase())
          return objectKeys.some((value, index, array) => lowercaseNameColumns.includes(value))
        })
        if (allObjectsHaveKeys) {
          console.log('All objects in jsonData have the required keys.')
        } else {
          console.log('Some objects in jsonData do not have the required keys.')
        }
      }
      let _image = [...files]
      _image.push(file)
      setCover(_image)
      setJsonData(jsonData)
    } else {
      let csv = file?.url?.split('data:text/csv;base64,')
      const workbook = XLSX.read(csv[1], { type: 'base64' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(sheet).map((item, index) => ({ ...item, id: index + 1 }))
      if (jsonData && jsonData.length > 0) {
        const lowercaseNameColumns = nameColums.map(column => column.toLowerCase())
        const allObjectsHaveKeys = jsonData.every(obj => {
          const objectKeys = Object.keys(obj).map(key => key.toLowerCase())
          return objectKeys.some((value, index, array) => lowercaseNameColumns.includes(value))
        })
        if (allObjectsHaveKeys) {
          console.log('All objects in jsonData have the required keys.')
        } else {
          console.log('Some objects in jsonData do not have the required keys.')
        }
      }
      let _image = [...files]
      _image.push(file)
      setCover(_image)
      setJsonData(jsonData)
    }
  }

  const handleCrate = async () => {
    let body = []
    dataSelect?.map((value, index, array) => {
      let coords = value?.coords.split(',')
      body.push({
        address: value?.address,
        category_id: value?.category_id,
        channel: dataSelectFromFile,
        complaint_name: value?.comment,
        coordinates: {
          latitude: +coords[1],
          longitude: +coords[0],
        },
        description: '',
        media_url: [value?.photo],
        mobile_number: '0000000000',
        status_data: [
          {
            description: 'รับเรื่อง',
            name: 'received',
            staff_by: '',
          },
        ],
        sub_category_id: value?.sub_category_id,
      })
    })
    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
    axios.post(`${process.env.REACT_APP_BASEURL}/complaint/api/v1/complaint/import`, body, config).then(res => {
      if (res?.data?.code === 201) {
        setModelConfirmeCreate(false)
        props?.openNotification()
        props?.getListData()
        props?.setDrawerImportFile(false)
        setSection(1)
        setCover([])
        setJsonData(null)
        setDataSelect([])
        setSelectedRowKeys([])
        setSelectedCategoryID('')
        setDataSelectFromFile('')
      }
    })
  }

  const getSubCategories = async val => {
    const resSubCategories = await getList({
      schema: `/master/api/v1/all/subcategories/${val ? val : selectedCategoryID}`,
      params: '',
    })
    setMasterData(pre => {
      return {
        ...pre,
        subCategories: resSubCategories?.items,
      }
    })
  }
  const getMasterData = async () => {
    const resCategories = await getList({
      schema: '/master/api/v1/all/categories',
      params: '',
    })

    setMasterData({
      categories: resCategories?.items,
    })
  }

  let checkSub = dataSelect?.every(obj => obj?.hasOwnProperty('sub_category_id'))
  let checkCategory = dataSelect?.every(obj => obj?.hasOwnProperty('category_id'))
  let checkBtn = !dataSelect?.length || !checkSub || !checkCategory

  return (
    <BoxDrawer>
      <Drawer
        open={props?.drawerImportFile}
        closable={false}
        width={section === 1 ? '40%' : section === 2 ? '60%' : '90%'}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={false}
        className="view-pc"
      >
        {/* <TextComponents /> */}
        {section === 1 ? (
          <Content>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Close
                onClick={() => {
                  props?.setDrawerImportFile(false)
                  setSection(1)
                  setCover(null)
                  setJsonData(null)
                  setSelectedRowKeys([])
                  setSelectedCategoryID('')
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '10%' }}>
              <img src={ImportFile} />
              <div className="text-title-import-file">นำเข้าไฟล์เรื่องร้องเรียน</div>
            </div>
            <p>
              อัปโหลดรูปภาพและวิดีโอ <span className="sub-text-upload">(อัปโหลดได้ครั้งละ 1 ไฟล์เท่านั้น)</span>
            </p>
            <CustomUploadfile>
              <UploadFileExcel
                accept={{
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                  'application/vnd.ms-excel': [],
                  'text/csv': [],
                }}
                keys={1}
                textType={'doc'}
                files={cover}
                isCrop={false}
                maxFile={1}
                maxSize={1024 * 1024 * 5}
                typeUpload={'doc'}
                textUpload={'อัปโหลดได้ครั้งละ 1 ไฟล์เท่านั้น เฉพาะ .XLS และ .CSV'}
                onFileRetuen={(files, file) => {
                  handleBase64String(files, file)
                }}
                onFileRemove={(files, res) => {
                  let _image = [...files]
                  _image = _image.filter((r, i) => i !== res.index)
                  setCover(_image)
                  setJsonData(null)
                }}
              />
            </CustomUploadfile>
            <a
              href="https://drive.google.com/uc?export=download&id=1yx491VI_blF-iLFH4amRoh8qwPcxABFQ"
              target="_blank"
              style={{
                color: '#f36b24',
                textDecoration: 'underline',
              }}
            >
              ตัวอย่างไฟล์นำเข้าเรื่องร้องเรียน
            </a>
            <p
              style={{
                marginTop: '20px',
              }}
            >
              ที่มาของไฟล์{' '}
            </p>
            <div>
              <Select
                style={{ width: '100%' }}
                options={[
                  { value: 'traffy fondue', label: 'Traffy Fondue' },
                  { value: 'other', label: 'อื่นๆ' },
                ]}
                onChange={setDataSelectFromFile}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '5%' }}>
              <Button
                className={!cover?.length || !dataSelectFromFile ? 'btn-confirm-file-disable' : 'btn-confirm-file'}
                onClick={() => setSection(2)}
                disabled={!cover?.length || !dataSelectFromFile}
              >
                ยืนยัน
              </Button>
            </div>
          </Content>
        ) : section === 2 ? (
          <Content>
            <p>เชื่อมโยงข้อมูล (Map field)</p>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                marginTop: '5%',
                backgroundColor: '#F9F9F9',
                height: '40px',
                borderRadius: '10px',
                alignItems: 'center',
                padding: '0 10px',
                textAlign: 'left',
              }}
            >
              {headerColums?.map((value, index, array) => {
                return (
                  <div>
                    <TextTH>{value}</TextTH>
                  </div>
                )
              })}
            </div>
            <BoxColums>
              <div>
                {nameColums?.map((value, index, array) => {
                  return (
                    <div>
                      <BoxNameColums>{value}</BoxNameColums>
                    </div>
                  )
                })}
              </div>

              <div>
                <div>
                  {jsonData?.map((value, index, array) => {
                    return (
                      index === 0 && (
                        <div>
                          <BoxNameColums>{value?.ticket_id}</BoxNameColums>
                          <BoxNameColums>{value?.comment}</BoxNameColums>
                          <BoxNameColums>{value?.type}</BoxNameColums>
                          <BoxNameColums>{value?.coords}</BoxNameColums>
                          <BoxNameColums>{value?.timestamp?.split('.')[0]}</BoxNameColums>
                          <BoxNameColums>{value?.state}</BoxNameColums>
                          <BoxNameColums>
                            <span>{value?.photo}</span>
                          </BoxNameColums>
                        </div>
                      )
                    )
                  })}
                </div>
              </div>
            </BoxColums>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '5%', gap: '10px' }}>
              <Button
                className="btn-go-black"
                onClick={() => {
                  props?.setDrawerImportFile(false)
                  setSection(1)
                  setCover(null)
                  setJsonData(null)
                  setSelectedRowKeys([])
                  setSelectedCategoryID('')
                }}
              >
                ยกเลิก
              </Button>
              <Button className="btn-confirm-file" onClick={() => setSection(3)}>
                ถัดไป
              </Button>
            </div>
          </Content>
        ) : (
          <Content>
            <p>
              <img src={IconBlack} onClick={() => setSection(2)} style={{ cursor: 'pointer' }} />
              <span style={{ fontSize: '20px', fontWeight: '500', color: '#150F0B' }}>
                เลือกข้อมูลและกรอกรายละเอียดเพิ่มเติม
              </span>
            </p>
            <TableCustom
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns(
                masterData,
                handleCategoryChange,
                handleSubCategoryChange,
                getSubCategories,
                selectedCategoryID
              )}
              dataSource={jsonData}
              pagination={false}
              scroll={{
                y: 600,
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2%', gap: '10px' }}>
              <Button
                className="btn-go-black"
                onClick={() => {
                  props?.setDrawerImportFile(false)
                  setSection(1)
                  setCover(null)
                  setJsonData(null)
                  setSelectedRowKeys([])
                  setSelectedCategoryID('')
                }}
              >
                ยกเลิก
              </Button>
              <Button
                className={checkBtn ? 'btn-confirm-file-disable' : 'btn-confirm-file'}
                onClick={() => setModelConfirmeCreate(true)}
                disabled={checkBtn}
              >
                นำเข้าเรื่องเรียน
              </Button>
            </div>
          </Content>
        )}
      </Drawer>
      <CustomModal
        transitionName=""
        centered
        open={modelConfirmeCreate}
        onOk={() => {
          handleCrate()
        }}
        onCancel={() => setModelConfirmeCreate(false)}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">ยืนยันการนำเข้าข้อมูลเรื่องร้องเรียน?</p>
        <p className="modal-message">หากคุณนำเข้าข้อมูลเรื่องร้องเรียนนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้</p>
      </CustomModal>
    </BoxDrawer>
  )
}
